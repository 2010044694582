import { Box, Breadcrumbs, Stack, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const SummaryBreadcrumbs = ({ steps, colors }) => {
  return (<Breadcrumbs separator={<></>} sx={{
    // m: 1,
    // p: 1,
    ol: { alignItems: "flex-start", justifyContent: "center", "& .MuiBreadcrumbs-separator": { mx: -1 } }
  }}>
    {steps.map(({ title, link, count, prevCount, subtitle, caption, titleOnly }, index) =>
      <Stack sx={{ py: 1, px: "2px", position: "relative" }} spacing={1}>
        <Box sx={{
          top: theme => theme.spacing(2),
          left: 0,
          width: "100%",
          zIndex: -1,
          position: "absolute",
          border: "2px solid #f46d3c",
          bgcolor: "#f46d3c",
          clipPath: index > 0 ? "polygon(calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%, 20px 50%, 0% 0%)" : "polygon(calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%, 0 50%, 0% 0%)",
          height: "40px",
          borderTopLeftRadius: index === 0 ? "20px" : "0",
          borderBottomLeftRadius: index === 0 ? "20px" : "0",
          borderTopRightRadius: index === steps.length - 1 ? "20px" : "0",
          borderBottomRightRadius: index === steps.length - 1 ? "20px" : "0",
          px: 5
          // pr: index === steps.length - 1 ? 4 : 1
        }} />
        <Stack alignItems={"center"} direction={"row"} sx={{
          position: "relative",
          border: "2px solid #f46d3c",
                bgcolor: count !== undefined ? subtitle === "Witnesses" ? (count > 1 ? "#f46d3c" : "white" ) : count > 0 ? "#f46d3c" : "white" : "white",
          clipPath:
            index > 0 ?
              index === steps.length - 1 ?
                "polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 20px 50%, 0% 0%)"
                :
                "polygon(calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%, 20px 50%, 0% 0%)"
              :
              "polygon(calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%, 0 50%, 0% 0%)",
          height: "40px",
          borderTopLeftRadius: index === 0 ? "20px" : "0",
          borderBottomLeftRadius: index === 0 ? "20px" : "0",
          borderTopRightRadius: index === steps.length - 1 ? "20px" : "0",
          borderBottomRightRadius: index === steps.length - 1 ? "20px" : "0",
          px: 2.5
          // pr: index === steps.length - 1 ? 4 : 1
        }}>
          {/* <Box sx={{
            position: "relative",
            mr: index === 0 ? 0 : 3,
            width: 0,
            height: 0,
            borderTop: "20px solid transparent",
            borderBottom: "20px solid transparent",
            borderLeft: `20px solid ${index > 0 && (prevCount === undefined || prevCount > 0)  ? '#f46d3c' : "transparent"}`
          }} /> */}
          {link ? <Link underline={"none"} component={RouterLink} to={link}><Typography
                    color={count !== undefined ? subtitle === "Witnesses" ? (count > 1 ? "white" : "#f46d3c") : count > 0 ? "white" : "#f46d3c" : "#f46d3c" } textAlign={"center"} fontWeight={"bold"}
            variant={"caption"}>{title}</Typography></Link> : <Typography
            color={count === undefined || count > 0 ? "white" : "#f46d3c"} textAlign={"center"} fontWeight={"bold"}
            variant={"caption"}>{title}</Typography>}
        </Stack>
        {!titleOnly && <>
          <Typography textAlign={"center"} variant={"caption"}>{caption}</Typography>
          <Typography textAlign={"center"} color={"#f46d3c"} variant={"h6"}>{count || "-"}</Typography>
          <Typography textAlign={"center"} color={"#f46d3c"} variant={"body"}>{subtitle}</Typography>
        </>}
      </Stack>)}
  </Breadcrumbs>);
}
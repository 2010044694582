import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Chip, TextField, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import axios from "axios";
import toastr from "toastr";
// mocks_
import Swal from "sweetalert2";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import account from '../../../_mock/account';
import { UserContext } from "../DashboardLayout";
import CenterModal from "../../../components/CenterModal";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    //   {
    //     label: 'My Wills',
    //     icon: 'eva:person-fill',
    //   },
    {
        id: 'delete_account',
        label: 'Delete Account',
        icon: 'eva:settings-2-fill',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const [open, setOpen] = useState(null);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(null);
    const [allowDelete, setAllowDelete] = useState(false);
    const [checkedAgreeOne, setCheckedAgreeOne] = useState(false);
    const [checkedAgreeTwo, setCheckedAgreeTwo] = useState(false);
    const [checkedAgreeThree, setCheckedAgreeThree] = useState(false);
    const navigate = useNavigate();
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (id) => {
        console.log('id', id);
        if (id === 'delete_account') {
            setAllowDelete(false);
            setCheckedAgreeOne(false);
            setCheckedAgreeTwo(false);
            setCheckedAgreeThree(false);
            setOpenDeleteAccount(true);
        }
        setOpen(null);
    };

    const { user } = useContext(UserContext);

    const deleteAccount = () => {
        // console.log('deleteAccount', user.email);
        if (allowDelete) {
            axios.delete(`/testator/account`).then(response => {
                setOpenDeleteAccount(false);
                // toastr.success("Account deleted successfully");
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Account deleted successfully",
                    showConfirmButton: false,
                    timer: 3000
                });
                navigate('/login', { replace: true });
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('profile_saved');
                sessionStorage.removeItem('beneficiary');
                sessionStorage.removeItem(`previousGenericbeneficiaryData`);
                sessionStorage.removeItem(`previousGenericexecutorData`);
                sessionStorage.removeItem(`previousGenericwitnessData`);
                sessionStorage.removeItem(`previousAssetData`);
                sessionStorage.removeItem(`previousProfileData`);
                sessionStorage.removeItem(`video_close_distribution`);
                sessionStorage.removeItem(`video_close_executors`);
                sessionStorage.removeItem(`video_close_assets`);
                sessionStorage.removeItem(`video_close_beneficiaries`);
                sessionStorage.removeItem(`video_close_witnesses`);
                sessionStorage.removeItem(`video_close_preview`);
                sessionStorage.removeItem(`video_close_app`);
                // axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', { replace: true }))

            }).catch(err => {
                // toastr.error("Failed to delete account")
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Failed to delete account",
                    showConfirmButton: false,
                    timer: 3000
                });
            });
        }
        else {
            // toastr.error("Please agree on statements");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Please agree on statements",
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    const handleAgreeCheck = (event) => {
        const { name, value } = event.target;
        // console.log(name,value);
        if (name === 'checkedAgreeOne') {
            setCheckedAgreeOne(value);
        }
        if (name === 'checkedAgreeTwo') {
            setCheckedAgreeTwo(value);
        }
        if (name === 'checkedAgreeThree') {
            setCheckedAgreeThree(value);
        }
    }

    useEffect(() => {
        if (checkedAgreeOne && checkedAgreeTwo && checkedAgreeThree) {
            setAllowDelete(true);
        }
    }, [checkedAgreeOne, checkedAgreeTwo, checkedAgreeThree]);

    return (
        <>
            {/* <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton> */}
            <Button onClick={handleOpen}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    flexWrap: "wrap",
                    textTransform: user?.full_name ? 'uppercase' : 'lowercase',
                    "& .MuiListItemText-root": {
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    },
                    "&.active": {
                        color: "text.primary",
                        borderLeft: '8px solid',
                        borderColor: 'primary.main',
                        bgcolor: "action.selected",
                        fontWeight: "fontWeightBold"
                    },
                    "& .MuiButton-endIcon": {
                        marginLeft: 0
                    }

                }}>
                {user?.full_name ? user?.full_name : user?.email}
            </Button>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {/* <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
                {user?.full_name ?? user?.name ?? 'Username'}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
            </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} /> */}
                {user.role === 'testator' && (
                    <Stack sx={{ p: 1 }}>
                        {MENU_OPTIONS.filter((option) => option.id === 'delete_account' && user.role === 'testator').map((option) => (
                            <MenuItem key={option.id} onClick={() => handleClose(option.id)}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Stack>
                )}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={() => {
                    axios.post('/auth/logout').then(() => {
                        // toastr.success('Logged out!')
                        Swal.fire({
                            position: "top-center",
                            icon: "success",
                            color: '#fff',
                            background: '#00838F',
                            toast: true,
                            title: "Logged out!",
                            showConfirmButton: false,
                            timer: 3000
                        });
                        sessionStorage.removeItem('profile_saved');
                        sessionStorage.removeItem('profile');
                        sessionStorage.removeItem('beneficiary');
                        sessionStorage.removeItem(`previousGenericbeneficiaryData`);
                        sessionStorage.removeItem(`previousGenericexecutorData`);
                        sessionStorage.removeItem(`previousGenericwitnessData`);
                        sessionStorage.removeItem(`previousAssetData`);
                        sessionStorage.removeItem(`previousProfileData`);
                        sessionStorage.removeItem(`video_close_distribution`);
                        sessionStorage.removeItem(`video_close_executors`);
                        sessionStorage.removeItem(`video_close_assets`);
                        sessionStorage.removeItem(`video_close_beneficiaries`);
                        sessionStorage.removeItem(`video_close_witnesses`);
                        sessionStorage.removeItem(`video_close_preview`);
                        sessionStorage.removeItem(`video_close_app`);
                    }).finally(() => navigate('/login', { replace: true }))
                }} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
            <CenterModal
                open={openDeleteAccount}
                onClose={() => {
                    setOpenDeleteAccount(false);
                }}
                centered
                title={`Confirm Delete Account`}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                    <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                        <Typography component={"h3"} fontWeight={"bold"} fontSize={20}
                            sx={{ color: "text.secondary" }}>
                            Are you sure you want to delete your account?
                        </Typography>
                        <br />
                        <Typography component={"h3"} fontWeight={"bold"} fontSize={20}
                            sx={{ color: "text.secondary" }}>
                            If you proceed,
                        </Typography>
                        <br />
                        <FormGroup>
                            <FormControlLabel checked={checkedAgreeOne} name='checkedAgreeOne' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your user data will be permanently deleted, including login credentials and any other personal information associated with your account." />
                            <br />
                            <FormControlLabel checked={checkedAgreeTwo} name='checkedAgreeTwo' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your subscriptions will be terminated. If you wish to use our service again in the future, you will need to sign up and register as a new user." />
                            <br />
                            <FormControlLabel checked={checkedAgreeThree} name='checkedAgreeThree' onChange={handleAgreeCheck} required control={<Checkbox />} label="If you have created any wills using our service, they will be deleted from our system as part of the account deletion process. To ensure you have a copy for your records, we recommend saving a digital and printed copy if you have not already done so." />
                        </FormGroup>
                        <br />
                    </Stack>
                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>

                    <Button onClick={() => {
                        setOpenDeleteAccount(false);
                    }}>Cancel</Button>
                    <Button style={{ marginTop: "8px" }} disabled={!allowDelete} onClick={() => {
                        deleteAccount();
                    }} variant="contained">
                        Delete
                    </Button>
                </Stack>
            </CenterModal>
        </>
    );
}

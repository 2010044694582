import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
import { filter, groupBy, property } from "lodash";
// @mui
import { useTheme } from "@mui/material/styles";
import {
    Grid,
    Container,
    Typography,
    Card,
    CardContent,
    CardActions,
    IconButton,
    TableContainer,
    TablePagination,
    Button,
    MenuItem, Popover, Stack, Table, TableHead, TableRow, TableCell, TableBody, Box, TextField, Modal
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
// components
import axios from "axios";
import toastr from 'toastr';
import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { Link } from 'react-router-dom';

import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import Swal from "sweetalert2";
import { CheckCircle, Close, Download, ViewAgenda, Visibility } from "@mui/icons-material";
import Iconify from "../components/iconify";
import { LoadingContext } from "../App";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import Scrollbar from "../components/scrollbar";

// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates
} from "../sections/@dashboard/app";
import { UserContext } from "../layouts/dashboard/DashboardLayout";

// ----------------------------------------------------------------------
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const TABLE_HEAD = [
    { id: "user", label: "User", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "comment", label: "Comment", alignRight: false },
    // { id: "nominee", label: "Nominee", alignRight: false, alignCenter: true },
    { id: "action", label: "View" }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy === 'comment' ? 'type' : orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy === 'comment' ? 'type' : orderBy);
}


function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    console.log('query', query);
    if (query) {

        console.log('query', filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1));
        return filter(array, (_user) => {
            const { review, ReviewerId, ...userWithoutReview } = _user;
            const userWithoutReviewModified = { ...userWithoutReview, Testator: { fullName: _user.Testator?.full_name, email: _user.Testator?.email } };
            return JSON.stringify(userWithoutReviewModified ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1
        });
    }
    return stabilizedThis.map((el) => el[0]);
}
export default function ReviewerDashboardAppPage() {
    const theme = useTheme();
    const { user, fetchUser } = useContext(UserContext);


    const [profile, setProfile] = useState(user?.profile ?? {});

    useEffect(() => {
        setProfile(user?.profile ?? {});
        if (user.Requests) {
            setRequests(user.Requests);
        }
    }, [user]);


    const [open, setOpen] = useState(null);
    const [reviewId, setReviewId] = useState(null);
    const [comment, setComment] = useState("");

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("desc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("createdAt");

    const [filterName, setFilterName] = useState("");
    const [requests, setRequests] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    const activeReview = useMemo(() => {
        return user?.Reviews?.find(rev => rev.status !== "CLOSED");
    }, [user]);

    useEffect(() => {

        if (user?.role === "testator" && !sessionStorage.getItem("video_close_app") && !isLoading) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading]);

    const closeRequest = useCallback(() => {
        axios.patch(`/wills/draft/review/${reviewId}`, { comment }).then(res => {
            // toastr.success("Request closed successfully")
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Request closed successfully`,
                showConfirmButton: false,
                timer: 3000
            });
        }).catch(e => {
            // toastr.error("Failed to close request")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to close request`,
                showConfirmButton: false,
                timer: 3000
            });
        }).finally(() => {
            setOpen(false);
            fetchUser();
        });
    }, [reviewId]);
    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort', property);
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;
    console.log('user.Requests', order, orderBy);
    const filteredRequests = applySortFilter(requests, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredRequests.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Dashboard | Get Will Done </title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Requests Summary
                </Typography>
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)}><Close /></IconButton>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Close Request
                        </Typography>
                        <TextField label={"Comment"} fullWidth multiline value={comment}
                            onChange={(e) => setComment(e.target.value)} id={"review-comment"} rows={4} />
                        <Button style={{ marginTop: "8px" }} onClick={() => closeRequest()}
                            variant="contained">
                            Close Request
                        </Button>
                    </Box>
                </Modal>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary title="Total reviews" total={user?.Requests?.length ?? 0} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary title="Open reviews"
                                    total={user?.Requests?.filter(req => req.status === "REQUESTED")?.length ?? 0} color="info"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <AppWidgetSummary title="Closed reviews"
                                    total={user?.Requests?.filter(req => req.status === "CLOSED")?.length ?? 0} color="info"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant={"h5"} component={"h5"}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>Requests</Typography>
                                <div style={{ flexGrow: 1 }}>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                                </div>
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 800 }}>
                                        <Table>
                                            <UserListHead
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={requests.length}
                                                numSelected={selected.length}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {/* {requests?.map(request => { */}
                                                {filteredRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, index) => {
                                                    return <TableRow>
                                                        <TableCell>{request.Testator?.full_name ? request.Testator?.full_name : request.Testator?.email}</TableCell>
                                                        <TableCell>{request.status}</TableCell>
                                                        <TableCell>{request.type === 'NOTARIZATION' && 'Notary at Doorstep'}{request.type === 'REGISTRATION' && 'Register your Will'}{request.type === 'REVIEW' && 'Review by Lawyer'}{request.type === 'SUPPORT' && 'Support Call Requested'}{request.type === 'CALL' && 'Call with Legal Expert'}{request.comment && ` - ${request.comment}`}</TableCell>
                                                        <TableCell>
                                                            <IconButton disabled={request.status === 'CLOSED' || request.type === 'CALL' || request.type === 'SUPPORT'} component={Link} to={`/reviewer/${request.id}/distribution`}><Visibility /></IconButton>
                                                            <IconButton disabled={request.status === 'CLOSED'} onClick={() => {
                                                                setOpen(true);
                                                                setReviewId(request.id);
                                                            }}><CheckCircle /></IconButton>
                                                        </TableCell>
                                                    </TableRow>;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>


                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25,50,100]}
                                    component="div"
                                    count={filteredRequests.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </CardContent>
                        </Card>
                    </Grid>



                </Grid>
            </Container>
        </>
    );
}

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import axios from 'axios';
import { filter } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'role',
    disablePadding: true,
    label: 'Role',
    alignRight: false,
  },
];

const IncompleteWillsPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [incompleteWillsData, setIncompletedWillsData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const navigate = useNavigate();

  const fetchCompletedWillsData = async () => {
    try {
      const response = await axios.get(`/admin/incomplete-wills-count?list=${true}`);
      const { incompleteWillsList } = response.data;
      setIncompletedWillsData(incompleteWillsList || []);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
    }
  };

  useEffect(() => {
    fetchCompletedWillsData();
  }, []);

  const filteredData = useMemo(() => {
    if (filterValue === 'testator') {
      return incompleteWillsData.filter((client) => client.role === 'testator');
    }
    if (filterValue === 'partner') {
      return incompleteWillsData.filter((client) => client.role === 'partner');
    }
    return incompleteWillsData;
  }, [filterValue, incompleteWillsData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - incompleteWillsData.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, incompleteWillsData, filterName, filterValue]
  );

  const handleBackClick = () => {
    navigate('/admin/analyticaldashboard');
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: '24px' }}>
      <Helmet>
        <title> Incomplete Wills | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: '24px' }}>
        <IconButton color="primary" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Incomplete Wills</Typography>
        <div style={{ flexGrow: 5 }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </div>
        <FormControl sx={{flexGrow: 1}}>
          <InputLabel>Role</InputLabel>
          <Select value={filterValue} onChange={handleFilterChange} label="Role">
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="testator">Testator/Direct</MenuItem>
            <MenuItem value="partner">Partner</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell component="th" scope="row" padding="none">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.role}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {incompleteWillsData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      No Wills Data Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

export default IncompleteWillsPage;
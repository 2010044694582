import { useEffect, useState } from 'react';
import axios from 'axios';
import { startCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const TopPerformingPartnerCard = () => {
  const [topPerformingPartnerData, setTopPerformingPartnerData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopPerformingPartner = async () => {
      try {
        const response = await axios.get('/admin/top-performing-partner');
        setTopPerformingPartnerData(response?.data || {});
      } catch (error) {
        console.error('Error fetching top performing data:', error);
      }
    };
    fetchTopPerformingPartner();
  }, []);

  const redirectPage = () => {
    navigate('/admin/top-performer-page');
  };

  return (
    <DashboardCard
      title="Top Performing Partner"
      subtitle={startCase(topPerformingPartnerData?.partner_name) || '--'}
      handleClick={redirectPage}
      isClickable
    />
  );
};

export default TopPerformingPartnerCard;

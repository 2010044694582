import { useState } from "react";
import { Box, IconButton, Stack, TextField, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OtpInput from "react-otp-input";

export const OTPField = ({ value, onChange }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  return <Stack direction={'row'} spacing={1}>
    <OtpInput containerStyle={{ justifyContent: "space-around", flexGrow: 1 }} inputStyle={{
      fontSize: theme.typography.fontSize,
      width: theme.spacing(5),
      padding: theme.spacing(1.5)
    }} value={value} onChange={onChange} numInputs={6} isInputNum isInputSecure={!showPassword} />
    <IconButton onClick={() => setShowPassword(show => !show)}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
  </Stack>
};
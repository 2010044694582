import { withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, Box, Button, FormControl, Popper, TextField } from "@mui/material";
import { and, isEnumControl, or, rankWith, scopeEndsWith } from "@jsonforms/core";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import "react-phone-input-material-ui/lib/high-res.css";
import { useDebouncedChange } from "@jsonforms/material-renderers";
import rawCountries from "../utils/rawCountries";

const countries = rawCountries.map(c => ({ country: c[0], code: c[2], dial: c[3] })).filter(c => !!c.dial);


const eventToValue = (ev) => ev.target.value === "" ? undefined : ev.target.value;

export const PhoneField = ({ value, handleChange, label, enabled, errors, required, ...props }) => {
    const [val, setVal] = useState(value);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputText, onChange] = useState(undefined);
    const [error, setError] = useState(null);
    const [helperText, setHelperText] = useState(null);
    // const [inputText, onChange, onClear] = useDebouncedChange(handleChange, "", value, path, eventToValue);


    useEffect(() => {
        if (inputText) {
            const [code, dial, phone] = inputText?.split(":");
            if (code && dial && phone) {
                setVal(phone);
                selectCountry(countries.find(c => c.code === code) ?? {
                    country: "India",
                    code: "in",
                    dial: "91"
                });
            } else {
                setVal("");
                selectCountry({
                    country: "India",
                    code: "in",
                    dial: "91"
                });
            }
        } else {
            setVal("");
            selectCountry({
                country: "India",
                code: "in",
                dial: "91"
            });
        }
    }, [inputText]);

    const [country, selectCountry] = useState({
        country: "India",
        code: "in",
        dial: "91"
    });

    const toggleMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(o => !o);
    };


    return <Box className={"react-tel-input"}
        sx={{ width: "100%", "input": { height: '56px!important', pl: "0!important" }, ".flag": { pl: 0 } }}>
        <TextField
            sx={{ '& :disabled': { cursor: 'default!important' } }}
            fullWidth
            required={required} label={label}
            variant={"outlined"}
            type="number"
            value={val}
            disabled={!enabled}
            helperText={helperText}
            error={error}
            onChange={e => {
                if (e.target.value) {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                        const value = `${country.code}:${country.dial}:${e.target.value}`;
                        onChange(value);
                        handleChange({ target: { value: `${country.code}:${country.dial}:${e.target.value}` } });
                        if (['in', 'us', 'gb'].includes(country.code)) {
                            if (['in', 'us'].includes(country.code) && e.target.value.length !== 10) {
                                setError(true);
                                setHelperText('Please enter a valid phone');
                            }
                            else if (['gb'].includes(country.code) && e.target.value.length !== 11) {
                                setError(true);
                                setHelperText('Please enter a valid phone');
                            }
                            else {
                                setError(null);
                                setHelperText(null);
                            }
                        }
                        else if (e.target.value.length < 7 || e.target.value.length > 12) {
                            setError(true);
                            setHelperText('Please enter a valid phone');
                        }
                        else {
                            setError(null);
                            setHelperText(null);
                        }
                    }

                } else {
                    onChange(undefined);
                    handleChange({ target: { value: "" } });
                }
            }}
            InputProps={{
                startAdornment: <Button disabled={!enabled} size={"small"} startIcon={<div className={`flag ${country.code}`} />} variant={"text"}
                    sx={{ color: 'black' }} onClick={toggleMenu}>+{country.dial}</Button>
            }}
        />
        <Popper sx={{ zIndex: 1301 }} placement={"bottom-start"} open={open} anchorEl={anchorEl}>
            <Box sx={{ width: 300, background: "white", p: 2 }}>
                <Autocomplete
                    open
                    getOptionLabel={(option) => option.country}
                    value={country}
                    onChange={(e, v) => {
                        console.log('v', v ?? v);
                        selectCountry(v ?? {
                            country: "India",
                            code: "in",
                            dial: "91"
                        });
                        if (val)
                            onChange(`${v.code}:${v.dial}:${val}`);

                        if (['in', 'us', 'gb'].includes(v.code)) {
                            if (['in', 'us'].includes(v.code) && val.length !== 10) {
                                setError(true);
                                setHelperText('Please enter a valid phone');
                            }
                            else if (['gb'].includes(v.code) && val.length !== 11) {
                                setError(true);
                                setHelperText('Please enter a valid phone');
                            }
                            else {
                                setError(null);
                                setHelperText(null);
                            }
                        }
                        else if (val.length < 7 || val.length > 12) {
                            setError(true);
                            setHelperText('Please enter a valid phone');
                        }
                        else {
                            setError(null);
                            setHelperText(null);
                        }

                        handleChange({ target: { value: `${v.code}:${v.dial}:${val}` } });
                        toggleMenu(e);

                    }}
                    renderInput={(params) => <TextField {...params} variant={"outlined"} />}
                    options={countries}
                />
            </Box>
        </Popper>
    </Box>;
};

import { withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, Button, Stack, TextField, Typography } from "@mui/material";
import { and, isEnumControl, rankWith, schemaTypeIs, scopeEndsWith } from "@jsonforms/core";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Iconify from "../iconify";

const ImagePicker = ({ data, handleChange, path, id, schema, label }) => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(data);
    useEffect(() => {
        setPreview(data);
    }, [data]);

    useEffect(() => {
        if (data) handleChange(path, data);
    }, []);

    useEffect(() => {
        if (!!file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreview(e.target.result);
                handleChange(path, e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);
    return <Stack alignItems={"center"}>
        <Typography variant="body2">{`File Upload Limit: 2 MB`}</Typography>
        {preview && <img style={{ width: "50%" }} src={preview} alt={""} />}
        {preview && <Button onClick={() => {
            setFile(null);
            setPreview(null);
            handleChange(path, '');
        }} startIcon={<Iconify icon={"fluent:delete-32-regular"} />}>Remove</Button>}
        {!preview &&
            <>
                <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                >
                    {label}
                    <input
                        onChange={(e) => {
                            if (!e.target.files) return;

                            const selectedFile = e.target.files[0];
                            console.log('selectedFile', selectedFile);
                            if (selectedFile) {
                                const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
                                if (!allowedTypes.includes(selectedFile.type)) {
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "warning",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `Only PNG, JPG, and JPEG files are allowed`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                } else if (selectedFile.size > 2000000) {
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "warning",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `File size should not be greater than ${2} MB`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                }
                                else {
                                    setFile(selectedFile);
                                }
                            }
                            // setFile(e.target.files[0]);
                        }}
                        type="file"
                        accept={"image/*"}
                        hidden
                    />
                </Button>
            </>}
    </Stack>;
};

export const ImagePickerControl = withJsonFormsControlProps(ImagePicker);

// eslint-disable-next-line no-prototype-builtins
export const ImagePickerTester = rankWith(3, and(schemaTypeIs("string"), scopeEndsWith("add_picture")));
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const usePreventScreenshot = () => {
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handleKeyDown = (event) => {
            if (
                event.key === 'PrintScreen' ||
                (event.ctrlKey && event.key === 'p') ||
                (event.ctrlKey && event.shiftKey && event.key === 's') || // Ctrl+Shift+S
                (event.metaKey && event.key === 'p') || // Cmd+P on macOS
                event.code === 'PrintScreen' // Detect PrintScreen by code
            ) {
                event.preventDefault();
                Swal.fire({
                    position: 'top-center',
                    icon: 'warning',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Screenshot and printing are disabled!',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        };

        const handleKeyUp = (event) => {
            // Blocking default PrintScreen key
            if (event.key === 'PrintScreen' || event.code === 'PrintScreen') {
                navigator.clipboard.writeText('');
                Swal.fire({
                    position: 'top-center',
                    icon: 'warning',
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: 'Screenshot and printing are disabled!',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        };

        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);
};

export default usePreventScreenshot;
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";
//
import { useContext, useMemo } from "react";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { UserContext } from "../../layouts/dashboard/DashboardLayout";

// ----------------------------------------------------------------------

NavSection.propTypes = {
    data: PropTypes.array
};

export default function NavSection({ data = [], isGrayedOut, disabled, ...other }) {
    const { user } = useContext(UserContext);

    const isProfileComplete = useMemo(() => {
        // if(user?.profile === null){
        //     return true;
        // }
        // return false;

        if (user.role === 'testator') {
            if (user.profile === null) {
                return false;
            }
            if (user.profile.salutation) {
                return true;
            }
            return false;
        }
        if (user.role === 'partner') {
            if (user.profile === null) {
                return false;
            }
            if (user.profile.dob) {
                return true;
            }
            return false;
        }
        return false;
    }, [user]);

    return (
        <Box style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
        }} {...other}>
            <List disablePadding sx={{ p: 1, mt: 8 }}>
                {data.filter(item => {
                    // item.role.includes(user?.role) || !item.role

                    // Check if the user role is included in the item's role array or if the item's role array is empty
                    const roleMatch = item.role.includes(user?.role) || item.role.length === 0;

                    // Check if the item's path is 'dashboard/commission' and if the user's role is 'partner' and partner_enroll_status is 'APPROVED'
                    const isPartnerApproved = item.path === '/dashboard/commission' && user?.role === 'partner' && user?.partner_enroll_status === 'APPROVED';

                    // Return true if either the role matches or the item's role array is empty,
                    // and if the item's path is not 'dashboard/commission' or if the user's role is 'partner' and partner_enroll_status is 'APPROVED'

                    const isShowPartnerDashboard = item.title === "Partner Dashboard" && user?.partner_enroll_status === 'APPROVED';

                    if (item.title === "Partner Dashboard") {
                        return roleMatch && isShowPartnerDashboard;
                    }
                    return roleMatch && (item.path !== '/dashboard/commission' || isPartnerApproved);

                }).map((item) => (
                    <NavItem key={item.title} item={item} disabled={disabled} isGrayedOut={['Partner Dashboard', 'profile', 'dashboard', 'Reports', 'Coupons', 'Advisors/Partners', 'Admin Dashboard', 'Partner'].includes(item.title) ? false : item.title === 'Services' ? !isProfileComplete : isGrayedOut} />
                ))}
            </List>
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object
};

function NavItem({ item, isGrayedOut, disabled }) {
    const { title, path, icon, info } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            disabled={title === 'profile' ? false : disabled}
            to={path}
            isGrayedOut={isGrayedOut}
            sx={{
                flexWrap: "wrap",
                height: "fit-content",
                fontSize: 11,
                "& .MuiListItemText-root": {
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontSize: 16
                },
                "&.active": {
                    color: "text.primary",
                    borderLeft: '8px solid',
                    ml: -1,
                    borderColor: 'primary.main',
                    bgcolor: "action.selected",
                    fontWeight: "fontWeightBold"
                }
            }}
        >
            <StyledNavItemIcon isGrayedOut={isGrayedOut}>{icon && icon}</StyledNavItemIcon>

            <ListItemText disableTypography primary={title} />

            {info && info}
        </StyledNavItem>
    );
}

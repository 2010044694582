import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Box, Stack, Tabs, Tab, Switch, Link, FormGroup, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { addDays, getYear } from "date-fns";
import axios from "axios";
import Swal from "sweetalert2";
import { UserContext } from "../DashboardLayout";
import CenterModal from "../../../components/CenterModal";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const GDPR = () => {
    const [openChooseCookies, setOpenChooseCookies] = useState(false);
    const { user, fetchUser } = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [analyticsCookie, setAnalyticsCookie] = useState(false);
    const [targetingCookie, setTargetingCookie] = useState(false);
    useEffect(() => {
        fetchUser();
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const acceptCookies = (acceptAll) => {
        // console.log('deleteAccount', user.email);
        axios.post(`/testator/consent`, { analyticsCookieAccepted: acceptAll ? true : analyticsCookie, targetingCookieAccepted: acceptAll ? true : targetingCookie }).then(async (response) => {
            setOpenChooseCookies(false);
            await fetchUser();
            // toastr.success("Account deleted successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Cookies accepted successfully",
                showConfirmButton: false,
                timer: 3000
            });

            // axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', { replace: true }))
        }).catch(err => {
            // toastr.error("Failed to delete account")
            setOpenChooseCookies(false);
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to accept cookies",
                showConfirmButton: false,
                timer: 3000
            });
        });
    }

    return (
        <Grid container sx={{
            mt: 5,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            padding: 1,
            backgroundColor: '#ccc',
            zIndex: 9999
            // backgroundColor: '#f0f0f0',
            // padding: '10px',
        }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="body2">{`This website uses cookies, details of which are set out in our`}<Link href="https://getwilldone.com/privacy-policy" target='_blank' variant="body2">
                    {' privacy policy'}
                </Link>. {`By continuing to access this website you agree to the terms of our privacy policy.`}<Button onClick={() => setOpenChooseCookies(true)}>Choose Cookies</Button>
                    <Button variant={"contained"} onClick={() => {
                        acceptCookies(true);
                    }}>Accept Cookies</Button>
                </Typography>
            </Grid>
            {/* <Grid item xs={4}>
                <Typography variant="body2">GDPR Column 2</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">GDPR Column 3</Typography>
            </Grid> */}

            <CenterModal
                open={openChooseCookies}
                onClose={() => {
                    setOpenChooseCookies(false);
                }}
                fullwidth
                title={`Choose Cookies`}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                >
                    <Tabs
                        orientation="vertical"
                        // variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '245px' }}
                    >
                        {/* <Tab label="Consent" {...a11yProps(0)} /> */}
                        <Tab label="Strictly Essential" {...a11yProps(0)} />
                        <Tab label="Analytics" {...a11yProps(1)} />
                        <Tab label="Targeting" {...a11yProps(2)} />

                    </Tabs>
                    {/* <TabPanel value={value} index={0}>
                        <Typography variant="h4">
                            Consent
                        </Typography>
                        <Typography variant="body2">
                            By clicking on the “Accept Cookies”, you agree to storing of cookies on your device the terms of the Cookie Policy. When you visit web sites, we may store or retrieve data in your web browser. This storage is often necessary for basic functionality of the website or the storage may be used for the purposes of marketing, analytics, and personalization of the website such as storing your preferences. For more details, read our Privacy Policy.
                        </Typography>
                    </TabPanel> */}
                    <TabPanel value={value} index={0}>
                        <Typography variant="h4">
                            Strictly Essential
                        </Typography>
                        <Typography variant="body2">
                            These cookies are necessary for the website to function and cannot be switched off. These cookies also are necessary for a functionality explicitly requested by you.
                        </Typography>
                        <FormGroup>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Disable</Typography>
                                <AntSwitch disabled defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Enable</Typography>
                            </Stack>
                        </FormGroup>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography variant="h4">
                            Analytics
                        </Typography>
                        <Typography variant="body2">
                            We use Analytical performance cookies, these cookies help us measure the performance of our website and features. They also help us improve our offerings and better serve our customers.
                        </Typography>
                        <FormGroup>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AntSwitch checked={analyticsCookie} onChange={(event) => { console.log('targeting', event.target.checked); setAnalyticsCookie(event.target.checked) }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>{analyticsCookie ? 'Enabled' : 'Disabled'}</Typography>
                            </Stack>
                        </FormGroup>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Typography variant="h4">
                            Targeting
                        </Typography>
                        <Typography variant="body2">
                            These cookies help us market to you on different platforms using our advertising partners.
                        </Typography>
                        <FormGroup>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* <Typography>Disable</Typography> */}
                                <AntSwitch checked={targetingCookie} onChange={(event) => { console.log('targeting', event.target.checked); setTargetingCookie(event.target.checked) }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>{targetingCookie ? 'Enabled' : 'Disabled'}</Typography>
                            </Stack>
                        </FormGroup>
                    </TabPanel>

                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Button onClick={() => {
                        setAnalyticsCookie(true);
                        setTargetingCookie(true);
                    }}>Enable All</Button>
                    <Button style={{ marginLeft: '8px', marginTop: "8px" }} onClick={() => {
                        console.log('acknowledge');
                        // acknowledge();
                        acceptCookies();
                    }} variant="contained">
                        Save Changes
                    </Button>
                </Stack>
            </CenterModal>
        </Grid>
    );
};

export default GDPR;

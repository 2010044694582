import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';
import { fCurrencyRupee } from '../../utils/formatNumber';

const TotalGstPaidCard = () => {
  const [totalGstPaidData, setTotalGstPaidData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGstPaidData = async () => {
      try {
        const response = await axios.get('/admin/total-gst');
        const { totalGSTAmount } = response.data;
        setTotalGstPaidData({ totalGSTAmount });
      } catch (error) {
        console.error('Error fetching total gst paid data:', error);
      }
    };

    fetchGstPaidData();
  }, []);

  const handleClick = () => {
    navigate('/admin/gst-details-page', { state: { fromAdminDashboard: true } });
  };

  return (
    <DashboardCard
      title="Total GST Paid"
      subtitle={fCurrencyRupee(totalGstPaidData.totalGSTAmount || 0)}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default TotalGstPaidCard;

import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');
const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
const last7days = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
const last30days = dayjs().subtract(29, 'day').format('YYYY-MM-DD');
const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
// const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');

const getDate = (timeRange) => {
  switch (timeRange) {
    case 'today':
      return { startDate: today, endDate: today };
    case 'yesterday':
      return { startDate: yesterday, endDate: yesterday };
    case 'last7days':
      return { startDate: last7days, endDate: today };
    case 'last30days':
      return { startDate: last30days, endDate: today };
    case 'thismonth':
      return { startDate: startOfMonth, endDate: today };
    default:
      return null;
  }
};

function generateDateRange(startDate = '', endDate = '') {
  const dates = [];

  // Convert startDate and endDate to Date objects if they are not empty strings
  const currentDate = startDate ? new Date(startDate) : null;
  const endTime = endDate ? new Date(endDate).getTime() : null;

  // Check if currentDate and endTime are valid before proceeding
  while (currentDate && endTime && currentDate.getTime() <= endTime) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export function matchDataWithDateRange(startDate = '', endDate = '', rawData = [], timeRange = '', identifier) {
  let dateRange;
  if (timeRange === 'custom') {
    dateRange = generateDateRange(startDate, endDate);
  } else {
    const { startDate, endDate } = getDate(timeRange);
    dateRange = generateDateRange(startDate, endDate);
  }
  const matchedData = dateRange?.map((date) => {
    const matchingEntry = rawData?.find((entry) => entry.date === date.toISOString().split('T')[0]);
    return matchingEntry || { date: date.toISOString().split('T')[0], [identifier]: 0 };
  });

  return matchedData;
}

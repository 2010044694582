import { sentenceCase } from "change-case";

export const translator = (type) => (key, defaultMessage, value) => {

    if ((value?.error?.instancePath?.endsWith('first_name') || value?.error?.instancePath?.endsWith('middle_name') || value?.error?.instancePath?.endsWith('last_name')) && value?.error?.keyword === 'pattern') {
        return "Only alphabets are allowed";
    }

    if ((value?.error?.instancePath?.endsWith('street') || value?.error?.instancePath?.endsWith('area') && value?.error?.data !== "" || value?.error?.instancePath?.endsWith('city') || value?.error?.instancePath?.endsWith('state') || value?.error?.instancePath?.endsWith('country') || value?.error?.instancePath?.endsWith('spouse_name') || value?.error?.instancePath?.endsWith('fathers_name') || value?.error?.instancePath?.endsWith('middle_name') || value?.error?.instancePath?.endsWith('last_name')) && value?.error?.keyword === 'pattern') {
        return "Only alphabets, numbers, comma(,), dash(-) are allowed";
    }

    if ((value?.error?.instancePath?.endsWith('area'))) {
        return "required field"
    }

    if (value?.error?.instancePath?.endsWith('registered_at') && value?.error?.keyword === 'formatMaximum') {
        return "Registration date needs to be prior to current date";
    }

    if (value?.error?.instancePath?.endsWith('signed_at') && value?.error?.keyword === 'formatMaximum') {
        return "Signing date needs to be prior to current date";
    }

    if (value?.error?.instancePath?.endsWith('registered_office_number') && value?.error?.keyword === 'pattern') {
        return "Please enter only digits";
    }

    if (value?.error?.instancePath?.endsWith('serial_number') && value?.error?.keyword === 'pattern') {
        return "Please enter only digits";
    } 

    if (value?.error?.message.startsWith("should be <=")) {
        return `${sentenceCase(type)} should be older than 18`
    }

    if (value?.error?.message.includes("required")) {
        return "required field";
    }

    if (value?.error?.instancePath?.endsWith('pan') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid PAN";
    }

    if (value?.error?.instancePath?.endsWith('phone') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid mobile number";
    }

    if (value?.error?.instancePath?.endsWith('accountIFSC') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid IFSC Code";
    }

    if (value?.error?.instancePath?.endsWith('email') && value?.error?.keyword === 'format') {
        return "Please enter a valid email";
    }

    if (value?.error?.instancePath?.endsWith('email') || value?.error?.instancePath?.endsWith('email_id') && value?.error?.keyword === 'format') {
        return "Please enter a valid email";
    }
 
    if (value?.error?.instancePath?.endsWith('email') || value?.error?.instancePath?.endsWith('email_id') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid email";
    }

    if (value?.error?.instancePath?.endsWith('email') || value?.error?.instancePath?.endsWith('email_password') && value?.error?.keyword === 'format') {
        return "Password should be min 8 and max 20 char long Alphanumeric";
    }
 
    if (value?.error?.instancePath?.endsWith('email') || value?.error?.instancePath?.endsWith('email_password') && value?.error?.keyword === 'pattern') {
        return "Password should be min 8 and max 20 char long Alphanumeric";
    }

    if (value?.error?.instancePath?.endsWith('gstin') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid GST Identification Number";
    }

    

    if ((value?.error?.instancePath?.endsWith('maturity_date') || value?.error?.instancePath?.endsWith('signed_at') || value?.error?.instancePath?.endsWith('registered_at') || value?.error?.instancePath?.endsWith('dob')) && value?.error?.keyword === 'format') {
        return "Please enter a valid date (DD-MM-YYYY) with hyphen";
    }

    if (value?.error?.instancePath?.endsWith('zip') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid PIN code";
    }

    if (value?.error?.instancePath?.endsWith('registered_at') && value?.error?.keyword === 'formatMinimum') {
        return "Registration date should be same or later than the signing date";
    }

    if (value?.error?.instancePath === '/account_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Account Number";
    }
    if (value?.error?.instancePath === '/share_certificate_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid share Certificate Number";
    }
    if (value?.error?.instancePath === '/policy_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Policy Number";
    }
    if (value?.error?.instancePath === '/patent_registration_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Registration Number / Application Number";
    }
    if (value?.error?.instancePath === '/trademark_registration_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Trademark Registration Number / Application Number";
    }
    if (value?.error?.instancePath === '/copyright_registration_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Trademark Copyright Number / Application Number";
    }
    if (value?.error?.instancePath === '/name' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Domain Name";
    }
    if (value?.error?.instancePath === '/dp_id' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid DP ID";
    }
    if (value?.error?.instancePath === '/client_id' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Client ID";
    }
    if (value?.error?.instancePath === '/folio_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Folio Number";
    }
    if (value?.error?.instancePath === '/number' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Certificate Number";
    }
    if (value?.error?.instancePath === '/account_no_or_client_id' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Account Number or Client ID";
    }
    if (value?.error?.instancePath === '/unique_identification_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Unique Identification Number";
    }
    if ((value?.error?.instancePath === '/uan_number' || value?.error?.instancePath === '/uan_no') && value?.error?.keyword === 'pattern') {
        return "Please enter a valid UAN Number";
    }
    if (value?.error?.instancePath === '/superannuation_number' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Superannuation Number";
    }
    if (value?.error?.instancePath === '/certificate_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Certificate Number";
    }
    if (value?.error?.instancePath === '/investment_amount' && value?.error?.keyword === 'minimum') {
        return "Please enter a investment amount greater than 0";
    }
    if (value?.error?.instancePath === '/prn_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid PRN Number";
    }
    if (value?.error?.instancePath === '/insurance_account_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Insurance Account Number";
    }
    if (value?.error?.instancePath === '/identification_no' && value?.error?.keyword === 'pattern') {
        return "Please enter a valid Identification Number";
    }
    if (value?.error?.instancePath === '/deposited_amount' && value?.error?.keyword === 'minimum') {
        return "Please enter a deposited amount greater than 0";
    }
    if (value?.error?.instancePath === '/weight' && value?.error?.keyword === 'minimum') {
        return "Please enter a Weight greater than 0";
    }
    if (value?.error?.instancePath === '/quantity' && value?.error?.keyword === 'minimum') {
        return "Please enter a Quantity greater than 0";
    }
    if (value?.error?.instancePath === '/interest' && value?.error?.keyword === 'minimum') {
        return "Please enter a Interest greater than 0";
    }
    return defaultMessage;
}
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";

// @mui
import {
    Stack,
    Button,
    Container,
    Typography,
    Grid,
    CardActionArea,
    FormControl,
    Alert,
    InputLabel,
    Select,
    MenuItem,
    CardContent,
    CardActions,
    Checkbox,
    Card, Box, TextField, Backdrop, CircularProgress, FormControlLabel, Chip, List, ListItem, ListItemText, IconButton, FormHelperText,
    useMediaQuery,
    Tooltip,
    useTheme
} from "@mui/material";
import { styled } from '@mui/system';

import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { format } from "date-fns";

import Swal from "sweetalert2";

import toastr from "toastr";
// components
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import CenterModal from "../components/CenterModal";
import { CCFrame } from "../components/CCFrame";
// mock

// ----------------------------------------------------------------------
const OriginalPrice = styled('span')({
    textDecoration: 'line-through',
    color: 'gray', // Adjust the color as needed
    marginRight: '8px', // Add some spacing between the prices
});

const DiscountedPrice = styled('span')({
    color: 'green', // Choose a color for the discounted price
});


export function SubscriptionPage() {
    const { user, fetchUser } = useContext(UserContext);
    const [subscriptions, setSubscriptions] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [offerCoupons, setOfferCoupns] = useState([]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentURL, setPaymentURL] = useState(null);
    const [ccPaymentPopUpOpen, setCcPaymentPopUpOpen] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [offer, setOffer] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [partnerCode, setPartnerCode] = useState('');
    const [appliedCouponCode, setAppliedCouponCode] = useState([]);
    const [appliedPartnerCodeDiscount, setAppliedPartnerCodeDiscount] = useState(0);
    const [selectedOffer, setSelectedOffer] = useState('');
    const [couponCodeError, setCouponCodeError] = useState(null);
    const [partnerCodeError, setPartnerCodeError] = useState(null);
    const [couponCodeApplied, setCouponCodeApplied] = useState(null);
    const [partnerCodeApplied, setPartnerCodeApplied] = useState(null);
    const [disableCouponCode, setDisableCouponCode] = useState(false);
    const [partnerDiscountApplicable, setPartnerDiscountApplicable] = useState(false);
    const [disablePartnerCode, setDisablePartnerCode] = useState(true);
    const [isDemoCoupon, setIsDemoCoupon] = useState(false);
    const [demoActivated, setDemoModeActivated] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:1199px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');

    const theme = useTheme();

    const handleCouponCodeChange = async (event) => {
        const { name, value } = event.target;
        setCouponCode(value);
        console.log('name, value', name, value);
    };
    const handlePartnerCode = async (event) => {
        const { name, value } = event.target;
        console.log('name, value', name, value, Number.isNaN(value));

        if (!Number.isNaN(value)) {

            if (value.length !== 5 && value.length !== 0) {
                setPartnerCodeError("Please enter exactly 5 characters");
            } else {
                setPartnerCodeError(false);
            }
            setPartnerCode(value);
        }
    };
    useEffect(() => {
        axios.get("/subscriptions").then(res => {
            setSubscriptions(res.data?.subscriptions ?? []);
        });

        axios.get("/coupons/active").then(res => {
            setCoupons(res.data?.coupons ?? []);
            console.log(res.data.coupons);
        });
    }, []);

    useEffect(() => {

    }, [offer, couponCode]);

    useEffect(() => {
        console.log('coupons', coupons);
        coupons.map(coupon => console.log('filter condition', coupon.startDate, new Date(coupon.startDate), new Date()));
        if (coupons.length === 0)
            setOfferCoupns([]);
        else
            setOfferCoupns(coupons.filter(coupon => new Date(coupon.startDate) <= new Date().setHours(0, 0, 0, 0) && coupon.type === 'date'));
    }, [coupons]);

    const [checkedSubscriptions, setCheckedSubscriptions] = useState([]);

    const handleBuyClick = (subscription) => {
        console.log('handle', subscription);
        const idExists = checkedSubscriptions.some((item) => item.id === subscription.id);
        console.log('idExists', idExists, checkedSubscriptions);
        if (idExists) {
            // If already checked, remove it from the checkedSubscriptions array
            setCheckedSubscriptions(checkedSubscriptions.filter((item) => item.id !== subscription.id));
        } else {
            // If not checked, add it to the checkedSubscriptions array
            setCheckedSubscriptions([...checkedSubscriptions, subscription]);
        }
        console.log('subscriptionId', subscription);
    };

    // Calculate the total price of selected products
    const calculateTotal = () => {
        console.log('subscriptions', subscriptions, checkedSubscriptions);
        console.log('filtered subscriptions', checkedSubscriptions.filter((subscription) => subscriptions.filter((item) => item.id === subscription.id)));

        return checkedSubscriptions
            .filter((subscription) => subscriptions.filter((item) => item.id === subscription.id))
            .reduce((total, subscription) => {
                let isApplicable = false;
                const { price, basePrice, igstPercentage, cgstPercentage, sgstPercentage } = subscription;
                let offerPrice = +basePrice;
                let originalBasePrice = +basePrice;

                let discount = 0;
                if (appliedCouponCode.length > 0) {
                    isApplicable = appliedCouponCode[0].applicableServices.includes(subscription.id);
                }
                console.log('isApplicable', isApplicable);
                if (isApplicable) {
                    discount = appliedCouponCode[0].discount;
                    offerPrice = ((originalBasePrice * (100 - discount)) / 100);
                }
                else if (partnerDiscountApplicable && subscription.id === 1) {
                    discount = appliedPartnerCodeDiscount;
                    offerPrice = ((originalBasePrice * (100 - discount)) / 100);
                    console.log('partnerDiscountApplicable else', partnerDiscountApplicable, appliedPartnerCodeDiscount, offerPrice)
                }
                // if(appliedPartnerCodeDiscount > 0){
                //     offerPrice = (offerPrice * (100 - appliedPartnerCodeDiscount)) / 100;
                // }

                if (user.profile.permanent.state.toLowerCase() === 'maharashtra' || user.profile.permanent.state.toLowerCase() === 'mh') {
                    originalBasePrice = Math.round((+offerPrice + (+offerPrice * (+cgstPercentage + +sgstPercentage) / 100)));
                    offerPrice = originalBasePrice;
                }
                else {
                    originalBasePrice = Math.round((+offerPrice + ((+offerPrice * (+igstPercentage)) / 100)));
                    offerPrice = originalBasePrice;
                }

                return total + (offerPrice);

            }, 0
            );

    };

    const daysSubscription = useMemo(() => {
        return subscriptions?.find(sub => sub.days > 0) ?? {};
    }, [subscriptions]);

    const subscribe = () => {
        const subscriptionIds = checkedSubscriptions.map((item) => item.id);
        console.log(subscriptionIds);

        axios.post(`/testator/subscribe`, { couponCode, offer, subscriptionIds }).then(res => {
            console.log('cc object', res, res.data, res.data.order_id, res.data.paymentUrl);
            console.log(res.data.order_id);
            const userSubscription = res.data.order_id;

            console.log(res.data.paymentUrl);
            // setPaymentURL(res.data.paymentUrl);
            setShowBackdrop(true);
            // Open CCAvenue in a new tab
            const paymentWindow = window.open(res.data.paymentUrl, '_blank');

            const checkPaymentStatus = (isTimeout = false) => {
                axios.post(`/testator/check/payment/${userSubscription}`).then(res => {
                    console.log('res', res, isTimeout);
                    if (res.data.userSubscription.payment_status !== "PENDING" || isTimeout) {
                        setShowBackdrop(false);
                        clearInterval(interval);
                        clearTimeout(backdropTimeout);
                        switch (res.data.userSubscription.payment_status) {
                            case 'Success':
                                // toastr.success(`Purchase is completed!`, null, {timeOut: 0});
                                Swal.fire({
                                    position: "top-center",
                                    icon: "success",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is completed!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                            case 'Failure':
                                // toastr.error(`Purchase is failed!`, null, { timeOut: 0 });
                                Swal.fire({
                                    position: "top-center",
                                    icon: "error",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is failed!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                            case 'Aborted':
                                // toastr.error(`Purchase is cancelled!`, null, { timeOut: 0 });
                                Swal.fire({
                                    position: "top-center",
                                    icon: "error",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is cancelled!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                            case 'Invalid':
                                // toastr.error(`Purchase is invalid!`, null, { timeOut: 0 });
                                Swal.fire({
                                    position: "top-center",
                                    icon: "error",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is invalid!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                            case 'PENDING':
                                // toastr.warning(`Purchase is still Pending`, "You can reinitiate payment!", { timeOut: 0 });
                                Swal.fire({
                                    position: "top-center",
                                    icon: "warning",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is still Pending, You can reinitiate payment!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                            default:
                                // toastr.error(`Purchase is unknown!`, null, { timeOut: 0 });
                                Swal.fire({
                                    position: "top-center",
                                    icon: "error",
                                    color: '#fff',
                                    background: '#00838F',
                                    toast: true,
                                    title: `Purchase is unknown!`,
                                    showConfirmButton: false,
                                    timer: 15000
                                });
                                break;
                        }

                        calculateTotal();
                        setCheckedSubscriptions([]);
                        removeCouponCode();
                        removePartnerCode();
                        if (res.data.userSubscription.payment_status === 'Success') {
                            fetchUser();
                        }
                    }
                }).catch((e) => {
                    console.log('error', e);
                    clearInterval(interval);
                    setShowBackdrop(false);
                    setCheckedSubscriptions([]);
                    clearTimeout(backdropTimeout);
                    removeCouponCode();
                    removePartnerCode();

                    // toastr.error("Failed to complete request. Please retry.", { timeOut: 0 });
                    Swal.fire({
                        position: "top-center",
                        icon: "error",
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Failed to complete request. Please retry.`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    fetchUser();
                });
            }
            // Periodically check for payment completion
            const interval = setInterval(() => {

                checkPaymentStatus();
                // if (sessionStorage.getItem('paymentCompleted')) {
                //     setShowBackdrop(false);
                //     sessionStorage.removeItem('paymentCompleted');
                //     toastr.success("Your payment was successful", { timeOut: 0 })
                //     // Payment is completed, close the payment window and clear interval
                //     // paymentWindow.close();
                //     clearInterval(interval);
                //     clearTimeout(backdropTimeout);
                // }
            }, 6000);

            // Periodically check for payment completion
            const backdropTimeout = setTimeout(() => {
                setShowBackdrop(false);
                clearInterval(interval);
                // toastr.info("Checking payment status");
                Swal.fire({
                    position: "top-center",
                    icon: "info",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Checking payment status`,
                    showConfirmButton: false,
                    timer: 3000
                });
                checkPaymentStatus(true);
                // navigate('/dashboard/app', {replace: true});
            }, 300000);



            // const { key: rzpKey, amount, id: orderId, currency } = res.data;
            // const options = {
            //     key: rzpKey, // Enter the Key ID generated from the Dashboard
            //     amount: amount.toString(),
            //     currency,
            //     name: "GetWillDone",
            //     description: "Purchase product",
            //     image: "https://beta.getwilldone.com/assets/will_logo.png",
            //     order_id: orderId,
            //     handler: async (response) => {
            //         const data = {
            //             orderCreationId: orderId,
            //             razorpayPaymentId: response.razorpay_payment_id,
            //             razorpayOrderId: response.razorpay_order_id,
            //             razorpaySignature: response.razorpay_signature
            //         };
            //         toastr.info("Checking payment status");
            //         axios.post("/testator/validate_payment", data).then(res => {
            //             toastr.success(`Purchase for "${name}" is completed!${request ? " Your request has been submitted successfully" : ""}`);
            //             fetchUser();
            //         }).catch((e) => {
            //             toastr.error("Failed to complete request. Please contact support for further help.");
            //             fetchUser();
            //         });
            //     },
            //     prefill: {
            //         name: user?.full_name,
            //         email: user?.email,
            //         contact: user?.phone
            //     },
            //     theme: {
            //         color: "#F15D27"
            //     },
            //     config: {
            //         display: {
            //             hide: [
            //                 {
            //                     method: "upi"
            //                 }, {
            //                     method: "paylater"
            //                 }, {
            //                     method: "wallet"
            //                 }, {
            //                     method: "netbanking"
            //                 }
            //             ],
            //             preferences: {
            //                 show_default_blocks: true
            //             }
            //         }
            //     }
            // };
            // const paymentObject = new window.Razorpay(options);
            // paymentObject.open();
        }).catch(e => {
            // toastr.error("Unable to purchase product");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Unable to purchase product`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchUser();
        });
    };

    const [confirmSub, setConfirmSub] = useState({
        open: false,
        title: "",
        text: "",
        sub: {}
    });

    const handleClose = () => {
        setConfirmSub({
            open: false,
            title: "",
            text: "",
            sub: {}
        });
    };

    const registerRequest = (row) => {
        console.log('here in register request')
        let type = "CALL";
        let message = "";
        switch (true) {
            case row.reviews > 0:
                type = "REVIEW";
                message = "I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.";
                break;
            case row.registration > 0:
                type = "REGISTRATION";
                message = "I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.";
                break;
            case row.notarization > 0:
                type = "NOTARIZATION";
                message = "I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for review of the Will.";
                break;
            case row.calls > 0:
                type = "CALL";
                message = `I agree to share my details and copy of my Will with GWD Systems Private Limited and the team of lawyers / associates for the purpose of seeking assistance in relation to creation of Will. <p><strong><span style="color:red">There will be only 1 call & it would last not more than 30 minutes.</span></strong></p>`;
                break;
            default:
                type = "CALL";
                break;
        }

        if (type === "REVIEW" || type === "CALL") {
            setConfirmSub({
                open: true,
                title: `Consent for ${row.name}`,
                // text: (`${row.reviews > 0 ? "Please confirm that your Will document will be shared with the designated Lawyer for Review." : ""} Your request will be submitted after purchase is successfully completed.`),
                text: message,
                sub: row,
                type

            });
        }
        else {
            setConfirmSub({
                open: true,
                title: `Get a Quote for ${row.name}`,
                // text: (`${row.reviews > 0 ? "Please confirm that your Will document will be shared with the designated Lawyer for Review." : ""} Your request will be submitted after purchase is successfully completed.`),
                text: message,
                sub: row,
                type
            });
        }


        // Swal.fire({
        //   title: `Confirm ${sentenceCase(type)} request`,
        //   text: (`${row.reviews > 0 ? "Please confirm that your Will document will be shared with the designated Lawyer for Review." : ""} Your request will be submitted after purchase is successfully completed.`),
        //   icon: "success",
        //   showCancelButton: true,
        //   allowEnterKey: false,
        //   confirmButtonColor: "#F15D27",
        //   cancelButtonColor: "#F15D27",
        //   confirmButtonText: "Yes, Purchase!"
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     subscribe({ ...row, request: true });
        //   }
        // });

        // axios.patch("/wills/draft/review", { type }).then(res => {
        //   if (row.reviews > 0) {
        //     Swal.fire({
        //       title: "Confirm Review",
        //       text: (`Please confirm that your Will document will be shared with the designated Lawyer for Review.`),
        //       icon: "success",
        //       showCancelButton: true,
        //       allowEnterKey: false,
        //       confirmButtonColor: "#F15D27",
        //       cancelButtonColor: "#F15D27",
        //       confirmButtonText: "Yes, Purchase!"
        //     }).then((result) => {
        //       if (result.isConfirmed) {
        //         subscribe(row.id);
        //       }
        //     });
        //   }
        // }).catch(e => {
        //   toastr.error("Request registration failed! Please try again.");
        // });
    };

    const getSubscribedExpiry = useMemo(() => {
        console.log(user);
        if (user?.Subscriptions?.length > 0) {
            const makeWillSub = user?.Subscriptions?.find(sub => (sub.id === 1));
            console.log(makeWillSub);
            if (makeWillSub)
                return makeWillSub.UserSubscription.SubscriptionId === 1 && makeWillSub.UserSubscription.active === true && makeWillSub.UserSubscription.expires_at !== null ? makeWillSub.UserSubscription.expires_at : null;
        }
        return null;
    }, [user]);
    const allowCalls = useMemo(() => {
        const callCount = user?.Reviews?.filter(rev => rev.type === "CALL")?.length ?? 0;
        const allowed = user?.Subscriptions?.reduce((p, c) => +p + +(c.calls ?? 0), 0);
        if (callCount < allowed) return true;
        return false;
    }, [user]);

    const activeCall = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === "CALL"))
            active = true;
        return active;
    }, [user]);

    const activeReview = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.will_version === user?.will_version && rev.type === "REVIEW"))
            active = true;
        console.log(active);
        return active;
    }, [user]);

    const activeRegistration = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.will_version === user?.will_version && rev.type === "REGISTRATION"))
            active = true;
        return active;
    }, [user]);

    const activeNotarization = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.will_version === user?.will_version && rev.type === "NOTARIZATION"))
            active = true;
        return active;
    }, [user]);

    const subscribed = useMemo(() => {
        let consumed = true;
        // let consumed = user?.Subscriptions ?.length === 0;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(sub => {
            if (sub) {
                console.log('sub', sub);
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1 && userSub.payment_status === 'Success') {
                    const today = new Date();
                    const subDay = new Date(userSub.createdAt);
                    const subDays = (today - subDay) / 86400 / 1000;
                    const allowed = sub.days;
                    if (subDays > allowed)
                        consumed = true;
                    else
                        consumed = false;
                    // console.log('here',subDays, allowed, consumed);
                }
                if (user.role === 'partner' && userSub && userSub.SubscriptionId === 1 && userSub.payment_status === 'DEMO') {
                    setDemoModeActivated(true);
                }
            }
        });
        return !consumed;
    }, [user]);

    useEffect(() => {
        if (user.referral_code !== null && user.referral_code !== undefined) {
            axios.post(`/partners/validate/${user.referral_code}`).then(res => {
                console.log('partner valid code', res.data);
                // console.log('res', res.data.consumed);
                // console.log('res.couponId', res.data.couponId);
                if (res.data.valid) {
                    if (res.data.consumed) {
                        setPartnerCodeError("This Partner Code is consumed");
                        setPartnerCodeApplied("Consumed");
                        setPartnerDiscountApplicable(false);
                    }
                    else {
                        const { discount } = res.data;

                        if (discount > 0) {
                            setPartnerCode(user.referral_code);
                            setAppliedPartnerCodeDiscount(discount);
                            setPartnerDiscountApplicable(true);
                        }
                        else {

                            setAppliedPartnerCodeDiscount(0);
                            setPartnerCodeApplied("Applied");
                            setDisablePartnerCode(Boolean(true));
                            setPartnerCodeError("Partner Code applied successfully");
                            setPartnerDiscountApplicable(false);
                        }
                    }
                }
                else {
                    setPartnerCodeApplied("Invalid");
                    setPartnerCodeError("Invalid Partner Code");
                    setPartnerDiscountApplicable(false);
                }
            }).catch((e) => {
                console.log('error', e);
                setPartnerCodeApplied("Invalid");
                setPartnerCodeError("Something went wrong");
                setPartnerDiscountApplicable(false);
                // toastr.error("Failed to complete request. Please retry.", { timeOut: 0 });
            });
        }
    }, [user]);

    const isCCPaymentPopupOpen = useMemo(() => {
        if (ccPaymentPopUpOpen)
            return true;
        return false;
    }, [ccPaymentPopUpOpen]);

    const applyCouponCode = () => {
        if (couponCode !== "") {
            axios.post(`/coupons/validate/${couponCode}`).then(res => {
                console.log('res', res.data.valid);
                console.log('res', res.data.consumed);
                console.log('res.couponId', res.data.couponId);
                setIsDemoCoupon(res.data.isDemoCoupon);
                if (res.data.valid) {
                    if (res.data.consumed) {
                        setCouponCodeError("This Coupon Code is consumed");
                        setCouponCodeApplied("Consumed");
                    }
                    else {
                        const { couponId } = res.data;

                        if (couponId > 0) {
                            // setAppliedCouponCode(coupons.filter(coupon => coupon.id === couponId));
                            setAppliedCouponCode(res.data.coupon);
                        }
                        setCouponCodeApplied("Applied");
                        setDisableCouponCode(Boolean(true));
                        setCouponCodeError("Coupon Code applied successfully");

                        if (res.data.isDemoCoupon) {

                            fetchUser();
                            Swal.fire({
                                position: "top-center",
                                icon: "success",
                                color: '#fff',
                                background: '#00838F',
                                toast: true,
                                title: `DEMO mode activated successfully`,
                                showConfirmButton: false,
                                timer: 3000
                            });
                        }
                    }
                }
                else {
                    setCouponCodeApplied("Invalid");
                    setCouponCodeError("Invalid Coupon Code");
                }
            }).catch((e) => {
                console.log('error', e);
                setCouponCodeApplied("Invalid");
                setCouponCodeError("Something went wrong");
                // toastr.error("Failed to complete request. Please retry.", { timeOut: 0 });
            });
        }
    }
    const applyPartnerCode = () => {
        if (partnerCode !== "") {
            axios.post(`/partners/validate/${partnerCode}`).then(res => {
                console.log('res', res.data);
                // console.log('res', res.data.consumed);
                // console.log('res.couponId', res.data.couponId);
                if (res.data.valid) {
                    if (res.data.consumed) {
                        setPartnerCodeError("This Partner Code is consumed");
                        setPartnerCodeApplied("Consumed");
                    }
                    else {
                        const { discount } = res.data;

                        if (discount > 0) {
                            setAppliedPartnerCodeDiscount(discount);
                        }
                        else {
                            setAppliedPartnerCodeDiscount(null);
                        }
                        setPartnerCodeApplied("Applied");
                        setDisablePartnerCode(Boolean(true));
                        setPartnerCodeError("Partner Code applied successfully");
                    }
                }
                else {
                    setPartnerCodeApplied("Invalid");
                    setPartnerCodeError("Invalid Partner Code");
                }
            }).catch((e) => {
                console.log('error', e);
                setPartnerCodeApplied("Invalid");
                setPartnerCodeError("Something went wrong");
                // toastr.error("Failed to complete request. Please retry.", { timeOut: 0 });
            });
        }
    }
    const removeCouponCode = () => {
        setCouponCodeApplied(null);
        setCouponCode('');
        setDisableCouponCode(Boolean(false));
        setCouponCodeError(null);
        setAppliedCouponCode([]);
    }
    const removePartnerCode = () => {
        setPartnerCodeApplied(null);
        setDisablePartnerCode(Boolean(false));
        setPartnerCode('');
        // setAppliedPartnerCodeDiscount(null);
        setPartnerCodeError(false);
    }
    const onPurchaseClick = (row) => {
        if ((checkedSubscriptions.some((item) => item.id === 2) || row.id !== 2) && (checkedSubscriptions.some((item) => item.id === 2) || row.id !== 2) && (checkedSubscriptions.some((item) => item.id === 5) || row.id !== 5)) {
            console.log('checkedSubscriptions here');
            handleBuyClick(row)
        }
        else {
            registerRequest(row)
        }
    };

    const onOfferChange = (e) => {
        console.log('onOfferChange', e);
        if (e.target.value === "") {
            setDisableCouponCode(Boolean(false));
            setAppliedCouponCode([]);
        }
        else {
            setAppliedCouponCode(coupons.filter(coupon => coupon.id === +e.target.value));
            setDisableCouponCode(Boolean(true));
        }
        setOffer(e.target.value);
    };
    return (
        <>
            <Helmet>
                <title> Services | Get Will Done </title>
            </Helmet>

            {/* <CCFrame onClose={() => {
                console.log('here in close')
                fetchUser().then(() => {
                const red = searchParams.get("ref");
                navigate(red ?? "/dashboard/app", { replace: true });
                });
            }} paymentURL={paymentURL} productName={'Subscription'} /> */}

            <CenterModal
                open={confirmSub.open}
                onClose={() => {
                    handleClose();
                }}
                centered
                title={confirmSub.title}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                    <Typography color="text.primary" variant="body2" component="p" sx={{ mb: 2 }}>
                        {/* {confirmSub.text} */}
                        <div dangerouslySetInnerHTML={{ __html: confirmSub.text }} />
                    </Typography>
                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Button onClick={() => {
                        handleClose();
                        // handleBuyClick(confirmSub.sub);
                    }}>Cancel</Button>
                    {(confirmSub.type === 'REVIEW' || confirmSub.type === 'CALL') && (
                        <Button style={{ marginTop: "8px" }} onClick={() => {

                            handleClose();
                            handleBuyClick(confirmSub.sub);
                            // subscribe({ ...confirmSub.sub, request: true });
                        }} variant="contained">
                            Confirm
                        </Button>

                    )}
                    {(confirmSub.type !== 'REVIEW' && confirmSub.type !== 'CALL') && (

                        <Button style={{ marginTop: "8px", textTransform: 'none' }} onClick={() => {

                            // handleClose();
                            // subscribe({ ...confirmSub.sub, request: true });
                            axios.patch("/testator/request/call", { comment: `Request for a quote`, type: confirmSub.type })
                                .then(res => {
                                    // toastr.success("Request to Get a Quote submmitted successfully")
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "success",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `Request to Get a Quote submmitted successfully`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                })
                                .catch(e => {
                                    // toastr.error("Failed to request a call")
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "error",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `Failed to request a call`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                })
                                .finally(() => {
                                    handleClose();
                                    fetchUser();
                                });
                        }} variant="contained">
                            Get a Quote
                        </Button>
                    )}
                </Stack>
            </CenterModal>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh', // Ensures it covers the full viewport height
                }} >
                    <CircularProgress color="primary" size={80} thickness={4} />
                    <Typography variant="h5" sx={{
                        marginTop: 2,
                    }}>
                        Waiting for response from payment gateway...
                    </Typography>
                </Box>


            </Backdrop>

            {/* <CenterModal
                // open={!isCCPaymentPopupOpen}
                open={!subscribed && !paymentURL}
                onClose={() => {
                    navigate("/dashboard/app");
                }}
                centered
                title={`Make your own Will`}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                    <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                        <Typography component={"h2"} fontWeight={"bold"} fontSize={28} align={"center"}
                            sx={{ color: "text.secondary" }}>
                            {daysSubscription.name}
                        </Typography>

                        <Stack direction={"column"} spacing={0}>
                            <Stack justifyContent={"center"} spacing={1} direction="row" sx={{ my: 2 }}>
                                {daysSubscription.price > 0 && <Typography component="h4" fontSize={20}>₹</Typography>}
                                {daysSubscription.discounted_price > -1 ? <strike><Typography component="h4"
                                    fontSize={20}>{daysSubscription.price === 0 ? "Free" : daysSubscription.price}</Typography></strike> :
                                    <Typography component="h4"
                                        fontSize={20}>{daysSubscription.price === 0 ? "Free" : daysSubscription.price}</Typography>}
                            </Stack>

                            {daysSubscription.discounted_price > -1 &&
                                <Stack justifyContent={"center"} spacing={1} direction="row" sx={{ my: 2 }}>
                                    {daysSubscription.price > 0 && <Typography component="h4" fontSize={20}>Offer Price: ₹</Typography>}

                                    <Typography component="h4"
                                        fontSize={20}>{daysSubscription.discounted_price === 0 ? "Free" : daysSubscription.discounted_price}</Typography>
                                </Stack>}
                        </Stack>
                    </Stack>
                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Button onClick={() => {
                        navigate("/dashboard/app");
                    }}>Cancel</Button>
                    <Button style={{ marginTop: "8px" }} onClick={() => {
                        setCcPaymentPopUpOpen(true);
                        subscribe(daysSubscription);
                    }} variant="contained">
                        Purchase
                    </Button>
                </Stack>
            </CenterModal> */}

            <Card>

                <Container fullWidth style={{ maxWidth: "unset" }}>

                    <Grid container spacing={2} padding={1} paddingBottom={2}>
                        <Grid item xs={16} sm={16} md={16} lg={8}>
                            <Grid container spacing={2}>

                                {subscriptions.sort((a, b) => a.id - b.id).filter((row) => [1].includes(row.id)).map((row) => {
                                    let price = Math.round(+row.basePrice + ((+row.basePrice * +row.igstPercentage) / 100));
                                    let discountedPrice = +row.discountedPrice > -1 ? Math.round(+row.discountedPrice + ((+row.discountedPrice * +row.igstPercentage) / 100)) : +row.discountedPrice;
                                    if (user.profile.permanent.state.toLowerCase() === "maharashtra" || user.profile.permanent.state.toLowerCase() === "mh") {
                                        console.log('service price', (+row.cgstPercentage + +row.sgstPercentage));
                                        price = Math.round(+row.basePrice + (+row.basePrice * (+row.cgstPercentage + +row.sgstPercentage) / 100));
                                        discountedPrice = +row.discountedPrice > -1 ? Math.round(+row.discountedPrice + ((+row.discountedPrice * +row.igstPercentage) / 100)) : +row.discountedPrice;
                                    }
                                    return (
                                        <Grid key={row.id} item xs={12} marginTop={4}>
                                            <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between", display: 'flex', alignItems: isSmallScreen || isMediumScreen ? 'center' : 'flex-start' }}>
                                                <Stack direction={"row"} alignItems={'center'}>
                                                    {(((row.id === 1 && !subscribed) || (row.id === 2 && !activeReview) || (row.id === 3 && !activeNotarization) || (row.id === 4 && !activeRegistration) || (row.id === 5 && !activeCall))) && <Box>
                                                        <Checkbox size="large" checked={checkedSubscriptions.some(checkedSubscription => checkedSubscription.id === row.id)} onChange={() => { onPurchaseClick(row) }} disabled={user.role === 'partner' && user.partner_enroll_status !== "APPROVED"}
                                                        />
                                                    </Box>}
                                                    <Typography variant="h2" align={"center"} sx={{ margin: '0 16px 0 16px' }}>
                                                        {`${row.name} - `}
                                                    </Typography>
                                                    <Stack justifyContent={"center"} spacing={1} direction="row" sx={{ marginRight: '16px' }}>
                                                        {discountedPrice > -1 ? <strike><Typography component="h4"
                                                            fontSize={20}>₹{price === 0 ? "Free" : price}</Typography></strike> :
                                                            <Typography fontWeight={'bold'} component="h4" fontSize={28}>{row.price === 0 ? "Free" : `₹${price}`}</Typography>}
                                                    </Stack>
                                                    {discountedPrice > -1 &&
                                                        <Stack justifyContent={"center"} direction="row">
                                                            {row.price > 0 && <Typography fontWeight={'bold'} component="h4" fontSize={20}>Offer Price: ₹</Typography>}

                                                            <Typography component="h4" fontWeight={'bold'}
                                                                fontSize={20}>{row.discounted_price === 0 ? "Free" : row.discounted_price}</Typography>
                                                        </Stack>}
                                                    <Tooltip placement="right" componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: '1.15rem',
                                                                // fontStretch: 'expanded',
                                                                // width: '1000px',
                                                                maxWidth: 550, // Set the maximum width
                                                                whiteSpace: 'normal', // Ensure text wrapping
                                                            },
                                                        },
                                                    }} title="Create, edit and generate your Will effortlessly with ease. Empower yourself with a year-long subscription to our platform. Take control of your legacy planning journey today!">
                                                        <InfoIcon sx={{ color: theme.palette.primary.main, mx: '8px' }} />
                                                    </Tooltip>
                                                </Stack>
                                                <Stack direction={"column"} spacing={0} justifyContent={'center'} width={'630px'}>
                                                    {/* <Stack justifyContent={"center"} spacing={1} direction="row">
                                                        {discountedPrice > -1 ? <strike><Typography component="h4"
                                                            fontSize={20}>₹{price === 0 ? "Free" : price}</Typography></strike> :
                                                            <Typography component="h4" fontSize={20}>{row.price === 0 ? "Free" : `₹${price}`}</Typography>}
                                                    </Stack> */}

                                                    {/* {discountedPrice > -1 && */}
                                                    {/* {true &&
                                                        <Stack justifyContent={"center"} spacing={1} direction="row">
                                                            {row.price > 0 && <Typography component="h4" fontSize={20}>Offer Price: ₹</Typography>}

                                                            <Typography component="h4"
                                                                fontSize={20}>{row.discounted_price === 0 ? "Free" : row.discounted_price}</Typography>
                                                        </Stack>} */}
                                                    {(row.id === 1 && subscribed) && (
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                                            <Typography sx={{ margin: '6px' }} variant="caption" textAlign={"center"} color={"success.main"}>Your subscription will expire on {format(new Date(getSubscribedExpiry), "dd-MM-yyyy")}</Typography>
                                                        </Box>
                                                    )}
                                                    {/* {row.calls > 0 && allowCalls &&
                                                    <Typography variant="caption" textAlign={"center"} color={"success.main"}>You already have a free
                                                        call. You can click "Request a Call" at the top right corner to proceed.</Typography>} */}
                                                    {row.calls > 0 && activeCall &&
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>You already have an active
                                                            call. Please wait for our call.</Typography>}
                                                    {(activeReview && row.id === 2) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>You already have an active
                                                            review. Please wait for our call.</Typography>
                                                    )}
                                                    {(activeNotarization && row.id === 3) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>Please call on +919156888418 to avail the services.</Typography>
                                                    )}
                                                    {(activeRegistration && row.id === 4) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>Please call on +919156888418 to avail the services.</Typography>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    );
                                }

                                )}
                                <Grid item xs={16} md={16} marginTop={4}>
                                    <Stack direction="row" sx={{ display: 'flex', justifyContent: isSmallScreen || isMediumScreen ? 'center' : 'flex-start', alignItems: 'center', paddingLeft: '16px' }}>
                                        <Typography variant="h3" gutterBottom>
                                            Value Added Services
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {/* {subscriptions.filter((sub) => sub.id > 1).map((row) =>  */}
                                {subscriptions.sort((a, b) => a.id - b.id).filter((row) => [2, 5].includes(row.id)).map((row) => {
                                    let price = Math.round(+row.basePrice + ((+row.basePrice * +row.igstPercentage) / 100));
                                    let discountedPrice = +row.discountedPrice > -1 ? Math.round(+row.discountedPrice + ((+row.discountedPrice * +row.igstPercentage) / 100)) : +row.discountedPrice;
                                    const isCheckBoxVisible = ((row.id === 1 && !subscribed) || (row.id === 2 && !activeReview) || (row.id === 3 && !activeNotarization) || (row.id === 4 && !activeRegistration) || (row.id === 5 && !activeCall));
                                    // const isCheckBoxVisible = false;
                                    console.log("isCheckBoxVisible ", isCheckBoxVisible);
                                    console.log("!isCheckBoxVisible ", !isCheckBoxVisible);

                                    if (user.profile.permanent.state.toLowerCase() === "maharashtra" || user.profile.permanent.state.toLowerCase() === "mh") {
                                        console.log('service price', (+row.cgstPercentage + +row.sgstPercentage));
                                        price = Math.round(+row.basePrice + (+row.basePrice * (+row.cgstPercentage + +row.sgstPercentage) / 100));
                                        discountedPrice = +row.discountedPrice > -1 ? Math.round(+row.discountedPrice + ((+row.discountedPrice * +row.igstPercentage) / 100)) : +row.discountedPrice;
                                    }
                                    return (
                                        <Grid key={row.id} item xs={12}>
                                            <Stack direction={"column"} sx={{ height: "100%", display: 'flex', alignItems: isSmallScreen || isMediumScreen ? 'center' : 'flex-start' }}>
                                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                    {isCheckBoxVisible && (
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1, pb: 1 }}>
                                                            <Checkbox size="large" checked={checkedSubscriptions.some(checkedSubscription => checkedSubscription.id === row.id)} onChange={() => { onPurchaseClick(row) }} disabled={user.role === 'partner' && user.partner_enroll_status !== "APPROVED"} />
                                                        </Box>
                                                    )}
                                                    <Typography marginLeft={!isCheckBoxVisible && "60px"} component={"h2"} fontWeight={"bold"} fontSize={28} align={"center"}
                                                        sx={{ color: "text.secondary" }}>
                                                        {`${row.name} -`}
                                                    </Typography>
                                                    <Stack justifyContent={"center"} spacing={1} direction="row" >
                                                        {row.discounted_price > -1 ? <strike><Typography component="h4"
                                                            fontSize={20}>₹{price === 0 ? "Free" : price}</Typography></strike> :
                                                            <Typography fontWeight={'bold'} sx={{ marginRight: '16px' }} component="h4" fontSize={20}>{price === 0 ? "Free" : `₹${price}`}</Typography>}
                                                    </Stack>

                                                    {row.discounted_price > -1 &&
                                                        <Stack justifyContent={"center"} direction="row">
                                                            {row.price > 0 && <Typography fontWeight={'bold'} component="h4" fontSize={20}>Offer Price: ₹</Typography>}
                                                            <Typography component="h4" fontWeight={'bold'}
                                                                fontSize={20}>{row.discounted_price === 0 ? "Free" : row.discounted_price}</Typography>
                                                        </Stack>}

                                                    <Tooltip placement="right" componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: '1.15rem',
                                                                maxWidth: 550, // Set the maximum width
                                                                whiteSpace: 'normal', // Ensure text wrapping
                                                            },
                                                        },
                                                    }} title={row.name === "Call with Legal Expert" ? 'Book a 30-minute call with our legal experts for clear guidance and peace of mind. Get expert help to clear your doubts and safeguard your legacy today.' : row.name === 'Review by Lawyer' ? 'Have our in-house lawyers review your Will and ensure your Will is legally sound. Protect your wishes with confidence.' : ''}>
                                                        <InfoIcon sx={{ color: theme.palette.primary.main }} />
                                                    </Tooltip>
                                                </Stack>

                                                <Stack direction={"column"} spacing={0} width={'100%'} maxWidth={'500px'} justifyContent={'center'}>
                                                    {/* <Stack justifyContent={"center"} spacing={1} direction="row">
                                                        {row.discounted_price > -1 ? <strike><Typography component="h4"
                                                            fontSize={20}>₹{price === 0 ? "Free" : price}</Typography></strike> :
                                                            <Typography component="h4" fontSize={20}>{price === 0 ? "Free" : `₹${price}`}</Typography>}
                                                    </Stack> */}

                                                    {/* {row.discounted_price > -1 && */}
                                                    {/* {true &&
                                                        <Stack justifyContent={"center"} spacing={1} direction="row">
                                                            {row.price > 0 && <Typography component="h4" fontSize={20}>Offer Price: ₹</Typography>}

                                                            <Typography component="h4"
                                                                fontSize={20}>{row.discounted_price === 0 ? "Free" : row.discounted_price}</Typography>
                                                        </Stack>} */}
                                                    {(row.id === 1 && subscribed) && (
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                                            <Typography sx={{ margin: '7px' }} variant="caption" textAlign={"center"} color={"success.main"}>Your subscription will expire on {format(new Date(getSubscribedExpiry), "dd-MM-yyyy")}</Typography>
                                                        </Box>
                                                    )}
                                                    {/* {row.calls > 0 && allowCalls &&
                                                    <Typography variant="caption" textAlign={"center"} color={"success.main"}>You already have a free
                                                        call. You can click "Request a Call" at the top right corner to proceed.</Typography>} */}
                                                    {row.calls > 0 && activeCall &&
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>You already have an active
                                                            call. Please wait for our call.</Typography>}
                                                    {(activeReview && row.id === 2) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>You already have an active
                                                            review. Please wait for our call.</Typography>
                                                    )}

                                                    {(activeNotarization && row.id === 3) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>Please call on +919156888418 to avail the services.</Typography>
                                                    )}
                                                    {(activeRegistration && row.id === 4) && (
                                                        <Typography variant="caption" textAlign={"center"} color={"error.main"}>Please call on +919156888418 to avail the services.</Typography>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    )
                                }

                                )}
                                {subscriptions.sort((a, b) => a.id - b.id).filter((row) => [3, 4].includes(row.id)).map((row) =>
                                    <Grid key={row.id} item xs={12}>
                                        <Stack direction={"column"} sx={{ height: "100%", width: '100%', placeContent: "space-between", display: 'flex', alignItems: isSmallScreen || isMediumScreen ? 'center' : 'flex-start', justifyContent: 'center' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 1, pb: 1 }}>
                                                    <Checkbox size="large" checked={checkedSubscriptions.some(checkedSubscription => checkedSubscription.id === row.id)} onChange={() => { registerRequest(row); }} disabled={(!((row.id === 3 && !activeNotarization) || (row.id === 4 && !activeRegistration))) || (user.role === 'partner' && user.partner_enroll_status !== "APPROVED")} />
                                                </Box>
                                                <Typography component={"h2"} fontWeight={"bold"} fontSize={28} align={"center"}
                                                    sx={{ color: "text.secondary" }}>
                                                    {`${row.name} - (Get a Quote)`}
                                                </Typography>
                                                <Tooltip placement="right" componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            fontSize: '1.15rem',
                                                            maxWidth: 550, // Set the maximum width
                                                            whiteSpace: 'normal', // Ensure text wrapping
                                                        },
                                                    },
                                                }} title={row.name === 'Notary at Doorstep ' ? 'Get your Will notarized at home. A notarized Will ensures your wishes are honored, even in challenging circumstances. Protect your future with ease.' : row.name === 'Register your Will' ? 'Register your Will to guarantee its authenticity and protect it from loss or tampering. You can retrieve it from the registrar or sub-registrar, if you lose your copy.  Register your Will now and support your legacy against uncertainties!' : ''} >
                                                    <InfoIcon sx={{ color: theme.palette.primary.main, mx: '8px' }} />
                                                </Tooltip>
                                            </Box>

                                            <Stack direction={"column"} spacing={0}>
                                                {(row.id === 1 && subscribed) && (
                                                    <Typography variant="caption" textAlign={"center"} color={"success.main"}>Your subscription will expire on {format(new Date(getSubscribedExpiry), "dd-MM-yyyy")}</Typography>
                                                )}
                                                {row.calls > 0 && allowCalls &&
                                                    <Typography variant="caption" textAlign={"center"} color={"success.main"}>You already have a free
                                                        call. You can click "Request a Call" at the top right corner to proceed.</Typography>}
                                                {row.calls > 0 && activeCall &&
                                                    <Typography variant="caption" textAlign={"center"} color={"error.main"}>You already have an active
                                                        call. Please wait for our call.</Typography>}
                                                {(activeReview && row.id === 2) && (
                                                    <Typography variant="caption" textAlign={"center"} color={"error.main"}>Please call on +919156888418 to avail the services.</Typography>
                                                )}


                                            </Stack>
                                        </Stack>
                                    </Grid>
                                )}
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={16} sm={16} md={16} lg={4}>
                            <Grid container>
                                <Grid item xs={12} marginTop={4}>
                                    <Card >
                                        <CardContent>
                                            <Typography gutterBottom variant="h4" component="div">
                                                Purchase Summary
                                            </Typography>
                                            {checkedSubscriptions.length > 0 && (
                                                <Typography variant="body2" color="text.secondary">
                                                    <List dense>

                                                        {checkedSubscriptions.sort((a, b) => a.id - b.id).map((checkedSubscription) => {
                                                            console.log('appliedCouponCode', appliedCouponCode);
                                                            const { basePrice, igstPercentage, cgstPercentage, sgstPercentage } = checkedSubscription;

                                                            let originalBasePrice = +basePrice;
                                                            let offerPrice = +basePrice;

                                                            let discount = 0;
                                                            let isApplicable = false;
                                                            let isPartnerDiscountApplicable = false;
                                                            if (appliedCouponCode.length > 0) {
                                                                isApplicable = appliedCouponCode[0].applicableServices.includes(checkedSubscription.id);
                                                            }
                                                            console.log('isApplicable', isApplicable);
                                                            if (isApplicable) {
                                                                discount = appliedCouponCode[0].discount;
                                                                offerPrice = ((+originalBasePrice * (100 - discount)) / 100);
                                                            }
                                                            else if (partnerDiscountApplicable && checkedSubscription.id === 1) {
                                                                discount = appliedPartnerCodeDiscount;
                                                                isPartnerDiscountApplicable = true;
                                                                offerPrice = ((+originalBasePrice * (100 - discount)) / 100);
                                                                console.log('partnerDiscountApplicable else', partnerDiscountApplicable, appliedPartnerCodeDiscount, offerPrice);
                                                            }

                                                            if (user.profile.permanent.state.toLowerCase() === 'maharashtra' || user.profile.permanent.state.toLowerCase() === 'mh') {
                                                                originalBasePrice = Math.round((+originalBasePrice + (+originalBasePrice * (+cgstPercentage + +sgstPercentage) / 100)));
                                                                offerPrice = Math.round((+offerPrice + (+offerPrice * (+cgstPercentage + +sgstPercentage) / 100)));
                                                            }
                                                            else {
                                                                originalBasePrice = Math.round(+originalBasePrice + ((+originalBasePrice * (+igstPercentage)) / 100));
                                                                offerPrice = Math.round(+offerPrice + ((+offerPrice * (+igstPercentage)) / 100));
                                                            }



                                                            return (
                                                                <ListItem
                                                                    key={checkedSubscription.id}
                                                                    secondaryAction={
                                                                        <IconButton edge="end" aria-label="delete" onClick={() => handleBuyClick(checkedSubscription)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    <ListItemText
                                                                        primary={checkedSubscription.name}
                                                                        primaryTypographyProps={{ variant: 'h6'}}
                                                                        // secondary={`₹ ${checkedSubscription.discounted_price > -1 ? checkedSubscription.discounted_price : checkedSubscription.price}`}
                                                                        secondary={
                                                                            <div style={{ fontSize: '18px'}}>
                                                                                <span style={{ textDecoration: isApplicable ? 'line-through' : 'none' }}>
                                                                                    ₹{originalBasePrice}
                                                                                </span>
                                                                                {(isApplicable || isPartnerDiscountApplicable) ? (
                                                                                    <span style={{ color: 'green' }}>
                                                                                        &nbsp;₹{offerPrice} ({discount}% off)
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            )
                                                        }
                                                        )}
                                                    </List>
                                                </Typography>
                                            )}

                                            {user.referral_code === null && (
                                                <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                                                    {(user.role === "testator" || (user.role === 'partner' && user.partner_enroll_status === "APPROVED")) && offerCoupons.length > 0 && (
                                                        <>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Typography gutterBottom variant="body" component="div" sx={{ marginRight: 1, marginBottom: 0 }}>
                                                                            Offers
                                                                        </Typography>
                                                                        {/* </Grid>
                                                        <Grid item xs={12}> */}
                                                                        <FormControl fullWidth >
                                                                            <InputLabel id="coupon-type-select-label">Choose Offer</InputLabel>
                                                                            <Select
                                                                                fullWidth
                                                                                labelId="coupon-type-select-label"
                                                                                label="Choose Offer"
                                                                                value={offer}
                                                                                onChange={onOfferChange}
                                                                                disabled={couponCode !== '' ? Boolean(true) : Boolean(false)}
                                                                            >
                                                                                <MenuItem value=''>
                                                                                    <em style={{ fontStyle: 'normal' }}>None</em>
                                                                                </MenuItem>
                                                                                {offerCoupons.map(offerCoupon => (
                                                                                    <MenuItem value={offerCoupon.id}>{offerCoupon.name}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography variant="body2" align={'center'} sx={{ m: 1 }}>
                                                                OR
                                                            </Typography>
                                                        </>
                                                    )}
                                                    <Box sx={{ display: 'flex', mt: 2 }}>
                                                        <Grid container alignItems="center" spacing={1}>
                                                            {/* <Grid item xs={5}>
                                                            <Typography gutterBottom variant="body" component="div" sx={{ marginBottom: 0 }}>
                                                                Have Coupon Code?
                                                            </Typography>
                                                        </Grid> */}
                                                            <Grid item xs={6}>
                                                                <FormControl
                                                                    required
                                                                    error={couponCodeError === "Coupon Code applied successfully" ? Boolean(false) : Boolean(true)}

                                                                    variant="standard"
                                                                >
                                                                    <TextField
                                                                        label="Coupon Code"

                                                                        disabled={disableCouponCode}
                                                                        // error={couponNameError}
                                                                        value={couponCode}
                                                                        onChange={handleCouponCodeChange}
                                                                    />
                                                                    <FormHelperText color={couponCodeError === "Coupon Code applied successfully" ? 'success' : 'error'}>{couponCodeError}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {couponCodeApplied !== "Applied" && (

                                                                    <Button
                                                                        onClick={applyCouponCode}
                                                                        disabled={disableCouponCode}
                                                                        // size="medium"
                                                                        // disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)}
                                                                        variant={"text"}
                                                                    >Apply</Button>
                                                                )}
                                                                {couponCodeApplied === "Applied" && (

                                                                    <Button fullWidth
                                                                        onClick={removeCouponCode}
                                                                        // disabled={disableCouponCode}
                                                                        // size="medium"
                                                                        // disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)}
                                                                        variant={"text"}
                                                                    >Clear</Button>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    {/* <Box sx={{ display: 'flex', mt:2}}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={5}>
                                                        <Typography  variant="body" component="div">
                                                            Have Advisor/Partner Code?
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Advisor/Partner Code"
                                                            type="number"
                                                            value={partnerCode}
                                                            disabled={disablePartnerCode}

                                                            onChange={handlePartnerCode}
                                                            inputProps={{
                                                                minLength: 5,
                                                                maxLength: 5,
                                                            }}
                                                            // error={partnerCodeError}
                                                            // helperText={partnerCodeError}
                                                        // error={couponNameError}
                                                        // value={couponName}
                                                        // onChange={handleCouponNameChange}
                                                        />
                                                        <FormHelperText color={partnerCodeError === "Partner Code applied successfully" ? 'success' : 'error'}>{partnerCodeError}</FormHelperText>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {partnerCodeApplied !== "Applied" && (

                                                            <Button
                                                                onClick={applyPartnerCode}
                                                                disabled={disablePartnerCode}
                                                                // size="medium"
                                                                // disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)}
                                                                variant={"text"}
                                                            >Apply</Button>
                                                        )}
                                                        {partnerCodeApplied === "Applied" && (

                                                            <Button fullWidth
                                                                onClick={removePartnerCode}
                                                                // disabled={disableCouponCode}
                                                                // size="medium"
                                                                // disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)}
                                                                variant={"text"}
                                                            >Remove</Button>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box> */}
                                                </Box>
                                            )}

                                            {/* <Typography variant="h4" > */}
                                                {/* <Typography component="h4" fontSize={20}>Total: ₹{Math.ceil(calculateTotal())}</Typography> */}
                                                <Typography variant="h5">Total: ₹{calculateTotal()}</Typography>
                                            {/* </Typography> */}
                                            {/* {appliedPartnerCodeDiscount > 0 && (
                                            <>
                                                <Typography variant="body2" color="text.secondary">
                                                    <Typography component="h4" fontSize={20}>Partner Discount: ₹{Math.ceil((calculateTotal() * (appliedPartnerCodeDiscount)) / 100)}<span style={{ color: 'green' }}>
                                                        &nbsp;({appliedPartnerCodeDiscount}% off) </span></Typography>
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    <Typography component="h4" fontSize={20}>Grand Total: ₹{Math.ceil(calculateTotal()) - Math.ceil((calculateTotal() * (appliedPartnerCodeDiscount)) / 100)}</Typography>
                                                </Typography>
                                            </>
                                        )} */}
                                        </CardContent>

                                        <CardActions>
                                            {/* <Button size="small" color="primary">
                                        Purchase
                                        </Button> */}
                                            <Button fullWidth sx={{ my: 1, fontSize: 18 }}
                                                onClick={subscribe}
                                                disabled={checkedSubscriptions.length > 0 ? Boolean(false) : Boolean(true)}
                                                variant={"contained"}>Purchase</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Card>                      
        </>
    );
}

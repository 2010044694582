import { sentenceCase } from "change-case";
import { filter } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";

// @mui
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    ListItem,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { format, parseISO } from 'date-fns';
// components
import { Edit, Refresh } from "@mui/icons-material";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { LoadingContext } from "../App";
import CenterModal from "../components/CenterModal";
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { a11yProps } from "../utils/Tab";
// mock
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    // { id: "id", label: "ID", alignRight: false },
    // { id: "type", label: "Category", alignRight: false },
    { id: "asset", label: "Asset", alignRight: false },
    { id: "distribution_type", label: "Distribution type", alignCenter: false },
    { id: "balance", label: "Balance", alignRight: false },
    { id: "distribution", label: "Asset distribution", alignCenter: true }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: (theme) => `${theme.palette.warning.lighter}`,
    border: "none",
    borderTop: "5px solid",
    borderTopColor: (theme) => theme.palette.primary.main,
    borderRadius: (theme) => theme.spacing(2),
    boxShadow: 24,
    p: 1
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function WillPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("name");

    const [filterName, setFilterName] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [assets, setAssets] = useState([]);

    const [schemas, setSchemas] = useState({});
    const [distributionError, setDistributionError] = useState(false);

    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [assetData, setAssetData] = useState({});
    const [will, setWill] = useState({});
    const [type, setType] = useState("");
    const [subType, setSubType] = useState("");
    const [mappings, setMappings] = useState([]);
    const [tab, setTab] = useState(0);
    const [newDistributionOpen, setNewDistributionOpen] = useState(false);
    const [evenDistributionOpen, setEvenDistributionOpen] = useState(false);
    const [distributionDefinition, setDistributionDefinition] = useState(new Set());
    const [evenDistributionDefinition, setEvenDistributionDefinition] = useState(new Set());
    const [leftColumnSelection, setLeftColumnSelection] = useState(new Set());
    const [middleColumnSelection, setMiddleColumnSelection] = useState(new Set());
    const [selectedOption, setSelectedOption] = useState('');
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
    const [duplicateBeneficiaryError, setDuplicateBeneficiaryError] = useState(false);
    const [emptyBeneficiaryError, setEmptyBeneficiaryError] = useState(false);
    const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
    const [blinking, setBlinking] = useState(true);

    const [generics, setGenerics] = useState({});
    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    const { review } = useParams();
    const { user } = useContext(UserContext);
    // const navigate = useNavigate()
    // const userMaritialStatus = user?.profile?.marital_status;
    // const isSpouseBeneficiary = generics?.beneficiary?.some(item => item.relation && item.relation.toLowerCase().includes("spouse"));
    // const isDistributionViewBlocked = isSpouseBeneficiary && userMaritialStatus !== 'married'; // isDistributionViewBlocked- If users maritial_status is not married and he have spouse as beneficiary, then we have to restrict them from distribution.
    
    // const isDistributionViewBlockedForSpouse = isSpouseBeneficiary === false && userMaritialStatus === 'married';
    // // isDistributionViewBlockedForSpouse - If users maritial_status is married and he dont have spouse as beneficiary then we have to restrict them from distribution.

    const hideEdit = useMemo(() => {
        console.log('willpage', ["partner", "testator"].includes(user?.role));
        return !["partner", "testator"].includes(user?.role) || !!review;
    }, [user, review]);

    const fetchGenerics = (genericType) => {
        axios.get(`/generics/${genericType}`).then(response => {
            setGenerics((generics) => ({ ...generics, [genericType]: response.data?.generics ?? [] }));
        }).catch(err => console.error(err));
    };

    const fetchAssets = () => {
        axios.get("/properties").then(response => {
            setGenerics((generics) => ({ ...generics, assets: response.data?.assets ?? [] }));
        }).catch(err => console.error(err));
    };

    const fetchForms = () => {
        axios.get("/properties/forms").then(response => {
            setSchemas(response.data ?? {});
        }).catch(err => console.error(err));
    };

    const fetchWills = (switchToNew = false) => {
        axios.get("/wills").then(response => {
            setGenerics((generics) => ({
                ...generics,
                wills: response.data?.wills ?? [],
                scenarios: response.data?.scenarios ?? []
            }));
            if (switchToNew) {
                setTab(response.data?.scenarios?.length ?? 0);
            }
            // setMappings(response.data.wills?.filter(will => will.ScenarioId) ?? []);
        }).catch(err => console.error(err));
    };

    const deleteWill = (scenario, id) => {
        axios.delete(`/wills/${scenario}/${id}`).then(response => {
            fetchWills();
        }).catch(err => console.error(err));
    };


    const addNewMapping = () => {
        // axios.post(`/wills/${selectedScenario?.id ?? 0}`, { asset_id: assetData.PropertyId, mappings: assetData.mapping }).then(response => {
        //   toastr.success("Mapping saved successfully")
        //   fetchWills();
        //   setAssetData({});
        //   setOpen(false);
        //   setEdit(false);
        // }).catch(err => toastr.error("Failed to add asset"));
    };

    const selectedScenario = useMemo(() => {
        return (tab === 0 ? { id: 0, deaths: [] } : generics?.scenarios?.[tab - 1]);
    }, [tab, generics?.scenarios]);


    const editMapping = () => {
        console.log('will.distribution_type', will.distrbution_type);
        axios.post(`/wills/${selectedScenario?.id ?? 0}`, {
            asset_id: editingId,
            distribution_type: will.distribution_type,
            mappings: will.mapping
        }).then(response => {
            //   toastr.success("Mapping updated successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Mapping updated successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchWills();
            setAssetData({});
            setOpen(false);
            setEdit(false);
        }).catch(err => {
            // toastr.error("Failed to update asset")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to update asset`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    const createScenario = () => {
        console.log('generics.scenarios', Array.from(distributionDefinition), generics.scenarios)

        const array1 = Array.from(distributionDefinition);
        const array2 = generics.scenarios;

        const scenarioExists = array2.some(obj => obj.deaths.length === array1.length && array1.every(num => obj.deaths.includes(num)));

        console.log('scenarioresult', scenarioExists); // Output: true
        if (scenarioExists) {
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Scenario already exists for this situation`,
                showConfirmButton: false,
                timer: 3000
            });
        }
        else {
            axios.post("/wills/scenario", { deaths: Array.from(distributionDefinition) }).then(response => {
                //   toastr.success("Scenario added successfully");
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Scenario added successfully`,
                    showConfirmButton: false,
                    timer: 3000
                });
                fetchWills(true);
                setNewDistributionOpen(false);
                setDistributionDefinition(new Set());
            }).catch(err => {
                // toastr.error("Failed to add scenario");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Failed to add scenario`,
                    showConfirmButton: false,
                    timer: 3000
                });
            });
        }

    };

    const distributeEvenly = useCallback(() => {
        const combinedSelection = new Set([...leftColumnSelection, ...middleColumnSelection]);
        setMappings(filteredUsers?.map(as => ({
            id: -1,
            PropertyId: as.id,
            distribution_type: as.sub_type === "livestock" ? "quantity" : "percentage",
            mapping: Array.from(combinedSelection)?.map(b => {
                console.log('evenDistribute', as, b, combinedSelection);
                if (["pets", "car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(as.sub_type)) {
                    return {
                        beneficiary_id: b,
                        share: 0
                    };
                }
                if (as.sub_type === "livestock") {
                    return {
                        beneficiary_id: b,
                        share: 0
                    };
                }
                return {
                    beneficiary_id: b,
                    share: (100 / (combinedSelection.size ?? 1)).toFixed(2)
                };
            })
        })));
        setEvenDistributionOpen(false);
        setLeftColumnSelection(new Set());
        setMiddleColumnSelection(new Set());
        setIsSelectAllChecked(false);
        setSelectedOption('')
    }, [generics?.assets, leftColumnSelection, middleColumnSelection]);


    // const createScenario = () => {
    //   axios.post(`/wills/scenario/`, { deaths: Array.from(distributionDefinition) }).then(response => {
    //     toastr.success("Scenario added successfully")
    //     fetchWills();
    //     setDistributionDefinition(new Set());
    //   }).catch(err => toastr.error("Failed to add scenario"));
    // }


    const updateMappings = useCallback(() => {
        axios.post(`/wills/bulk/map/${selectedScenario?.id ?? 0}`, {
            mappings: mappings.filter(map => map.id !== -99) ?? []
        }).then(response => {
            //   toastr.success("Mapping updated successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Mapping updated successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchWills();
            setAssetData({});
            setOpen(false);
            setEdit(false);
            // console.log('generics', generics);
            // if (tab === 0 && generics?.scenarios.length === 0) {
            //     // setTimeout(() => {
            //     //     Swal.fire({
            //     //         title: `The next step is to add another distribution scenario.`,
            //     //         // text: ("Add Asset"),
            //     //         icon: "info",
            //     //         showConfirmButton: false,
            //     //         showCancelButton: true,
            //     //         allowEnterKey: false,
            //     //         allowOutsideClick: false,
            //     //         // confirmButtonColor: "#3085d6",
            //     //         cancelButtonColor: "#d33",
            //     //         cancelButtonText: "OK"
            //     //     }).then((result) => {
            //     //         console.log('pop up result', result);
            //     //         if (result.isConfirmed) {
            //     //             // updateMappings();
            //     //         }
            //     //         else 
            //     //     });
            //     // }, 3000);
            //     if (tab === generics?.scenarios?.length)
            //         setShowSuggestion(true);
            // }
            // // else if (tab !== 0 && generics?.scenarios.length === 1) {

            // // }

            if (tab === generics?.scenarios?.length)
                setShowSuggestion(true);
        }).catch(err => {
            // toastr.error("Failed to update asset")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to update asset`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    }, [mappings]);

    const deleteScenario = (id) => {
        axios.delete(`/wills/scenario/${id}`).then(response => {
            console.log('scenarios', response);
            // toastr.success(`${selectedScenario.id > 0 ? 'Scenario' : 'Distribution'} deleted successfully`);
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `${selectedScenario.id > 0 ? 'Scenario' : 'Distribution'} deleted successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchWills();
            setNewDistributionOpen(false);
            setDistributionDefinition(new Set());
            setTab(0);
        }).catch(err => {
            // toastr.error("Failed to delete scenario")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to delete scenario`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    useEffect(() => {
        if (user?.role === "reviewer" && !!review) {
            axios.interceptors.request.use(config => {
                config.headers["X-Review-Id"] = +review;
                return config;
            });
        }
        fetchAssets();
        fetchGenerics("beneficiary");
        fetchWills();
        fetchForms();
    }, [user, review]);

    const handleOpenMenu = (event, row, will = {}) => {
        console.log('handleOpenMenu', row, will);
        setSubType(row.sub_type);
        setOpen(event.currentTarget);
        setType(row.type);
        setSelectedBeneficiaries([]);
        // setEmptyBeneficiaryError(false);
        setAssetData(row.information);
        const _mappings = [...(will?.mapping ?? [])];
        generics.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.forEach(beneficiary => {
            const hasDuplicates = _mappings.some(obj => obj.beneficiary_id === beneficiary.id);
            if (!hasDuplicates)
                _mappings.push({ beneficiary_id: beneficiary.id, share: 0 });
            // _mappings[index].beneficiary_id = e.target.value;
        })
        setWill({ ...will, mapping: [..._mappings], distribution_type: row.sub_type === "livestock" ? "quantity" : "percentage" });
        // setWill({ ...will, distribution_type: row.sub_type === "livestock" ? "quantity" : "percentage" });
        setEditingId(row.id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setAssetData({});
        setType("");
        setEditingId(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = assets.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };


    const filteredUsers = applySortFilter([...(generics.assets ?? []), { id: null, information: { caption: 'Any other assets not included in the Assets that need to be distributed' }, type: '', sub_type: 'Residual Assets' }], getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - generics.assets.length) : 0;

    const mappingBalance = useMemo(() => {
        let count = 0;
        filteredUsers.forEach((row) => {
            const { id, type, information, sub_type: subType } = row;
            console.log('mappings', mappings);
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === "value") {
                value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
            } else if (will?.distribution_type === "quantity") {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach(mapping => {
                total += +(mapping.share ?? 0);
            });
            if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
                total = value;
            }
            if (total !== value) {
                count += 1;
            }
        });
        return count;

        // let count = 0;
        // mappings.forEach(map => {
        //   let value = 100;
        //   const total = map?.mapping?.reduce((p, c) => +p + +c.share, 0) ?? 0;
        //   const { information } = generics?.assets?.find(as => as.id === map.PropertyId) ?? {};
        //   if (map?.distribution_type === "value") {
        //     value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
        //     if (!value) {
        //       value = 100;
        //     }
        //   } else if (map?.distribution_type === "quantity") {
        //     value = information?.quantity ?? information?.qty ?? information?.number;
        //     if (!value) {
        //       value = 100;
        //     }
        //   }
        //   if (value !== total) {
        //     count += 1;
        //   }
        // });
        // return count;
    }, [filteredUsers, mappings]);

    const petMappingBalance = useMemo(() => {
        const petResult = [];
        filteredUsers.forEach((row) => {
            const { id, type, information, sub_type: subType } = row;
            if (["pets", "car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(subType)) {
                const will = mappings?.find(({ PropertyId }) => {
                    return PropertyId === id;
                });

                // let value = 100;
                // let total = 0;
                if (will?.distribution_type === "percentage") {
                    // will?.mapping?.forEach(mapping => {
                    //     // total += +(mapping.share ?? 0);
                    // });

                    const hasValue100 = will?.mapping?.filter(obj => obj.share === 100 || obj.share === '100.00').length === 1;
                    console.log('petmappingbalance hasValue100', hasValue100);

                    const allOthersAreZero = will?.mapping?.every(obj => obj.share === 0 || obj.share === 100 || obj.share === '100.00');
                    console.log('petmappingbalance allOthersAreZero', allOthersAreZero);

                    if (hasValue100 && allOthersAreZero) {
                        petResult.push(true);
                        console.log('Exactly one object has a value of 100, and all others are zero');
                    } else {
                        petResult.push(false);
                        console.log('Either none or more than one object has a value of 100, or not all others are zero');
                    }
                }
            }
        });
        return petResult;

    }, [filteredUsers, mappings]);

    const isNotFound = !filteredUsers.length && !!filterName;


    const unusedAssets = useMemo(() => {
        if (edit) return generics.assets;
        return generics.assets?.filter(asset => {
            return !generics.wills?.find(will => will.PropertyId === asset.id);
        }) ?? [];
    }, [generics, edit]);

    const addedAssetTypes = useMemo(() => {
        const assetTypes = new Set();
        unusedAssets?.forEach((asset) => {
            assetTypes.add(asset.type);
        });
        return Array.from(assetTypes);
    }, [generics.assets]);

    const allowedTypes = useMemo(() => {
        const information = assetData;
        let _value = 100;
        console.log('allowedTypes', subType);
        const percentage = subType !== "livestock";
        let quantity = false;
        let value = false;
        _value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
        if (_value) value = true;
        _value = information?.quantity ?? information?.qty ?? information?.number;
        if (_value) quantity = true;
        return { percentage, quantity, value };
    }, [assetData]);

    const totalMapping = useMemo(() => {
        const information = assetData;
        let value = 100;
        let total = 0;
        if (will?.distribution_type === "value") {
            value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
        } else if (will?.distribution_type === "quantity") {
            value = information?.quantity ?? information?.qty ?? information?.number;
        }
        if (subType === "livestock") {
            value = information?.number;
        }
        console.log('totalMapping', JSON.stringify(type), subType, will, information);
        will?.mapping?.forEach(mapping => {
            total += +(mapping.share ?? 0);
        });
        if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
            total = value;
        }
        return { total, value, distrbution_type: subType !== "livestock" ? "percentage" : "" };
    }, [assetData, will]);

    const allBeneficiariesSelected = useMemo(() => {
        console.log('assetData', will);
        const information = assetData;
        let value = 100;
        let total = 0;
        if (will?.distribution_type === "value") {
            value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
        } else if (will?.distribution_type === "quantity") {
            value = information?.quantity ?? information?.qty ?? information?.number;
        }
        if (subType === "livestock") {
            value = information?.number;
        }
        console.log('totalMapping', subType, will, information);
        will?.mapping?.forEach(mapping => {
            total += +(mapping.share ?? 0);
        });
        if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
            total = value;
        }
        return { total, value, distrbution_type: subType !== "livestock" ? "percentage" : "" };
    }, [assetData, will]);

    const petMappingError = useMemo(() => {
        if (["pets", "car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(subType)) {
            console.log('mappingError subType', subType, will);
            const hasValue100 = will?.mapping?.filter(obj => obj.share === 100).length === 1;
            console.log('petmappingbalance hasValue100', hasValue100);

            const allOthersAreZero = will?.mapping?.every(obj => obj.share === 0 || obj.share === 100);
            console.log('petmappingbalance allOthersAreZero', allOthersAreZero);

            if (hasValue100 && allOthersAreZero) {
                console.log('Exactly one object has a value of 100, and all others are zero');
                return null;
            }
            console.log('Either none or more than one object has a value of 100, or not all others are zero');
            return subType === "pets" ? "Distribution of pet assets is limited to a single beneficiary, with entire allocation (100%)." : ["car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(subType) ? "Distribution of vehicle assets is limited to a single beneficiary, with entire allocation (100%)." : "";
        }
        if (totalMapping.total > totalMapping.value) {
            return `Distribution should not exceed ${totalMapping.value}${totalMapping.distrbution_type === "percentage" ? "%" : ""}`;
        }
        if (totalMapping.total < totalMapping.value) {
            return `Distribution pending: ${totalMapping.value - totalMapping.total} ${totalMapping.distrbution_type === "percentage" ? "%" : ""}`;
        }
        return null;
    }, [totalMapping]);


    const mappingError = useMemo(() => {
        if (["pets", "car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(subType)) {
            console.log('mappingError subType', subType, will);
            const hasValue100 = will?.mapping?.filter(obj => obj.share === 100).length === 1;
            console.log('petmappingbalance hasValue100', hasValue100);

            const allOthersAreZero = will?.mapping?.every(obj => obj.share === 0 || obj.share === 100);
            console.log('petmappingbalance allOthersAreZero', allOthersAreZero);

            if (hasValue100 && allOthersAreZero) {
                console.log('Exactly one object has a value of 100, and all others are zero');
                return "";
            }
            console.log('Either none or more than one object has a value of 100, or not all others are zero');
            return subType === "pets" ? "Distribution of pet assets is limited to a single beneficiary, with entire allocation (100%)." : ["car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(subType) ? "Distribution of vehicle assets is limited to a single beneficiary, with entire allocation (100%)." : "";
        }
        if (totalMapping.total > totalMapping.value) {
            return `Distribution should not exceed ${totalMapping.value}${totalMapping.distrbution_type === "percentage" ? " % " : ""}`;
        }
        if (totalMapping.total < totalMapping.value) {
            return `Distribution pending: ${totalMapping.value - totalMapping.total} ${totalMapping.distrbution_type === "percentage" ? "%" : ""}`;
        }
        return "";
    }, [totalMapping]);

    const normalizedWills = useMemo(() => {
        return generics?.wills?.map(will => {
            will.ScenarioId = will.ScenarioId ?? 0;
            return will;
        }) ?? [];
    }, [generics?.wills]);

    useEffect(() => {
        const scenarioMappings = normalizedWills.filter(will => will.ScenarioId === (tab === 0 ? 0 : generics?.scenarios?.[tab - 1]?.id));
        setMappings(scenarioMappings);
        console.log('useeffect', scenarioMappings.length === 0, !isLoading, generics?.assets?.length > 0);
        // if (scenarioMappings.length === 0 && !isLoading && generics?.assets?.length > 0 && isDistributionViewBlocked === false && isDistributionViewBlockedForSpouse === false) {
        //     setEvenDistributionDefinition(new Set());
        //     setEvenDistributionOpen(true);
        // } else {
        //     setEvenDistributionOpen(false);
        // }
        if (scenarioMappings.length === 0 && !isLoading && generics?.assets?.length > 0) {
            setEvenDistributionDefinition(new Set());
            setEvenDistributionOpen(true);
        } else {
            setEvenDistributionOpen(false);
        }
    }, [normalizedWills, generics?.scenarios, tab, isLoading]);
    const handleTabChange = (event, newValue) => {
        setDistributionError(false);
        if (mappings?.find(m => m.id === -1)) {
            // toastr.warning("Unsaved changes. Please click update mapping before you proceed!");
            Swal.fire({
                position: "top-center",
                icon: "warning",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Unsaved changes. Please click update mapping before you proceed!`,
                showConfirmButton: false,
                timer: 3000
            });
        }
        else setTab(newValue);
    };

    const [view, setView] = useState("list");
    const primaryMappings = normalizedWills.filter(will => will.ScenarioId === 0);

    useEffect(() => {
        let error = false;
        filteredUsers.forEach((row) => {
            const { id, type, information, sub_type: subType } = row;
            const will = mappings?.find(({ PropertyId }) => {
                return PropertyId === id;
            });
            let value = 100;
            let total = 0;
            if (will?.distribution_type === "value") {
                value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
            } else if (will?.distribution_type === "quantity") {
                value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach(mapping => {
                total += +(mapping.share ?? 0);
            });
            if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
                total = value;
            }
            if (total > value) {
                error = true;
            }
        });
        setDistributionError(error);
    }, [filteredUsers, mappings]);


    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === "enter" && !e.repeat) {
                e.preventDefault();
                if (!distributionError) {
                    if (mappingBalance > 0) {
                        Swal.fire({
                            title: "Are you sure?",
                            text: ("Some assets are not distributed completely. Do you wish to continue?"),
                            icon: "warning",
                            showCancelButton: true,
                            allowEnterKey: false,
                            allowOutsideClick: false,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, update distribution"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                updateMappings();
                            }
                        });
                    } else {
                        updateMappings();
                    }
                }
            }
        };
        window.addEventListener("keyup", handleEnter);
        return () => {
            window.removeEventListener("keyup", handleEnter);
        };
    }, [distributionError, mappingBalance, updateMappings]);

    useEffect(() => {
        if (!sessionStorage.getItem(`video_close_distribution`) && !isLoading) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setBlinking(prevBlinking => !prevBlinking);
        }, 500); // Blinking interval: 500 milliseconds

        // Clear the interval after 5 seconds
        setTimeout(() => {
            clearInterval(intervalId);
            setBlinking(false);
        }, 10000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // empty dependency array ensures the effect runs only once on mount

    // Defines a function named formatDate that formats a given date string
    function formatDate(dateString) {
        try {
            // Attempts to parse the input date string to ISO format and then format it to 'dd-MM-yyyy'
            return format(parseISO(dateString), 'dd-MM-yyyy');
        } catch (error) {
            // Logs an error message to the console if there's a problem with date parsing or formatting
            console.error('Error formatting date:', error);
            // Returns the original date string if there was an error during parsing or formatting
            return dateString;
        }
    }

    // useEffect(() => {
    //   async function handleBlockedView() {
    //     if (isDistributionViewBlocked) {
    //       Swal.fire({
    //         title: 'Restricted',
    //         text: `Your Maritial Status is not Married and you have Spouse as a beneficiary. Either Delete/Edit Your Spouse Beneficiary or Change your Maritial Status`,
    //         icon: 'warning',
    //         showCancelButton: false,
    //         allowEnterKey: false,
    //         allowOutsideClick: false,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes',
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           // If the user confirms, you can perform some action here
    //           navigate('/dashboard/beneficiaries');
    //         }
    //       });
    //     }
    //     if (isDistributionViewBlockedForSpouse) {
    //       Swal.fire({
    //         title: 'Restricted',
    //         text: `Your Maritial Status is Married and you Don't have Spouse as a beneficiary. Either Add Beneficiary with relation to Spouse or Change your Maritial Status`,
    //         icon: 'warning',
    //         showCancelButton: false,
    //         allowEnterKey: false,
    //         allowOutsideClick: false,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes',
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           // If the user confirms, you can perform some action here
    //           navigate('/dashboard/beneficiaries');
    //         }
    //       });
    //     }
    //   }

    //   handleBlockedView(); // Call the function when the component mounts
    // }, [isDistributionViewBlocked, isDistributionViewBlockedForSpouse]);
    
    return (
        <>
            <Helmet>
                <title> Asset Distribution | Get Will Done </title>
            </Helmet>

            <Container fullWidth style={{ maxWidth: "unset" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Asset Distribution
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    </div>
                    {/* <div style={{ display: "flex" }}>
                        <ButtonGroup>
                            <IconButton onClick={() => {
                                setView("list");
                            }} sx={{ backgroundColor: view === "list" ? "lightgrey" : "transparent" }}><List /></IconButton>
                            <IconButton onClick={() => {
                                setView("grid");
                                fetchWills();
                            }} sx={{
                                backgroundColor: (view === "grid" || blinking) ? blinking ? "#F15D27" : "lightgrey" : "transparent",
                                animation: blinking ? "blink 0.5s infinite" : "none"
                                // backgroundColor: view === "grid" ? "lightgrey" : "transparent" 
                            }}><Dashboard /></IconButton>
                        </ButtonGroup>
                    </div> */}
                </Stack>
                {/* {isDistributionViewBlocked && Swal.fire({
                    title: "Restricted",
                    text: (`You can't access distribution page`),
                    icon: "warning",
                    showCancelButton: true,
                    allowEnterKey: false,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes"
                }).then((result) => {
                    if (result.isConfirmed) {
                        // deleteGeneric(id);
                    }
                })}; */}
                <CenterModal
                    open={newDistributionOpen}
                    onClose={() => {
                        setNewDistributionOpen(false);
                        setDistributionDefinition(new Set());
                    }}
                    title={"Create new distribution scenario"}
                >
                    <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        <Typography variant="body1" component="h6">
                            In case of death(s) of Beneficiaries
                        </Typography>
                        {generics?.beneficiary?.map((bene, index) => <ListItem sx={{ p: 0 }}><FormControlLabel
                            sx={{ mr: 0 }} control={<Checkbox sx={{ color: '#F15D27' }} checked={distributionDefinition.has(bene.id)} onChange={(e, value) => {
                                if (value) {
                                    setDistributionDefinition(dist => {
                                        dist.add(bene.id);
                                        return new Set(Array.from(dist));
                                    });
                                } else {
                                    setDistributionDefinition(dist => {
                                        dist.delete(bene.id);
                                        return new Set(Array.from(dist));
                                    });
                                }
                            }} />} label={`${bene.name} (${bene.relation})`} /></ListItem>)}
                    </Box>
                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setNewDistributionOpen(false);
                            setDistributionDefinition(new Set());
                        }}>Cancel</Button>
                        <Button disabled={distributionDefinition.size === 0} onClick={() => createScenario()}
                            variant={"contained"}>Create</Button>
                    </Stack>
                </CenterModal>

                <CenterModal
                    open={showSuggestion}
                    onClose={() => {
                        setShowSuggestion(false);
                    }}
                    title={"More scenarios"}
                >
                    <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        <Typography variant="body1" component="h6">
                            {generics?.scenarios?.length > 2 ? "Do you want to designate any other alternate beneficiary in case any of your primary beneficiaries are not available?" : "Do you want to designate the alternate beneficiary in case any of your primary beneficiaries are not available?"}
                        </Typography>
                    </Box>
                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setShowSuggestion(false);
                            // setTimeout(() => {
                            //     Swal.fire({
                            //         title: `The next step is to add Executor.`,
                            //         // text: ("Add Asset"),
                            //         icon: "info",
                            //         showConfirmButton: false,
                            //         showCancelButton: true,
                            //         allowEnterKey: false,
                            //         allowOutsideClick: false,
                            //         // confirmButtonColor: "#3085d6",
                            //         cancelButtonColor: "#d33",
                            //         cancelButtonText: "OK"
                            //     }).then((result) => {
                            //         console.log('pop up result', result);
                            //         if (result.isConfirmed) {
                            //             // updateMappings();
                            //         }
                            //         // else if (tab === generics?.scenarios?.length)
                            //         //     setShowSuggestion(true);
                            //     });
                            // }, 1000);
                        }}>No</Button>
                        <Button onClick={() => {
                            setShowSuggestion(false);
                            setNewDistributionOpen(true);
                        }}
                            variant={"contained"}>Yes</Button>
                    </Stack>
                </CenterModal>

                <CenterModal
                    open={evenDistributionOpen}
                    onClose={() => {
                        setEvenDistributionOpen(false);
                        setLeftColumnSelection(new Set());
                        setMiddleColumnSelection(new Set());
                        setSelectedOption('');
                    }}
                    title={`Distribute assets ${selectedScenario.id > 0 ? (selectedScenario.deaths.length > 0 ? `- In case of death(s) of ${selectedScenario.deaths.map(beneId => {
                        const bene = generics?.beneficiary?.find(bene => bene.id === beneId);
                        return `${bene.name} (${bene.relation})`;
                    }).join(" and ")}` : "") : ''}`}
                >
                    <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        <Grid container spacing={2}>
                            {selectedScenario.id === 0 && generics?.beneficiary?.some(bene => bene.relation === "Spouse") && <><Grid item xs={3.5}>
                                {/* Left Column */}
                                <Box display="flex" alignItems="center" sx={{ mt: 2 }} >
                                    <Checkbox
                                        sx={{ color: '#F15D27' }}
                                        checked={selectedOption === 'left'}
                                        onChange={(e, value) => {
                                            if (value) {
                                                setSelectedOption('left');
                                                generics?.beneficiary?.filter(bene => bene.relation === "Spouse" && selectedScenario.deaths.indexOf(bene.id) === -1)?.forEach(bene => {
                                                    setLeftColumnSelection(dist => {
                                                        const newDist = new Set(dist);
                                                        newDist.add(bene.id);
                                                        return newDist;
                                                    });
                                                });
                                                setMiddleColumnSelection(new Set());
                                                setIsSelectAllChecked(false);
                                            } else {
                                                setSelectedOption('');
                                                setLeftColumnSelection(new Set());
                                            }
                                        }}
                                    />
                                    <Typography variant="body1" component="h6">
                                        Do you wish to distribute all assets to your spouse?
                                    </Typography>
                                </Box>
                                {generics?.beneficiary?.filter(bene => bene.relation === "Spouse" && selectedScenario.deaths.indexOf(bene.id) === -1)?.map(bene => (
                                    <ListItem sx={{ pl: 5 }} key={bene.id}>
                                        <FormControlLabel
                                            sx={{ mr: 0 }}
                                            control={
                                                <Checkbox   
                                                    sx={{ color: '#F15D27' }}
                                                    checked={leftColumnSelection.has(bene.id)}
                                                    onChange={(e, value) => {
                                                        setLeftColumnSelection(dist => {
                                                            const newDist = new Set(dist);
                                                            if (value) {
                                                                newDist.add(bene.id);
                                                                setMiddleColumnSelection(new Set());
                                                                setSelectedOption('left');
                                                                setIsSelectAllChecked(false);
                                                            } else {
                                                                newDist.delete(bene.id);
                                                            }
                                                            return newDist;
                                                        });
                                                    }}
                                                />
                                            }
                                            label={`${bene.name} (${bene.relation})`}
                                        />
                                    </ListItem>
                                ))}
                            </Grid>
                                <Grid item xs={0.5} container justifyContent="center" alignItems="center">
                                    <Divider orientation="vertical" sx={{
                                        width: '1px', // Make the divider bolder by increasing the width
                                        backgroundColor: 'black', // Change the color if needed
                                    }} />
                                </Grid>
                            </>}
 
                            <Grid item xs={selectedScenario.id > 0 || !generics?.beneficiary?.some(bene => bene.relation === "Spouse") ? 5.5 : 4}>
                                {/* Middle Column */}
                                {selectedScenario.id > 0 && (
                                    <>
                                        {selectedScenario.deaths.some(beneId => generics?.beneficiary?.find(bene => bene.id === beneId)?.relation === "Spouse") && (<><Typography variant="body1" component="h6">
                                            You may provide alternate beneficiary to whom the properties will be given in absence of your spouse.
                                        </Typography><br /></>)}
                                        <Typography variant="body1" component="h6">
                                            If you would like to distribute assets to any other beneficiaries than those listed below. Please <Link to={'/dashboard/beneficiaries'}>click here</Link> to add new beneficiary.
                                        </Typography>
                                    </>
                                )}
                                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                                    {selectedScenario.id === 0 && <Checkbox
                                        sx={{ color: '#F15D27' }}
                                        checked={selectedOption === 'middle'}
                                        onChange={(e, value) => {
                                            if (value) {
                                                setSelectedOption('middle');
                                                setLeftColumnSelection(new Set());
                                            } else {
                                                setSelectedOption('');
                                                setMiddleColumnSelection(new Set());
                                                setIsSelectAllChecked(false);
                                            }
                                        }}
                                    />}
                                    <Typography variant="body1" component="h6">
                                        Do you wish to equally distribute all assets to your beneficiaries?
                                    </Typography>
                                </Box>
                                <ListItem sx={{ p: 0, pl: 5 }}>
                                    <FormControlLabel
                                        sx={{ mr: 0 }}
                                        control={
                                            <Checkbox
                                                sx={{ color: '#F15D27' }}
                                                checked={isSelectAllChecked}
                                                onChange={(e, value) => {
                                                    if (value) {
                                                        generics?.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.forEach(bene => {
                                                            setMiddleColumnSelection(dist => {
                                                                const newDist = new Set(dist);
                                                                newDist.add(bene.id);
                                                                return newDist;
                                                            });
                                                        });
                                                        setIsSelectAllChecked(true);
                                                        setSelectedOption('middle');
                                                        setLeftColumnSelection(new Set());
                                                    } else {
                                                        setIsSelectAllChecked(false);
                                                        setMiddleColumnSelection(new Set());
                                                    }
                                                }}
                                            />
                                        }
                                        label={`Select All`}
                                    />
                                </ListItem>
                                {generics?.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.map(bene => (
                                    <ListItem sx={{ p: 0, pl: 5 }} key={bene.id}>
                                        <FormControlLabel
                                            sx={{ mr: 0 }}
                                            control={
                                                <Checkbox
                                                    sx={{ color: '#F15D27' }}
                                                    checked={middleColumnSelection.has(bene.id)}
                                                    onChange={(e, value) => {
                                                        setMiddleColumnSelection(dist => {
                                                            const newDist = new Set(dist);
                                                            if (value) {
                                                                newDist.add(bene.id);
                                                                setLeftColumnSelection(new Set());
                                                                setSelectedOption('middle');
                                                            } else {
                                                                newDist.delete(bene.id);
                                                            }
                                                            return newDist;
                                                        });
                                                    }}
                                                />
                                            }
                                            label={`${bene.name} (${bene.relation})`}
                                        />
                                    </ListItem>
                                ))}
                            </Grid>
                            {selectedScenario.id === 0 && <><Grid item xs={0.5} container justifyContent="center" alignItems="center">
                                <Divider orientation="vertical" sx={{
                                    width: '1px', // Make the divider bolder by increasing the width
                                    backgroundColor: 'black', // Change the color if needed
                                }} />
                            </Grid>
                                <Grid item xs={!generics?.beneficiary?.some(bene => bene.relation === "Spouse")?6:3.5}>
                                    {/* Right Column */}
                                    <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                                        <Checkbox
                                            sx={{ color: '#F15D27' }}
                                            checked={selectedOption === 'right'}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setSelectedOption('right');
                                                    setLeftColumnSelection(new Set());
                                                    setMiddleColumnSelection(new Set());
                                                    setIsSelectAllChecked(false);
                                                } else {
                                                    setSelectedOption('');
                                                }
                                            }}
                                        />
                                        <Typography variant="body1" component="h6">
                                            Do you wish to manually distribute all assets to your beneficiaries?
                                        </Typography>
                                    </Box>
                                </Grid></>}
                        </Grid>
                        <Grid justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
                            {filteredUsers.some(user => ["pets", "car", "two_wheeler", "large_vehicles", "other_vehicles"].includes(user.sub_type)) && (
                                <Typography color={'error'} variant="body1" component="h6" sx={{ mt: 2 }}>
                                    Pet/Vehicle assets cannot be distributed evenly, requires manual distribution.
                                </Typography>
                            )}
                        </Grid>
                    </Box>
                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setEvenDistributionOpen(false);
                            setLeftColumnSelection(new Set());
                            setMiddleColumnSelection(new Set());
                            setSelectedOption('');
                            setIsSelectAllChecked(false);
                        }}>No</Button>
                        <Button disabled={leftColumnSelection.size === 0 &&
                            middleColumnSelection.size === 0 &&
                            selectedOption !== 'right'} onClick={() => distributeEvenly()} variant={"contained"}>Yes</Button>
                    </Stack>
                </CenterModal>


                <CenterModal
                    open={addNew || edit}
                    onClose={() => {
                        setAddNew(false);
                        setEdit(false);
                    }}
                    title={"Asset Distribution"}
                >
                    <Box id={"new-generic-form"} sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        <Typography variant="body1" component="h6">
                            {sentenceCase(type ?? "")}
                        </Typography>
                        <Typography sx={{ whiteSpace: "break-spaces" }} variant="caption" component="h6">
                            {Object.keys(assetData ?? {}).filter(key => key !== 'add_picture').map(key => {
                                // Apply date formatting if the value looks like a date
                                const isDate = key.toLowerCase().includes('date');// Check if the key contains 'date'
                                const value = assetData[key]; // Get the value corresponding to the key
                                const formattedValue = isDate ? formatDate(value) : value; // Format the value if it's a date
                                return `${sentenceCase(key)}: ${formattedValue}`; // Return the key-value pair
                            }).join("\n")}
                        </Typography>
                        {assetData?.add_picture && (
                            <img alt="Asset" width={"400px"} src={assetData.add_picture} />
                        )}
                        <hr />
                        <Typography variant="caption" color={"error"} component="h6">{mappingError}</Typography>
                        {!!mappingError && <hr />}
                        <Typography variant="body1" component="h6">Distribute this asset</Typography>
                        <Stack sx={{ width: "50%" }} direction={"row"} alignItems={"center"} spacing={1}>
                            <FormControl sx={{ my: 1 }} fullWidth size={"small"}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subType === "livestock" ? "quantity" : will?.distribution_type ?? "percentage"}
                                    onChange={(e) => setWill({ ...will, distribution_type: e.target.value })}
                                >
                                    {allowedTypes.percentage && <MenuItem value="percentage">By Percentage</MenuItem>}
                                    {allowedTypes.value && <MenuItem value="value">By Value</MenuItem>}
                                    {allowedTypes.quantity && <MenuItem value="quantity">By Quantity</MenuItem>}
                                </Select>
                            </FormControl>
                            <Typography variant="body1" component="h6">to</Typography>
                        </Stack>
                        {will?.mapping?.map((mapping, index) => {
                            const shareLabel = subType === "livestock" ? `Quantity` : `Share ${will?.distribution_type === "percentage" ? "%" : will?.distribution_type === "value" ? String.fromCharCode("0x20B9") : ""}`;
                            return <Stack sx={{ width: "50%", marginBottom: 2 }} direction={"row"} alignItems={"center"} spacing={2} key={index}>
                                {/* <FormControl sx={{ my: 1 }} fullWidth size={"small"}>
                        <InputLabel id="demo-simple-select-label">Select Beneficiary</InputLabel>
                        <Select
                        
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={mapping?.beneficiary_id ?? ""}
                            error={emptyBeneficiaryError.index === index}
                                label="Select Beneficiary"
                            onChange={(e) => {
                                // setDuplicateBeneficiaryError(null);
                                // setEmptyBeneficiaryError(false);
                                const _mappings = [...(will?.mapping ?? [])];
                                const hasDuplicates = _mappings.some(obj => obj.beneficiary_id === e.target.value);
                                console.log('hasDuplicates', _mappings, hasDuplicates, typeof e.target.value);
                                if (!selectedBeneficiaries.includes(e.target.value)) {
                                    // If it doesn't exist, create a new array and update the state
                                    setSelectedBeneficiaries((prevArray) => [...prevArray, e.target.value]);
                                    // if (!hasDuplicates) {
                                        _mappings[index].beneficiary_id = e.target.value;
                                        setWill({ ...will, mapping: [..._mappings] });
                                        
                                        // setDuplicateBeneficiaryError(null);
                                    // }
                                    setSelectedBeneficiaries(_mappings.map(obj => obj.beneficiary_id));
                                }
                                else {
                                    const mappingIndex = _mappings.findIndex(obj => obj.beneficiary_id === e.target.value);
                                    console.log('mappingIndex',index, mappingIndex, e.target.value);
                                    // if (index !== mappingIndex)
                                    //     setDuplicateBeneficiaryError({ index, error: true });
                                    // else
                                    //     setDuplicateBeneficiaryError(null);

                                }
                            }}
                        >
                            <MenuItem value="">Select beneficiary</MenuItem>
                            {generics.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.map(beneficiary =>
                                <MenuItem key={beneficiary.id} value={beneficiary.id}>{beneficiary.name}</MenuItem>
                            )}
                        </Select>
                        {(duplicateBeneficiaryError && duplicateBeneficiaryError.index === index )  && (
                                <Typography variant="caption" color={"error"} component="h6">Duplicate Beneficiary</Typography>
                        )}
                                {(emptyBeneficiaryError && emptyBeneficiaryError.index === index)  && (
                                <Typography variant="caption" color={"error"} component="h6">Beneficiary required</Typography>
                        )}
                    </FormControl> */}
                                {generics.beneficiary?.filter(bene => mapping?.beneficiary_id === bene.id)?.map(beneficiary =>
                                    <FormControl sx={{ my: 1 }} fullWidth size={"small"}><Typography fullWidth key={beneficiary.id} value={beneficiary.id}>{beneficiary.name}</Typography></FormControl>
                                )}
                                <TextField
                                    sx={{ my: 1 }}
                                    id="standard-number"
                                    label={shareLabel}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    size={"small"}
                                    value={mapping?.share ?? ""}
                                    onChange={(e) => {
                                        let { value } = e.target;
                                        console.log('values', value);
                                        const inputValue = e.target.value;
                                        const regex = /^\d*\.?\d{0,2}$/;
                                        const integerRegex = /^[0-9]+$/;
                                        // const regex = /^\d*\.?\d{0,2}0*$/;
                                        console.log('regextest', inputValue, regex.test(inputValue));
                                        if (subType === "livestock") {
                                            if (integerRegex.test(inputValue) || inputValue === "") {
                                                if (value < 0)
                                                    value = 0;
                                                const _mappings = [...(will?.mapping ?? [])];
                                                _mappings[index].share = +value;
                                                setWill({ ...will, mapping: [..._mappings] });
                                            }
                                        }
                                        else if (regex.test(inputValue)) {
                                            if (value < 0)
                                                value = 0;
                                            const _mappings = [...(will?.mapping ?? [])];
                                            _mappings[index].share = +value;
                                            setWill({ ...will, mapping: [..._mappings] });
                                        }


                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}

                                />
                                {/* <IconButton onClick={() => {
                  let _mappings = [...(will?.mapping ?? [])];
                  _mappings = _mappings.filter((_, i) => i !== index);
                  setWill({ ...will, mapping: [..._mappings] });
                }}><Delete /></IconButton> */}
                            </Stack>;
                        })}
                        {/* <Button style={{ marginTop: "8px" }} onClick={() => {
              const _mappings = [...(will?.mapping ?? [])];
              _mappings.push({});
              setWill({ ...will, mapping: [..._mappings] });
            //   setEmptyBeneficiaryError({index: _mappings.length-1, });
            }} disabled={totalMapping.total > totalMapping.value || emptyBeneficiaryError}
                    variant="contained" startIcon={<Iconify icon="eva:save-fill" />}>
              Add Beneficiary
            </Button> */}
                        <hr />
                    </Box>
                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, p: 1 }} direction={"row"} spacing={1} justifyContent={"space-between"}>
                        <Button style={{ marginTop: "8px" }} disabled={subType === ""} onClick={() => {
                            setEdit(false);
                            setAddNew(false);
                            fetchWills();
                        }}>Discard</Button>
                        {petMappingBalance}
                        <Button style={{ marginTop: "8px" }} disabled={subType === "" || totalMapping.total > totalMapping.value || petMappingError !== null}
                            onClick={() => {
                                console.log('petMappingBalance', petMappingBalance, edit);
                                // if (petMappingBalance.length > 0 && petMappingBalance.some(value => value === false))
                                if (edit) {
                                    editMapping();
                                } else {
                                    addNewMapping();
                                }
                            }}
                            variant="contained" startIcon={<Iconify icon="eva:save-fill" />}>
                            Save
                        </Button>
                    </Stack>
                </CenterModal>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab label={"Primary distribution"} {...a11yProps(0)} />
                        {generics?.scenarios?.map((scenarioId, index) => <Tab
                            label={`Secondary Distribution ${index + 1}`} {...a11yProps(index + 1)} />)}
                        {!hideEdit && <Button onClick={() => {
                            setNewDistributionOpen(true)
                        }}
                            variant="text"
                            color="secondary"
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            Secondary distribution
                        </Button>}
                    </Tabs>

                </Box>
                <Typography align={"center"} variant="body1" component="h6" sx={{ m: 1 }}>
                    {selectedScenario.deaths.length > 0 ? `In case of death(s) of ${selectedScenario.deaths.map(beneId => {
                        const bene = generics?.beneficiary?.find(bene => bene.id === beneId);
                        return `${bene.name} (${bene.relation})`;
                    }).join(" and ")}, ` : ""} How would you like your Assets to be distributed amongst the beneficiaries?
                </Typography>
                {view === "list" ? <><Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={generics.wills?.length ?? 0}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                disableSort
                            // secondRow={generics.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.map(bene => ({
                            //   id: bene.id,
                            //   label: `${bene.name} (${bene.relation})`
                            // }))}
                            />
                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, type, information, sub_type: subType } = row;
                                    const selectedUser = selected.indexOf(id) !== -1;
                                    // const asset = generics.assets?.find(asset => asset.id === PropertyId);
                                    const will = mappings?.find(({ PropertyId }) => {
                                        return PropertyId === id;
                                    });
                                    console.log('will', will);
                                    let value = 100;
                                    let total = 0;
                                    let disableValue = false;
                                    let disableQty = false;
                                    const disablePercentage = false;
                                    if (will?.distribution_type === "value") {
                                        value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
                                        if (!value) {
                                            value = 100;
                                            disableValue = true;
                                        }
                                    } else if (will?.distribution_type === "quantity") {
                                        value = information?.quantity ?? information?.qty ?? information?.number;
                                        if (!value) {
                                            value = 100;
                                            disableQty = true;
                                        }
                                    }
                                    if (subType === "livestock") {
                                        value = information?.quantity ?? information?.qty ?? information?.number;
                                        if (!value) {
                                            value = 100;
                                        }
                                    }
                                    const allowValue = !disableValue && Object.keys(information ?? {}).some(key => ["amount", "insured_amount", "investment_amount", "value", "deposited_amount"].includes(key));
                                    const allowQty = !disableQty && information?.quantity || information?.qty || information?.number;
                                    const allowPercentage = !disablePercentage && subType !== 'livestock';
                                    value = +value;


                                    will?.mapping?.forEach(mapping => {
                                        total += +(mapping.share ?? 0);
                                    });
                                    if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
                                        total = value;
                                    }
                                    const primaryWill = primaryMappings?.find(({ PropertyId }) => {
                                        return PropertyId === id;
                                    });
                                    let color = "primary";
                                    if (value < total) {
                                        color = "error.main";
                                    } else if (value > total) {
                                        color = "warning.main";
                                    } else if (value === total) {
                                        color = "success.main";
                                    }

                                    let unit = "%";
                                    if (will?.distribution_type === 'quantity' || subType === "livestock") unit = 'nos.';
                                    else if (will?.distribution_type === 'value') unit = '₹';

                                    return (
                                        <TableRow key={id} tabIndex={-1}>

                                            <TableCell align="left" width={'30%'}>
                                                <Typography variant={"body2"}
                                                    component={"h6"}>{sentenceCase(type ?? "")} {!!type && '/'} {sentenceCase(subType ?? "")}</Typography>
                                                <Typography sx={{ whiteSpace: "pre-wrap" }} variant={"caption"}
                                                    component={"h6"}>{[0, 1].includes(Object.keys(information ?? {}).indexOf("add_picture")) ? "-" : Object.values(information ?? {})?.[0]}</Typography>
                                            </TableCell>

                                            <TableCell align="left">
                                                <Stack direction={"row"}>
                                                    <FormControl disabled={hideEdit} sx={{ my: 1 }} fullWidth size={"small"}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={will?.distribution_type ? will?.distribution_type : subType === "livestock" ? "quantity" : "percentage"}
                                                            onChange={(e) => {
                                                                setMappings((mps) => ([...mps, ...(!will ? [{
                                                                    id: -1,
                                                                    mapping: [],
                                                                    PropertyId: id,
                                                                    distribution_type: will?.distribution_type ?? "percentage"
                                                                }] : [])]).map((mp) => {
                                                                    if (mp.PropertyId === id) {
                                                                        mp.distribution_type = e.target.value;
                                                                    }
                                                                    return mp;
                                                                }));
                                                            }}
                                                        >
                                                            {allowPercentage && <MenuItem value="percentage">By Percentage</MenuItem>}
                                                            {allowValue && <MenuItem value="value">By Value</MenuItem>}
                                                            {allowQty && <MenuItem value="quantity">By Quantity</MenuItem>}
                                                        </Select>
                                                    </FormControl>
                                                    {!hideEdit && <IconButton onClick={async () => {
                                                        Swal.fire({
                                                            title: "Are you sure?",
                                                            text: ("Do you want to reset the distribution for this asset?"),
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            allowEnterKey: false,
                                                            allowOutsideClick: false,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Yes"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                deleteWill(selectedScenario?.id, id);
                                                            }
                                                        });
                                                    }} size={"small"}><Refresh fontSize={"small"} /></IconButton>}
                                                </Stack>
                                            </TableCell>
                                            <TableCell><Typography color={color}
                                                variant={"h6"}>{(value - total).toFixed(2)} {unit}</Typography></TableCell>
                                            <TableCell><Grid container spacing={1}>
                                                {generics.beneficiary?.filter(bene => selectedScenario.deaths.indexOf(bene.id) === -1)?.map(beneficiary => {
                                                    const dist = will?.mapping?.find(map => beneficiary.id === map.beneficiary_id);
                                                    const primDist = primaryWill?.mapping?.find(map => beneficiary.id === map.beneficiary_id);
                                                    const shareLabel = `Share ${will?.distribution_type === "percentage" ? "%" : will?.distribution_type === "value" ? String.fromCharCode("0x20B9") : ""}`;
                                                    return <Grid key={beneficiary.id} item xs={4}>
                                                        <TextField disabled={hideEdit} aria-valuemin={0} size={"small"} type="number" inputProps={{ min: 0, step: 0.01 }}
                                                            name="share"
                                                            label={beneficiary.name}
                                                            value={dist?.share ?? 0}
                                                            onChange={(e) => {

                                                                const inputValue = e.target.value;
                                                                const regex = /^\d*\.?\d{0,2}$/;
                                                                const integerRegex = /^[0-9]+$/;
                                                                // const regex = /^\d*\.?\d{0,2}0*$/;
                                                                console.log('regextest', inputValue, regex.test(inputValue));
                                                                if (subType === "livestock") {
                                                                    if (integerRegex.test(inputValue) || inputValue === "") {
                                                                        console.log("Valid integer");
                                                                        console.log('regex inside', will);
                                                                        setMappings((mps) => ([...mps, ...(!will ? [{
                                                                            id: -1,
                                                                            mapping: [],
                                                                            PropertyId: id,
                                                                            distribution_type: subType === "livestock" ? "quantity" : will?.distribution_type ?? "percentage"
                                                                        }] : [])]).map((mp) => {
                                                                            console.log('mp', mp, subType);
                                                                            if (mp.PropertyId === id) {
                                                                                let _mappings = [...(mp.mapping ?? [])];
                                                                                const distIndex = _mappings?.findIndex(map => beneficiary.id === map.beneficiary_id);
                                                                                if (distIndex === -1) {
                                                                                    _mappings.push({
                                                                                        beneficiary_id: beneficiary.id,
                                                                                        share: e.target.value < 0 ? 0 : Math.ceil(e.target.value)
                                                                                    });
                                                                                } else {
                                                                                    _mappings[distIndex].share = +e.target.value < 0 ? 0 : Math.ceil(+e.target.value);
                                                                                    if (e.target.value?.trim() === "" || e.target.value?.trim() === "0") {
                                                                                        _mappings = _mappings.filter(mp => beneficiary.id !== mp.beneficiary_id);
                                                                                        if (_mappings.length === 0) {
                                                                                            mp.id = -99;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                mp.mapping = _mappings;
                                                                            }
                                                                            return mp;
                                                                        }));
                                                                    }
                                                                }
                                                                else if (regex.test(inputValue) || inputValue === "") {
                                                                    console.log('regex inside', will);
                                                                    setMappings((mps) => ([...mps, ...(!will ? [{
                                                                        id: -1,
                                                                        mapping: [],
                                                                        PropertyId: id,
                                                                        distribution_type: subType === "livestock" ? "quantity" : will?.distribution_type ?? "percentage"
                                                                    }] : [])]).map((mp) => {
                                                                        console.log('mp', mp, subType);
                                                                        if (mp.PropertyId === id) {
                                                                            let _mappings = [...(mp.mapping ?? [])];
                                                                            const distIndex = _mappings?.findIndex(map => beneficiary.id === map.beneficiary_id);
                                                                            if (distIndex === -1) {
                                                                                _mappings.push({
                                                                                    beneficiary_id: beneficiary.id,
                                                                                    share: e.target.value < 0 ? 0 : e.target.value
                                                                                });
                                                                            } else {
                                                                                _mappings[distIndex].share = +e.target.value < 0 ? 0 : +e.target.value;
                                                                                if (e.target.value?.trim() === "" || e.target.value?.trim() === "0") {
                                                                                    _mappings = _mappings.filter(mp => beneficiary.id !== mp.beneficiary_id);
                                                                                    if (_mappings.length === 0) {
                                                                                        mp.id = -99;
                                                                                    }
                                                                                }
                                                                            }
                                                                            mp.mapping = _mappings;
                                                                        }
                                                                        return mp;
                                                                    }));
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                const inputValue = e.target.value.trim();
                                                                const regex = /^\d*\.?\d{0,2}$/;
                                                                if (will?.distribution_type === "percentage" && parseFloat(inputValue) > 0) {
                                                                    const updatedValue = regex.test(inputValue) ? parseFloat(inputValue).toFixed(2) : '';
                                                                    setMappings((mps) => mps.map((mp) => {
                                                                        if (mp.PropertyId === id) {
                                                                            const _mappings = [...(mp.mapping ?? [])];
                                                                            const distIndex = _mappings.findIndex(map => map.beneficiary_id === beneficiary.id);

                                                                            if (distIndex !== -1) {
                                                                                _mappings[distIndex].share = updatedValue;
                                                                                mp.mapping = _mappings;
                                                                            }
                                                                        }
                                                                        return mp;
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        {selectedScenario?.id > 0 &&
                                                            <Typography variant={"caption"} color={'secondary'}
                                                                component={"h6"}>Primary: {primDist?.share ?? 0} {primaryWill?.distribution_type === "percentage" && "%"}</Typography>}
                                                    </Grid>;
                                                    // return <p>{beneficiary?.name} ({beneficiary?.relation}) : {dist.share}</p>;
                                                })}
                                            </Grid>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>

                                                <Typography variant="body2">
                                                    No results found for &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Try checking for typos or using complete words.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                    {['testator', 'partner'].includes(user.role) && (
                        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                            {
                                selectedScenario.id > -1 && <Button color={"error"} variant={"contained"} onClick={() => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: (`Do you want to delete the ${selectedScenario.id > 0 ? 'Scenario' : 'Distribution'}?`),
                                        icon: "warning",
                                        showCancelButton: true,
                                        allowEnterKey: false,
                                        allowOutsideClick: false,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deleteScenario(selectedScenario.id);
                                        }
                                    });
                                }}>Delete {selectedScenario.id > 0 ? 'Scenario' : 'Distribution'}</Button>
                            }

                            < Button disabled={distributionError} variant={"contained"} onClick={() => {
                                //   petMappingBalance
                                console.log('petMappingBalance', mappingBalance, petMappingBalance);
                                if (petMappingBalance.length > 0 && petMappingBalance.some(value => value === false)) {
                                    console.log('petmappingbalance');

                                    Swal.fire({
                                        title: "Pet/Vehicle assets not distributed properly",
                                        text: ("Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%)."),
                                        icon: "warning",
                                        showConfirmButton: false,
                                        showCancelButton: true,
                                        allowEnterKey: false,
                                        allowOutsideClick: false,
                                        // confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        cancelButtonText: "OK"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            // updateMappings();
                                        }
                                    });
                                }
                                else if (mappingBalance > 0) {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: ("Some assets are not distributed completely. Do you wish to continue?"),
                                        icon: "warning",
                                        showCancelButton: true,
                                        allowEnterKey: false,
                                        allowOutsideClick: false,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, update distribution"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            updateMappings();
                                        }
                                    });
                                } else {
                                    updateMappings();
                                }

                            }}>Distribute Assets</Button>
                        </Stack>
                    )}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={filteredUsers?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </> :
                    <Grid container spacing={2}>
                        {filteredUsers?.map((row) => {
                            const { id, type, sub_type: subType, information } = row;
                            const selectedUser = selected.indexOf(id) !== -1;
                            // const asset = generics.assets?.find(asset => asset.id === PropertyId);
                            const will = mappings?.find(({ PropertyId }) => {
                                return PropertyId === id;
                            });
                            console.log('grid information', row);
                            let totalShare = 0;
                            will?.mapping?.forEach(mapping => {
                                totalShare += mapping.share;
                            });

                            let color = "success.lighter";
                            if (will?.distribution_type?.toLowerCase() === "percentage") {
                                if (totalShare < 100) {
                                    color = "warning.lighter";
                                }
                            }
                            if (!will || totalShare === 0) {
                                color = "error.lighter";
                            }

                            return <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Card sx={{ borderRadius: 1, backgroundColor: color, height: "100%" }}>
                                    <Stack sx={{ height: "100%", placeContent: "space-between" }}>
                                        <CardContent>
                                            <Typography variant={"body1"} component={"p"}>{sentenceCase(type ?? "")}</Typography>
                                            <Typography variant={"caption"} component={"h6"}>{sentenceCase(subType ?? "")}</Typography>
                                            <Typography sx={{ whiteSpace: "pre-wrap" }} variant={"caption"}
                                                component={"h6"}>{Object.keys(information ?? {}).filter(key => key !== 'add_picture').filter(key => !!schemas.schemas?.[type]?.properties?.[key]?.show).map(key => `${sentenceCase(key)}: ${information[key]}`).join("\n")}</Typography>
                                            <Typography variant={"caption"} component={"h6"}>Distribution</Typography>
                                            {will ?
                                                <>
                                                    <Typography variant={"caption"} component={"h6"}>
                                                        By {sentenceCase(will?.distribution_type)}
                                                    </Typography>
                                                    <Typography variant={"caption"} component={"h6"}>
                                                        {will?.mapping.map(dist => {
                                                            const beneficiary = generics.beneficiary?.find(beneficiary => beneficiary.id === dist.beneficiary_id);
                                                            return <p>{beneficiary?.name} ({beneficiary?.relation}) : {dist.share}{will?.distribution_type === "percentage" ? "%" : ""}</p>;
                                                        })}
                                                    </Typography>
                                                </> : "-"}
                                        </CardContent>
                                        <CardActions sx={{ alignSelf: "end" }}>
                                            <ButtonGroup size={"small"}>
                                                {!hideEdit && <>
                                                    <IconButton onClick={(e) => {
                                                        console.log('row edit', row, will);
                                                        handleOpenMenu(e, row, will);
                                                        setOpen(false);
                                                        setEdit(true);
                                                    }} size={"small"}><Edit fontSize={"small"} /></IconButton>
                                                    <IconButton onClick={async () => {
                                                        Swal.fire({
                                                            title: "Are you sure?",
                                                            text: ("Do you want to reset the distribution for this asset?"),
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            allowEnterKey: false,
                                                            allowOutsideClick: false,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Yes"
                                                        }).then((result) => {
                                                            if (result.isConfirmed) {
                                                                deleteWill(selectedScenario?.id, id);
                                                            }
                                                        });
                                                    }} size={"small"}><Refresh fontSize={"small"} /></IconButton></>}
                                            </ButtonGroup>
                                        </CardActions>
                                    </Stack>
                                </Card>
                            </Grid>;
                        })}
                    </Grid>
                }
            </Container>
        </>
    )
        ;
}

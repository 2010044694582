import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../admin-dashboard/common/DashboardCard';

const PartnersCompleteWillsCard = () => {
  const [completeWillData, setCompleteWillData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGstPaidData = async () => {
      try {
        const response = await axios.get('/partners/dashboard/completeWills');
        const { completeWills } = response.data;
        setCompleteWillData({ completeWills });
      } catch (error) {
        console.error('Error fetching complete wills data:', error);
      }
    };

    fetchGstPaidData();
  }, []);

  const handleClick = () => {
    navigate('/dashboard/complete-wills-page');
  };

  return (
    <DashboardCard
      title="Complete Wills"
      subtitle={completeWillData?.completeWills ?? '--'}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default PartnersCompleteWillsCard;

import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
    Box,
    Stack,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Typography,
    Tooltip,
    Modal,
    TextField,
    Chip,
    Avatar
} from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

// utils
import { Link, useNavigate, useParams } from "react-router-dom";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import axios from "axios";
import { ChevronLeft, ChevronRight, Close, CloseRounded, HelpOutline, Logout } from "@mui/icons-material";

import toastr from "toastr";
import Swal from "sweetalert2";
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import _palette from "../../../theme/palette";
import { UserContext } from "../DashboardLayout";
import SideModal from "../../../components/SideModal";
import CenterModal from "../../../components/CenterModal";
import { LoadingContext } from "../../../App";

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: "none"
    // [theme.breakpoints.up('lg')]: {
    //   width: open ? `calc(100% - ${NAV_WIDTH + 1}px)` : 'initial',
    // },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up("lg")]: {
        minHeight: HEADER_MOBILE,
        padding: theme.spacing(0, 5, 0, 0)
    }
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

export default function Header({ onOpenNav, openNav }) {
    const { user, fetchUser } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [comment, setComment] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const { review } = useParams();
    const navigate = useNavigate();
    const [openEnrollAcknowledge, setOpenEnrollAcknowledge] = useState(null);

    const [profile, setProfile] = useState(user?.profile ?? {});
    const [generics, setGenerics] = useState({});
    const [schemas, setSchemas] = useState({});

    const fetchForms = () => {
        axios.get("/testator/forms").then(response => {
            setSchemas(response.data ?? {});
        }).catch(err => console.error(err));
    };

    useEffect(() => {
        setProfile(user?.profile ?? {});
    }, [user]);

    useEffect(() => {
        fetchUser();
        fetchForms();
    }, []);

    const saveProfile = () => {
        axios.patch(`/testator`, { profile }).then(response => {
            toastr.success("Profile updated successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Profile updated successfully",
                showConfirmButton: false,
                timer: 3000
            });
            fetchUser();
        }).catch(err => {
            // toastr.error("Failed to update profile")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to update profile",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    useEffect(() => {
        // console.log("profile",user);

        if (user.role === 'partner' && user.partner_enroll_acknowledge === false && user.partner_enroll_status === 'APPROVED') {
            setOpenEnrollAcknowledge(true);
        }

    }, [user]);

    const requestReview = () => {
        axios.patch("/testator/request/call", { comment }).then(res => {
            // toastr.success("Call request submitted")
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Call request submitted",
                showConfirmButton: false,
                timer: 3000
            });
        }).catch(e => {
            // toastr.error("Failed to request a call")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to request a call",
                showConfirmButton: false,
                timer: 3000
            });
        }).finally(() => {
            setOpen(false);
            fetchUser();
            if (user?.role === "reviewer") navigate("/reviewer", { replace: true });
        });
    };

    useEffect(() => {
        if (user?.role === "reviewer" && !!review) {
            axios.interceptors.request.use(config => {
                config.headers["X-Review-Id"] = +review;
                return config;
            });
        }
        return () => {
            axios.interceptors.request.clear();
        };
    }, [user, review]);
    const allowCalls = useMemo(() => {
        const callCount = user?.Reviews?.filter(rev => rev.type === "CALL")?.length ?? 0;
        const allowed = user?.Subscriptions?.reduce((p, c) => +p + +(c.calls ?? 0), 0);
        if (callCount < allowed) return true;
        return false;
    }, [user]);

    const allowSupportCalls = useMemo(() => {
        const callCount = user?.Reviews?.filter(rev => rev.type === "SUPPORT" && rev.status !== "CLOSED")?.length ?? 0;
        console.log('callCount', callCount);
        const allowed = 1;
        if (callCount < allowed) return true;
        return false;
    }, [user]);

    const demoModeActivated = useMemo(() => {
        const demoMode = user?.Subscriptions?.filter(sub => sub.UserSubscription.SubscriptionId === 1 && sub.UserSubscription.payment_status === "DEMO")?.length ?? 0;
        console.log('demoMode', demoMode);
        if (demoMode === 1 && user.role === 'partner') return true;
        return false;
    }, [user]);

    const usage = useMemo(() => {
        const used = user?.Reviews?.filter(rev => rev.type === "CALL")?.length ?? 0;
        const sub = user?.Subscriptions;
        const allowed = sub?.reduce((p, c) => +p + +c.calls, 0) ?? 0;
        return { used, allowed };
    }, [user]);

    const { isLoading, setShowHelp, video } = useContext(LoadingContext);

    const activeReview = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === 'REVIEW'))
            active = true;
        return active;
    }, [user]);

    const activeCallSupport = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === 'SUPPORT'))
            active = true;
        return active;
    }, [user]);


    const enrollAcknowledge = () => {
        // console.log('deleteAccount', user.email);
        axios.put(`/testator/enroll-acknowledge`).then(async (response) => {
            setOpenEnrollAcknowledge(false);
            await fetchUser();
            // toastr.success("Account deleted successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Acknowledged successfully",
                showConfirmButton: false,
                timer: 3000
            });

            // axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', { replace: true }))
        }).catch(err => {
            // toastr.error("Failed to delete account")
            setOpenEnrollAcknowledge(false);
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to acknowledge",
                showConfirmButton: false,
                timer: 3000
            });
        });
    }

    return (
        <StyledRoot sx={{
            width: openNav ? `calc(100% - ${NAV_WIDTH + 1}px)` : "calc(100% - 48px - 24px)"
        }}>
            <StyledToolbar>
                {/* <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary"
          }}
        >
          <img src="/assets/icons/menu-burger.png" alt="" />
        </IconButton> */}

                {/* {!!video && <Button startIcon={<HelpOutline />} onClick={() => setShowHelp(true)} sx={{ color: "primary.main" }}> */}
                {!!video && <Button onClick={() => {
                    // if(window.location.pathname === "/partner"){
                    setShowHelp(true);
                    // }
                }
                } sx={{ color: "primary.main" }}>
                    Videos
                </Button>}

                <Button href="https://www.getwilldone.com/faqs"
                    target="_blank" sx={{ color: "primary.main" }}>
                    FAQs
                </Button>

                {["partner", "testator"].includes(user?.role) &&
                    <Button disabled={activeCallSupport} onClick={() => {
                        if (allowSupportCalls)
                            setOpen(true);
                        else {
                            //   toastr.info("You request has been already registered, GWD Support team will get back to you");
                            Swal.fire({
                                position: "top-center",
                                icon: "info",
                                color: '#fff',
                                background: '#00838F',
                                toast: true,
                                title: "You request has been already registered, GWD Support team will get back to you",
                                showConfirmButton: false,
                                timer: 3000
                            });
                            //   navigate('/dashboard/subscriptions');
                        }
                    }}>Request a Call</Button>}
                <Button href="https://getwilldone.com/contact.us"
                    target="_blank" sx={{ color: "primary.main" }}>
                    Help
                </Button>
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1
                    }}
                >

                    {/* {demoModeActivated && <Chip label={<Typography variant="button" display="block">DEMO MODE</Typography>} color="primary" />} */}
                    {demoModeActivated && <Typography
                        color="primary"
                        sx={{
                            fontWeight: "bold",
                            fontSize: '14px',
                            flexWrap: "wrap",
                            textTransform: user?.full_name ? 'uppercase' : 'lowercase',
                            "& .MuiListItemText-root": {
                                textAlign: "center",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            },
                            "&.active": {
                                color: "text.primary",
                                borderLeft: '8px solid',
                                borderColor: 'primary.main',
                                bgcolor: "action.selected",
                                fontWeight: "fontWeightBold"
                            }
                        }}>
                        DEMO MODE
                    </Typography>}
                    {(["partner"].includes(user?.role) && !user.is_approved && user.partner_enroll_status !== "APPROVED") && <Tooltip title={'Enroll as a Partner'}><Button variant={"contained"} disabled={!user.profile_saved} sx={{ textTransform: 'none' }}
                        onClick={() => {
                            if (user.partner_enroll_status === null) {
                                Swal.fire({
                                    title: "Are you sure you want to initiate partner enrollment?",
                                    text: (``),
                                    icon: "warning",
                                    showCancelButton: true,
                                    allowEnterKey: false,
                                    allowOutsideClick: false,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        console.log('clicked');
                                        axios.get('/partners/enroll/partner').then((res) => {
                                            console.log('res', res);
                                            if (res.data.success) {
                                                if (!res.data.alreadyRequested) {
                                                    Swal.fire({
                                                        title: "Partner Enrollment process is initiated.",
                                                        text: ("You will receive an Email with Partner Enrollment Approval Details within 7 working days from GetWillDone. Now you will be Logged Out. Sign in after you have read the received email."),
                                                        icon: "success",
                                                        allowEnterKey: false,
                                                        allowOutsideClick: false,
                                                        confirmButtonColor: "#3085d6",
                                                        confirmButtonText: "OK"
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            // updateMappings();
                                                            axios.post('/auth/logout').then(() => {
                                                                // toastr.warning("Logged out successfully", "You have been idle for more than 15 minutes", { timeOut: 0 });
                                                                Swal.fire({
                                                                    position: "top-center",
                                                                    icon: "warning",
                                                                    color: '#fff',
                                                                    background: '#00838F',
                                                                    toast: true,
                                                                    title: "You have been logged out successfully.",
                                                                    showConfirmButton: false,
                                                                    timer: 3000
                                                                });
                                                                sessionStorage.removeItem('profile_saved');
                                                                sessionStorage.removeItem('profile');
                                                                sessionStorage.removeItem('beneficiary');
                                                                sessionStorage.removeItem(`previousGenericbeneficiaryData`);
                                                                sessionStorage.removeItem(`previousGenericexecutorData`);
                                                                sessionStorage.removeItem(`previousGenericwitnessData`);
                                                                sessionStorage.removeItem(`previousAssetData`);
                                                                sessionStorage.removeItem(`previousProfileData`);
                                                                sessionStorage.removeItem(`video_close_distribution`);
                                                                sessionStorage.removeItem(`video_close_executors`);
                                                                sessionStorage.removeItem(`video_close_assets`);
                                                                sessionStorage.removeItem(`video_close_beneficiaries`);
                                                                sessionStorage.removeItem(`video_close_witnesses`);
                                                                sessionStorage.removeItem(`video_close_preview`);
                                                                sessionStorage.removeItem(`video_close_app`);

                                                            })
                                                                .finally(() => navigate('/login', { replace: true }));
                                                        }
                                                    });
                                                }

                                            }
                                        })
                                    }
                                });
                            }
                            if (user.partner_enroll_status === 'REQUESTED') {
                                Swal.fire({
                                    title: "You have already initiated partner enrollment process",
                                    text: (`Please check your inbox for an email with the subject 'GetWillDone - Partner Enrollment Status'.`),
                                    icon: "warning",
                                    showConfirmButton: false,
                                    showCancelButton: true,
                                    allowEnterKey: false,
                                    allowOutsideClick: false,
                                    // confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    cancelButtonText: "OK"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // updateMappings();
                                    }
                                });
                            }


                            //   .finally(() => navigate('/login', { replace: true }))
                        }}>Enroll as a Partner</Button></Tooltip>}
                    {["partner", "testator"].includes(user?.role) && <Tooltip title={'Preview Will'}><Button variant={"contained"} component={Link} disabled={!user.profile_saved}
                        to={review ? `/reviewer/${review}/preview` : "/dashboard/preview"}>Preview Will</Button></Tooltip>}

                    <CenterModal
                        open={open}
                        centered
                        onClose={() => {
                            setOpen(false);
                        }}
                        title={`${review ? 'Close' : 'Request'} a Call`}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                            {!review && <Typography color="text.primary" variant="body2" component="p" sx={{ mb: 2 }}>
                                {/* You have {(usage.allowed - usage.used) ?? 0} free call of 30 min with your subscription. Please press "Request a call" button to register the call request. Our support team Will get back to you within next 48 hours. Calls left {(usage.allowed - usage.used) ?? 0} / {usage.allowed ?? 0} */}
                                This service is only for General Help. Please press "Request a Call" button to register the call request. Our support team will get back to you within 48 hours. Please use the "Call with Legal Expert" listed in the Services Tab for Legal Help.
                            </Typography>}
                        </Box>
                        <Stack sx={{ bgcolor: '#BDBDBD', m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                            <Button onClick={() => {
                                setOpen(false);
                            }}>Cancel</Button>
                            <Button disabled={!user.profile?.phone} style={{ marginTop: "8px" }} onClick={() => {
                                requestReview();
                            }}
                                variant="contained">
                                Request a Call
                            </Button>
                        </Stack>
                    </CenterModal>
                    <CenterModal
                        open={openEnrollAcknowledge}
                        // onClose={() => {
                        //     setOpenAcknowledge(false);
                        // }}
                        fullwidth
                        title={`GetWILLDone Partner Approved – Securely Preserve Your Legacy`}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                            <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    Thank you for choosing {' '}
                                    <strong>GetWillDone</strong>
                                    <span> – Your One-Stop Secure Platform for WILL and Inheritance.</span>
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    We appreciate your trust in GetWillDone as you embark on the journey to create your WILL. <strong>GetWillDone</strong> is a secure and user-friendly tool designed to preserve your legacy.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Your Partner Enrolment request has been Approved.</strong></Typography>
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Link:</strong></Typography> Please advise your customers to use this <a href={`${process.env.PUBLIC_URL}/register?partner_code=${user.partner_code}`} rel="noopener noreferrer" target="_blank">
                                        <span style={{ color: '#2c32e5' }}>
                                            <u>
                                                link
                                            </u>
                                        </span>
                                    </a>{' '}  to sign up and start making their WILL.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <span>
                                        Here's why <strong>GetWillDone</strong> is your best choice:
                                    </span>
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Data Privacy:</strong></Typography> We understand the sensitivity of this matter, and we treat it with the utmost care. Our platform employs industry-standard security measures, including encryption and robust data security protocols (SSL certified using SHA 256 encryption) to keep your information safe from unauthorized access, loss, or misuse. Your data is protected with high-level encryption and data security protocols.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>The Document:</strong></Typography> Find comfort in knowing that <strong>GetWillDone</strong>'s WILL format is prepared and vetted by one of the most trusted legal firms in the industry.
                                </Typography>
                                {/* Add the rest of your content using Typography and Link components */}
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Create Your WILL:</strong></Typography> You can start crafting your WILL by logging in at <a color="inherit" href="https://wills.getwilldone.com" rel="noopener noreferrer" target="_blank">
                                        <u>
                                            GetWillDone
                                        </u>
                                    </a>{' '}using your registered email ID or using your Google / Linkedin credentials.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Take Your Time:</strong></Typography> Whether you prefer to create your WILL in one go or over multiple sessions, your subscription allows for both. The Subscription is valid for 1 year from the date of payment.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>FAQs and Infographics:</strong></Typography> Our platform provides helpful infographics to guide you along the way, and you can find additional FAQs on our website for further assistance.
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <span>
                                        In the meantime, if you have any questions, please don't hesitate to reach out to us at <a target="_blank" rel="noopener noreferrer" color="inherit" href="mailto:info@getwilldone.com">
                                            <span style={{ color: '#2c32e5' }}>
                                                <u>
                                                    info@getwilldone.com
                                                </u>
                                            </span>
                                        </a>{' '} or SMS/WhatsApp at +91-9156888419.
                                    </span>
                                </Typography>
                                {/* Add the rest of your content using Typography and Link components */}
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    Thank you for choosing GetWillDone and now Get WILL Done!
                                </Typography>
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    Warm regards,<br />
                                    <strong>The GetWillDone Team</strong><br />
                                    <a color="inherit" href="https://wills.getwilldone.com" rel="noopener noreferrer" target="_blank">
                                        <u>
                                            www.GetWillDone.com
                                        </u>
                                    </a>
                                </Typography>
                                {/* <FormGroup>
                                <FormControlLabel checked={checkedAgreeOne} name='checkedAgreeOne' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your user data will be permanently deleted, including login credentials and any other personal information associated with your account." />
                                <br />
                                <FormControlLabel checked={checkedAgreeTwo} name='checkedAgreeTwo' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your subscriptions will be terminated. If you wish to use our service again in the future, you will need to sign up and register as a new user." />
                                <br />
                                <FormControlLabel checked={checkedAgreeThree} name='checkedAgreeThree' onChange={handleAgreeCheck} required control={<Checkbox />} label="If you have created any wills using our service, they will be deleted from our system as part of the account deletion process. However, please be aware that any wills you have already made using our service remain legally valid in accordance with applicable laws. To ensure you have a copy for your records, we recommend saving a digital and printed copy if you have not already done so." />
                            </FormGroup> */}
                            </Stack>
                        </Box>
                        <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"}>
                            <Button style={{ marginLeft: '8px', marginTop: "8px" }} onClick={() => {
                                console.log('acknowledge');
                                enrollAcknowledge();
                            }} variant="contained">
                                Acknowledge
                            </Button>
                        </Stack>
                    </CenterModal>
                    {/* <Chip variant="flat" label={user?.full_name} /> */}
                    {/* <Button sx={{
            flexWrap: "wrap",
            height: "fit-content",
            fontSize: 11,
            "& .MuiListItemText-root": {
              textAlign: "center",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            },
            "&.active": {
              color: "text.primary",
              borderLeft: '8px solid',
              borderColor: 'primary.main',
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold"
            }
          }} startIcon={<Logout sx={{ml: 1.5}} />} onClick={() => {
            axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', {replace: true}))
          }}>
            Logout
          </Button> */}
                    <AccountPopover />
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import { format } from "date-fns";

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, useTheme, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Swal from "sweetalert2";
import Iconify from "../../../components/iconify";
import { useStateWithCallback } from "../../../utils/useStateWithCallback";
import { PasswordField } from "../../../components/PasswordField";
import { OTPField } from "../../../components/OTPField";

export default function ForgotPasswordForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [pan, setPan] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [maskedEmail, setMaskedEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [dob, setDob] = useState(null);
    const [step, setStep] = useState(0);
    const [otp, setOTP] = useState("");
    const [isPhone, setIsPhone] = useState(true);
    const interval = useRef(null);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref);
            //   toastr.error("OTPs have expired. Please click resend to generate new OTPs");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000
            });
        }
    });

    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    const handleClick = useCallback(() => {
        axios.post("/auth/update-password", {
            email, password, otp
        }).then(() => {
            //   toastr.success("Password updated successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Password updated successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            navigate("/login", { replace: true });
        }).catch(() => {
            //   toastr.error("Invalid OTP! Please enter valid OTP");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Invalid OTP! Please enter valid OTP`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    }, [email, password, otp]);

    const generateOTP = useCallback(() => {
        // const formattedDOB = format(dob, "yyyy-MM-dd");
        axios.post("/auth/forgot-password", {
            //   email, pan, dob: formattedDOB
            email
        }).then((res) => {
            setStep(1);
            if (res.data.sendTo === "email") {
                setMaskedEmail(res.data.email);
                setIsPhone(false);
            }
            else {
                setPhone(res.data.phone);
                setIsPhone(true);
            }
            if (interval.ref !== null) clearInterval(interval.ref);
            setSeconds(900);
            interval.ref = setInterval(() => {
                setSeconds(s => s - 1);
            }, 1000);
        }).catch((e) => {
            if (e.response.data.message) {
                // toastr.error(e.response.data.message);
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            else {
                // toastr.error("Unable to generate OTP! Please check the entered details");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Unable to generate OTP! Please check the entered details`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [email, pan, dob]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === "enter" && !e.repeat) {
                e.preventDefault();
                if (step === 0) {
                    //   if (/^[5-9][0-9]{9}$/.test(phone ?? "") && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "")) && age > 18) {
                    if (/^\+\d{1,3}-\d{6,10}$/.test(phone ?? "") && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "")) && age > 18) {
                        generateOTP();
                    }
                } else if (step === 1) {
                    if (otp.length === 6 && seconds > 0 && password.length > 7) {
                        handleClick();
                    }
                }
            }
        };
        window.addEventListener("keyup", handleEnter);
        return () => {
            window.removeEventListener("keyup", handleEnter);
        };
    }, [phone, email, password, otp, step, handleClick]);

    const age = useMemo(() => {
        const birthDate = dob ?? new Date();
        const today = new Date();
        let yrs = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            yrs -= 1;
        }
        return yrs;
    }, [dob]);

    const theme = useTheme();

    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%^&*()_+])[a-zA-Z0-9!@#\\$%^&*()_+]{8,}$/;
    return (
        <>
            <Stack spacing={3}>
                {[
                    <>
                        <Typography variant="h3">Forgot Password</Typography>
                        {/* <TextField name="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} /> */}
                        <TextField name="email" error={!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "")}
                            helperText={!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") ? "Please enter a valid email" : ""}
                            label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {/* <TextField name="email" error={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "")}
                       helperText={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "") ? "Please enter a valid PAN" : ""}
                       label="PAN" id="pan-input" value={pan} onChange={(e) => setPan(e.target.value)} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker inputFormat={"dd-MM-yyyy"} onChange={setDob} value={dob} label={"Date of Birth"}
                          renderInput={(params) => <TextField {...params} placeholder={"DD-MM-YYYY"}
                                                              helperText={!dob ? "Please select your Date of Birth" : age < 18 ? "You should be older than 18" : ""}
                                                              error={!dob || age < 18} />} />
            </LocalizationProvider> */}
                        <LoadingButton
                            //   disabled={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "") || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || !(age >= 18)}
                            disabled={!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "")}
                            fullWidth size="large" type="button" variant="contained" onClick={() => generateOTP()}>
                            Request OTP
                        </LoadingButton>
                        <Typography align={"center"} variant="body2" sx={{ mb: 5 }}>
                            Remembered your password? <Link component={RouterLink} to={"/login"} variant="subtitle2">Sign In</Link>
                        </Typography>
                    </>, <>
                        <Typography variant="h5" align="center">OTP Verification</Typography>
                        <Typography variant="body2" align={"center"}>
                            Enter the OTP sent to your registered {isPhone ? "mobile number" : "email address"}: {isPhone ? phone : maskedEmail}
                        </Typography>
                        <OTPField containerStyle={{ justifyContent: "space-around" }} inputStyle={{
                            fontSize: theme.typography.fontSize,
                            width: theme.spacing(4),
                            padding: theme.spacing(1.5)
                        }} value={otp} onChange={setOTP} numInputs={6} isInputNum isInputSecure />
                        <PasswordField name="password" type="password" label="Password" value={password} error={!passwordRegex.test(password)} helperText={!passwordRegex.test(password) ? "Password should be at least 8 characters long and must contain at least one uppercase, one lowercase, one number and one special case character" : ""}
                            onChange={(e) => setPassword(e.target.value)} />
                        <PasswordField error={password !== confirmPassword}
                            helperText={password !== confirmPassword ? "Password mismatch! Please enter same password in both fields" : ""}
                            name="confirm-password" type="password" label="Confirm Password" value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} />
                        <LoadingButton disabled={seconds <= 0 || (otp.length !== 6) || password !== confirmPassword || password.length < 8} fullWidth
                            size="large"
                            type="submit" variant="contained" onClick={handleClick}>
                            Verify
                        </LoadingButton>
                        <Typography variant="body2"
                            sx={{ mb: 5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{
                                display: "flex",
                                color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : "text.primary"
                            }}><Iconify sx={{ color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : "text.primary" }}
                                icon="uil:clock" /> {seconds > 0 ? timer : "OTP Expired! Please click resend to generate new ones"}
                            </Box><Link component={Button} disabled={seconds > 840} href={"#"} onClick={(e) => {
                                e.preventDefault();
                                generateOTP();
                            }} variant="subtitle2">Resend</Link>
                        </Typography>
                    </>
                ][step]}
            </Stack>
        </>
    );
}

import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Link, Container, Typography, Divider, Stack, Button, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import Fab from '@mui/material/Fab';
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Logo from "../components/logo";
import Iconify from "../components/iconify";
// sections
import { LoginForm, RegisterForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function RegistrationPage() {
  const mdUp = useResponsive("up", "md");

  return (
    <>
        <Helmet>
            <title> Register | Get Will Done </title>
        </Helmet>
        <StyledRoot>
            <Container maxWidth={false} sx={{ width: '50vw',  minWidth: '500px', margin: 0}}>
                <StyledContent style={{ justifyContent: 'center',alignItems: 'center', paddingTop: 0, paddingBottom: 6 }}>
                    <div style={{padding: '24px', paddingTop:0, background: 'white'}}>
                        <Logo sx={{alignItems : "center"}} disabledLink />
                        <RegisterForm />
                    </div>
                </StyledContent>
                <div className='hexagons-container'>
                    <div className="lines lines1" />
                    <div className="lines lines2" />
                    <div className="hexagon hexagon1"><div className="hexagon-in1"><div className="hexagon-in2" /></div></div>
                    <div className="hexagon hexagon2"><div className="hexagon-in1"><div className="hexagon-in2" /></div></div>
                    <div className="hexagon hexagon3"><div className="hexagon-in1"><div className="hexagon-in2" /></div></div>
                    <div className="hexagon hexagon4"><div className="hexagon-in1"><div className="hexagon-in2" /></div></div>
                    <div className="hexagon hexagon5"><div className="hexagon-in1"><div className="hexagon-in2" /></div></div>
                </div>
                <Tooltip title="Help">
                    <Fab sx={{ position: 'fixed', bottom: 16, right: 16 }} size="small" aria-label={"Help"} color='primary'
                        href="https://www.getwilldone.com/faqs"
                        target="_blank"
                    >
                        {<HelpIcon />}
                    </Fab>
                </Tooltip>
            </Container>
        </StyledRoot>
    </>
  );
}

import { Box, Container, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import TotalNumberOfClientsCard from '../components/admin-dashboard/TotalNumberOfClientsCard';
import TopPerformingPartnerCard from '../components/admin-dashboard/TopPerformingPartnerCard';
import TotalNumberOfPartnersCard from '../components/admin-dashboard/TotalNumberOfPartnersCard';
import ConversionRatioCard from '../components/admin-dashboard/ConversionRatioCard';
import AgeDemographicChart from '../components/admin-dashboard/AgeDemographicChart';
import WillGenerationTrendsChart from '../components/admin-dashboard/WillGenerationTrendsChart';
import AverageTimeCard from '../components/admin-dashboard/AverageTimeCard';
import RepeatRateCard from '../components/admin-dashboard/RepeatRateCard';
import TotalRevenueCard from '../components/admin-dashboard/TotalRevenueCard';
import TotalCommissionPaidCard from '../components/admin-dashboard/TotalCommissionPaidCard';
import TotalGstPaidCard from '../components/admin-dashboard/TotalGstPaidCard';
import RevenueTrendsCart from '../components/admin-dashboard/RevenueTrendsChart';
import PartnerVsDirectRevenueChart from '../components/admin-dashboard/PartnerVsDirectRevenueChart';
import UserJourney from '../components/admin-dashboard/UserJourney';
import CompleteWillsCard from '../components/admin-dashboard/CompleteWillsCard';
import IncompleteWillsCard from '../components/admin-dashboard/IncompleteWillsCard';
import ServiceWiseRevenue from '../components/admin-dashboard/ServiceWiseRevenue';

const AdminAnalyticalDashboard = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px)');
  return (
    <Container
      maxWidth="xl"
      sx={{ p: '8px 16px', display: 'flex', flexDirection: 'column', gap: '56px', marginBottom: '96px' }}
    >
      <Helmet>
        <title> Admin Dashboard | Get Will Done </title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
          gap: '8px',
        }}
      >
        <TotalNumberOfClientsCard />
        <TotalNumberOfPartnersCard />
        <TopPerformingPartnerCard />
        <ConversionRatioCard />
        <AverageTimeCard />
        <RepeatRateCard />
        <TotalRevenueCard />
        <TotalCommissionPaidCard />
        <TotalGstPaidCard />
        <CompleteWillsCard />
        <IncompleteWillsCard />
      </Box>
      <Box>
        <WillGenerationTrendsChart />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: !isLargeScreen ? '48px' : '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: isSmallScreen || isMediumScreen ? 'wrap' : 'nowrap',
        }}
      >
        <AgeDemographicChart />
        <PartnerVsDirectRevenueChart />
      </Box>
      <Box>
        <RevenueTrendsCart />
      </Box>
      <Box>
        <ServiceWiseRevenue />
      </Box>
      <Box>
        <UserJourney />
      </Box>
    </Container>
  );
};

export default AdminAnalyticalDashboard;

import { Box, Container, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import TotalNumberOfPartnerClientsCard from '../components/partner-dashboard/TotalNumberOfPartnerClientsCard';
import PartnersConversionRatioCard from '../components/partner-dashboard/PartnersConversionRatioCard';
import PartnersCompleteWillsCard from '../components/partner-dashboard/PartnersCompleteWillsCard';
import PartnersIncompleteWillsCard from '../components/partner-dashboard/PartnersIncompleteWillsCard';
import TotalCommissionReceivedCard from '../components/partner-dashboard/TotalCommissionReceivedCard';
import PartnersWillGenerationTrendsChart from '../components/partner-dashboard/PartnersWillGenerationTrendsChart';
import PartnersAgeDemographicChart from '../components/partner-dashboard/PartnersAgeDemographicChart';

const PartnerAnalyticalDashboard = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px)');
  return (
    <Container
      maxWidth="xl"
      sx={{ p: '8px 16px', display: 'flex', flexDirection: 'column', gap: '56px', marginBottom: '96px' }}
    >
      <Helmet>
        <title> Partner Dashboard | Get Will Done </title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen || isMediumScreen ? '1fr' : 'repeat(2, 1fr)',
          gap: '8px',
        }}
      >
        <TotalNumberOfPartnerClientsCard />
        <PartnersConversionRatioCard />
        <PartnersCompleteWillsCard />
        <PartnersIncompleteWillsCard />
        <Box
          sx={{
            gridColumn: isSmallScreen || isMediumScreen ? 'span 1' : 'span 2',
            justifySelf: 'center',
            width: isSmallScreen || isMediumScreen ? '100%' : '50%',
          }}
        >
          <TotalCommissionReceivedCard />
        </Box>
      </Box>
      <PartnersWillGenerationTrendsChart/>
      <PartnersAgeDemographicChart/>
    </Container>
  );
};

export default PartnerAnalyticalDashboard;

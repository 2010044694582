import { Navigate, Outlet, useNavigate, useRoutes } from "react-router-dom";
// layouts
import { useEffect, useState } from "react";
import axios from "axios";

import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import BlogPage from "./pages/BlogPage";
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/ProductsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import AssetPage from "./pages/AssetPage";
import GenericPage from "./pages/GenericsPage";
import WillPage from "./pages/WillPage";
import PreviewPage from "./pages/PreviewPage";
import ReviewerDashboardAppPage from "./pages/ReviewerDashboardAppPage";
import AdminDashboardAppPage from "./pages/AdminDashboardAppPage";
import { ErrorBoundary } from "./components/ErrorBoundary";
import RegistrationPage from "./pages/RegistrationPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import CouponsPage from "./pages/CouponsPage";
import PartnersPage from "./pages/PartnersPage";
import PartnerSignUpPage from "./pages/PartnerSignUpPage";
import DataChecklistPage from "./pages/DataChecklistPage";
import ReportsPage from "./pages/Reports";
import CommissionPage from "./pages/CommissionPage";
import AdminAnalyticalDashboard from "./pages/AdminAnalyticalDashboard";
import PartnerAnalyticalDashboard from "./pages/PartnerAnalyticalDashboard";
import NumberOfClientsPage from "./pages/AdminDashboardPages/NumberOfClientsPage";
import TopPerformerPage from "./pages/AdminDashboardPages/TopPerformerPage";
import ConversionRatioPage from "./pages/AdminDashboardPages/ConversionRatioPage";
import RepeatRatePage from "./pages/AdminDashboardPages/RepeatRatePage";
import IncompleteWillsPage from "./pages/AdminDashboardPages/IncompleteWillsPage";
import CompletedWillsPage from "./pages/AdminDashboardPages/CompletedWillsPage";
import GstPaidDetailsPage from "./pages/AdminDashboardPages/GstPaidDetailsPage";
import CommissionDetailsPage from "./pages/AdminDashboardPages/CommissionDetailsPage";
import PartnerNumberOfClientsPage from "./pages/PartnerDashboardPages/PartnerNumberOfClientsPage";
import PartnerConversionRatioPage from "./pages/PartnerDashboardPages/PartnerConversionRatioPage";
import PartnerCompletedWillsPage from "./pages/PartnerDashboardPages/PartnerCompletedWillsPage";
import PartnerIncompleteWillsPage from "./pages/PartnerDashboardPages/PartnerIncompleteWillsPage";

// ----------------------------------------------------------------------

export default function Router() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [tokenCheck, setTokenCheck] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('useEffect routes.js', console.log('location', window.location.pathname));
        axios.get("/auth/user").then(response => {
            setTokenCheck(true);
        }).catch(err => {
            setTokenCheck(false);
            setLoggedIn(false);
            console.log('useEffect routes.js', console.log('location', window.location.pathname));
            if (window.location.pathname === "/partner") {
                navigate('/partner', { replace: true });
            }
            console.log('location', window.location.search);
            const params = new URLSearchParams(window.location.search);
            console.log("errorparams", params);
            const partnerCode = params.get('partner_code');

            if (partnerCode && window.location.pathname === "/register") {
                navigate(`/register?partner_code=${partnerCode}`, { replace: true });
            }
        }).finally(() => setLoading(false));
    }, []);

    const isProfileComplete = (role, profile) => {
        if (role === 'testator') {
            if (profile === null) {
                return false;
            }
            if (profile.salutation) {
                return true;
            }
            return false;
            // { "current": { "house_name": "205, New Mamta Apartment", "street": "Near", "house_no": "225", "area": "205, New Mamta Apartment", "zip": "421004", "city": "THANE", "state": "Maharashtra", "country": "India" }, "permanent": { "house_name": "205, New Mamta Apartment", "street": "Near", "house_no": "225", "area": "205, New Mamta Apartment", "zip": "421004", "city": "THANE", "state": "Maharashtra", "country": "India" }, "salutation": "Mr.", "first_name": "Sameer", "last_name": "Awate", "pan": "ABCDE1234G", "dob": "1985-01-01", "same_as_current": true, "saved": true, "phone": "in:91:4569878955" }
        }
        if (role === 'partner') {
            if (profile === null) {
                return false;
            }
            if (profile.dob) {
                return true;
            }
            return false;
        }
        return false;
    }
    useEffect(() => {
        if (!tokenCheck) return;
        setLoading(true);
        axios.get("/auth/user").then(response => {
            setLoggedIn(true);
            setUser(response.data.user);
            console.log('response.data.user', response.data.user);
            // console.log('response.data.user', response.data.user.role === 'testator', response.data.user.profile !== null, response.data.user.Subscriptions.length === 0);
            if (["testator"].includes(response.data.user.role) && isProfileComplete(response.data.user.role, response.data.user.profile) && response.data.user.Subscriptions.length === 0) {
                navigate("/dashboard/subscriptions");
            }
            if (["partner"].includes(response.data.user.role) && isProfileComplete(response.data.user.role, response.data.user.profile) && response.data.user.profile.dob !== null && response.data.user.Subscriptions.length === 0) {
                navigate("/dashboard/subscriptions");
            }
            if (["admin"].includes(response.data.user.role)) {
                navigate("/admin/analyticaldashboard");
            }
        }).catch(err => {
            setLoggedIn(false);
            setUser({});
            console.error(err);
        }).finally(() => setLoading(false));
    }, [tokenCheck]);


    const routes = useRoutes([
        {
            path: "*",
            element: loading ? <div>Loading...</div> : <Outlet />,
            children: [
                { element: <Navigate replace to="admin/app" />, index: true },
                {
                    path: "dashboard",
                    element: loggedIn && ["testator", "partner"].includes(user?.role) ? <DashboardLayout /> : <Navigate replace to="/login" />,
                    children: [
                        { element: <Navigate replace to="/dashboard/app" />, index: true },
                        { path: "app", element: <DashboardAppPage /> },
                        { path: "user", element: <UserPage /> },
                        { path: "assets", element: <AssetPage /> },
                        { path: "beneficiaries", element: <GenericPage genericType={"beneficiary"} /> },
                        { path: "executors", element: <GenericPage genericType={"executor"} /> },
                        { path: "witnesses", element: <GenericPage genericType={"witness"} /> },
                        { path: "distribution", element: <WillPage /> },
                        { path: "subscriptions", element: <SubscriptionPage /> },
                        { path: "preview", element: <ErrorBoundary><PreviewPage /></ErrorBoundary> },
                        { path: "products", element: <ProductsPage /> },
                        { path: "blog", element: <BlogPage /> },
                        { path: "datachecklist", element: <DataChecklistPage /> },
                        { path: "commission", element: <CommissionPage /> },
                        { path: 'partnerdashboard', element: <PartnerAnalyticalDashboard /> },
                        { path: 'no-of-clients', element: <PartnerNumberOfClientsPage /> },
                        { path: 'conversion-ratio-page', element: <PartnerConversionRatioPage /> },
                        { path: 'commission-details-page', element: <CommissionPage /> },
                        { path: 'complete-wills-page', element: <PartnerCompletedWillsPage /> },
                        { path: 'incomplete-wills-page', element: <PartnerIncompleteWillsPage /> },
                    ]
                },
                {
                    path: "reviewer",
                    element: loggedIn && user?.role === "reviewer" ? <DashboardLayout /> : <Navigate replace to="/dashboard/app" />,
                    children: [
                        { element: <Navigate replace to="/reviewer/app" />, index: true },
                        { path: "app", element: <ReviewerDashboardAppPage /> },
                        {
                            path: ":review", element: <Outlet />,
                            children: [
                                { path: "distribution", element: <WillPage /> },
                                { path: "preview", element: <ErrorBoundary><PreviewPage /></ErrorBoundary> },
                            ]
                        },
                        { path: "user", element: <UserPage /> },
                        { path: "assets", element: <AssetPage /> },
                        { path: "beneficiaries", element: <GenericPage genericType={"beneficiary"} /> },
                        { path: "executors", element: <GenericPage genericType={"executor"} /> },
                        { path: "witnesses", element: <GenericPage genericType={"witness"} /> },
                        { path: "distribution", element: <WillPage /> },
                        { path: "preview", element: <ErrorBoundary><PreviewPage /></ErrorBoundary> },
                        { path: "products", element: <ProductsPage /> },
                        { path: "blog", element: <BlogPage /> }
                    ]
                },
                {
                    path: "agent",
                    element: loggedIn && user?.role === "agent" ? <DashboardLayout /> : <Navigate replace to="/reviewer/app" />,
                    children: [
                        { element: <Navigate replace to="/reviewer/app" />, index: true },
                        { path: "app", element: <ReviewerDashboardAppPage /> },
                        {
                            path: ":review", element: <Outlet />,
                            children: [
                                { path: "distribution", element: <WillPage /> },
                                { path: "preview", element: <ErrorBoundary><PreviewPage /></ErrorBoundary> },
                            ]
                        },
                        { path: "user", element: <UserPage /> },
                        { path: "assets", element: <AssetPage /> },
                        { path: "beneficiaries", element: <GenericPage genericType={"beneficiary"} /> },
                        { path: "executors", element: <GenericPage genericType={"executor"} /> },
                        { path: "witnesses", element: <GenericPage genericType={"witness"} /> },
                        { path: "distribution", element: <WillPage /> },
                        { path: "preview", element: <ErrorBoundary><PreviewPage /></ErrorBoundary> },
                        { path: "products", element: <ProductsPage /> },
                        { path: "blog", element: <BlogPage /> }
                    ]
                },
                {
                  path: 'admin',
                  element: loggedIn && user?.role === 'admin' ? <DashboardLayout /> : <Navigate replace to="/agent/app" />,
                  children: [
                    { element: <Navigate replace to="/admin/app" />, index: true },
                    // { path: "app", element: <CouponsPage /> },
                    { path: 'app', element: <AdminAnalyticalDashboard /> },
                    {
                      path: ':review',
                      element: <Outlet />,
                      children: [
                        { path: 'distribution', element: <WillPage /> },
                        {
                          path: 'preview',
                          element: (
                            <ErrorBoundary>
                              <PreviewPage />
                            </ErrorBoundary>
                          ),
                        },
                      ],
                    },
                    { path: 'user', element: <UserPage /> },
                    { path: 'assets', element: <AssetPage /> },
                    { path: 'beneficiaries', element: <GenericPage genericType={'beneficiary'} /> },
                    { path: 'executors', element: <GenericPage genericType={'executor'} /> },
                    { path: 'witnesses', element: <GenericPage genericType={'witness'} /> },
                    { path: 'distribution', element: <WillPage /> },
                    {
                      path: 'preview',
                      element: (
                        <ErrorBoundary>
                          <PreviewPage />
                        </ErrorBoundary>
                      ),
                    },
                    { path: 'products', element: <ProductsPage /> },
                    { path: 'blog', element: <BlogPage /> },
                    {
                      path: 'analyticaldashboard',
                      element: <AdminAnalyticalDashboard />,
                      children: [
                        // { path: 'no-of-clients', element: <NumberOfClientsPage /> }
                        // { path: 'top-performer-page', element: <TopPerformerPage /> }
                      ],
                    },
                    { path: 'no-of-clients', element: <NumberOfClientsPage /> },
                    { path: 'top-performer-page', element: <TopPerformerPage /> },
                    { path: 'conversion-ratio-page', element: <ConversionRatioPage /> },
                    { path: 'repeat-rate-page', element: <RepeatRatePage /> },
                    { path: 'commission-details-page', element: <CommissionDetailsPage /> },
                    { path: 'partner-details-page', element: <PartnersPage /> },
                    { path: 'gst-details-page', element: <GstPaidDetailsPage /> },
                    { path: 'complete-wills-page', element: <CompletedWillsPage /> },
                    { path: 'incomplete-wills-page', element: <IncompleteWillsPage /> },
                    // { path: 'partnerdashboard', element: <PartnerAnalyticalDashboard /> },
                    { path: 'coupons', element: <CouponsPage /> },
                    { path: 'partners', element: <PartnersPage /> },
                    { path: 'reports', element: <ReportsPage /> },
                  ],
                },
                // {
                //     path: "analyticaldashboard",
                //     // element: <NumberOfClientsPage />
                //     children
                // },
                {
                    path: "login",
                    element: <LoginPage />
                },
                {
                    path: "register",
                    element: <RegistrationPage />
                },
                {
                    path: "partner",
                    element: <PartnerSignUpPage />
                },
                {
                    path: "forgot-password",
                    element: <ForgotPasswordPage />
                },
                {
                    element: <SimpleLayout />,
                    children: [
                        { element: <Navigate replace to="/dashboard/app" />, index: true },
                        { path: "404", element: <Page404 /> },
                        { path: "*", element: <Navigate replace to="/404" /> }
                    ]
                },
                {
                    path: "*",
                    element: <Navigate to="/404" replace />
                }
            ]
        }
    ]);

    return routes;
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const TotalNumberOfClientsCard = () => {
  const [clientsData, setClientsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalNumberOfClients = async () => {
      try {
        const response = await axios.get('/admin/total-testators');
        const { totalTestators } = response.data;
        setClientsData({ totalTestators });
      } catch (error) {
        console.error('Error fetching number of clients data:', error);
      }
    };

    fetchTotalNumberOfClients();
  }, []);

  const handleClick = () => {
    navigate('/admin/no-of-clients');
  };

  return (
    <>
      <DashboardCard
        title="Number of Clients"
        subtitle={(clientsData.totalTestators ?? '--') || 0}
        handleClick={handleClick}
        isClickable
      />
    </>
  );
};

export default TotalNumberOfClientsCard;

import Joyride, { STATUS } from "react-joyride";
import { Helmet } from "react-helmet-async";
import { filter, isNull, omitBy } from "lodash";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import toastr from "toastr";
import { differenceInYears, format, subYears } from "date-fns";

import Swal from "sweetalert2";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Box, Modal, FormControl, InputLabel, Select, ButtonGroup, FormControlLabel, Tooltip
} from "@mui/material";

import { Check, CheckCircle, Close, CloseRounded } from "@mui/icons-material";
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
// components
import { useNavigate } from "react-router-dom";
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { OneOfControl, OneOfTester } from "../components/renderers/Autocomplete";
import CustomAutocomplete, { OneOfCustomAutocompleteControl } from "../components/renderers/CustomAutocomplete";
import { ImagePickerControl, ImagePickerTester } from "../components/renderers/ImagePicker";
import BottomModal from "../components/BottomModal";
import CenterModal from "../components/CenterModal";
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import { translator } from "../utils/jsonForms";
import { fetchDataFromZip } from "../utils/zip";
import { LoadingContext } from "../App";
import { PhoneInputControl, PhoneInputTester } from "../components/renderers/PhoneInput";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: "id", label: "Serial", alignRight: false },
    { id: "type", label: "Type", alignRight: false },
    { id: "information", label: "Information", alignRight: false },
    { id: "" }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80vh",
    overflow: "auto",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const formatPhone = (value) => {
    if (value) {
        const [country, dial, phone] = value?.split(":");
        if (country && dial && phone) {
            return `+${dial} ${phone}`;
        }
    }
    return undefined;
};

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const map = {
    executor: "Executors",
    witness: "Witnesses",
    beneficiary: "Beneficiaries"
};

let timeoutId = null; // Declare a variable to store the timeout ID

export default function GenericPage({ genericType }) {
    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("");

    const [filterName, setFilterName] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [generics, setGenerics] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [witnesses, setWitnesses] = useState([]);
    const [executors, setExecutors] = useState([]);

    const [sameAsTestator, setsameAsTestator] = useState(false);
    const [sameAsTestatorGuardian, setsameAsTestatorGuardian] = useState(false);

    const [schemas, setSchemas] = useState({});

    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [genericData, setGenericData] = useState({ same_as_testator: false, guardian: { same_as_testator: false } });
    const [previousGenericData, setPreviousGenericData] = useState(null);
    const [errors, setErrors] = useState({});
    const [type, setType] = useState("");
    const [copyFromBeneficiary, setCopyFromBeneficiary] = useState(null);

    const navigate = useNavigate();

    // const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
    const [helperText, setHelperText] = useState(null);

    const fetchGenerics = (type) => {
        axios.get(`/generics/${type}`).then(response => {
            if (type === "beneficiary") setBeneficiaries(response.data?.generics ?? []);
            if (type === "witness") setWitnesses(response.data?.generics ?? []);
            if (type === "executor") setExecutors(response.data?.generics ?? []);
            if (type === genericType) setGenerics(response.data?.generics ?? []);
        }).catch(err => console.error(err));
    };

    const fetchForms = () => {
        axios.get(`/generics/${genericType}/forms`).then(response => {
            setSchemas(response.data ?? {});
        }).catch(err => console.error(err));
    };

    const addNewGeneric = () => {
        axios.post(`/generics/${genericType}`, { ...genericData }).then(response => {
            // toastr.success(`${sentenceCase(genericType)} saved successfully`);
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `${sentenceCase(genericType)} saved successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchGenerics(genericType);
            setGenericData({});
            setAddNew(false);
            setEdit(false);
            console.log('generics.length', generics.length)
            let message = ``;
            if (genericType === 'beneficiary' && generics.length === 0)
                message = `The next step is to add an Asset`;
            if (genericType === 'executor' && generics.length === 0)
                message = `The next step is to add an Witness`;
            if (genericType === 'witness' && generics.length === 0)
                message = `Minimum two witnesses required.`;
            if (genericType === 'witness' && generics.length === 1)
                message = `The next step is to Generate WILL`;

            if (message) {
                setTimeout(() => {
                    Swal.fire({
                        title: message,
                        // text: ("Add Asset"),
                        icon: "info",
                        showConfirmButton: false,
                        showCancelButton: true,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                        // confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: "OK"
                    }).then((result) => {
                        console.log('pop up result', result);
                        if (result.isConfirmed) {
                            // updateMappings();
                        }
                        else if (genericType === 'witness' && generics.length === 1) {
                            navigate('/dashboard/preview')
                        }
                    });
                }, 3000);
            }

        }).catch(err => {
            // toastr.error(`Failed to add ${sentenceCase(genericType)}`)
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to add ${sentenceCase(genericType)}`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    const setPreviousGeneric = () => {
        if (editingId) {
            console.log('setPreviousGeneric', genericData);
            const previousGenericData = JSON.parse(sessionStorage.getItem(`previousGeneric${genericType}Data`));
            console.log('setPreviousGeneric', previousGenericData);
            axios.post(`/generics/${genericType}/${editingId}`, { ...previousGenericData, pan: previousGenericData?.pan !== undefined ? previousGenericData?.pan.toUpperCase() : null, middle_name: previousGenericData?.middle_name !== undefined ? previousGenericData?.middle_name : null, email: previousGenericData?.email !== undefined ? previousGenericData?.email : null, phone: previousGenericData?.phone !== undefined ? previousGenericData?.phone : null, house_no: previousGenericData?.house_no !== undefined ? previousGenericData?.house_no : null, house_name: previousGenericData?.house_name !== undefined ? previousGenericData?.house_name : null, street: previousGenericData?.street !== undefined ? previousGenericData?.street : null, area: previousGenericData?.area !== undefined ? previousGenericData?.area : null, city: previousGenericData?.city !== undefined ? previousGenericData?.city : null, state: previousGenericData?.state !== undefined ? previousGenericData?.state : null, country: previousGenericData?.country !== undefined ? previousGenericData?.country : null, zip: previousGenericData?.zip !== undefined ? previousGenericData?.zip : null }).then(response => {
                // toastr.success(`${sentenceCase(genericType)} updated successfully`);
                sessionStorage.removeItem(`previousGeneric${genericType}Data`);
                fetchGenerics(genericType);
            }).catch(err => {
                // toastr.error(`Failed to update ${sentenceCase(genericType)}`);
                // Swal.fire({
                //     position: "top-center",
                //     icon: "error",
                //     color: '#fff',
                //     background: '#00838F',
                //     toast: true,
                //     title: `Failed to update ${sentenceCase(genericType)}`,
                //     showConfirmButton: false,
                //     timer: 3000
                // });
            });
        }

    };

    const editGeneric = (autoSave = false) => {
        console.log('editGenericData', genericData);
        axios.post(`/generics/${genericType}/${editingId}`, { ...genericData, pan: genericData.pan !== undefined ? genericData.pan.toUpperCase() : null, middle_name: genericData.middle_name !== undefined ? genericData.middle_name : null, email: genericData.email !== undefined ? genericData.email : null, phone: genericData.phone !== undefined ? genericData.phone : null, house_no: genericData.house_no !== undefined ? genericData.house_no : null, house_name: genericData.house_name !== undefined ? genericData.house_name : null, street: genericData.street !== undefined ? genericData.street : null, area: genericData.area !== undefined ? genericData.area : null, city: genericData.city !== undefined ? genericData.city : null, state: genericData.state !== undefined ? genericData.state : null, country: genericData.country !== undefined ? genericData.country : null, zip: genericData.zip !== undefined ? genericData.zip : null }).then(response => {
            // toastr.success(`${sentenceCase(genericType)} updated successfully`);
            if (autoSave === false) {

                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `${sentenceCase(genericType)} updated successfully`,
                    showConfirmButton: false,
                    timer: 3000
                });
                setEdit(false);
                setEditingId(null);
                setGenericData({});
                setOpen(false);
            }
            fetchGenerics(genericType);
        }).catch(err => {
            // toastr.error(`Failed to update ${sentenceCase(genericType)}`);
            if (autoSave === false) {

                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Failed to update ${sentenceCase(genericType)}`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    };

    const deleteGeneric = (id) => {
        axios.delete(`/generics/${genericType}/${id}`).then(response => {
            // toastr.success(`${sentenceCase(genericType)} deleted successfully`);
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `${sentenceCase(genericType)} deleted successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchGenerics(genericType);
            setGenericData({});
        }).catch(err => {
            // toastr.error(`Failed to update ${sentenceCase(genericType)}`)
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to update ${sentenceCase(genericType)}`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    useEffect(() => {
        console.log('useEffect editgeneric', editingId);
        if (editingId) {

            // const saveToSessionStorage = () => {
            if (errors.length === 0 && additionalErrors.length === 0) {
                editGeneric(true);
            }
            // };
            // // Set up auto-save interval
            // const autoSaveInterval = setInterval(saveToSessionStorage, 5000);

            // // Clean up interval on component unmount
            // return () => clearInterval(autoSaveInterval);
        }

        // return () => { };
    }, [genericData, editingId]);

    useEffect(() => {
        fetchGenerics(genericType);
        setFilterName("");
        if (genericType !== "beneficiary") fetchGenerics("beneficiary");
        if (genericType !== "witness") fetchGenerics("witness");
        if (genericType !== "executor") fetchGenerics("executor");
        fetchForms();
    }, [genericType]);

    // Function to map a label to its corresponding value based on options
    const customCountryCodeMap = (value, options) => {
        // Convert the value to lowercase for comparison
        const lowercaseValue = value.toLowerCase();
        // Find the option with the matching title (case-insensitive)
        const option = options.find((option) => option.title.toLowerCase() === lowercaseValue);
        // Return the const value of the option if found, otherwise return null
        return option ? `${option.const}` : null;
    };

    // Function to map country label to country code and update data
    const mapCountryToCountryCode = (data, schema) => {
        // Use customCountryCodeMap to map country label to country code
        const value = customCountryCodeMap(data.country, schemas.schemas[genericType].properties.countryCode.oneOf);
        // If a value is found, update data with the mapped country code
        if (value) {
            data.countryCode = value;
        }
    };

    const handleOpenMenu = (event, row) => {
        console.log('genericevent', event);
        setOpen(event.currentTarget);
        const temp = { ...row };
        delete temp.id;
        Object.keys(temp ?? {}).forEach(key => {
            if (temp?.[key] === null)
                delete temp?.[key];
        });
        if (temp.guardian) {
            Object.keys(temp.guardian ?? {}).forEach(key => {
                if (temp?.guardian?.[key] === null)
                    delete temp?.guardian?.[key];
            });
        }

        if (genericType === "beneficiary") {
            const dob = temp.dob;
            if (dob) {
                const birthDate = new Date(dob);
                const today = new Date();
                const age = differenceInYears(new Date(), birthDate);
                if (age < 18) {
                    temp.age = age;
                    const gdob = temp.guardian?.dob;
                    if (gdob) {
                        const gbirthDate = new Date(gdob);
                        const today = new Date();
                        const gage = differenceInYears(new Date(), gbirthDate);
                        temp.guardian.age = gage;
                        temp.needs_guardian = true;
                        // temp.copy_from_beneficiary = -1;
                    }
                } else {
                    temp.needs_guardian = false;
                    temp.age = age;
                    delete temp.guardian;
                }
            }
        }

        // Check if the genericType is 'beneficiary' and map country label to country code
        if (genericType === "beneficiary" && temp?.country) {
            mapCountryToCountryCode(temp, schemas.schemas.beneficiary.properties.countryCode.oneOf);
        }

        // Check if the genericType is 'executor' and map country label to country code
        if (genericType === "executor" && temp?.country) {
            mapCountryToCountryCode(temp, schemas.schemas.executor.properties.countryCode.oneOf);
        }

        // Check if the genericType is 'witness' and map country label to country code
        if (genericType === "witness" && temp?.country) {
            mapCountryToCountryCode(temp, schemas.schemas.witness.properties.countryCode.oneOf);
        }

        // Check if the genericType is 'beneficiary' and if guardian has a country label,
        // then map it to country code
        if (genericType === "beneficiary" && temp?.guardian?.country) {
            mapCountryToCountryCode(temp.guardian, schemas?.schemas.beneficiary?.properties?.guardian?.properties?.countryCode?.oneOf);
        }

        setGenericData(temp);
        setPreviousGenericData(temp);
        setEditingId(row.id);
    };

    const handleCloseMenu = () => {
        setPreviousGeneric();
        setOpen(false);
        setEdit(false);
        setGenericData({});
        setType("");
        setEditingId(null);
    };

    useEffect(() => {
        handleCloseMenu();
    }, [genericType]);
    // useEffect(() => {
    //   if(addNew){
    //     setEdit(false);
    //     setEditingId(null);
    //     setGenericData({});
    //   }
    // }, [addNew]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = generics.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - generics.length) : 0;

    const filteredUsers = applySortFilter(generics, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const { user } = useContext(UserContext);

    // const genericData = useMemo(() => {
    //
    //   return data;
    // }, [genericData])

    const schema = useMemo(() => {
        const _schema = { ...(schemas.schemas?.[genericType] ?? {}) };
        if (genericType === "executor") {
            if (_schema?.properties) {

                const oneOfBeneficiary = beneficiaries?.length > 0 ? beneficiaries?.filter(bene => bene.age >= 18).map(bene => ({
                    value: bene.id,
                    label: bene.name
                })) : [];
                // if (oneOfBeneficiary.length === 0) {
                //     oneOfBeneficiary.push({
                //         const: -1,
                //         title: "No beneficiaries added"
                //     });
                // }

                // _schema.properties.copy_from_beneficiary = {
                //     type: "number",
                //     oneOf: oneOfBeneficiary
                // };
                _schema.properties.copy_from_beneficiary = {
                    options: oneOfBeneficiary
                };
                // setBeneficiaryOptions(oneOfBeneficiary);
                // _schema.properties.beneficiary_id = {
                //     type: "number",
                //     oneOf: beneficiaries?.length > 0 ? beneficiaries?.filter(bene => bene.age >= 18).map(bene => ({
                //         const: bene.id,
                //         title: bene.name
                //     })) : [{
                //         const: -1,
                //         title: "No beneficiaries added"
                //     }]
                // };
            }
        } else if (genericType === "beneficiary") {
            const age = +genericData.age;
            if (age < 18) {
                console.log('generics', generics, genericData, editingId);
                // if(editingId === null){
                //     console.log('generics first if');
                //     _schema.properties.guardian.properties.beneficiary_id = {
                //         type: "number",
                //         oneOf: generics?.length > 0 ? generics?.filter(bene => bene.id !== editingId && bene.age >= 18)?.map(bene => ({
                //             const: bene.id,
                //             title: bene.name
                //         })) : [{
                //             const: -1,
                //             title: "No beneficiaries added"
                //         }]
                //     };
                // }

                // if (editingId !== null) {

                const oneOfBeneficiary = generics?.length > 0 ? generics?.filter(bene => bene.id !== editingId && +bene.age >= 18)?.map(bene => ({
                    // const: bene.id,
                    // title: bene.name
                    value: bene.id,
                    label: bene.name
                })) : [];

                // setBeneficiaryOptions(oneOfBeneficiary);

                // console.log('oneOfBeneficiary', oneOfBeneficiary);
                // if (oneOfBeneficiary.length === 0) {

                //     oneOfBeneficiary.push({
                //         const: -1,
                //         title: "No beneficiaries added"
                //     });
                // }

                _schema.properties.guardian.properties.copy_from_beneficiary = {
                    options: oneOfBeneficiary
                };
                // }

                // _schema.properties.guardian.properties.beneficiary_id.oneOf = generics?.filter(bene => bene.id !== editingId && bene.age >= 18)?.map(bene => ({
                //     const: bene.id,
                //     title: bene.name
                // }));
                const today = new Date();
                const limit = subYears(today, 18);
                // if (_schema?.properties?.guardian?.properties?.dob)
                //   _schema.properties.guardian.properties.dob.formatMaximum = format(limit, "yyyy-MM-dd");
            }
            if (genericData?.add_as_executor) {
                _schema.required = [
                    "salutation",
                    "first_name",
                    "last_name",
                    "dob",
                    "pan",
                    "phone",
                    "email",
                    "house_no",
                    "house_name",
                    "area",
                    "city",
                    "state",
                    "countryCode",
                    "zip"
                ];
                _schema.properties.zip.pattern = "^[a-zA-Z0-9]{3,10}$";
            }
            // console.log('guardian required', _schema.properties.guardian?.required);

            if (genericData?.guardian?.add_as_executor) {
                _schema.properties.guardian.required = [
                    "salutation",
                    "first_name",
                    "last_name",
                    "dob",
                    "pan",
                    "phone",
                    "email",
                    "house_no",
                    "house_name",
                    "area",
                    "city",
                    "state",
                    "countryCode",
                    "zip"
                ];
                _schema.properties.zip.pattern = "^[a-zA-Z0-9]{3,10}$";
            } else if (_schema?.properties?.guardian) {
                _schema.properties.guardian.required = [
                    "salutation",
                    "first_name",
                    "last_name",
                    "dob",
                    // "pan",
                    "relation"
                ];
                _schema.properties.zip.pattern = "^[a-zA-Z0-9]{3,10}$";
            }
        } else {
            const today = new Date();
            const limit = subYears(today, 18);
            //   if (_schema?.properties?.dob)
            //     _schema.properties.dob.formatMaximum = format(limit, "yyyy-MM-dd");
        }
        return _schema;
    }, [schemas.schemas, genericData, beneficiaries, generics]);

    const isObjectEqual = (obj1, obj2) => {
        // // Helper function to compare two objects deeply
        // return JSON.stringify(obj1) === JSON.stringify(obj2);

        // Helper function to compare two objects deeply with case-insensitivity
        const stringifyAndLowerCase = obj =>
            JSON.stringify(obj, (_, value) => (typeof value === 'string' ? value.toLowerCase() : value));

        return stringifyAndLowerCase(obj1) === stringifyAndLowerCase(obj2);
    }

    const isObjectInArray = (obj, array) => {
        // Use some to check if any object in the array is equal to the given object
        return array.some(item => isObjectEqual(item, obj));
    }


    const additionalErrors = useMemo(() => {
        const errors = [];
        const benePans = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.pan?.toUpperCase() ?? "").filter(x => !!x);
        const witnessPans = (witnesses ?? []).filter(w => w.id !== editingId).map(w => w.pan?.toUpperCase() ?? "").filter(x => !!x);
        const exePans = (executors ?? []).filter(e => e.id !== editingId).map(e => e.pan?.toUpperCase() ?? "").filter(x => !!x);

        const guardianPans = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.guardian?.pan?.toUpperCase() ?? "").filter(x => !!x);
        const guardianEmails = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.guardian?.email ?? "").filter(x => !!x);
        const guardianPhones = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.guardian?.phone ?? "").filter(x => !!x);
        console.log('guardianPans', guardianPans);

        const beneEmails = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.email ?? "").filter(x => !!x);
        const witnessEmails = (witnesses ?? []).filter(w => w.id !== editingId).map(w => w.email ?? "").filter(x => !!x);
        const exeEmails = (executors ?? []).filter(e => e.id !== editingId).map(e => e.email ?? "").filter(x => !!x);

        const benePhones = (beneficiaries ?? []).filter(b => b.id !== editingId).map(b => b.phone ?? "").filter(x => !!x);
        const witnessPhones = (witnesses ?? []).filter(w => w.id !== editingId).map(w => w.phone ?? "").filter(x => !!x);
        const exePhones = (executors ?? []).filter(e => e.id !== editingId).map(e => e.phone ?? "").filter(x => !!x);

        const selfPans = (generics ?? []).filter(g => g.id !== editingId).map(g => g.pan?.toUpperCase() ?? "").filter(x => !!x);
        const selfEmails = (generics ?? []).filter(g => g.id !== editingId).map(g => g.email ?? "").filter(x => !!x);
        const selfPhones = (generics ?? []).filter(g => g.id !== editingId).map(g => g.phone ?? "").filter(x => !!x);

        const selfNames = (generics ?? []).filter(g => g.id !== editingId).map(g => {
            return { salutation: g.salutation, first_name: g.first_name, last_name: g.last_name, dob: g.dob } ?? {};
        }).filter(x => !!x);

        const beneNames = (beneficiaries ?? []).filter(g => g.id !== editingId).map(g => {
            return { salutation: g.salutation, first_name: g.first_name, last_name: g.last_name, dob: g.dob } ?? {};
        }).filter(x => !!x);

        const executorNames = (executors ?? []).filter(g => g.id !== editingId).map(g => {
            return { salutation: g.salutation, first_name: g.first_name, last_name: g.last_name, dob: g.dob } ?? {};
        }).filter(x => !!x);

        const witnessNames = (witnesses ?? []).filter(g => g.id !== editingId).map(g => {
            return { salutation: g.salutation, first_name: g.first_name, last_name: g.last_name, dob: g.dob } ?? {};
        }).filter(x => !!x);

        console.log('selfNames', selfNames);

        if (genericType === "beneficiary" && witnessPans.includes(genericData?.pan?.toUpperCase())) {
            errors.push({
                instancePath: "/pan",
                message: "Beneficiary cannot be same as a witness"
            });
        }
        // if (genericType === "witness" && benePans.includes(genericData?.pan?.toUpperCase())) {
        //     errors.push({
        //         instancePath: "/pan",
        //         message: "Witness cannot be same as a beneficiary"
        //     });
        // }
        if (genericType === "beneficiary" && witnessPans.includes(genericData?.guardian?.pan?.toUpperCase())) {
            errors.push({
                instancePath: "/guardian/pan",
                message: "Beneficiary cannot be same as a witness"
            });
        }
        // if (genericType === "witness" && guardianPans.includes(genericData?.pan?.toUpperCase())) {
        //     errors.push({
        //         instancePath: "/pan",
        //         message: "Witness cannot be same as a guardian"
        //     });
        // }

        if (genericType === "beneficiary" && genericData?.guardian?.add_as_executor && exePans.includes(genericData?.guardian?.pan?.toUpperCase())) {
            errors.push({
                instancePath: "/guardian/pan",
                message: "Executor already added with this PAN"
            });
        }


        if (genericType === "beneficiary" && genericData?.add_as_executor && exePans.includes(genericData?.pan?.toUpperCase())) {
            errors.push({
                instancePath: "/pan",
                message: "Executor already added with this PAN"
            });
        }

        /* else if (genericType === "executor" && witnessPans.includes(genericData?.pan?.toLowerCase())) {
          errors.push({
            instancePath: "/pan",
            message: "Executor cannot be same as a witness"
          });
        } */
        if (genericType === "beneficiary") {

            if (selfPans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Duplicate PAN"
                });
            }
            if (selfEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Duplicate Email"
                });
            }
            if (selfPhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Duplicate Phone"
                });
            }

            const beneficiaryExistsWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, selfNames);
            console.log('selfNames beneficiaryExistsWithNameDob', genericData, beneficiaryExistsWithNameDob);
            if (beneficiaryExistsWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Duplicate Beneficiary"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Duplicate Beneficiary"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Duplicate Beneficiary"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Duplicate Beneficiary"
                });
            }

            if (genericData?.pan && genericData?.pan?.toUpperCase() === user?.profile?.pan?.toUpperCase()) {
                errors.push({
                    instancePath: "/pan",
                    message: "PAN cannot be same as testator"
                });
            }

            if (genericData?.guardian?.pan && genericData?.guardian?.pan?.toUpperCase() === user?.profile?.pan?.toUpperCase()) {
                errors.push({
                    instancePath: "/guardian/pan",
                    message: "PAN cannot be same as testator"
                });
            }
            if (user?.email === genericData?.email) {
                errors.push({
                    instancePath: "/email",
                    message: "Email cannot be same as testator"
                });
            }

            if (user?.email === genericData?.guardian?.email) {
                errors.push({
                    instancePath: "/guardian/email",
                    message: "Email cannot be same as testator"
                });
            }
            console.log('benephone', genericData?.phone);
            // if (genericData?.phone && genericData?.phone.includes(user.phone.substring(1))) {
            //     errors.push({
            //         instancePath: "/phone",
            //         message: "Phone cannot be same as testator"
            //     });
            // }

            // if (genericData?.guardian?.phone && genericData?.guardian?.phone.includes(user.phone.substring(1))) {
            //     errors.push({
            //         instancePath: "/guardian/phone",
            //         message: "Phone cannot be same as testator"
            //     });
            // }

            const beneficiarySameAsTestatorWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, [{ salutation: user?.profile?.salutation, first_name: user?.profile?.first_name, last_name: user?.profile?.last_name, dob: user?.profile?.dob }]);
            console.log('selfNames beneficiarySameAsTestatorWithNameDob', genericData, beneficiarySameAsTestatorWithNameDob);
            if (beneficiarySameAsTestatorWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Beneficiary cannot be same as testator"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Beneficiary cannot be same as testator"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Beneficiary cannot be same as testator"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Beneficiary cannot be same as testator"
                });
            }
            const guardianSameAsTestatorWithNameDob = isObjectInArray({ salutation: genericData?.guardian?.salutation, first_name: genericData?.guardian?.first_name, last_name: genericData?.guardian?.last_name, dob: genericData?.guardian?.dob }, [{ salutation: user?.profile?.salutation, first_name: user?.profile?.first_name, last_name: user?.profile?.last_name, dob: user?.profile?.dob }]);
            console.log('selfNames guardianSameAsTestatorWithNameDob', genericData, guardianSameAsTestatorWithNameDob);
            if (guardianSameAsTestatorWithNameDob) {
                errors.push({
                    instancePath: "/guardian/salutation",
                    message: "Guardian cannot be same as testator"
                });
                errors.push({
                    instancePath: "/guardian/first_name",
                    message: "Guardian cannot be same as testator"
                });
                errors.push({
                    instancePath: "/guardian/last_name",
                    message: "Guardian cannot be same as testator"
                });
                errors.push({
                    instancePath: "/guardian/dob",
                    message: "Guardian cannot be same as testator"
                });
            }

            const beneficiarySameAsWitnessWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, witnessNames);
            console.log('selfNames beneficiarySameAsWitnessWithNameDob', genericData, beneficiarySameAsWitnessWithNameDob);
            if (beneficiarySameAsWitnessWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Beneficiary cannot be same as witness"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Beneficiary cannot be same as witness"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Beneficiary cannot be same as witness"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Beneficiary cannot be same as witness"
                });
            }
        }

        if (genericType === "executor") {

            if (selfPans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Duplicate PAN"
                });
            }
            if (selfEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Duplicate Email"
                });
            }
            if (selfPhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Duplicate Phone"
                });
            }
            const executorExistsWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, selfNames);
            console.log('selfNames executorExistsWithNameDob', genericData, executorExistsWithNameDob);
            if (executorExistsWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Duplicate Executor"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Duplicate Executor"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Duplicate Executor"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Duplicate Executor"
                });
            }

            if (witnessPans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Executor cannot be same as witness"
                });
            }

            if (witnessEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Executor cannot be same as witness"
                });
            }

            if (witnessPhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Executor cannot be same as witness"
                });
            }

            if (genericData?.pan && genericData?.pan?.toUpperCase() === user?.profile?.pan?.toUpperCase()) {
                errors.push({
                    instancePath: "/pan",
                    message: "PAN cannot be same as testator"
                });
            }


            if (user?.email === genericData?.email) {
                errors.push({
                    instancePath: "/email",
                    message: "Email cannot be same as testator"
                });
            }


            // if (genericData?.phone && genericData?.phone.includes(user.phone.substring(1))) {
            //     errors.push({
            //         instancePath: "/phone",
            //         message: "Phone cannot be same as testator"
            //     });
            // }

            const executorSameAsTestatorWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, [{ salutation: user?.profile?.salutation, first_name: user?.profile?.first_name, last_name: user?.profile?.last_name, dob: user?.profile?.dob }]);
            console.log('selfNames executorSameAsTestatorWithNameDob', genericData, executorSameAsTestatorWithNameDob);
            if (executorSameAsTestatorWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Executor cannot be same as testator"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Executor cannot be same as testator"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Executor cannot be same as testator"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Executor cannot be same as testator"
                });
            }

            const executorSameAsWitnessWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, witnessNames);
            console.log('selfNames executorSameAsWitnessWithNameDob', genericData, executorSameAsWitnessWithNameDob);
            if (executorSameAsWitnessWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Executor cannot be same as witness"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Executor cannot be same as witness"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Executor cannot be same as witness"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Executor cannot be same as witness"
                });
            }
        }

        if (genericType === "witness") {

            if (selfPans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Duplicate PAN"
                });
            }
            const witnessExistsWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, selfNames);
            console.log('selfNames witnessExistsWithNameDob', genericData, witnessExistsWithNameDob);
            if (witnessExistsWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Duplicate Witness"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Duplicate Witness"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Duplicate Witness"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Duplicate Witness"
                });
            }

            else if (genericData?.pan && genericData?.pan?.toUpperCase() === user?.profile?.pan?.toUpperCase()) {
                errors.push({
                    instancePath: "/pan",
                    message: "PAN cannot be same as testator"
                });
            }
            else if (guardianPans.includes(genericData?.pan?.toUpperCase()) && benePans.includes(genericData?.pan?.toUpperCase()) && exePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as a guardian/beneficiary/executor"
                });
            }
            else if (guardianPans.includes(genericData?.pan?.toUpperCase()) && benePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as a beneficiary/executor"
                });
            }
            else if (guardianPans.includes(genericData?.pan?.toUpperCase()) && exePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as a guardian/executor"
                });
            }
            else if (benePans.includes(genericData?.pan?.toUpperCase()) && exePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as a beneficiary/executor"
                });
            }
            else if (benePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as a beneficiary"
                });
            }
            else if (exePans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as executor"
                });
            }
            else if (guardianPans.includes(genericData?.pan?.toUpperCase())) {
                errors.push({
                    instancePath: "/pan",
                    message: "Witness cannot be same as guardian"
                });
            }

            if (selfEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Duplicate Email"
                });
            }
            else if (user?.email === genericData?.email) {
                errors.push({
                    instancePath: "/email",
                    message: "Email cannot be same as testator"
                });
            }
            else if (beneEmails.includes(genericData?.email) && exeEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Witness cannot be same as a beneficiary/executor"
                });
            }
            else if (beneEmails.includes(genericData?.email)) {
                errors.push({
                    instancePath: "/email",
                    message: "Witness cannot be same as a beneficiary"
                });
            }
            else if (exeEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Witness cannot be same as executor"
                });
            }
            else if (guardianEmails.includes(genericData?.email?.toLowerCase())) {
                errors.push({
                    instancePath: "/email",
                    message: "Witness cannot be same as guardian"
                });
            }

            if (selfPhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Duplicate Phone"
                });
            }
            else if (genericData?.phone && genericData?.phone.includes(user.phone.substring(1))) {
                errors.push({
                    instancePath: "/phone",
                    message: "Phone cannot be same as testator"
                });
            }
            else if (guardianPans.includes(genericData?.pan?.toUpperCase()) && benePhones.includes(genericData?.phone) && exePhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Witness cannot be same as a beneficiary/executor/guardian"
                });
            }
            else if (benePhones.includes(genericData?.phone) && exePhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Witness cannot be same as a beneficiary/executor"
                });
            }
            else if (benePhones.includes(genericData?.phone)) {
                errors.push({
                    instancePath: "/phone",
                    message: "Witness cannot be same as a beneficiary"
                });
            }
            else if (exePhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Witness cannot be same as executor"
                });
            }
            else if (guardianPhones.includes(genericData?.phone?.toLowerCase())) {
                errors.push({
                    instancePath: "/phone",
                    message: "Witness cannot be same as guardian"
                });
            }

            const witnessSameAsTestatorWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, [{ salutation: user?.profile?.salutation, first_name: user?.profile?.first_name, last_name: user?.profile?.last_name, dob: user?.profile?.dob }]);
            console.log('selfNames witnessSameAsTestatorWithNameDob', genericData, witnessSameAsTestatorWithNameDob);
            if (witnessSameAsTestatorWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Witness cannot be same as testator"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Witness cannot be same as testator"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Witness cannot be same as testator"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Witness cannot be same as testator"
                });
            }

            const witnessSameAsBeneficiaryWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, beneNames);
            console.log('selfNames witnessSameAsBeneficiaryWithNameDob', genericData, witnessSameAsBeneficiaryWithNameDob);
            const witnessSameAsExecutorWithNameDob = isObjectInArray({ salutation: genericData?.salutation, first_name: genericData?.first_name, last_name: genericData?.last_name, dob: genericData?.dob }, executorNames);
            console.log('selfNames witnessSameAsExecutorWithNameDob', genericData, witnessSameAsExecutorWithNameDob);

            if (witnessSameAsBeneficiaryWithNameDob && witnessSameAsExecutorWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Witness cannot be same as beneficiary/executor"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Witness cannot be same as beneficiary/executor"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Witness cannot be same as beneficiary/executor"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Witness cannot be same as beneficiary/executor"
                });
            }
            else if (witnessSameAsBeneficiaryWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Witness cannot be same as beneficiary"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Witness cannot be same as beneficiary"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Witness cannot be same as beneficiary"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Witness cannot be same as beneficiary"
                });
            }
            else if (witnessSameAsExecutorWithNameDob) {
                errors.push({
                    instancePath: "/salutation",
                    message: "Witness cannot be same as executor"
                });
                errors.push({
                    instancePath: "/first_name",
                    message: "Witness cannot be same as executor"
                });
                errors.push({
                    instancePath: "/last_name",
                    message: "Witness cannot be same as executor"
                });
                errors.push({
                    instancePath: "/dob",
                    message: "Witness cannot be same as executor"
                });
            }
        }



        return errors;
    }, [genericType, beneficiaries, witnesses, generics, executors, genericData, user]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === "enter" && !e.repeat) {
                e.preventDefault();
                if (additionalErrors.length === 0 && errors?.length === 0) {
                    // if (editingId) editGeneric();
                    // else addNewGeneric();
                }
            }
        };
        window.addEventListener("keyup", handleEnter);
        return () => {
            window.removeEventListener("keyup", handleEnter);
        };
    }, [editingId, additionalErrors, errors]);

    const steps = [
        {
            target: "#new-generic",
            content: `Click to add new ${genericType}`
        },
        {
            target: "body",
            content: `Fill in the data for ${genericType}`
        },
        {
            target: "#new-generic-button",
            content: "Click the save button"
        }
    ];
    const handleJoyrideCallback = (data) => {
        const { index, action, size } = data;
        if (action === "next" && index === 0) {
            setAddNew(true);
        } else if (action === "next" && index === size - 1) {
            setAddNew(false);
        }
    };

    const columns = {
        beneficiary: ["name", "relation", "full_address", "phone", "email"],
        executor: ["name", "full_address", "PAN", "phone", "email"],
        witness: ["name", "full_address", "PAN", "phone", "email"]
    };

    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    useEffect(() => {
        if (!sessionStorage.getItem(`video_close_${map[genericType].toLowerCase()}`) && !isLoading && generics.length === 0) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading, isLoading, generics]);

    const isValidDate = (dateString) => {
        // Check if the input is not empty
        if (!dateString) return false;

        // Attempt to create a Date object from the input
        const date = new Date(dateString);
        // console.log('dob is', !Number.isNaN(date.getTime()));
        // console.log('dob instance', date instanceof Date);
        // Check if the created date is a valid date
        return !Number.isNaN(date.getTime()) && date instanceof Date;
    }

    function isDateStringValid(dateString) {
        const regex = /^\d{2}-\d{2}-\d{4}$/; // DD-MM-YYYY format
        const regex1 = /^\d{4}-\d{2}-\d{2}$/; // DD-MM-YYYY format

        return regex.test(dateString) || regex1.test(dateString);
    }

    const handleGenericDataChange = ({ data, errors }) => {
        setHelperText('');
        // console.log('dataall', data);
        // const bene = generics.find(b => b.id === data?.guardian?.copy_from_beneficiary);
        // console.log('beene', bene, data?.guardian?.copy_from_beneficiary);
        // console.log()
        // if (bene) {
        //     data.guardian = omitBy(bene, isNull);
        // }
        // console.log('copy_from_bene', data?.guardian.copy_from_beneficiary);

        if (genericType === "executor") {
            console.log('handleGenericDataChange,data', data);

            const bene1 = beneficiaries.find(b => b.id === data?.copy_from_beneficiary);

            // Check if the genericType is 'beneficiary' and map country label to country code
            if (bene1?.country && schemas?.schemas?.executor?.properties?.countryCode?.oneOf) {
                mapCountryToCountryCode(bene1, schemas.schemas.executor.properties.countryCode.oneOf);
            }
            if (bene1) {
                console.log('bene1 if');
                data = omitBy(bene1, isNull);
            }
            console.log('bene1', data, editingId, bene1);
            if (bene1 && (editingId === null || editingId === undefined)) {
                // if (bene1.pan && bene1.email && bene1.phone) {
                setCopyFromBeneficiary(data?.copy_from_beneficiary);
                data = omitBy(bene1, isNull);
                // }
                // else {
                //     const errorExists = errors.find(error => error.instancePath === "/copy_from_beneficiary");
                //     if (!errorExists) {
                //         errors.push({
                //             // AJV style path to the property in the schema
                //             instancePath: "/copy_from_beneficiary",
                //             // message to display
                //             message: 'To copy beneficiary, add PAN, Phone, Email in selected beneficiary',
                //             schemaPath: "",
                //             keyword: '',
                //             params: {},
                //         });
                //     }

                // }
            }
            if (editingId !== null && editingId !== undefined) {
                // console.log('beneficiaries', beneficiaries);
                const isExecutorMatch = beneficiaries.some((item) => {
                    // console.log('beneficiary', item, data);
                    return item?.id === data.copy_from_beneficiary && ((data.pan !== null && item.pan?.toUpperCase() === data.pan?.toUpperCase()) || (data.email !== null && item.email === data.email) || (data.pan !== null && item.phone === data.phone));
                });
                // console.log('isExecutorMatch', isExecutorMatch);
                if (isExecutorMatch) {
                    const errorExists = errors.find(error => error.instancePath === "/copy_from_beneficiary");
                    if (!errorExists) {
                        // errors.push({
                        //     // AJV style path to the property in the schema
                        //     instancePath: "/copy_from_beneficiary",
                        //     // message to display
                        //     message: 'This Executor is copied from Beneficiary, edit Beneficiary to update Executor',
                        //     schemaPath: "",
                        //     keyword: '',
                        //     params: {},
                        // });
                        // setEdit(false);
                    }
                }
            }

            if (data.pan) {
                data.pan = data.pan.toUpperCase();
            }
        }
        else if (genericType === "beneficiary") {
            // console.log('data.all', data.guardian);
            // console.log('genericType', genericType, data?.guardian?.copy_from_beneficiary);
            const bene1 = beneficiaries.find(b => b.id === data?.guardian?.copy_from_beneficiary);
            console.log('bene1', bene1);
            // Check if the genericType is 'beneficiary' and map country label to country code
            if (bene1?.country && schemas?.schemas?.beneficiary?.properties?.countryCode?.oneOf) {
                mapCountryToCountryCode(bene1, schemas.schemas.beneficiary.properties.countryCode.oneOf);
            }
            if (bene1 && (editingId === null || editingId === undefined)) {
                // if (bene1.pan && bene1.email && bene1.phone) {
                console.log('inside pan email phone ', data?.guardian.copy_from_beneficiary);
                setCopyFromBeneficiary(data?.guardian?.copy_from_beneficiary);
                // bene1.copy_from_beneficiary = data?.guardian?.copy_from_beneficiary;
                data.guardian = omitBy(bene1, isNull);
                // console.log('omitBy', data.guardian);
                // }
                // else {
                //     // console.log('errors else', errors);
                //     const errorExists = errors.find(error => error.instancePath === "/guardian/copy_from_beneficiary");
                //     if (!errorExists) {
                //         errors.push({
                //             // AJV style path to the property in the schema
                //             instancePath: "/guardian/copy_from_beneficiary",
                //             // message to display
                //             message: 'To copy beneficiary add PAN, Phone, Email in beneficiary',
                //             schemaPath: "",
                //             keyword: '',
                //             params: {},
                //         });
                //     }
                // }
            }
            if (editingId !== null && editingId !== undefined) {

                // console.log('added_as_executor', data.added_as_executor);
                let type = "executor/guardian";
                if (data.added_as_executor) {
                    type = "executor";
                }
                if (data.added_as_guardian) {
                    type = "guardian";
                }
                if (data.added_as_executor || data.added_as_guardian) {
                    // console.log('data.pan', data)
                    // if(!data.pan){
                    //     const errorExists = errors.find(error => error.instancePath === "/pan");
                    //     if (!errorExists) {
                    //         errors.push({
                    //             // AJV style path to the property in the schema
                    //             instancePath: "/pan",
                    //             // message to display
                    //             message: `This beneficiary added as ${type}, PAN cannot be empty`,
                    //             schemaPath: "",
                    //             keyword: '',
                    //             params: {},
                    //         });
                    //     }
                    // }
                    // if (!data.phone) {
                    //     const errorExists = errors.find(error => error.instancePath === "/phone");
                    //     if (!errorExists) {
                    //         errors.push({
                    //             // AJV style path to the property in the schema
                    //             instancePath: "/phone",
                    //             // message to display
                    //             message: `This beneficiary added as ${type}, Phone cannot be empty`,
                    //             schemaPath: "",
                    //             keyword: '',
                    //             params: {},
                    //         });
                    //     }
                    // }
                    // if (!data.email) {
                    //     const errorExists = errors.find(error => error.instancePath === "/email");
                    //     if (!errorExists) {
                    //         errors.push({
                    //             // AJV style path to the property in the schema
                    //             instancePath: "/email",
                    //             // message to display
                    //             message: `This beneficiary added as ${type}, Email cannot be empty`,
                    //             schemaPath: "",
                    //             keyword: '',
                    //             params: {},
                    //         });
                    //     }
                    // }
                }
                if (editingId !== null && editingId !== undefined) {
                    console.log('data.guardian989', editingId, data);
                    if (data.guardian) {
                        if (data.guardian.copy_from_beneficiary !== null && editingId !== data.id) {
                            // console.log('1081');
                            // setHelperText('This Guardian is copied from Beneficiary, edit Beneficiary to update Guardian');
                        }
                    }
                }
                if (bene1) {

                    // if (bene1.pan && bene1.email && bene1.phone) {
                    // console.log('inside pan email phone ', data?.guardian.copy_from_beneficiary);
                    setCopyFromBeneficiary(data?.guardian?.copy_from_beneficiary);
                    // bene1.copy_from_beneficiary = data?.guardian?.copy_from_beneficiary;
                    data.guardian = omitBy(bene1, isNull);
                    // console.log('omitBy', data.guardian);
                    // }
                    // else {
                    //     const errorExists = errors.find(error => error.instancePath === "/guardian/copy_from_beneficiary");
                    //     // console.log('errors else', errors, errorExists);
                    //     if (!errorExists) {
                    //         errors.push({
                    //             // AJV style path to the property in the schema
                    //             instancePath: "/guardian/copy_from_beneficiary",
                    //             // message to display
                    //             message: 'To copy beneficiary add PAN, Phone, Email in beneficiary',
                    //             schemaPath: "",
                    //             keyword: '',
                    //             params: {},
                    //         });
                    //     }
                    // }
                }
            }


            if (data.pan) {
                data.pan = data.pan.toUpperCase();
            }
        }
        else {
            // console.log('else genericType', genericType);
            const bene1 = beneficiaries.find(b => b.id === data?.beneficiary_id);
            if (bene1) {
                setCopyFromBeneficiary(data?.beneficiary_id);
                data = omitBy(bene1, isNull);
            }
            if (data.pan) {
                data.pan = data.pan.toUpperCase();
            }
        }
        if (genericType === "beneficiary") {
            if (data.same_as_testator) {
                data.house_no = user.profile?.current?.house_no;
                data.house_name = user.profile?.current?.house_name;
                data.street = user.profile?.current?.street;
                data.area = user.profile?.current?.area;
                data.city = user.profile?.current?.city;
                data.state = user.profile?.current?.state;
                data.country = user.profile?.current?.country;
                data.countryCode = user.profile?.current?.countryCode;
                data.zip = user.profile?.current?.zip;
                console.log('data', data);
                setsameAsTestator(true);
            } else if (data.same_as_testator === false) {
                if (sameAsTestator) {
                    data.house_no = '';
                    data.house_name = '';
                    data.street = '';
                    data.area = '';
                    data.city = '';
                    data.state = '';
                    data.country = '';
                    data.countryCode = undefined
                    data.zip = undefined;
                    setsameAsTestator(false);
                }
            }

            if (data.guardian && data.guardian?.same_as_testator) {
                data.guardian.house_no = user.profile?.current?.house_no;
                data.guardian.house_name = user.profile?.current?.house_name;
                data.guardian.street = user.profile?.current?.street;
                data.guardian.area = user.profile?.current?.area;
                data.guardian.city = user.profile?.current?.city;
                data.guardian.state = user.profile?.current?.state;
                data.guardian.country = user.profile?.current?.country;
                data.guardian.countryCode = user.profile?.current?.countryCode;
                data.guardian.zip = user.profile?.current?.zip;
                setsameAsTestatorGuardian(true);
            } else if (data.guardian && data.guardian?.same_as_testator === false) {
                if (sameAsTestatorGuardian) {
                    data.guardian.house_no = '';
                    data.guardian.house_name = '';
                    data.guardian.street = '';
                    data.guardian.area = '';
                    data.guardian.city = '';
                    data.guardian.state = '';
                    data.guardian.country = '';
                    data.guardian.countryCode = undefined
                    data.guardian.zip = undefined;
                    setsameAsTestatorGuardian(false);
                }
            }

            // const dob = new Date(data.dob);
            const dob = data.dob;
            // if (!Number.isNaN(dob.getTime())) {
            if (isValidDate(dob) && isDateStringValid(dob)) {
                const currentDate = new Date();
                const birthDate = new Date(dob);
                const today = new Date();
                const ageLimitDate = new Date();
                ageLimitDate.setFullYear(currentDate.getFullYear() - 18);
                const age = differenceInYears(new Date(), birthDate);
                if (age < 18) {
                    if (birthDate > currentDate) {
                        // console.log('dob future limit data', birthDate, currentDate, birthDate > currentDate);
                        const errorExists = errors.find(error => error.instancePath === "/dob");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                "instancePath": "/dob",
                                // message to display
                                message: `Date of birth to be prior to current date`,
                                "schemaPath": "#/properties/dob/maximum",
                                keyword: '',
                                params: {},
                            });
                        }

                    }
                    data.age = age;
                    const gdob = data.guardian?.dob;
                    if (isValidDate(gdob)) {
                        const gbirthDate = new Date(gdob);
                        const today = new Date();
                        const gage = differenceInYears(new Date(), gbirthDate);
                        data.guardian.age = gage;

                        if (gbirthDate > ageLimitDate) {
                            // console.log('age limit data', gbirthDate, ageLimitDate, gbirthDate > ageLimitDate);
                            const errorExists = errors.find(error => error.instancePath === "/guardian/dob");
                            if (!errorExists) {
                                errors.push({
                                    // AJV style path to the property in the schema
                                    instancePath: "/guardian/dob",
                                    // message to display
                                    message: 'Guardian must be older than 18',
                                    schemaPath: "",
                                    keyword: '',
                                    params: {},
                                });
                            }
                        }

                    }
                    data.needs_guardian = true;
                    // console.log('data.guardian', data, data?.guardian?.copy_from_beneficiary === undefined);
                    // if (data?.guardian?.copy_from_beneficiary === undefined){
                    //     data.guardian = {copy_from_beneficiary: null};
                    // }
                    // console.log('958', data.guardian);

                    if (!data.guardian?.salutation) {
                        const errorExists = errors.find(error => error.instancePath === "/guardian/salutation");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                instancePath: "/guardian",
                                // message to display
                                message: 'This field is required',
                                schemaPath: "#/properties/guardian/required",
                                keyword: '',
                                params: { missingProperty: "salutation" },
                            });

                        }
                    }
                    if (!data.guardian?.first_name) {
                        const errorExists = errors.find(error => error.instancePath === "/guardian/first_name");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                instancePath: "/guardian",
                                // message to display
                                message: 'This field is required',
                                schemaPath: "#/properties/guardian/required",
                                keyword: '',
                                params: { missingProperty: "first_name" },
                            });
                        }
                    }
                    if (!data.guardian?.last_name) {
                        const errorExists = errors.find(error => error.instancePath === "/guardian/last_name");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                instancePath: "/guardian",
                                // message to display
                                message: 'This field is required',
                                schemaPath: "#/properties/guardian/required",
                                keyword: '',
                                params: { missingProperty: "last_name" },
                            });
                        }
                    }
                    // if (!data.guardian?.pan) {
                    //     const errorExists = errors.find(error => error.instancePath === "/guardian/pan");
                    //     if (!errorExists) {
                    //         errors.push({
                    //             // AJV style path to the property in the schema
                    //             instancePath: "/guardian",
                    //             // message to display
                    //             message: 'This field is required',
                    //             schemaPath: "#/properties/guardian/required",
                    //             keyword: '',
                    //             params: { missingProperty: "pan" },
                    //         });
                    //     }
                    // }
                    if (!data.guardian?.dob) {
                        const errorExists = errors.find(error => error.instancePath === "/guardian/dob");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                instancePath: "/guardian",
                                // message to display
                                message: 'This field is required',
                                schemaPath: "#/properties/guardian/required",
                                keyword: '',
                                params: { missingProperty: "dob" },
                            });
                        }
                    }
                    if (!data.guardian?.relation) {
                        const errorExists = errors.find(error => error.instancePath === "/guardian/relation");
                        if (!errorExists) {
                            errors.push({
                                // AJV style path to the property in the schema
                                instancePath: "/guardian",
                                // message to display
                                message: 'This field is required',
                                schemaPath: "#/properties/guardian/required",
                                keyword: '',
                                params: { missingProperty: "relation" },
                            });
                        }
                    }
                    if (data.guardian?.pan) {
                        data.guardian.pan = data.guardian?.pan.toUpperCase();
                    }
                } else {
                    data.needs_guardian = false;
                    data.age = age;
                    delete data.guardian;
                }
            }
            if (data.add_as_executor && executors.length >= 3) {
                data.add_as_executor = false;
                // toastr.warning("You have already added 3 executors! You cannot add any more executors");
                Swal.fire({
                    position: "top-center",
                    icon: "warning",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `You have already added 3 executors! You cannot add any more executors`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        }
        if (genericType === "witness") {
            const { dob } = data;
            if (dob) {
                const currentDate = new Date();
                const birthDate = new Date(dob);
                const today = new Date();
                const ageLimitDate = new Date();
                ageLimitDate.setFullYear(currentDate.getFullYear() - 18);
                const age = differenceInYears(new Date(), birthDate);
                if (age < 18) {

                    // console.log('age limit data', birthDate, ageLimitDate, birthDate > ageLimitDate);
                    const errorExists = errors.find(error => error.instancePath === "/dob");
                    if (!errorExists) {
                        errors.push({
                            // AJV style path to the property in the schema
                            instancePath: "/dob",
                            // message to display
                            message: 'Witness should be older than 18',
                            schemaPath: "",
                            keyword: '',
                            params: {},
                        });
                    }

                }
            }
        }
        if (genericType === "executor") {
            const { dob } = data;
            if (dob) {
                const currentDate = new Date();
                const birthDate = new Date(dob);
                const today = new Date();
                const ageLimitDate = new Date();
                ageLimitDate.setFullYear(currentDate.getFullYear() - 18);
                const age = differenceInYears(new Date(), birthDate);
                if (age < 18) {

                    const errorExists = errors.find(error => error.instancePath === "/dob");
                    if (!errorExists) {
                        // console.log('age limit data', birthDate, ageLimitDate, birthDate > ageLimitDate);
                        errors.push({
                            // AJV style path to the property in the schema
                            instancePath: "/dob",
                            // message to display
                            message: 'Executor should be older than 18',
                            schemaPath: "",
                            keyword: '',
                            params: {},
                        });
                    }

                }
            }
        }
        console.log('handleGenericError', errors);
        if (editingId) {
            console.log('genericDatachange', previousGenericData);
            // const previousGenericData = {...genericData};
            sessionStorage.setItem(`previousGeneric${genericType}Data`, JSON.stringify(previousGenericData));
        }
        setGenericData(data);
        setErrors(errors);

        // Function to map a value to its corresponding label using options
        const customLabelMap = (value, options) => {
            // Find the option with the matching value
            const option = options.find((option) => option.const === value);
            // Return the title of the option if found, otherwise return null
            return option ? `${option.title}` : null;
        };

        // Function to map country code to its corresponding label
        const mapCountryToLabel = (genericData, schema) => {
            // Use customLabelMap to retrieve the label of the selected option
            const label = customLabelMap(genericData.countryCode, schema);
            // If a label is found, assign it to the 'country' property of genericData
            if (label) {
                genericData.country = label;
            }
        };

        // Check if the genericType is 'beneficiary' and if data has a countryCode
        if (genericType === "beneficiary" && data?.countryCode) {
            // Map country code to label using the corresponding schema
            mapCountryToLabel(data, schemas.schemas.beneficiary.properties.countryCode.oneOf);
        }else{
            // If the conditions are not met, reset the country property to undefined
            data.country=undefined
        }

        // Check if the genericType is 'executor' and if data has a countryCode
        if (genericType === "executor" && data?.countryCode) {
            // Map country code to label using the corresponding schema
            mapCountryToLabel(data, schemas.schemas.executor.properties.countryCode.oneOf);
        }

        // Check if the genericType is 'witness' and if data has a countryCode
        if (genericType === "witness" && data?.countryCode) {
            // Map country code to label using the corresponding schema
            mapCountryToLabel(data, schemas.schemas.witness.properties.countryCode.oneOf);
        }

        // Check if the genericType is 'beneficiary' and if guardian has a countryCode
        if (genericType === "beneficiary" && data?.guardian?.countryCode) {
            // Map guardian's country code to label using the corresponding schema
            mapCountryToLabel(data?.guardian, schemas?.schemas.beneficiary?.properties?.guardian.properties.countryCode.oneOf);
        }

        if (
            // Check if zip code, country code, and zip code length meet requirements
            data?.zip &&
            data.countryCode &&
            data.zip.length >= 4 &&
            data.zip.length <= 11 &&
             // Ensure the new zip code is different from the one in the generic data
             data.zip !== genericData.zip &&
            // Check if city and state are undefined
            data.city === undefined &&
            data.state === undefined
        ) {
            // Clear previous timeout if it exists
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            // Set a timeout to delay fetching data
            timeoutId = setTimeout(() => {
                // Fetch data from the provided zip code
                fetchDataFromZip(data).then(({ city, state, placeNames }) => {
                    // Update generic data with fetched city and state
                    setGenericData((d) => ({
                        ...d,
                        city,
                        state
                    }));
                    // Function to update enum array in UI schema
                    const updateEnumArray = (schemas, genericType, placeNames) => {
                        schemas.schemas[genericType].properties.area.anyOf[0].enum = [null]; // Clear the enum array before adding new values

                        // Add new values to the enum array
                        if (placeNames && placeNames[0] !== null) {
                            placeNames.forEach((newValue) => {
                                schemas.schemas[genericType].properties.area.anyOf[0].enum.push(newValue);
                            });
                        }
                    };

                    // Call the function with appropriate parameters for each generic type
                    if (genericType === "beneficiary") {
                        updateEnumArray(schemas, "beneficiary", placeNames);
                    }
                    if (genericType === "executor") {
                        updateEnumArray(schemas, "executor", placeNames);
                    }
                    if (genericType === "witness") {
                        updateEnumArray(schemas, "witness", placeNames);
                    }
                });
            }, 1000); // Delay of 1 seconds (1000 milliseconds)
        }

        if (
            // Check if zip code, country code, and zip code length meet requirements for guardian
            data?.guardian?.zip &&
            data?.guardian?.countryCode &&
            data?.guardian?.zip?.length >= 4 &&
            data?.guardian?.zip?.length <= 11 &&
             // Ensure the new zip code is different from the one in the generic guardian data
             data?.guardian?.zip !== genericData?.guardian?.zip &&
            // Check if city and state are undefined for guardian
            data?.guardian?.city === undefined &&
            data?.guardian?.state === undefined
        ) {
            // Clear previous timeout if it exists
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            // Set a timeout to delay fetching data for guardian
            timeoutId = setTimeout(() => {
                // Fetch data from the provided zip code for guardian
                fetchDataFromZip(data.guardian).then(({ city, state, placeNames }) => {
                    // Update generic data with fetched city and state for guardian
                    setGenericData((d) => ({
                        ...d,
                        guardian: { ...d.guardian, city, state }
                    }))
                    // Clear the enum array before adding new values for beneficiary
                    if (genericType === "beneficiary")
                        schemas.schemas.beneficiary.properties.guardian.properties.area.anyOf[0].enum = [null];

                    // Add new values to the enum array for beneficiary
                    if (placeNames && placeNames[0] !== null) {
                        placeNames.forEach(newValue => {
                            if (genericType === "beneficiary")
                                schemas.schemas.beneficiary.properties.guardian.properties.area.anyOf[0].enum.push(newValue);
                        });
                    }
                });
            }, 1000); // Delay of 1 seconds (1000 milliseconds)
        }

    };

    return (
        <>
            <Helmet>
                <title> {map[genericType]} | Get Will Done </title>
            </Helmet>

            <Container fullWidth style={{ maxWidth: "unset" }}>
                {/* <Joyride callback={handleJoyrideCallback} run={generics.length === 0} continuous showProgress showSkipButton
                 steps={steps}
                 styles={{ options: { zIndex: 10000 } }} /> */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        {map[genericType]}
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    </div>
                    <Tooltip title={`New ${sentenceCase(genericType)}`}>
                        <Button id={"new-generic"}
                            disabled={(genericType === "witness" && generics.length >= 2) || (genericType === "executor" && generics.length >= 3)}
                            onClick={() => {
                                setGenericData({ copy_from_beneficiary: null });
                                setEdit(false);
                                setEditingId(null);
                                setAddNew(true);
                            }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                            New {sentenceCase(genericType)}
                        </Button>
                    </Tooltip>
                </Stack>
                <CenterModal
                    open={addNew}
                    title={`New ${sentenceCase(genericType)}`}
                    onClose={() => {
                        setAddNew(false);
                        setGenericData({});
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box id={"new-generic-form"} sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        {genericType === "executor" &&
                            <Typography sx={{ p: 1, color: "common.black" }} fontWeight={"normal"} variant={"h6"}>
                                Please provide details of the executor who will dispose off or oversee the distribution and settlement of
                                your assets and liabilities under your wishes as enumerated in the Will. You can select executor from
                                your
                                beneficiaries.
                                You may appoint more than one executor to your Will. Platform enables you to appoint upto three
                                executors.
                            </Typography>}
                        {genericType === "witness" &&
                            <Typography sx={{ p: 1, color: "common.black" }} fontWeight={"normal"} variant={"h6"}>
                                You have added {generics?.length || "no"} witnesses, would you like to
                                add {generics?.length ? "another" : "one"}? You need to have 2 witnesses for your Will.<Typography sx={{ p: 1, color: "primary.main" }} display="inline" fontWeight={"bold"} variant={"h6"}>Beneficiary can't be witness.</Typography>
                            </Typography>}
                        <JsonForms
                            i18n={{ translate: translator(genericType) }}
                            schema={schema}
                            additionalErrors={additionalErrors}
                            uischema={schemas.ui_schemas?.[genericType]}
                            data={genericData}
                            renderers={[...materialRenderers,
                            {
                                tester: (uischema) => {
                                    // console.log('uischema:', uischema);
                                    return uischema && (uischema.scope === '#/properties/guardian/properties/copy_from_beneficiary' || uischema.scope === '#/properties/copy_from_beneficiary');
                                },
                                renderer: OneOfCustomAutocompleteControl
                                // renderer: (props) => { console.log('props', props); return <OneOfCustomAutocompleteControl options={beneficiaryOptions} handleChange={handleGenericDataChange} {...props} />}
                            },
                            {
                                tester: OneOfTester,
                                renderer: OneOfControl
                            }, {
                                tester: PhoneInputTester,
                                renderer: PhoneInputControl
                            }, { tester: ImagePickerTester, renderer: ImagePickerControl }]}
                            cells={materialCells}
                            onChange={handleGenericDataChange}
                        />
                    </Box>
                    <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setAddNew(false);
                            setEdit(false);
                            setGenericData({});
                        }}>Cancel</Button>
                        <Button disabled={errors.length > 0 || additionalErrors.length > 0} id={"new-generic-button"}
                            onClick={() => {
                                addNewGeneric();
                            }}
                            variant="contained">
                            Save
                        </Button>
                    </Stack>
                </CenterModal>

                <Card>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={[{ id: "dummy" }, ...columns[genericType].filter(key => !["add_as_executor", "guardian"].includes(key)).map(key => {
                                        return {
                                            id: key.toLowerCase(),
                                            label: key.toUpperCase() === key ? key : sentenceCase(key),
                                            alignRight: false
                                        };
                                    }), { id: "actions" }]}
                                    rowCount={generics.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { id } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;

                                        return (<>
                                            <TableRow
                                                sx={{ bgcolor: (theme) => editingId === id ? "rgba(255, 156, 65, 0.18)" : "initial" }}
                                                key={id} tabIndex={-1}>
                                                <TableCell align="right">
                                                    <IconButton onClick={(e) => {
                                                        if (editingId === id) {
                                                            handleCloseMenu();
                                                        } else {
                                                            handleOpenMenu(e, row);
                                                        }
                                                    }}><Iconify icon={editingId === id ? "uil:angle-down" : "uil:angle-right"} /></IconButton>
                                                </TableCell>
                                                {columns[genericType].map(key => {
                                                    if (schemas?.schemas?.[genericType]?.properties?.[key?.toLowerCase()]?.type === "boolean") return <TableCell
                                                        align="left">{row[key?.toLowerCase()] ? <Check /> : <CloseRounded />}</TableCell>;
                                                    return <TableCell align="left">{key?.toLowerCase() === 'phone' ? formatPhone(row[key?.toLowerCase()]) : row[key?.toLowerCase()]}</TableCell>;
                                                })}
                                                <TableCell>
                                                    {<>
                                                        <ButtonGroup>
                                                            <Tooltip title={`Edit ${sentenceCase(genericType)}`}>

                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    handleOpenMenu(e, row);
                                                                    console.log('editexecutor', genericType, row);
                                                                    // if(genericType === "executor"){

                                                                    //     const isExecutorMatch = executors.some((item) => {
                                                                    //         return (row.pan !== null && item.pan.toUpperCase() === row.pan.toUpperCase()) || (row.email !== null && item.email === row.email) || item.phone === row.phone;
                                                                    //     });
                                                                    //     console.log('isExecutorMatch', isExecutorMatch);
                                                                    //     if(isExecutorMatch){
                                                                    //         setErrors([{
                                                                    //             // AJV style path to the property in the schema
                                                                    //             instancePath: "/copy_from_beneficiary",
                                                                    //             // message to display
                                                                    //             message: 'To copy beneficiary add PAN, Phone, Email in beneficiary',
                                                                    //             schemaPath: "",
                                                                    //             keyword: '',
                                                                    //             params: {},
                                                                    //         }]);
                                                                    //     }
                                                                    // }
                                                                    // else{
                                                                    setEdit(true);
                                                                    // }
                                                                }}>
                                                                    <Iconify icon={"fluent:edit-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={`Delete ${sentenceCase(genericType)}`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {

                                                                    if (genericType === 'beneficiary') {

                                                                        const deletingObject = beneficiaries.find((obj) => obj.id === id);

                                                                        const isExecutorMatch = executors.some((item) => {
                                                                            return (item.salutation.toUpperCase() === deletingObject.salutation.toUpperCase() && item.first_name.toUpperCase() === deletingObject.first_name.toUpperCase() && item.last_name.toUpperCase() === deletingObject.last_name.toUpperCase() && item.dob.toUpperCase() === deletingObject.dob.toUpperCase()) || (deletingObject.pan !== null && item.pan.toUpperCase() === deletingObject.pan.toUpperCase()) || (deletingObject.email !== null && item.email === deletingObject.email) || item.phone === deletingObject.phone;
                                                                        });

                                                                        const isGuardianMatch = beneficiaries.some((item) => {
                                                                            return item.id !== deletingObject.id && ((item.guardian?.salutation.toUpperCase() === deletingObject.salutation.toUpperCase() && item.guardian?.first_name.toUpperCase() === deletingObject.first_name.toUpperCase() && item.guardian?.last_name.toUpperCase() === deletingObject.last_name.toUpperCase() && item.guardian?.dob.toUpperCase() === deletingObject.dob.toUpperCase()) || (deletingObject.pan?.toUpperCase() !== undefined && item?.guardian?.pan?.toUpperCase() === deletingObject?.pan?.toUpperCase()) || (deletingObject.email !== null && item?.guardian?.email === deletingObject?.email) || (deletingObject.phone !== null && item?.guardian?.phone === deletingObject?.phone));
                                                                        });
                                                                        console.log('isGuardianMatch', isGuardianMatch);
                                                                        if (isExecutorMatch || isGuardianMatch) {
                                                                            Swal.fire({
                                                                                title: "Are you sure you want to delete this Beneficiary?",
                                                                                html: (`<div style="text-align: left;"><strong>Important Notes: </strong><br/>
                                                                                ${isGuardianMatch ? 'Guardian Role for this Beneficiary will be retained. To remove, you must do so explicitly from the minor Beneficiary\'s profile.<br/>' : ''}<br/>
                                                                                ${isExecutorMatch ? 'Executor Role for this Beneficiary will be retained. To remove you must do so explicitly from the Executors section.<br/>' : ''}</div>`),
                                                                                icon: "warning",
                                                                                showCancelButton: true,
                                                                                allowEnterKey: false,
                                                                                allowOutsideClick: false,
                                                                                confirmButtonColor: "#3085d6",
                                                                                cancelButtonColor: "#d33",
                                                                                confirmButtonText: "Yes"
                                                                            }).then((result) => {
                                                                                if (result.isConfirmed) {
                                                                                    deleteGeneric(id);
                                                                                }
                                                                            });
                                                                        }
                                                                        else {
                                                                            Swal.fire({
                                                                                title: "Are you sure you want to delete this Beneficiary?",
                                                                                text: (`Do you want to delete the ${sentenceCase(genericType)}?`),
                                                                                // html: (row.relation === 'Spouse' &&  user?.profile?.marital_status === "married") && `<div style="text-align: center;"><strong>Important Note: </strong><br/>You are trying to Delete Spouse as Beneficiary. To remove, you must explicitly change your Maritial Status to Divorced, Widow or single, etc. from Profile Section<br/><br/>
                                                                                // </div>`,
                                                                                icon: "warning",
                                                                                showCancelButton: true,
                                                                                allowOutsideClick: false,
                                                                                allowEnterKey: false,
                                                                                confirmButtonColor: "#3085d6",
                                                                                cancelButtonColor: "#d33",
                                                                                confirmButtonText: "Yes",
                                                                                // preConfirm: () => {
                                                                                //     if (user?.profile?.marital_status === "married") {
                                                                                //         Swal.showValidationMessage('You cannot delete this beneficiary while your marital status is married but you can edit this beneficiary. OR You can make the distribution for this beneficiary to 0(zero)%') ;
                                                                                //         return false;
                                                                                //     }
                                                                                //     return true;
                                                                                // } 
                                                                            }).then((result) => {
                                                                                // if (result.isConfirmed && row.relation !== 'Spouse' &&  user?.profile?.marital_status !== "married") {
                                                                                if (result.isConfirmed) {
                                                                                    deleteGeneric(id);
                                                                                }
                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: "Are you sure?",
                                                                            text: (`Do you want to delete the ${sentenceCase(genericType)}?`),
                                                                            icon: "warning",
                                                                            showCancelButton: true,
                                                                            allowEnterKey: false,
                                                                            allowOutsideClick: false,
                                                                            confirmButtonColor: "#3085d6",
                                                                            cancelButtonColor: "#d33",
                                                                            confirmButtonText: "Yes"
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                deleteGeneric(id);
                                                                            }
                                                                        });
                                                                    }


                                                                }}>
                                                                    <Iconify icon={"fluent:delete-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                            {(editingId === id && open !== null) && <TableRow
                                                sx={{ bgcolor: (theme) => editingId === id ? "rgba(255, 156, 65, 0.18)" : "initial" }}>
                                                <TableCell
                                                    colSpan={Object.keys(schemas?.schemas?.[genericType]?.properties ?? {}).filter(key => key !== "add_as_executor")?.length + 2}>
                                                    <Box sx={{ width: "100%", bgcolor: "white", p: 2 }}>
                                                        <JsonForms
                                                            i18n={{ translate: translator(genericType) }}
                                                            readonly={!edit}
                                                            schema={schema}
                                                            additionalErrors={additionalErrors}
                                                            uischema={schemas.ui_schemas?.[genericType]}
                                                            data={genericData}
                                                            renderers={[...materialRenderers,
                                                            {
                                                                tester: (uischema) => {
                                                                    // console.log('uischema:', uischema);
                                                                    return uischema && uischema.scope === '#/properties/guardian/properties/copy_from_beneficiary';
                                                                },
                                                                renderer: OneOfCustomAutocompleteControl
                                                                // renderer: (props) => { console.log('props', props); return <OneOfCustomAutocompleteControl options={beneficiaryOptions} handleChange={handleGenericDataChange} {...props} />}
                                                            },
                                                            {
                                                                tester: OneOfTester,
                                                                renderer: OneOfControl
                                                            }, {
                                                                tester: PhoneInputTester,
                                                                renderer: PhoneInputControl
                                                            }, {
                                                                tester: ImagePickerTester,
                                                                renderer: ImagePickerControl
                                                            }]}
                                                            cells={materialCells}
                                                            onChange={handleGenericDataChange}
                                                        />
                                                        {helperText && <Typography color='error'>{helperText}</Typography>}
                                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                                            <></>
                                                            <Stack direction="column" justifyContent={"space-between"} spacing={1}>
                                                                {Object.prototype.hasOwnProperty.call(genericData, 'guardian') && Object.keys(genericData?.guardian).length > 0 && (<Button onClick={() => {
                                                                    Swal.fire({
                                                                        title: "Are you sure you want to clear Guardian?",
                                                                        // text: ("Distribution of pet assets is limited to a single beneficiary, with entire allocation (100%)."),
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        allowEnterKey: false,
                                                                        allowOutsideClick: false,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        cancelButtonText: "No",
                                                                        confirmButtonText: "Yes"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            // updateMappings();
                                                                            setGenericData({ ...genericData, guardian: { copy_from_beneficiary: null } });
                                                                        }
                                                                    });

                                                                }
                                                                } style={{ marginTop: "8px" }}
                                                                >
                                                                    <Iconify icon={"fluent:delete-32-regular"} />Clear Guardian Data
                                                                </Button>)}

                                                            </Stack>
                                                        </Stack>
                                                        <Stack direction="row" justifyContent={"space-between"} spacing={1}>
                                                            <Button style={{ marginTop: "8px" }} onClick={() => {
                                                                handleCloseMenu();
                                                            }}
                                                                variant="text">
                                                                Cancel
                                                            </Button>
                                                            {edit &&
                                                                <>

                                                                    <Stack direction="column" justifyContent={"space-between"} spacing={1}>

                                                                        <Button disabled={errors.length > 0 || additionalErrors.length > 0}
                                                                            style={{ marginTop: "8px" }} onClick={() => {
                                                                                editGeneric();
                                                                            }}
                                                                            variant="contained">
                                                                            Save
                                                                        </Button>
                                                                    </Stack>
                                                                </>
                                                            }
                                                        </Stack>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>}
                                        </>);
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        // hidden
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={generics.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

        </>
    );
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const IncompleteWillsCard = () => {
  const [incompleteWillsData, setIncompleteWillsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncompleteWillsData = async () => {
      try {
        const response = await axios.get('/admin/incomplete-wills-count');
        const { incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount } = response.data;
        setIncompleteWillsData({ incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount });
      } catch (error) {
        console.error('Error fetching total incomplete wills data:', error);
      }
    };

    fetchIncompleteWillsData();
  }, []);

  const handleClick = () => {
    navigate('/admin/incomplete-wills-page');
  };

  return (
    <DashboardCard
      title="Total Incomplete Wills(Individual v/s Partner)"
      subtitle={`${incompleteWillsData?.incompleteWillsCount ?? '--'} (${
        incompleteWillsData?.testatorIncompleteWillsCount ?? '--'
      } / ${incompleteWillsData?.partnerIncompleteWillsCount ?? '--'})`}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default IncompleteWillsCard;

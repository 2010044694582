import { Helmet } from "react-helmet-async";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";


// @mui
import {
    Card,
    Stack,
    Button,
    Checkbox,
    Container,
    Typography,
    IconButton,
    Box,
    FormControl,
    Grid,
    TextField,
    Tooltip,
    Stepper,
    FormControlLabel,
    Step,
    StepLabel,
    Link,
    Autocomplete,
    Divider
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import RemoveIcon from '@mui/icons-material/Remove';

// components
import Swal from "sweetalert2";
import { translator } from "../utils/jsonForms";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import CenterModal from "../components/CenterModal";
import { LoadingContext } from "../App";
import Logo from "../components/logo";
import { PhoneInputControl, PhoneInputTester } from "../components/renderers/PhoneInput";
// mock

// ----------------------------------------------------------------------


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

// JSONForms schema and UI schema for wealth manager details
const schemas = {
    schemas: {
        profile: {
            type: 'object',
            properties: {
                name: { type: 'string' },
                phone: { type: 'string', pattern: "^[a-z]{2}:[0-9]+:[1-9][0-9]{9,11}$" },
                email: {
                    type: 'string', pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
                    errorMessage: "Please enter a valid email",
                    default: null
                },
                clientId: { type: "number" },
                relationshipManager: { type: 'string' }
            },
            required: ['name', 'phone', 'email', 'clientId'],
        },
    },
    ui_schemas: {
        profile: {
            type: 'VerticalLayout',
            elements: [
                {
                    type: 'HorizontalLayout',
                    elements: [
                        { type: 'Control', scope: '#/properties/name' },
                        { type: 'Control', scope: '#/properties/clientId' },
                    ],
                },
                {
                    type: 'HorizontalLayout',
                    elements: [
                        { type: 'Control', scope: '#/properties/email' },
                        { type: 'Control', scope: '#/properties/phone', tester: 'PhoneInputTester', renderer: 'PhoneInputControl' },
                    ],
                },
                {
                    type: 'HorizontalLayout',
                    elements: [
                        { type: 'Control', scope: '#/properties/relationshipManager' }, // Set width to 50% for half width
                    ],
                },
            ],
        },
    },
};

export default function PreviewPage() {

    const [page, setPage] = useState("");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [resp, setResp] = useState({});
    const [step, setStep] = useState(0);
    const [generated, setGenerated] = useState(false);
    const [bankNames, setBankNames] = useState([]);
    const [willParams, setWillParams] = useState({
        sellImmovable: false,
        additionalCopy: false,
        additionalCopyPerson: "",
        additionalCopyPlace: "",
        bankLockerWillKept: false,
        bankLockerNumber: "",
        branchName: "",
        branchAddress: "",
        bankName: null,

    });
    const [hasWealthManager, setHasWealthManager] = useState(false)
    const [managerData, setManagerData] = useState([{
        name: undefined,
        phone: undefined,
        email: undefined,
        clientId: undefined,
        relationshipManager: undefined,
    }]);
    const [managerDataErrors, setManagerDataErrors] = useState([
        {}, // Initially no errors for the first manager
    ]);
    // Add a new manager data field
    const addManagerDataField = () => {
        if (managerData.length < 3) {
            setManagerData([
                ...managerData,
                {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                    clientId: undefined,
                    relationshipManager: undefined,
                },
            ]);
        }
    };

    // Remove a manager data field
    const removeManagerDataField = (index) => {
        if (managerData.length > 1) {
            const updatedManagerData = [...managerData];
            updatedManagerData.splice(index, 1);
            setManagerData(updatedManagerData);
        }
    };
    // Function to check if wealth manager form is valid
    const isWealthManagerFormValid = () => {
        if (hasWealthManager) {
            // Check if all required fields are filled
            const requiredFieldsFilled = managerData.every((manager) => manager.name && manager.phone && manager.email && manager.clientId);
            // Check for errors in the form
            const hasErrors = managerDataErrors.some((errors) => Object.keys(errors).length > 0);
            return requiredFieldsFilled && !hasErrors;
        }
        return true; // If wealth manager is not required, form is always considered valid
    };


    const { review } = useParams();
    const { user, fetchUser } = useContext(UserContext);
    const previewDraft = () => {
        axios.patch(`/wills/draft/preview`).then(response => {
            if (response.data?.lastWill?.has_wealth_manager) {
                setHasWealthManager(response.data?.lastWill?.has_wealth_manager)
                setManagerData(response.data?.lastWill?.wealth_manager_details)
            }
            if (response.data?.lastWill?.additional_copy) {
                setWillParams((w) => ({
                    ...w,
                    additionalCopy: response.data?.lastWill?.additional_copy,
                    additionalCopyPerson: response.data?.lastWill?.additional_copy_person,
                    additionalCopyPlace: response.data?.lastWill?.additional_copy_place
                }));
            }
            if (response.data.lastWill && response.data.lastWill.bank_locker_will_kept) {
                setWillParams((w) => ({
                    ...w,
                    bankLockerWillKept: response.data?.lastWill?.bank_locker_will_kept,
                    bankName: response.data?.lastWill?.bank_name,
                    bankLockerNumber: response.data?.lastWill?.bank_locker_number,
                    branchName: response.data?.lastWill?.branch_name,
                    branchAddress: response.data?.lastWill?.branch_address
                }));
            }
            setPage(response.data.html);
            setResp(response.data);
        }).catch(err => console.error(err));
    };
    const generateWill = () => {
        axios.patch(`/wills/draft/generate`, {
            data: willParams,
            wealthManagerInfo: {
                hasWealthManager,
                managerData
            }
        })
            .then(response => {
                console.log(response);
                /**
                 * Commented download will automatically after will generation as per Client's request
                 */
                // const link = document.createElement("a");
                // link.target = "_blank";
                // link.href = `/wills/download/${response.data.will}`;
                // //   link.href = `http://localhost:3001/wills/download/${response.data.will}`;
                // link.download = `will-${user?.full_name}.pdf`;
                // link.click();
                // link.remove();

                setGenerated(true);
            }).catch(err => console.error(err));
    };

    useEffect(() => {
        if (user?.role === "reviewer" && !!review) {
            axios.interceptors.request.use(config => {
                config.headers["X-Review-Id"] = +review;
                return config;
            });
        }
        previewDraft();
    }, [user, review]);
    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);
    useEffect(() => {
        console.log('video_close_preview', !generated);
        if (!sessionStorage.getItem(`video_close_preview`) && !isLoading && !Object.values(prechecks ?? {})?.every(v => v === true || v.length === 0)) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading]);


    const [comment, setComment] = useState("");
    const [reviewOpen, setReviewOpen] = useState(false);
    const demoModeActivated = useMemo(() => {
        const demoMode = user?.Subscriptions?.filter(sub => sub.UserSubscription.SubscriptionId === 1 && sub.UserSubscription.payment_status === "DEMO")?.length ?? 0;
        console.log('demoMode', demoMode);
        if (demoMode === 1 && user.role === 'partner') return true;
        return false;
    }, [user]);
    const usage = useMemo(() => {
        const used = user?.Reviews?.filter(rev => rev.type === "REVIEW")?.length ?? 0;
        const sub = user?.Subscriptions;
        const allowed = sub?.reduce((p, c) => +p + +c.reviews, 0) ?? 0;
        return { used, allowed };
    }, [user]);
    const requestReview = () => {
        axios.patch("/wills/draft/review", {
            type: "REVIEW",
            comment
        }).then(res => {
            // toastr.success("Review request submitted")
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Review request submitted`,
                showConfirmButton: false,
                timer: 3000
            });
        }).catch(e => {
            // toastr.error("Failed to request review")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to request review`,
                showConfirmButton: false,
                timer: 3000
            });

        }).finally(() => {
            setReviewOpen(false);
            fetchUser();
        });
    };

    const activeReview = useMemo(() => {
        let active = false;
        if (user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === "REVIEW")) active = true;
        return active;
    }, [user]);

    const subscribed = useMemo(() => {
        // let consumed = user?.Subscriptions ?.length === 0;
        let consumed = true;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(sub => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1) {
                    const today = new Date();
                    const subDay = new Date(userSub.createdAt);
                    const subDays = (today - subDay) / 86400 / 1000;
                    const allowed = sub.days;
                    if (subDays > allowed)
                        consumed = true;
                    else
                        consumed = false;

                }
            }
        });
        return !consumed;
    }, [user]);

    const prechecks = useMemo(() => {
        const testator = ["salutation", "first_name", "last_name", "pan", "dob"].filter(f => !Object.keys(user?.profile ?? {}).includes(f));
        console.log('resp', resp);
        const distribution = [...(resp?.assets ?? []), { id: null, information: { caption: 'Any other assets not included in the Assets that need to be distributed' }, type: '', sub_type: 'Residual Assets' }].map(({ id, name, information }) => {
            let mapped = true;
            [{ id: 0, deaths: [] }, ...(resp?.scenarios ?? [])].forEach(sc => {
                const will = resp?.wills?.find(w => w.PropertyId === id && sc.id === w.ScenarioId);
                if (!will) {
                    mapped = false;
                } else {
                    let value = 100;
                    let total = 0;
                    if (will?.distribution_type === "value") {
                        value = information?.value ?? information?.amount ?? information?.insured_amount ?? information?.investment_amount ?? information?.deposited_amount;
                    } else if (will?.distribution_type === "quantity") {
                        value = information?.quantity ?? information?.qty ?? information?.number;
                    }
                    will?.mapping?.forEach(mapping => {
                        total += +(mapping.share ?? 0);
                    });
                    if (Math.abs(Number((value - total).toFixed(2))) <= 0.01) {
                        total = value;
                    }
                    mapped = mapped && total === value;
                }
            });
            return {
                name: information?.name ?? information?.description ?? information?.description_or_name ?? information?.description_of_vehicle,
                mapped
            };
        }).filter(f => !f.mapped);
        // const assets = resp?.wills?.every(w => w.mapping?.reduce((p, c) => +p + +c.share, 0) === 100) ?? false; // TODO: Value and quantity check to be fixed
        const assets = resp?.assets?.length > 0 ?? false;
        const beneficiaries = resp?.beneficiaries?.length > 0 ?? false;
        const executors = resp?.executors?.length > 0 ?? false;
        const witnesses = subscribed ? resp?.witnesses?.length === 2 ?? false : resp?.witnesses?.length === 2 || resp?.witnesses?.length === 1 || false
        // console.log('distribution', distribution);
        return { testator, assets, distribution, beneficiaries, executors, witnesses };
    }, [resp, user]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchForms();
    }, []);

    const fetchForms = () => {
        axios.get("/properties/forms").then(response => {
            if (response.data) {
                // Convert the object into an array of objects
                const bankNames = response.data.schemas.bank_investments.bank_account.properties.bank_name.dummyEnum;
                // assign banknames to setBankNames
                setBankNames(bankNames)
            }
        }).catch(err => console.error(err));
    };

    useEffect(() => {
        if (!subscribed && (prechecks?.witnesses || prechecks?.executors)) {
            Swal.fire({
                position: 'center',
                icon: "info",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: 'To preview full will and generate a Will, please purchase the "Make your Own Will Service" from services page.',
                showConfirmButton: false,
                timer: 5000,
            });
        }
    }, [subscribed, prechecks]);

    return (<>
        <Helmet>
            <title> Generate Will | Get Will Done </title>
        </Helmet>

        <Container fullWidth style={{ maxWidth: "unset" }}>
            <CenterModal
                open={reviewOpen}
                onClose={() => {
                    setReviewOpen(false);
                }}
                title={`${review ? "Close" : "Request"} Review before generation`}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                    {!review && <Typography color="warning.dark" variant="body2" component="p" sx={{ mb: 2 }}>
                        Please note the number of reviews are limited and have to be purchased separately. You can purchase
                        additional reviews from the profile page. Reviews
                        left {(usage.allowed - usage.used) ?? 0} / {usage.allowed ?? 0}
                    </Typography>}
                    <TextField label={"Comment"} fullWidth multiline value={comment}
                        onChange={(e) => setComment(e.target.value)} id={"review-comment"} rows={4} />
                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Button disabled={(usage.allowed - usage.used) < 1} style={{ marginTop: "8px" }} onClick={() => {
                        requestReview();
                    }}
                        variant="contained">
                        Request Review
                    </Button>
                    <Button disabled={!user.Subscriptions?.[0]} style={{ marginTop: "8px" }} onClick={() => {
                        setOpen(true);
                        setReviewOpen(false);
                    }}
                        variant="contained">
                        Finalise without review
                    </Button>
                </Stack>
            </CenterModal>
            <CenterModal
                centered
                open={open}
                onClose={() => {
                    setOpen(false);
                    setStep(0);
                }}
                title={generated ? "" : "Will generation checklist"}
            >
                {generated ? <Box sx={{ bgcolor: "#FFFFFF", width: "500px", mx: -1, mb: -1, borderRadius: 2 }}>
                    <Stack direction={"column"} spacing={1} justifyContent={"space-evenly"} alignItems={"center"}
                        sx={{ width: "500px", height: "auto", fontSize: "48px" }}>
                        <Box sx={{ width: "50%" }}><Logo disabledLink minimized /></Box>
                        {/* <img src={"/assets/images/success-message.png"} alt={""} /> */}
                        <Typography color={"primary.main"} align="center" fontSize={36} variant={"body1"} sx={{ p: 2 }}>
                            Will Generated Successfully
                        </Typography>
                        <Stack direction={"column"} spacing={1}>
                            <Typography align="justify" variant={"body2"} sx={{ p: 2 }}>
                                <strong>Dear {user?.full_name},</strong><br />
                                <span>Congratulations!! Your Will is successfully generated.
                                    You can make any changes to your Will as you wish, on or before 365 days, at no additional cost. Should you have any questions, drop us an email at <Link
                                        href={"mailto:info@getwilldone.com"}>info@getwilldone.com</Link>.</span>
                                <center style={{ marginTop: "24px" }}>Once again thank you for choosing GetWillDone</center>
                                <center style={{ marginTop: "24px" }}><strong>Please check your email.</strong></center>
                            </Typography>
                        </Stack>
                        <Stack direction={"column"} spacing={1}>
                            {/* <Button component={RouterLink} to={"/dashboard/app"} variant={"contained"}>Go to dashboard</Button> */}
                            <Button sx={{ mb: 2 }} onClick={() => {
                                setOpen(false);
                                setStep(0);
                                setGenerated(false);
                                navigate('/dashboard/subscriptions')
                            }}>OK</Button>
                        </Stack>
                    </Stack>
                </Box> : <Box sx={{ m: 1, p: 2, bgcolor: "white", mx: -1 }}>
                    <Stepper activeStep={step} alternativeLabel>
                        <Step><StepLabel>Checklist</StepLabel></Step>
                        {/* <Step><StepLabel>Immovable Properties</StepLabel></Step> */}
                        <Step><StepLabel>Copies for Executor</StepLabel></Step>
                        <Step><StepLabel>Finalise Will</StepLabel></Step>
                    </Stepper>
                    <hr />
                    {[<>
                        <Stack spacing={1}>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography variant={"body"}>Testator
                                details</Typography> <Tooltip
                                    title={prechecks?.testator?.length > 0 ? `Please fill ${prechecks?.testator?.map(x => sentenceCase(x)).join(", ")} in your profile` : ""}><IconButton
                                        component={RouterLink} to={"/dashboard/app"}
                                        color={prechecks?.testator?.length === 0 ? "success" : "error"}
                                        sx={{ float: "right" }}>{prechecks?.testator?.length === 0 ?
                                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography
                                variant={"body"}>Beneficiaries</Typography> <Tooltip
                                    title={prechecks?.beneficiaries ? "" : "Please add atleast one beneficiary"}><IconButton
                                        component={RouterLink} to={"/dashboard/beneficiaries"}
                                        color={prechecks?.beneficiaries ? "success" : "error"}
                                        sx={{ float: "right" }}>{prechecks?.beneficiaries ?
                                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography
                                variant={"body"}>Assets</Typography>
                                <Tooltip title={prechecks?.assets ? "" : "Please add atleast one asset"}><IconButton
                                    component={RouterLink} to={"/dashboard/assets"} color={prechecks?.assets ? "success" : "error"}
                                    sx={{ float: "right" }}>{prechecks?.assets ?
                                        <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                        <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography
                                variant={"body"}>Distribution</Typography> <Tooltip
                                    title={prechecks?.distribution?.length === 0 ? "" : "Please ensure all assets are distributed to 100% or full value"}><IconButton
                                        component={RouterLink} to={"/dashboard/distribution"}
                                        color={prechecks?.distribution?.length === 0 ? "success" : "error"}
                                        sx={{ float: "right" }}>{prechecks?.distribution?.length === 0 ?
                                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography
                                variant={"body"}>Executors</Typography> <Tooltip
                                    title={prechecks?.executors ? "" : "Please add atleast one executor"}><IconButton
                                        component={RouterLink}
                                        to={"/dashboard/executors"}
                                        color={prechecks?.executors ? "success" : "error"}
                                        sx={{ float: "right" }}>{prechecks?.executors ?
                                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}><Typography
                                variant={"body"}>Witnesses</Typography> <Tooltip
                                    title={prechecks?.witnesses ? "" : "Please add exactly 2 witnesses"}><IconButton
                                        component={RouterLink}
                                        to={"/dashboard/witnesses"}
                                        color={prechecks?.witnesses ? "success" : "error"}
                                        sx={{ float: "right" }}>{prechecks?.witnesses ?
                                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>

                        </Stack>
                    </>
                        // , <>
                        //     <FormControl>
                        //         <FormLabel id="demo-row-radio-buttons-group-label">Do you intend to sell immovable Property and
                        //             distribute
                        //             the consideration amount in favour of the Beneficiaries?</FormLabel>
                        //         <RadioGroup
                        //             row
                        //             value={willParams.sellImmovable}
                        //             onChange={e => setWillParams(w => ({ ...w, sellImmovable: e.target.value }))}
                        //             aria-labelledby="demo-row-radio-buttons-group-label"
                        //             name="row-radio-buttons-group"
                        //         >
                        //             <FormControlLabel value control={<Radio />} label="Yes" />
                        //             <FormControlLabel value={false} control={<Radio />} label="No" />
                        //         </RadioGroup>
                        //     </FormControl>
                        // </>
                        , <Stack spacing={2} direction={"column"}>
                        {/* Checkbox and text */}
                        <Typography variant={'body1'}>You must keep one copy of the Will with each of your executors</Typography>
                        {/* <FormControlLabel
                            sx={{ m: 0 }}
                            control={<Checkbox checked={willParams.executorCopy} onChange={(e, value) => {
                                setWillParams((w) => ({ ...w, executorCopy: value }));
                            }} />}
                            label={<Typography variant={'body1'}>I have kept one copy of this Will with each of my Executor/s</Typography>}
                        /> */}
                        <FormControlLabel
                            sx={{ m: 0 }}
                            control={<Checkbox checked={willParams.additionalCopy} onChange={(e, value) => {
                                setWillParams((w) => ({ ...w, additionalCopy: value, additionalCopyPerson: "", additionalCopyPlace: "" }));
                            }} />} label={`I have additionally kept one copy of this Will with the following person`} />

                        {/* Stack containing two TextFields */}
                        <Stack direction={"row"} spacing={1}>
                            {/* First TextField - Person */}
                            <TextField
                                required={willParams.additionalCopy}
                                disabled={!willParams.additionalCopy}
                                value={willParams.additionalCopyPerson}
                                onChange={e => setWillParams(w => ({ ...w, additionalCopyPerson: e.target.value }))}
                                label="Person"
                                variant="standard"
                                fullWidth
                            />
                            {/* Second TextField - Place */}
                            <TextField
                                required={willParams.additionalCopy}
                                disabled={!willParams.additionalCopy}
                                value={willParams.additionalCopyPlace}
                                onChange={e => setWillParams(w => ({ ...w, additionalCopyPlace: e.target.value }))}
                                label="Place"
                                variant="standard"
                                fullWidth
                            />
                        </Stack>

                        {/* Checkbox and text */}
                        <FormControlLabel
                            sx={{ m: 0 }}
                            control={<Checkbox checked={willParams.bankLockerWillKept} onChange={(e, value) => {
                                setWillParams((w) => ({
                                    ...w,
                                    bankLockerNumber: "",
                                    branchName: "",
                                    branchAddress: "",
                                    bankName: null,
                                    bankLockerWillKept: value
                                }));
                            }} />} label={`I have also kept a copy of this Will in the following bank locker`} />

                        {/* Stack containing two TextFields */}
                        <Stack>
                            {/* First TextField - Bank Name Dropdown and branchName */}
                            <Grid container spacing={1}>
                                <Grid item xs={6}> {/* Bank Name Dropdown */}
                                    {/* <InputLabel id="bank-select-label" >Bank Name</InputLabel> */}
                                    {/* <FormControl > */}
                                    <Autocomplete
                                        labelId="bank-select-label"
                                        id="bank-select"
                                        fullWidth
                                        disabled={!willParams.bankLockerWillKept}
                                        value={willParams.bankName}
                                        options={bankNames}
                                        onChange={(e, newValue) => {
                                            setWillParams(w => ({ ...w, bankName: newValue }))
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Bank Name" variant="standard" />
                                        )}
                                    />
                                    {/* </FormControl> */}

                                    {/* <Select
                                            labelId="bank-select-label"
                                            id="bank-select"
                                            value={willParams.bankName}
                                            label="Bank Name"
                                            onChange={(e) => {
                                                setWillParams(w => ({ ...w, bankName: e.target.value }))
                                            }}
                                            required={willParams.bankLockerWillKept}
                                            disabled={!willParams.bankLockerWillKept}
                                            fullWidth
                                        // MenuProps={{  // Customizing menu props
                                        //     PaperProps: {
                                        //         style: {
                                        //             maxHeight: 200,  // Adjust maxHeight as needed
                                        //             width: 'auto',
                                        //         },
                                        //     },
                                        //     // getContentAnchorEl: null, // Align menu to top
                                        //     // anchorOrigin: {
                                        //     //     vertical: 'bottom',
                                        //     //     horizontal: 'center',
                                        //     // },
                                        // }}
                                        >
                                            {bankNames.map((bankName, index) => (
                                                <MenuItem key={index} value={bankName}>
                                                    {bankName}
                                                </MenuItem>
                                            ))}


                                        </Select> */}
                                </Grid>
                                <Grid item xs={6}> {/* branchName */}
                                    <TextField
                                        required={willParams.bankLockerWillKept}
                                        disabled={!willParams.bankLockerWillKept}
                                        value={willParams.branchName}
                                        onChange={e => setWillParams(w => ({ ...w, branchName: e.target.value }))}
                                        label="Branch Name"
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} marginTop={0}>
                                {/* Second row */}
                                {/* First TextField - Bank Locker Number */}
                                <Grid item xs={6}>
                                    <TextField
                                        required={willParams.bankLockerWillKept}
                                        disabled={!willParams.bankLockerWillKept}
                                        value={willParams.bankLockerNumber}
                                        onChange={e => setWillParams(w => ({ ...w, bankLockerNumber: e.target.value }))}
                                        label="Bank Locker Number"
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                {/* Second TextField - Bank Address */}
                                <Grid item xs={6}>
                                    <TextField
                                        required={willParams.bankLockerWillKept}
                                        disabled={!willParams.bankLockerWillKept}
                                        value={willParams.branchAddress}
                                        onChange={e => setWillParams(w => ({ ...w, branchAddress: e.target.value }))}
                                        label="Branch Address"
                                        variant="standard"
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                        <Box id={'WealthManager'} className={'WealthManager'} sx={{ m: -1, p: 1, bgcolor: 'white' }}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <FormControlLabel
                                        sx={{ m: 0 }}
                                        control={
                                            <Checkbox
                                                checked={hasWealthManager}
                                                onChange={(event) => {
                                                    setHasWealthManager(event.target.checked);
                                                    if (!event.target.checked) {
                                                        // Reset form if wealth manager is disabled
                                                        setManagerData([
                                                            {
                                                                name: undefined,
                                                                phone: undefined,
                                                                email: undefined,
                                                                clientId: undefined,
                                                                relationshipManager: undefined,
                                                            },
                                                        ]);
                                                    }
                                                }
                                                }
                                            />
                                        }
                                        label="Details of my wealth manager"
                                    />
                                </Grid>
                                <Grid item>
                                    {managerData.length < 3 && hasWealthManager && (
                                        <Tooltip title="Wealth Manager">
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={addManagerDataField}
                                                    disabled={!hasWealthManager}
                                                >
                                                    <AddIcon />
                                                    Wealth Manager
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                            {managerData.map((manager, index) => (
                                <Box key={index} sx={{ mb: 3 }}>
                                    <JsonForms
                                        i18n={{ translate: translator('you') }}
                                        schema={schemas.schemas.profile}
                                        uischema={schemas.ui_schemas.profile}
                                        data={manager}
                                        renderers={[
                                            ...materialRenderers,
                                            { tester: PhoneInputTester, renderer: PhoneInputControl }
                                        ]}
                                        cells={materialCells}
                                        validationMode={!hasWealthManager ? 'ValidateAndHide' : 'ValidateAndShow'}
                                        onChange={({ data, errors }) => {
                                            const updatedManagers = [...managerData];
                                            updatedManagers[index] = data;
                                            setManagerData(updatedManagers);
                                            setManagerDataErrors(updatedManagers.map((manager) => errors));
                                        }}
                                        readonly={!hasWealthManager} // Disable the form when hasWealthManager is false
                                    />
                                    {managerData.length > 1 && (
                                         <Tooltip title="Remove">
                                         <span> {/* This span is necessary to ensure the tooltip works with a disabled button */}
                                             <Button
                                                 variant="outlined"
                                                 color="error"
                                                 onClick={() => removeManagerDataField(index)}
                                                 sx={{ mt: 1, ml: 1 }}
                                                 disabled={!hasWealthManager}
                                             >
                                                 Remove
                                             </Button>
                                         </span>
                                     </Tooltip>
                                    )}
                                    {index < managerData.length - 1 && <Divider
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            borderBottomWidth: 2,
                                            borderColor: 'info.darker'
                                        }} />}
                                </Box>
                            ))}
                        </Box>


                    </Stack>
                        , <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}
                            sx={{ fontSize: "48px", height: "350px" }}>
                        <Box>
                            <Logo disabledLink />
                        </Box>
                        <Typography sx={{ mt: 2 }} align={"left"} variant="p" component="p">
                            Congratulations!! Your Will is now ready.
                            Once generated, you can make any changes to your Will as you wish, on or before 365 days, at no
                            additional cost.
                        </Typography>
                        <Typography align={"center"} variant="p" component="p">
                            Thank you for using GetWillDone
                        </Typography>
                    </Stack>][step]}
                </Box>}
                {!generated && (
                    <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        {/* Back button */}
                        <Button onClick={() => {
                            if (step === 0) {
                                setOpen(false);
                                setStep(0);
                            } else {
                                setStep(s => s - 1);
                            }
                        }}>{step === 0 ? "Cancel" : "Back"}</Button>

                        {/* Next/Generate button and deactivation message */}
                        <Stack direction="row" alignItems="center">
                            {/* Display the deactivation message if demo mode is activated and step is 2 */}
                            {demoModeActivated && step === 2 && (
                                <span style={{
                                    transform: 'translateX(0%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    color: '#fff',
                                    padding: '3px 7px',
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    marginRight: '4px', // Add spacing between the message and button
                                    marginTop: "8px"
                                }}>
                                    Deactivated since DEMO MODE
                                    {/* Triangle marker for deactivation message */}
                                    <span style={{
                                        position: 'absolute',
                                        top: '50%', // Position the arrow vertically centered
                                        left: '100%', // Position the arrow at the end of the message
                                        transform: 'translateY(-50%)', // Center the arrow vertically
                                        borderLeft: '6px solid rgba(0, 0, 0, 0.8)', // Change border to left
                                        borderTop: '6px solid transparent',
                                        borderBottom: '6px solid transparent',
                                    }} />
                                </span>
                            )}
                            {/* Next/Generate button */}
                            <Tooltip title={step === 2 ? "Generate" : "Next"}>
                                <Button disabled={(step === 2 && demoModeActivated) || !Object.values(prechecks ?? {})?.every(v => v === true || v.length === 0) || (willParams.additionalCopy && !(willParams.additionalCopyPerson && willParams.additionalCopyPlace)) || (step === 1 && willParams.bankLockerWillKept && !(willParams.bankLockerNumber && willParams.branchAddress && willParams.branchName && willParams.bankName)) || (step === 1 && !isWealthManagerFormValid())}
                                    style={{ marginTop: "8px" }} onClick={() => {
                                        if (step < 2) setStep(s => s + 1);
                                        else generateWill();
                                    }}
                                    variant="contained">
                                    {step === 2 ? "Generate" : "Next"}
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )}

            </CenterModal>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Preview Will Draft
                </Typography>
                {(!review) && (
                    <Tooltip title={`Finalise and Generate Will`}>
                        <Button onClick={() => setOpen(true)} disabled={!subscribed} variant="contained">
                            Finalise and Generate Will
                        </Button>
                    </Tooltip>
                )}

            </Stack>

            <Card style={{ padding: "1cm" }}>
                {/* Comented code for disabled checklist and maked preview available on each stage */}
                { // Object.values(prechecks ?? {})?.every(v => v === true || v.length === 0) && (
                    // (
                    //     <Scrollbar>
                    //         <span dangerouslySetInnerHTML={{ __html: page }} />
                    //     </Scrollbar>)
                    // )
                }
                {(
                    <Scrollbar>
                        <span dangerouslySetInnerHTML={{ __html: page }} />
                    </Scrollbar>
                )}

                {/* Comented code for disabled checklist and maked preview available on each stage */}
                {// !Object.values(prechecks ?? {})?.every(v => v === true || v.length === 0) && (
                    //    <Grid container justifyContent="center">
                    //        <Grid item xs={12}>
                    //            {/* Your content goes here */}
                    //            <Typography variant="h4">Will Generation Checklist</Typography>
                    //            <Stack width={"50%"} spacing={1}>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography variant={"body"}>Testator
                    //                    details</Typography> <Tooltip
                    //                        title={prechecks?.testator?.length > 0 ? `Please fill ${prechecks?.testator?.map(x => sentenceCase(x)).join(", ")} in your profile` : ""}><IconButton
                    //                            component={RouterLink} to={"/dashboard/app"}
                    //                            color={prechecks?.testator?.length === 0 ? "success" : "error"}
                    //                            sx={{ float: "right" }}>{prechecks?.testator?.length === 0 ?
                    //                                <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                                <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography
                    //                    variant={"body"}>Beneficiaries</Typography> <Tooltip
                    //                        title={prechecks?.beneficiaries ? "" : "Please add atleast one beneficiary"}><IconButton
                    //                            component={RouterLink} to={"/dashboard/beneficiaries"}
                    //                            color={prechecks?.beneficiaries ? "success" : "error"}
                    //                            sx={{ float: "right" }}>{prechecks?.beneficiaries ?
                    //                                <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                                <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography
                    //                    variant={"body"}>Assets</Typography>
                    //                    <Tooltip title={prechecks?.assets ? "" : "Please add atleast one asset"}><IconButton
                    //                        component={RouterLink} to={"/dashboard/assets"} color={prechecks?.assets ? "success" : "error"}
                    //                        sx={{ float: "right" }}>{prechecks?.assets ?
                    //                            <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                            <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography
                    //                    variant={"body"}>Distribution</Typography> <Tooltip
                    //                        title={prechecks?.distribution?.length === 0 ? "" : "Please ensure all assets are distributed to 100% or full value"}><IconButton
                    //                            component={RouterLink} to={"/dashboard/distribution"}
                    //                            color={prechecks?.distribution?.length === 0 ? "success" : "error"}
                    //                            sx={{ float: "right" }}>{prechecks?.distribution?.length === 0 ?
                    //                                <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                                <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography
                    //                    variant={"body"}>Executors</Typography> <Tooltip
                    //                        title={prechecks?.executors ? "" : "Please add atleast one executor"}><IconButton
                    //                            component={RouterLink}
                    //                            to={"/dashboard/executors"}
                    //                            color={prechecks?.executors ? "success" : "error"}
                    //                            sx={{ float: "right" }}>{prechecks?.executors ?
                    //                                <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                                <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    //                <Stack direction={"row"} justifyContent={"space-between"}><Typography
                    //                    variant={"body"}>Witnesses</Typography> <Tooltip
                    //                        title={prechecks?.witnesses ? "" : "Please add exactly 2 witnesses"}><IconButton
                    //                            component={RouterLink}
                    //                            to={"/dashboard/witnesses"}
                    //                            color={prechecks?.witnesses ? "success" : "error"}
                    //                            sx={{ float: "right" }}>{prechecks?.witnesses ?
                    //                                <Iconify icon={"fluent:checkmark-circle-24-filled"} /> :
                    //                                <Iconify icon={"fluent:error-circle-24-filled"} />}</IconButton></Tooltip></Stack>
                    // 
                    //            </Stack>
                    //        </Grid>
                    //     </Grid>
                    // 
                    // )
                }
            </Card>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
                <Typography variant="h4" gutterBottom>
                    &nbsp;
                </Typography>
                {(!review) && (
                    <Tooltip title={`Finalise and Generate Will`}>
                        <Button onClick={() => setOpen(true)} disabled={!subscribed} variant="contained">
                            Finalise and Generate Will
                        </Button>
                    </Tooltip>)}
            </Stack>
        </Container >
    </>
    );
}
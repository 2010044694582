import { Helmet } from "react-helmet-async";
import Joyride, { STATUS } from "react-joyride";
// @mui
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Popover, Step, Stepper, StepLabel,
    Box, Chip, Checkbox, FormGroup, FormControlLabel,
    Typography, Link, Stack, ButtonGroup, TextField
} from "@mui/material";

import dayjs from "dayjs";
import { format, subYears, startOfDay } from "date-fns";
// components
import axios from "axios";
import toastr from "toastr";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { isEqual, groupBy } from "lodash";
import { Close, CloseRounded, SignalCellularNullSharp, TypeSpecimenOutlined, UnfoldLess, UnfoldMore } from "@mui/icons-material";
import Swal from "sweetalert2";
import { rankWith, uiTypeIs } from "@jsonforms/core";
import { sentenceCase } from "change-case";
import CenterModal from "../components/CenterModal";
import Iconify from "../components/iconify";
// sections
import { AppOrderTimeline, AppWidgetSummary } from "../sections/@dashboard/app";
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import AppDonutSummary from "../sections/@dashboard/app/AppDonutSummary";
import AppRadialSummary from "../sections/@dashboard/app/AppRadialSummary";
import AppPieSummary from "../sections/@dashboard/app/AppPieSummary";
import { SummaryBreadcrumbs } from "../components/SummaryBreadcrumbs";
import { LoadingContext } from "../App";
import { FileUploadControl, FileUploadTester } from "../components/renderers/FileUpload";
import { translator } from "../utils/jsonForms";
import { fetchDataFromZip } from "../utils/zip";
import { PhoneInputControl, PhoneInputTester } from "../components/renderers/PhoneInput";


// ----------------------------------------------------------------------
let timeoutId = null; // Declare a variable to store the timeout ID
export default function DashboardAppPage() {
    const theme = useTheme();
    const { user, fetchUser } = useContext(UserContext);
    console.log('user.role.', user.role, user.role === "partner" ? user?.profile : { current: {}, permanent: {} });
    const [profile, setProfile] = useState({});
    // const [previousProfile, setPreviousProfile] = useState(user?.profile ?? {});
    const redirectToSubscriptions = user?.Subscriptions?.length === 0 && ["partner", "testator"].includes(user?.role) ? Boolean(true) : Boolean(false);
    const [schemas, setSchemas] = useState({});
    const [errors, setErrors] = useState([]);
    const [readonly, setReadonly] = useState(true);
    const [showProfile, setShowProfile] = useState(false);
    const [assets, setAssets] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [witnesses, setWitnesses] = useState([]);
    const [executors, setExecutors] = useState([]);
    const [openAcknowledge, setOpenAcknowledge] = useState(null);
    const [demoCouponCode, setDemoCouponCode] = useState(null);
    const [firstProfileEdit, setFirstProfileEdit] = useState(true);
    const [willData, setWillData] = useState({});
    const [willErrors, setWillErrors] = useState([]);
    const [willUploadValidationMode, setWillUploadValidationMode] = useState('ValidateAndHide');

    const navigate = useNavigate();
    useEffect(() => {
        sessionStorage.setItem('previousProfileData', JSON.stringify(profile));
    }, []);
    useEffect(() => {
        axios.get("/dashboard/testator").then(res => {
            const { assets: _assets, beneficiaries: _beneficiaries, witnesses: _witnesses, executors: _executors } = res.data;
            setAssets(_assets ?? []);
            setBeneficiaries(_beneficiaries ?? []);
            setWitnesses(_witnesses ?? []);
            setExecutors(_executors ?? []);
        });
    }, []);

    useEffect(() => {
        // console.log("profile",user);
        console.log('here in useEffect user', user.welcome_acknowledge);

        if (user.welcome_acknowledge === false) {
            axios.get("/coupons/demo-coupon").then(res => {
                const { couponCodePrefix } = res.data.demoCoupon;
                console.log('couponCodePrefix', couponCodePrefix);
                setDemoCouponCode(couponCodePrefix);
                setOpenAcknowledge(true);
            });

        }
        console.log('user?.prof', user.profile, user?.profile, !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt));
        if (user?.profile) {
            user.profile.saved = !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt);
            const prof = JSON.parse(sessionStorage.getItem('profile'));
            console.log('here insideif', prof !== null ? Object.keys(prof).length === 0 : prof);
            // if ((prof === null || (prof !== null && Object.keys(prof).length === 0) ) && user.role === "partner") {
            // sessionStorage.setItem('profile', JSON.stringify(user.profile));
            // }
            // else{
            //     sessionStorage.setItem('profile', JSON.stringify({}));
            // }
            console.log('useEffect prof', JSON.parse(sessionStorage.getItem('profile')), user?.profile, profile, !user.profile_saved, !sessionStorage.getItem('profile_saved'), prof, !prof?.profile?.current?.house_no);
            if (!user.profile_saved) {
                // console.log('useEffect prof 1');
                // if(user.role === "testator")
                const storageProfile = sessionStorage.getItem('profile');
                console.log('storageProfile', storageProfile);
                // if(storageProfile === null){
                //     setProfile({current: {}, permanent: {}});
                // }
                // else
                // if(user.role === 'partner')
                //     setProfile(JSON.parse(sessionStorage.getItem('profile')));
                // if(user.role === "partner"){

                setProfile(user.profile);
                // }
                setReadonly(false);
            }
            else {
                setProfile(user.profile);
                // sessionStorage.setItem('profile_saved', true);
                // const areObjectsEqual = isEqual(profile, { ...(user?.profile ?? {}), saved: ((user.role === 'partner' && user.profile?.pan) ? Boolean(true) : Boolean(false)) || !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt) });
                // console.log('areObjectEqual', areObjectsEqual);
                // setProfile({
                //     ...(user?.profile ?? {}), saved: ((user.role === 'partner' && user.profile?.pan) ? Boolean(true) : Boolean(false)) || !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt)
                // } ?? {});
                // if (!areObjectsEqual) {
                // }
            }
        }
        else if (user.role === 'testator') {
            // sessionStorage.setItem('profile', JSON.stringify({}));
            setProfile({ current: {}, permanent: {} });
        }
    }, [user]);

    useEffect(() => {
        if (!readonly) {
            if (errors.length === 0 && user.profile_saved) {
                saveProfile(true);
            }
        }
    }, [profile, readonly]);

    const fetchForms = () => {
        axios.get("/testator/forms").then(response => {
            const { schemas: ds, ui_schemas: uis } = response.data;
            // const today = new Date();
            // const limit = subYears(today, 18);
            // if (ds?.profile?.properties?.dob)
            // ds.profile.properties.dob.formatMaximum = format(limit, "yyyy-MM-dd");
            setSchemas({ schemas: ds, ui_schemas: uis } ?? {});
        }).catch(err => console.error(err));
    };

    useEffect(() => {
        fetchUser();
        fetchForms();
    }, []);

    const [open, setOpen] = useState(null);
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const savePreviousProfile = () => {
        const profile = JSON.parse(sessionStorage.getItem('previousProfileData'));
        const will = { ...profile?.will };
        delete profile?.will;
        axios.patch(`/testator`, { previousProfileUpdate: true, profile, will }).then(async response => {
            // toastr.success("Profile updated successfully");
            sessionStorage.removeItem('previousProfileData');
            await fetchUser();
        }).catch(err => {
            // toastr.error("Failed to update profile")
            // Swal.fire({
            //     position: "top-center",
            //     icon: "error",
            //     color: '#fff',
            //     background: '#00838F',
            //     toast: true,
            //     title: "Failed to update profile",
            //     showConfirmButton: false,
            //     timer: 3000
            // });

        });
    };
    const saveProfile = (autoSave = false) => {
        const will = { ...profile.will };
        delete profile.will;
        axios.patch(`/testator`, { profile, will }).then(async response => {
            // toastr.success("Profile updated successfully");
            if (!autoSave) {
                await fetchUser();
                sessionStorage.removeItem('previousProfileData');
                sessionStorage.setItem('profile_saved', true);
                sessionStorage.removeItem('profile');
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Profile updated successfully",
                    showConfirmButton: false,
                    timer: 3000
                });
                setReadonly(true);
            }
            if (!autoSave && redirectToSubscriptions) {
                navigate("/dashboard/subscriptions");
            }
        }).catch(err => {
            // toastr.error("Failed to update profile")
            if (!autoSave) {
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Failed to update profile",
                    showConfirmButton: false,
                    timer: 3000
                });
            }

        });
    };

    const uploadWill = () => {
        axios.post(`/testator/will`, { will: willData }).then(response => {
            // toastr.success("Will uploaded successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Will uploaded successfully",
                showConfirmButton: false,
                timer: 3000
            });
            setWillData({});
            fetchUser();
        }).catch(err => {
            // toastr.error("Failed to upload will")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to upload will",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === "enter" && !e.repeat) {
                e.preventDefault();
                if (!readonly && errors?.length === 0) {
                    // saveProfile();
                }
            }
        };
        window.addEventListener("keyup", handleEnter);
        return () => {
            window.removeEventListener("keyup", handleEnter);
        };
    }, [readonly, errors]);

    const resetProfile = useCallback(() => {
        if (user.profile_saved) {
            savePreviousProfile();
        }
        setReadonly(true);
        setProfile({
            ...user?.profile, saved: !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt)
        } ?? {});
    }, [user]);


    const versions = useMemo(() => {
        const currentDate = new Date();

        // Convert it to the format "2023-09-02T00:00:00.000Z"
        const formattedDate = format(startOfDay(currentDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");


        const versions = [{
            id: user?.id,
            version: user?.will_version,
            startedOn: user?.Mappings?.find(will => will.version === user?.will_version)?.createdAt,
            signed_at: formattedDate,

        }];
        const comments = [];
        user?.Reviews?.filter(rev => rev.status === "CLOSED")?.forEach(review => comments.push(review.review?.comment ?? ""));
        versions[0].comments = comments;
        user?.Wills?.forEach(will => {
            const generatedOn = will.createdAt;
            const link = `/wills/download/${will.id}`;
            if (user?.will_version !== will.version)
                versions.push({
                    id: will.id, version: will.version, downloadFile: will.file, signed_at: will.uploaded ? will.signed_at : will.createdAt, generatedOn, link, uploaded: will.uploaded, registered_with: will.uploaded ? will.registered_with : null, registered_office_number: will.uploaded ? will.registered_office_number : null, serial_number: will.uploaded ? will.serial_number : null
                });
        });
        // Object.keys(groupBy(user?.Mappings ?? [], "version")).forEach(version => {
        //   const generatedOn = user?.Mappings?.find(will => will.version === +version)?.deletedAt;
        //   // const startedOn = user?.Mappings?.find(will => will.version === +version)?.createdAt;
        //   const link = `/wills/downloads/files/will-${user.id}-${user.will_version}.pdf`;
        //   if (user?.will_version !== +version)
        //     versions.push({ version, generatedOn, link });
        // });
        // return versions.sort((a, b) => b.version - a.version);
        const sortedVersions = versions
            .filter(version => version.generatedOn !== undefined) // Filter by the condition
            .sort((a, b) => new Date(b.signed_at) - new Date(a.signed_at)); // Sort based on signed_at

        // Merge the sorted versions with the original array
        return versions.map(version =>
            version.generatedOn !== undefined ? sortedVersions.shift() : version
        );

        // return versions.filter(version => version.generatedOn !== undefined).sort((a, b) => new Date(b.signed_at) - new Date(a.signed_at));
    }, [user]);

    const activeReview = useMemo(() => {
        return user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === 'REVIEW');
    }, [user]);

    const subscribed = useMemo(() => {
        const sub = true;
        if (sub) {
            const userSub = sub.UserSubscription;
            if (userSub && userSub.SubscriptionId === 1) {
                const today = new Date();
                const subDay = new Date(userSub.createdAt);
                const subDays = (today - subDay) / 86400 / 1000;
                const allowed = sub.days;
                return {
                    subscribedOn: userSub.createdAt,
                    days: Math.round(subDays < allowed ? allowed - subDays : 0),
                    subscription: sub
                };
            }
        }
        return {};
    }, [user]);

    const beneficiarySplit = useMemo(() => {
        const allocated = beneficiaries.filter(bene =>
            assets.some(asset => asset.Mappings.some(will => will.mapping.some(map => map.beneficiary_id === bene.id)))).length;
        const unallocated = beneficiaries.length - allocated;
        const data = {
            labels: ["Unassigned", "Assigned"],
            series: [unallocated, allocated]
        };
        return data;
    }, [beneficiaries, assets]);

    const assetSplit = useMemo(() => {
        const data = {
            series: [assets.filter(asset => asset.Mappings.length === 0).length, assets.filter(asset => asset.Mappings.length > 0).length],
            labels: ["Not Distributed", "Distributed"]
        };
        return data;
    }, [assets]);

    const assetTypes = useMemo(() => {
        const data = {
            series: [],
            labels: []
        };
        const cats = groupBy(assets, "sub_type");
        Object.keys(cats ?? {}).forEach(key => {
            data.series.push(cats[key]?.length ?? 0);
            data.labels.push(sentenceCase(key));
        });
        return data;
    }, [assets]);

    const assetTypesRadial = useMemo(() => {
        const data = {
            series: [],
            labels: []
        };
        const cats = groupBy(assets, "sub_type");
        Object.keys(cats ?? {}).forEach(key => {
            data.series.push((cats[key]?.filter(asset => asset?.Mappings.length > 0).length ?? 0) / (cats[key]?.length ?? 1) * 100);
            data.labels.push(sentenceCase(key));
        });
        return data;
    }, [assets]);

    const assetsMapped = useMemo(() => assets.filter(asset => asset.Mappings.length > 0 && !asset.deletedAt).length, [assets]);

    const steps = [
        {
            target: "#completion-chart",
            content: "Test content for completion chart"
        },
        {
            target: "#beneficiary-split",
            content: "Test content for bene chart"
        },
        {
            target: "#asset-split",
            content: "Test content for asset chart"
        }
    ];
    const [close, setClose] = useState(!!sessionStorage.getItem("video_close_app") ?? false);
    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    useEffect(() => {
        // if (editProfile) setReadonly(false);
    }, [editProfile]);

    useEffect(() => {

        if (!sessionStorage.getItem("video_close_app") && !isLoading && assets.length === 0 && beneficiaries.length === 0) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading, assets, beneficiaries]);

    const convertPhoneNumber = (input) => {
        // Split the input string by ":"
        if (input && input.indexOf(":") !== -1) {

            const parts = input.split(":");

            // Check if there are at least three parts (including the "+" sign)
            if (parts.length >= 3) {
                // Extract the "in" and "91" values
                const countryCode = parts[1];
                const phoneNumber = parts[2];

                // Construct the formatted phone number
                const formattedPhoneNumber = `+${countryCode}-${phoneNumber}`;

                return formattedPhoneNumber;
            }
        }

        // Return the original input if it doesn't match the expected format
        return input;
    }

    const [additionalErrors, setAdditionalErrors] = useState([]);
    const schema = useMemo(() => {
        const _schema = {
            "properties": {
                "signed_at": {
                    "type": "string",
                    "format": "date"
                },
                "registered_at": {
                    "type": "string",
                    "format": "date"
                },
                "registered_with": {
                    "type": "string",
                },
                "registered_office_number": {
                    "type": "string",
                    "pattern": "^[0-9]*$"
                },
                "serial_number": {
                    "type": "string",
                    "pattern": "^[0-9]*$",
                },
                "file": {
                    "type": "string",
                    "format": "file",
                }
            },
            "required": ["file"]
        };
        // _schema.properties.registered_at.formatMinimum = willData.signed_at;
        // _schema.properties.signed_at.formatMaximum = format(new Date(), 'yyyy-MM-dd');
        // _schema.properties.registered_at.formatMaximum = format(new Date(), 'yyyy-MM-dd');
        return _schema;
    }, [willData]);
    

    const acknowledge = () => {
        // console.log('deleteAccount', user.email);
        axios.put(`/testator/acknowledge`).then(async (response) => {
            setOpenAcknowledge(false);
            await fetchUser();
            // toastr.success("Account deleted successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Acknowledged successfully",
                showConfirmButton: false,
                timer: 3000
            });

            // axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', { replace: true }))
        }).catch(err => {
            // toastr.error("Failed to delete account")
            setOpenAcknowledge(false);
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to acknowledge",
                showConfirmButton: false,
                timer: 3000
            });
        });
    }
    // Function to map a label to its corresponding value based on options
    const customCountryCodeMap = (value, options) => {
        // Convert the value to lowercase for comparison
        const lowercaseValue = value.toLowerCase();
        // Find the option with the matching title (case-insensitive)
        const option = options.find((option) => option.title.toLowerCase() === lowercaseValue);
        // Return the const value of the option if found, otherwise return null
        return option ? `${option.const}` : null;
    };

    // Function to map country code to label and update data
    const mapCountryToCountryCode = (data, propertyName, schema) => {
        // Use customLabelMap to map country code to label
        const value = customCountryCodeMap(data[propertyName]?.country, schema.properties.countryCode.oneOf);
        if (value) {
            // Update data with mapped label
            data[propertyName].countryCode = value;
        }
    };

    useEffect(() => {
        // Check if both current and permanent country properties exist in profile
        if (profile?.current?.country && profile?.permanent?.country && schemas?.schemas?.profile?.properties?.current && schemas?.schemas?.profile?.properties?.permanent) {
            // Map country code to label for current and permanent properties in profile
            mapCountryToCountryCode(profile, "current", schemas.schemas.profile.properties.current);
            mapCountryToCountryCode(profile, "permanent", schemas.schemas.profile.properties.permanent);
        }
    }, [user]);


    return (
        <>
            <Helmet>
                <title> Profile | Get Will Done </title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography textAlign={"center"} variant="h3"
                    sx={{ mb: 1 }}>Welcome to GetWillDone, {user?.full_name || "Update your Profile"}</Typography>
                {/* <Typography textAlign={"center"} variant="h5" sx={{ mb: 2 }}>Progress of your Will Creation</Typography> */}
                {/* <Joyride run={assets.length === 0 && beneficiaries.length === 0} continuous showProgress showSkipButton
                 steps={steps}
                 styles={{ options: { zIndex: 10000 } }} /> */}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SummaryBreadcrumbs steps={[
                            { title: `Update profile`, link: "", count: user.profile?.pan ? 1 : 0, titleOnly: true },
                            {
                                title: "Add Beneficiaries",
                                link: user.profile_saved ? "/dashboard/beneficiaries" : "#",
                                count: beneficiaries.length ?? 0,
                                caption: beneficiaries.length ? "You have added" : "Not added",
                                subtitle: "Beneficiaries"
                            },
                            {
                                prevCount: beneficiaries.length ?? 0,
                                title: "Add Assets",
                                link: user.profile_saved ? "/dashboard/assets" : "#",
                                count: assets.length ?? 0,
                                caption: assets.length ? "You have added" : "Not added",
                                subtitle: "Assets"
                            },
                            {
                                prevCount: assets.length ?? 0,
                                title: "Distribute Assets",
                                link: user.profile_saved ? "/dashboard/distribution" : "#",
                                count: assetsMapped ?? 0,
                                caption: assetsMapped ? "You have distributed" : "Not started",
                                subtitle: "Assets"
                            },
                            {
                                prevCount: assetsMapped ?? 0,
                                title: "Add Executors",
                                link: user.profile_saved ? "/dashboard/executors" : "#",
                                count: executors.length ?? 0,
                                caption: executors.length ? "You have added" : "Not added",
                                subtitle: "Executors"
                            },
                            {
                                prevCount: executors.length ?? 0,
                                title: "Add Witnesses",
                                link: user.profile_saved ? "/dashboard/witnesses" : "#",
                                count: witnesses.length ?? 0,
                                caption: witnesses.length ? "You have added" : "Not added",
                                subtitle: "Witnesses"
                            },
                            { prevCount: witnesses.length ?? 0, count: (beneficiaries.length && assets.length && assetsMapped && executors.length && witnesses.length > 1) ? 1 : 0, title: "Preview Will", link: user.profile_saved ? "/dashboard/preview" : "#", titleOnly: true }
                        ]} colors={theme.palette.graphs} />
                    </Grid>
                    {/* <Grid item xs={12}>
            <Card sx={{ p: 4 }} id={"completion-chart"}>
              <Stepper activeStep={1}>
                <Step key={"Assets"}><StepLabel>Assets</StepLabel></Step>
                <Step key={"Beneficiaries"}><StepLabel>Beneficiaries</StepLabel></Step>
                <Step key={"Witnesses"}><StepLabel>Witnesses</StepLabel></Step>
                <Step key={"Executors"}><StepLabel>Executors</StepLabel></Step>
                <Step key={"Distribution"}><StepLabel>Distribution</StepLabel></Step>
                <Step key={"Generate Will"}><StepLabel>Generate Will</StepLabel></Step>
              </Stepper>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={8} sx={{ height: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} sx={{ height: "400px" }} id={"beneficiary-split"}>
                <AppPieSummary title="Beneficiaries" data={beneficiarySplit}
                               emptyComponent={<Typography variant={"h6"} fontWeight={500}>ADD YOUR FIRST
                                 BENEFICIARY<br /><Link to={"/dashboard/beneficiaries"} component={RouterLink}>Add
                                   Beneficiary</Link></Typography>} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ height: "400px" }} id={"asset-split"}>
                <AppPieSummary title="Assets" data={assetSplit}
                               emptyComponent={<Typography variant={"h6"} fontWeight={500}>ADD YOUR FIRST
                                 ASSET<br /><Link to={"/dashboard/assets"} component={RouterLink}>Add
                                   Asset</Link></Typography>} />
              </Grid>
               <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Subscription validity" subtitle={"Subscribed on"} unit={"days"}
                                  secondaryCount={subscribed?.subscribedOn ? format(new Date(subscribed.subscribedOn), "MMM dd, yyyy") : "Not purchased yet"}
                                  total={subscribed?.days ?? 0} color="info" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Since last login" unit={"days"} subtitle={"Last logged in on"}
                                  secondaryCount={format(new Date(), "MMM dd, yyyy")}
                                  total={generics.assets?.length ?? 0} color="info" />
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ height: "400px" }} id={"asset-types"}>
                <AppDonutSummary title="Asset types" data={assetTypes}
                                 emptyComponent={<Typography variant={"h6"} fontWeight={500}>ADD ASSETS TO VIEW
                                   TYPES</Typography>} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ height: "400px" }} id={"asset-types-radial"}>
                <AppRadialSummary title="Asset distribution summary" data={assetTypesRadial}
                                  emptyComponent={<Typography variant={"h6"} fontWeight={500}>ADD ASSETS TO VIEW
                                    DISTRIBUTION</Typography>} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} sx={{ height: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AppWidgetSummary id={"subscription-summary"} title="Subscription validity" subtitle={"Last renewed on"}
                                  unit={"Days"}
                                  total={subscribed?.days ?? 0}
                                  secondaryContent={<Typography align="left" variant="caption" fontWeight={500} sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                  }}>
                                    <span>{subscribed?.subscribedOn ? `Last renewed on: ${format(new Date(subscribed.subscribedOn), "MMM dd, yyyy")}` : "Not purchased yet"}</span><Link
                                    to="/dashboard/subscriptions" id={"upgrade-subscription"}
                                    component={RouterLink}>Upgrade</Link>
                                  </Typography>} color="info" />
              </Grid>
              <Grid item xs={12} sx={{ height: "100%" }}>
                <AppOrderTimeline
                  title="Will timeline"
                  list={versions.map(version => {
                    let status = "In Progress";
                    if (!!activeReview) status = `Review ${sentenceCase(activeReview.status ?? "")}`;
                    if (!!version.generatedOn) status = "Generated";
                    return {
                      id: version.version,
                      link: version.link,
                      title: version.generatedOn ? `Will generated, Version: ${version.version}` : `Active Will, Version: ${version.version}, ${status}`,
                      type: `order${version.generatedOn ? "2" : "3"}`,
                      time: version.generatedOn ?? version.startedOn
                    };
                  })}
                />
              </Grid>
            </Grid>
          </Grid> */}


                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Box sx={{
                            bgcolor: `secondary.main`,
                            border: "none",
                            boxShadow: (theme) => theme.customShadows.card,
                            // borderTop: "5px solid",
                            // borderTopColor: (theme) => theme.palette.primary.main,
                            borderRadius: (theme) => theme.spacing(1),
                            background: (theme) => `linear-gradient(${theme.palette.secondary.main} 0 calc(100% - 8px), #BDBDBD calc(100% - 8px) 100%), linear-gradient(white, white)`,
                            p: 1,
                            pb: 2
                        }}>
                            <Typography sx={{ mb: 1, p: 1, display: "flex", alignItems: "center", color: "white" }}
                                id="modal-modal-title"
                                variant="h6"
                                component="h2">
                                {user?.full_name ? `${user.full_name} ` : 'Your Profile '}&nbsp;<small style={{ fontWeight: 'normal' }}>(Email: {user?.email})</small>
                                <ButtonGroup sx={{ ml: 2 }}>
                                    {readonly &&
                                        <Button variant={"text"} sx={{ color: "white" }} onClick={() => { sessionStorage.setItem('previousProfileData', JSON.stringify(profile)); setReadonly(false) }}
                                            startIcon={<Iconify
                                                icon="fluent:edit-32-regular" />}>Edit</Button>}

                                    {/* {!readonly && <IconButton disabled={errors.length > 0} color={'success'} onClick={() => saveProfile()}><Iconify icon='ion:save' /></IconButton>} */}
                                </ButtonGroup>

                            </Typography>
                            <Box id={"profile"} className={"profile"} sx={{ m: -1, p: 3, bgcolor: "white" }}>
                                {schemas.ui_schemas?.profile && <JsonForms
                                    i18n={{ translate: translator("you") }}
                                    schema={schemas.schemas?.profile}
                                    uischema={schemas.ui_schemas?.profile}
                                    data={profile}
                                    renderers={[...materialRenderers, {
                                        tester: PhoneInputTester,
                                        renderer: PhoneInputControl
                                    }, { tester: FileUploadTester, renderer: FileUploadControl }]}
                                    cells={materialCells}
                                    readonly={readonly}
                                    validationMode={readonly ? 'ValidateAndHide' : 'ValidateAndShow'}
                                    onChange={({ data, errors }) => {
                                        if (!firstProfileEdit && !user.profile_saved) {
                                            console.log('inside edit', profile, data);
                                            // sessionStorage.setItem('profile', JSON.stringify(data));
                                        }
                                        if (!user.profile_saved && firstProfileEdit && !readonly) {
                                            setFirstProfileEdit(false);
                                            if (user.role === 'partner') {
                                                // sessionStorage.setItem('profile', JSON.stringify(profile));
                                                data = profile;
                                            }
                                            if (user.role === 'testator') {
                                                data = { current: {}, permanent: {} };
                                            }
                                        }


                                        console.log('profile', profile);
                                        console.log('profiledata', data);
                                        console.log('profile errors', errors);
                                        // console.log('currentExists', Object.hasOwnProperty.call(data, 'current'))
                                        if (data && !data?.current) {
                                            data.current = {};
                                            const errorExists = errors.some((error) => (
                                                error.schemaPath === "#/properties/current/required" &&
                                                error.params?.missingProperty === "house_no"
                                            ));

                                            // If it doesn't exist, push the new error
                                            if (!errorExists) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/current',
                                                    // message to display
                                                    message: "must have required property 'house_no'",
                                                    schemaPath: "#/properties/current/required",
                                                    keyword: 'required',
                                                    schema: [
                                                        "house_no",
                                                        "house_name",
                                                        "area",
                                                        "zip",
                                                        "city",
                                                        "state",
                                                        "country"
                                                    ],
                                                    params: {
                                                        "missingProperty": "house_no"
                                                    }
                                                });
                                            }


                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'house_name'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "house_name"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'area'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "area"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'zip'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "zip"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'city'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "city"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'state'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "state"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/current',
                                                // message to display
                                                message: "must have required property 'country'",
                                                schemaPath: "#/properties/current/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "country"
                                                }
                                            });
                                        }
                                        if (data && !data?.permanent) {
                                            data.permanent = {};
                                            // if(!data?.permanent){
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'house_no'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "house_no"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'house_name'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "house_name"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'area'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "area"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'zip'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "zip"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'city'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "city"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'state'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "state"
                                                }
                                            });
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/permanent',
                                                // message to display
                                                message: "must have required property 'country'",
                                                schemaPath: "#/properties/permanent/required",
                                                keyword: 'required',
                                                schema: [
                                                    "house_no",
                                                    "house_name",
                                                    "area",
                                                    "zip",
                                                    "city",
                                                    "state",
                                                    "country"
                                                ],
                                                params: {
                                                    "missingProperty": "country"
                                                }
                                            });
                                        }
                                        if (!readonly)
                                            setProfile(data);
                                        setErrors(errors);
                                        if (data?.dob) {
                                            const dob = new Date(data.dob);
                                            const ageLimitDate = new Date();
                                            ageLimitDate.setFullYear(new Date().getFullYear() - 18);
                                            if (dob > ageLimitDate) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/dob',
                                                    // message to display
                                                    message: 'You must be older than 18',
                                                    schemaPath: '',
                                                    keyword: '',
                                                    params: {},
                                                });
                                            }
                                        }

                                        if (data?.same_as_current) {
                                            data.permanent = { ...data.current };
                                            // setErrors(errors.filter(item => item.instancePath !== '/permanent'));
                                            // Check if the new "permanent" is the same as the previous one
                                            if (isEqual(data.current, profile.permanent)) {
                                                console.log('permanent is the same');
                                            } else {
                                                console.log('permanent is different');
                                                setProfile((prof) => ({
                                                    ...prof,
                                                    permanent: { ...data.current }
                                                }));
                                            }

                                        }
                                        if (!readonly) {
                                            console.log('readonly', readonly);
                                            if (data?.same_as_current) {
                                                console.log('erros-filter', errors.filter(item => item.instancePath !== '/permanent'));
                                                setErrors(errors.filter(item => item.instancePath !== '/permanent'));
                                            }
                                            else
                                                setErrors(errors);
                                        }

                                        // Function to map value to label based on options
                                        const customLabelMap = (value, options) => {
                                            // Find the option object with matching constant value
                                            const option = options.find((option) => option.const === value);
                                            // Return the label if found, otherwise return null
                                            return option ? `${option.title}` : null;
                                        };

                                        // Function to map country code to label and update data
                                        const mapCountryToLabel = (data, propertyName, schema) => {
                                            // Use customLabelMap to map country code to label
                                            const label = customLabelMap(data[propertyName].countryCode, schema.properties.countryCode.oneOf);
                                            if (label) {
                                                // Update data with mapped label
                                                data[propertyName].country = label;
                                            }
                                        };

                                        // Check and update current address data
                                        if (data?.current?.countryCode !== undefined) {
                                            // Map country code to label for current address
                                            mapCountryToLabel(data, "current", schemas.schemas.profile.properties.current);
                                        } else {
                                            // Reset current country if countryCode is not defined
                                            data.current.country = undefined;
                                        }

                                        // Check and update permanent address data
                                        if (data?.permanent?.countryCode !== undefined) {
                                            // Map country code to label for permanent address
                                            mapCountryToLabel(data, "permanent", schemas.schemas.profile.properties.permanent);
                                        } else {
                                            // Reset permanent country if countryCode is not defined
                                            data.permanent.country = undefined;
                                        }

                                        // Fetch data from zip code for current address
                                        if (
                                            // Check if zip code, country code, and zip code length meet requirements
                                            data?.current?.zip &&
                                            data?.current?.country &&
                                            data?.current?.zip?.length >= 4 &&
                                            data?.current?.zip?.length <= 11 &&
                                            // Ensure the new zip code is different from the one in the profile
                                            data?.current?.zip !== profile?.current?.zip &&
                                            // Check if city and state are undefined
                                            data?.current?.city === undefined &&
                                            data?.current?.state === undefined
                                        ) {
                                            // Clear previous timeout if it exists
                                            if (timeoutId) {
                                                clearTimeout(timeoutId);
                                            }

                                            // Set a timeout to delay fetching data
                                            timeoutId = setTimeout(() => {
                                                // Fetch data from the provided zip code
                                                fetchDataFromZip(data.current)
                                                    .then(({ city, state, placeNames }) => {
                                                        // Update profile with fetched city and state
                                                        setProfile((prof) => ({
                                                            ...prof,
                                                            current: { ...prof.current, city, state }
                                                        }));
                                                        // Update UI schema with fetched place names
                                                        schemas.schemas.profile.properties.current.properties.area.anyOf[0].enum = [null];
                                                        if (placeNames && placeNames[0] !== null) {
                                                            placeNames.forEach(newValue => {
                                                                schemas.schemas.profile.properties.current.properties.area.anyOf[0].enum.push(newValue);
                                                            });
                                                        }
                                                    })
                                                    .catch(error => {
                                                        // Log any errors that occur during data fetching
                                                        console.error("Error fetching data from zip for current address:", error);
                                                    });

                                            }, 1000); // Delay of 1 seconds (1000 milliseconds)
                                        }
                                        // Fetch data from zip code for permanent address
                                        if (
                                            // Check if zip code, country code, and zip code length meet requirements
                                            data?.permanent?.zip &&
                                            data?.permanent?.countryCode &&
                                            data.permanent.zip.length >= 4 &&
                                            data.permanent.zip.length <= 11 &&
                                            // Ensure the new zip code is different from the one in the profile
                                            data.permanent.zip !== profile.permanent.zip &&
                                            // Check if city and state are undefined
                                            data?.permanent?.city === undefined &&
                                            data?.permanent?.state === undefined
                                        ) {
                                            // If the permanent address is not the same as the current address
                                            if (!data.same_as_current) {
                                                // Clear previous timeout if it exists
                                                if (timeoutId) {
                                                    clearTimeout(timeoutId);
                                                }
                                                // Set a timeout to delay fetching data
                                                timeoutId = setTimeout(() => {
                                                    // Fetch data from the provided zip code for permanent address
                                                    fetchDataFromZip(data.permanent)
                                                        .then(({ city, state, placeNames }) => {
                                                            // Update profile with fetched city and state for permanent address
                                                            setProfile((prof) => ({
                                                                ...prof,
                                                                permanent: { ...prof.permanent, city, state }
                                                            }));
                                                            // Update UI schema with fetched place names for permanent address
                                                            schemas.schemas.profile.properties.permanent.properties.area.anyOf[0].enum = [null];
                                                            if (placeNames && placeNames[0] !== null) {
                                                                placeNames.forEach(newValue => {
                                                                    schemas.schemas.profile.properties.permanent.properties.area.anyOf[0].enum.push(newValue);
                                                                });
                                                            }
                                                        })
                                                        .catch(error => {
                                                            // Log any errors that occur during data fetching for permanent address
                                                            console.error("Error fetching data from zip for permanent address:", error);
                                                        });
                                                }, 1000); // Delay of 1 seconds (1000 milliseconds)
                                            } else {
                                                // If same_as_current is true, update profile without fetching data
                                                console.log('data fetch from zip else');
                                                setProfile((prof) => ({
                                                    ...prof,
                                                    permanent: { ...prof.permanent }
                                                }));
                                            }
                                        }
                                        // if(!errors && data.permanent.zip && !data.permanent.country)
                                    }}
                                />}
                            </Box>
                            {!readonly &&
                                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                                    <Button onClick={() => resetProfile()}>Cancel</Button>
                                    <Button disabled={errors.length > 0} id={"new-generic-button"}
                                        onClick={() => {
                                            saveProfile();
                                        }}
                                        variant="contained">
                                        Save
                                    </Button>
                                </Stack>}
                        </Box>
                        <Box sx={{
                            bgcolor: `secondary.main`,
                            border: "none",
                            boxShadow: (theme) => theme.customShadows.card,
                            // borderTop: "5px solid",
                            // borderTopColor: (theme) => theme.palette.primary.main,
                            borderRadius: (theme) => theme.spacing(1),
                            background: (theme) => `linear-gradient(${theme.palette.secondary.main} 0 calc(100% - 8px), #BDBDBD calc(100% - 8px) 100%), linear-gradient(white, white)`,
                            p: 1,
                            mt: 2,
                            pb: 2
                        }}>
                            <Typography sx={{ mb: 1, p: 1, display: "flex", alignItems: "center", color: "white" }}
                                id="modal-modal-title"
                                variant="h6"
                                component="h2">
                                Upload Existing Signed Will - If you have one (Optional)
                            </Typography>
                            <Box id={"will-upload"} className={"will-upload"} sx={{ m: -1, p: 3, bgcolor: "white" }}>
                                <JsonForms
                                    i18n={{ translate: translator("you") }}
                                    schema={schema}
                                    uischema={{
                                        "type": "HorizontalLayout",
                                        "elements": [
                                            {
                                                "type": "Control",
                                                "scope": "#/properties/signed_at",
                                                "label": "Signing date",
                                                "options": {
                                                    "format": "date",
                                                    "dateFormat": "DD-MM-YYYY"
                                                }
                                            },
                                            // {
                                            //     "type": "Control",
                                            //     "scope": "#/properties/registered_at",
                                            //     "label": "Registration date",
                                            //     "options": {
                                            //         "format": "date",
                                            //         "dateFormat": "DD-MM-YYYY"
                                            //     }
                                            // },
                                            {
                                                "type": "Control",
                                                "scope": "#/properties/registered_with",
                                                "label": "Sub-Registrar’s Office Place",
                                                "options": {
                                                    "format": "textarea"
                                                }
                                            },
                                            {
                                                "type": "Control",
                                                "scope": "#/properties/registered_office_number",
                                                "label": "Sub-Registrar’s Office Number",
                                                "options": {
                                                    "format": "textarea"
                                                },
                                            },
                                            {
                                                "type": "Control",
                                                "scope": "#/properties/serial_number",
                                                "label": "Serial Number",
                                                "options": {
                                                    "format": "textarea"
                                                }
                                            },
                                            {
                                                "type": 'VerticalLayout',
                                                "elements": [
                                                    {
                                                        "type": "Control",
                                                        "scope": "#/properties/file",
                                                        "label": "Select Signed Copy",
                                                        "placement": 'left'     
                                                    },
                                                    {
                                                        "type": "Label",
                                                        "text": "Only .pdf files of maximum size 10MB are allowed",
                                                        "options": {
                                                            "style": {
                                                                "color": "black",
                                                                "fontWeight": "400",
                                                                "fontSize": "12px",
                                                                "textAlign": 'center',
                                                                "padding-top": '8px'
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }}
                                    data={willData}
                                    renderers={[...materialRenderers,  { tester: rankWith(3, uiTypeIs('Label')), renderer: CustomLabelRenderer }, { tester: FileUploadTester, renderer: FileUploadControl },]}
                                    cells={materialCells}
                                    validationMode={willUploadValidationMode}
                                    onChange={({ data, errors }) => {
                                        if (Object.entries(data).length === 0) {
                                            setWillUploadValidationMode('ValidateAndHide');
                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/signed_at',
                                                // message to display
                                                message: 'Signing date is required',
                                                schemaPath: '#/properties/signed_at',
                                                keyword: '',
                                                params: {},
                                            });

                                            errors.push({
                                                // AJV style path to the property in the schema
                                                instancePath: '/file',
                                                // message to display
                                                message: 'Will file is required',
                                                schemaPath: '',
                                                keyword: '',
                                                params: {},
                                            });
                                        }
                                        else {
                                            console.log('upload will onchange executed inside if', data.signed_at);
                                            setWillUploadValidationMode('ValidateAndShow');
                                            const signedAt = data.signed_at ? dayjs(data.signed_at).startOf('day') : undefined;

                                            if (signedAt === undefined) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/signed_at',
                                                    // message to display
                                                    message: 'Signing date is required',
                                                    schemaPath: '',
                                                    keyword: '',
                                                    params: {},
                                                });
                                            }
                                            // console.log('signedAt', signedAt, dayjs().endOf('day'), signedAt.isAfter(dayjs().endOf('day')));
                                            if (signedAt?.isAfter(dayjs().endOf('day'))) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/signed_at',
                                                    // message to display
                                                    message: 'Signing date needs to be prior to current date',
                                                    schemaPath: '',
                                                    keyword: '',
                                                    params: {},
                                                });
                                            }

                                            const minDate = data.signed_at ? dayjs(data.signed_at).startOf('day') : null;
                                            const maxDate = dayjs().startOf('day');
                                            const registeredAt = data.registered_at ? dayjs(data.registered_at).startOf('day') : null;
                                            const errorExists = errors.some((error) => (
                                                error.schemaPath === "#/properties/registered_at/format"
                                            ));
                                            console.log('uploaderrors', errors);
                                            if (!errorExists && registeredAt && (minDate === null || minDate > registeredAt)) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/registered_at',
                                                    // message to display
                                                    message: 'Registration date should be same or later than the signing date',
                                                    schemaPath: '',
                                                    keyword: '',
                                                    params: {},
                                                });
                                            }

                                            if (registeredAt && minDate !== null && registeredAt > maxDate) {
                                                errors.push({
                                                    // AJV style path to the property in the schema
                                                    instancePath: '/registered_at',
                                                    // message to display
                                                    message: 'Registration date needs to be prior to current date',
                                                    schemaPath: '',
                                                    keyword: '',
                                                    params: {},
                                                });
                                           }

                                            // _schema.properties.registered_at.formarriatMinimum = willData.signed_at;
                                            // // _schema.properties.signed_at.formatMaximum = format(new Date(), 'yyyy-MM-dd');
                                            // _schema.properties.registered_at.formatMaximum = format(new Date(), 'yyyy-MM-dd');

                                            setWillData(data);
                                        }

                                        setWillErrors(errors);
                                    }}
                                />
                            </Box>
                            <Stack sx={{ bgcolor: "#BDBDBD", m: -1, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                                <Button onClick={() => {
                                    setWillData({});
                                }}>Reset</Button>
                                <Button disabled={willErrors.length > 0} id={"new-generic-button"} onClick={() => {
                                    uploadWill();
                                }}
                                    variant="contained">
                                    Upload
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <AppOrderTimeline
                            title="Will timeline"
                            list={versions.map((version, index) => {
                                let status = "In Progress";
                                if (!!activeReview) status = `Review ${sentenceCase(activeReview.status ?? "")}`;
                                if (!!version.generatedOn) status = "Generated";
                                return {
                                    id: version.id,
                                    version: version.version,
                                    isGenerated: version.generatedOn ? Boolean(true) : Boolean(false),
                                    link: version.link,
                                    uploaded: version.uploaded,
                                    downloadFile: version.downloadFile,
                                    title: version.generatedOn ? `Will ${version.uploaded ? "uploaded" : "generated"}, Version: ${versions.length - index}` : `Active Will, Version: ${versions.length - index}, ${status}`,
                                    type: `order${version.generatedOn ? "2" : "3"}`,
                                    time: version.signed_at,
                                    registeredWith: version.registered_with,
                                    serialNumber: version.serial_number,
                                    registeredOfficeNumber:version.registered_office_number

                                };

                            })}
                        />

                    </Grid>
                </Grid>
                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => {
                        setReadonly(false);
                        setOpen(null);
                    }}>
                        <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>
                </Popover>
                <CenterModal
                    open={openAcknowledge}
                    // onClose={() => {
                    //     setOpenAcknowledge(false);
                    // }}
                    fullwidth
                    title={`Welcome to GetWillDone ${user?.role === "partner" ? 'Partner' : ''} – Securely Preserve Your Legacy`}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                        <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                Thank you for choosing {' '}
                                <strong>GetWillDone</strong>
                                <span> – Your One-Stop Secure Platform for WILL and Inheritance.</span>
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                We appreciate your trust in GetWillDone as you embark on the journey to create your WILL. <strong>GetWillDone</strong> is a secure and user-friendly tool designed to preserve your legacy.
                            </Typography>
                            {user.role === 'partner' && (
                                <>
                                    <Typography variant="body1" style={{ margin: '10px 0' }}>
                                        <Typography display="inline" color="primary.main"><strong>Demo:</strong></Typography> We have created a Coupon that allows you to Demo our product. Please use <Typography display="inline" color="primary.main"><strong>{demoCouponCode}</strong></Typography> at the payment check out section on GetWillDone platform on <a color="inherit" href="https://wills.getwilldone.com" rel="noopener noreferrer" target="_blank"><span style={{ color: '#2c32e5' }}>
                                            <u>GetWillDone</u>
                                        </span>
                                        </a>{' '}. The coupon will allow you to access the platform in a Demo mode.
                                    </Typography>
                                    <Typography variant="body1" style={{ margin: '10px 0' }}>
                                        <Typography display="inline" color="primary.main"><strong>Next Steps after Demo:</strong></Typography> Please hit the Enrol Partner button that is reflected on the platform once you are satisfied with the demo. GWD will approve this request of Onboarding you within 7 working days after you have enrolled.
                                    </Typography>
                                </>
                            )}

                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <span>
                                    Here's why <strong>GetWillDone</strong> is your best choice:
                                </span>
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <Typography display="inline" color="primary.main"><strong>Data Privacy:</strong></Typography> We understand the sensitivity of this matter, and we treat it with the utmost care. Our platform employs industry-standard security measures, including encryption and robust data security protocols (SSL certified using SHA 256 encryption) to keep your information safe from unauthorized access, loss, or misuse. Your data is protected with high-level encryption and data security protocols.
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <Typography display="inline" color="primary.main"><strong>The Document:</strong></Typography> Find comfort in knowing that <strong>GetWillDone</strong>'s WILL format is prepared and vetted by one of the most trusted legal firms in the industry.
                            </Typography>
                            {/* Add the rest of your content using Typography and Link components */}
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <Typography display="inline" color="primary.main"><strong>Create Your WILL:</strong></Typography> You can start crafting your WILL by logging in at <a color="inherit" href="https://wills.getwilldone.com" rel="noopener noreferrer" target="_blank">
                                    <u>
                                        GetWillDone
                                    </u>
                                </a>{' '}using your registered email ID or using your Google / Linkedin credentials.
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <Typography display="inline" color="primary.main"><strong>Take Your Time:</strong></Typography> Whether you prefer to create your WILL in one go or over multiple sessions, your subscription allows for both. The Subscription is valid for 1 year from the date of payment.
                            </Typography>
                            {user.role === 'testator' && (
                                <Typography variant="body1" style={{ margin: '10px 0' }}>
                                    <Typography display="inline" color="primary.main"><strong>Inheritance Services:</strong></Typography> In addition to helping you create your WILL, we also offer a full suite of inheritance and estate planning services offline through our legal partner, LegaLogic. Please reach us at
                                    {' '}<a color="inherit" href="mailto:info@getwilldone.com" rel="noopener noreferrer" target="_blank">
                                        <span style={{ color: '#2c32e5' }}>
                                            <u>
                                                info@getwilldone.com
                                            </u>
                                        </span>
                                    </a>{' '}
                                    or SMS/WhatsApp at +91-9156888419 to explore these services.
                                </Typography>
                            )}
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <Typography display="inline" color="primary.main"><strong>FAQs and Infographics:</strong></Typography> Our platform provides helpful infographics to guide you along the way, and you can find additional FAQs on our website for further assistance.
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                <span>
                                    In the meantime, if you have any questions, please don't hesitate to reach out to us at <a target="_blank" rel="noopener noreferrer" color="inherit" href="mailto:info@getwilldone.com">
                                        <span style={{ color: '#2c32e5' }}>
                                            <u>
                                                info@getwilldone.com
                                            </u>
                                        </span>
                                    </a>{' '} or SMS/WhatsApp at +91-9156888419.
                                </span>
                            </Typography>
                            {/* Add the rest of your content using Typography and Link components */}
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                Thank you for choosing GetWillDone and now Get WILL Done!
                            </Typography>
                            <Typography variant="body1" style={{ margin: '10px 0' }}>
                                Warm regards,<br />
                                <strong>The GetWillDone Team</strong><br />
                                <a color="inherit" href="https://wills.getwilldone.com" rel="noopener noreferrer" target="_blank">
                                    <u>
                                        www.GetWillDone.com
                                    </u>
                                </a>
                            </Typography>
                            {/* <FormGroup>
                                <FormControlLabel checked={checkedAgreeOne} name='checkedAgreeOne' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your user data will be permanently deleted, including login credentials and any other personal information associated with your account." />
                                <br />
                                <FormControlLabel checked={checkedAgreeTwo} name='checkedAgreeTwo' onChange={handleAgreeCheck} required control={<Checkbox />} label="All your subscriptions will be terminated. If you wish to use our service again in the future, you will need to sign up and register as a new user." />
                                <br />
                                <FormControlLabel checked={checkedAgreeThree} name='checkedAgreeThree' onChange={handleAgreeCheck} required control={<Checkbox />} label="If you have created any wills using our service, they will be deleted from our system as part of the account deletion process. However, please be aware that any wills you have already made using our service remain legally valid in accordance with applicable laws. To ensure you have a copy for your records, we recommend saving a digital and printed copy if you have not already done so." />
                            </FormGroup> */}
                        </Stack>
                    </Box>
                    <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"}>
                        <Button style={{ marginLeft: '8px', marginTop: "8px" }} onClick={() => {
                            console.log('acknowledge');
                            acknowledge();
                        }} variant="contained">
                            Acknowledge
                        </Button>
                    </Stack>
                </CenterModal>
            </Container>
        </>
    );
}

const CustomLabelRenderer = ({ uischema }) => {
    const { text, options } = uischema || {};
    const { style } = options || {};
    return (
        <div style={style}>
            {text}
        </div>
    );
};
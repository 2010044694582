// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      hover: {
        backgroundColor: 'transparent'
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.primary,
          padding: theme.spacing(2),
          fontWeight: 400,
          fontSize: '14px',
          backgroundColor: `rgba(237, 108, 2, 0.04)`
        },
        body: {
          color: theme.palette.text.primary,
          borderBottom: '1px solid #d3d3d3',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: 1,
          padding: theme.spacing(2)
        }
      },
    },
  };
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const TotalNumberOfPartnersCard = () => {
  const [partnersData, setPartnersData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalNumberOfPartners = async () => {
      try {
        const response = await axios.get('/admin/total-partners');
        const { totalPartners } = response.data;
        setPartnersData({ totalPartners });
      } catch (error) {
        console.error('Error fetching number of partners data:', error);
      }
    };

    fetchTotalNumberOfPartners();
  }, []);

  const handleClick = () => {
    navigate('/admin/partner-details-page', { state: { fromAdminDashboard: true } });
  };

  return (
    <DashboardCard
      title="Total Number of Advisors/Partners"
      subtitle={(partnersData.totalPartners ?? '--') || 0}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default TotalNumberOfPartnersCard;

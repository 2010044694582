import { withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, TextField } from "@mui/material";
import { and, isEnumControl, or, rankWith, scopeEndsWith } from "@jsonforms/core";
import { useEffect, useRef, useState } from "react";
import { values } from "lodash";

const AutocompleteControl = ({ data, handleChange, path, id, schema, label, enabled, errors, required, ...props }) => {

    const [val, setVal] = useState(data);
    const { options = {} } = props.uischema;
    const { freeSolo } = options;
    useEffect(() => {
        if (data === undefined) setVal(undefined);
    }, [data]);

    useEffect(() => {
        handleChange(path, val);
    }, [val]);

    return <Autocomplete
        id={id}
        freeSolo={freeSolo === false ? Boolean(false) : Boolean(true)}
        value={val ?? null}
        options={schema.dummyEnum ?? schema.enum ?? []}
        onInputChange={(e, value) => {
            if (schema.dummyEnum && schema.dummyEnum.includes(value)) setVal(value);
            else if (schema.enum && schema.enum.includes(value)) setVal(value);
            else setVal(undefined);
        }}
        renderInput={(params) => <TextField {...params} required={required} helperText={errors} error={errors} variant={'standard'} label={label} />}
        disabled={!enabled}
    />;
};

export const OneOfControl = withJsonFormsControlProps(AutocompleteControl);

// eslint-disable-next-line no-prototype-builtins
export const OneOfTester = rankWith(3, or(scopeEndsWith('bank_name'), scopeEndsWith('organization_name'), scopeEndsWith('nominee'), scopeEndsWith('joint_owner_name'), scopeEndsWith('joint_holder_name'), scopeEndsWith('type_of_pet')))
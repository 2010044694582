// @mui
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Card, Typography, useTheme } from "@mui/material";
// utils
import ReactApexChart from "react-apexcharts";
// components
import { useMemo } from "react";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

AppDonutSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object
};

export default function AppDonutSummary({ title, data, color = "primary", sx, emptyComponent, ...other }) {
  const theme = useTheme();
  const options = {
    labels: data?.labels ?? [],
    colors: [...theme.palette.graphs, ...Object.values(theme.palette.grey)],
    stroke: {
      width: 0
    },
    chart: {
      type: "pie"
    },
    legend: {
      show: false,
      position: "bottom"
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: 20,
        fontWeight: 600
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        customScale: 0.75,
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: false,
              fontWeight: "bold",
              fontSize: "2rem"
            },
            total: {
              show: false,
              fontWeight: "bold",
              fontSize: "2rem"
            }
          }
        }
      }
    }
    // responsive: [{
    //   breakpoint: 480,
    //   options: {
    //     chart: {
    //       width: 100
    //     },
    //     legend: {
    //       position: 'bottom'
    //     }
    //   }
    // }]
  };

  const totals = useMemo(() => {
    return {
      assigned: data?.series?.[1],
      unassigned: data?.series?.[0],
      total: (data?.series?.[0] ?? 0) + (data?.series?.[1] ?? 0)
    };
  }, [data?.series]);

  return (
    <Card
      sx={{
        height: "100%",
        py: 1,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color][900],
        bgcolor: (theme) => theme.palette[color][500],
        ...sx
      }}
      {...other}
    >
      <ReactApexChart height={data?.series?.length > 0 ? 300 : 150} options={options} series={data?.series ?? []} type="donut" />
      {data?.series?.length > 0 ? <Typography sx={{
        pr: 3,
        pb: 3,
        position: "absolute",
        bottom: 0,
        right: 0,
        lineHeight: 0.5,
        fontWeight: 500,
        "& small": {
          fontSize: "0.4em",
          fontWeight: 400,
          whiteSpace: "pre",
          color: (theme) => theme.palette.text.disabled
        }
      }} variant="h1" fontWeight="normal" textAlign="right">{totals.total}<small><br />Assets</small></Typography> : emptyComponent}
    </Card>
  );
}

import { formLabelClasses } from "@mui/material";
import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiListItem: {
      styleOverrides: {
        root: {
          p: 0
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        color: "secondary",
        variant: "outlined"
      }
    },
    MuiInputBase: {
      defaultProps: {
        color: "secondary",
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled }
          }
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      defaultProps: {
        color: "secondary",
        variant: "outlined"
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          "&:hover": {
            backgroundColor: alpha(theme.palette.grey[500], 0.16)
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: alpha(theme.palette.grey[500], 0.32)
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          [`&.${formLabelClasses.focused}`]: {
            color: theme.palette.secondary.main
          }
        }
      }
    }
  };
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../admin-dashboard/common/DashboardCard';

const PartnersConversionRatioCard = () => {
  const [conversionRatioData, setConversionRatioData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConversionRatio = async () => {
      try {
        const response = await axios.get('/partners/dashboard/signupVsPaidRatio');
        const { signupVsPaidRatio } = response.data;
        setConversionRatioData({ signupVsPaidRatio });
      } catch (error) {
        console.error('Error fetching conversion ratio data:', error);
      }
    };

    fetchConversionRatio();
  }, []);

  const handleClick = () => {
    navigate('/dashboard/conversion-ratio-page');
  };

  return (
    <DashboardCard
      title="Sign-Up v/s Paid Ratio"
      subtitle={conversionRatioData.signupVsPaidRatio || '-/-'}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default PartnersConversionRatioCard;

import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo } from "react";
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import axios from "axios";
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, ListItemText } from "@mui/material";
// mock
import Swal from "sweetalert2";
import { Logout, Money, Subscriptions } from "@mui/icons-material";
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { UserContext } from "../DashboardLayout";
import { StyledNavItem, StyledNavItemIcon } from "../../../components/nav-section/styles";

// ----------------------------------------------------------------------

export const NAV_WIDTH = 250;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'md');

    // useEffect(() => {
    //   if (openNav) {
    //     onCloseNav();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pathname]);

    const { user, fetchUser } = useContext(UserContext);
    const subscribe = () => {
        axios.post('/testator/subscribe').then(res => toastr.success('Product purchase successful')).catch(e => toastr.error('Unable to purchase product')).finally(() => {
            navigate('/dashboard/app', { replace: true });
            fetchUser();
        });
    }

    const subscribed = useMemo(() => {
        // let consumed = user?.Subscriptions ?.length === 0;
        let consumed = true;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(sub => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1) {
                    const today = new Date();
                    const subDay = new Date(userSub.createdAt);
                    const subDays = (today - subDay) / 86400 / 1000;
                    const allowed = sub.days;
                    if (subDays > allowed)
                        consumed = true;
                    else
                        consumed = false;

                }
            }
        });
        return !consumed;
    }, [user]);

    const navigate = useNavigate();

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, position: 'absolute' }}>
                <Logo minimized={!openNav} />
            </Box>

            {/* <Box sx={{ mb: 5, mx: 2.5 }}> */}
            {/*   <Link underline="none"> */}
            {/*     <StyledAccount> */}
            {/*       <Avatar src={account.photoURL} alt="photoURL" /> */}

            {/*       <Box sx={{ ml: 2 }}> */}
            {/*         <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
            {/*           {user?.profile?.name ?? user?.name ?? 'Username'} */}
            {/*         </Typography> */}

            {/*         <Typography variant="body2" sx={{ color: 'text.secondary' }}> */}
            {/*           {account.role} */}
            {/*         </Typography> */}
            {/*       </Box> */}
            {/*     </StyledAccount> */}
            {/*   </Link> */}
            {/* </Box> */}

            <NavSection data={navConfig} disabled={['partner', 'testator'].includes(user.role) ? !user.profile_saved : false} isGrayedOut={user.role === 'partner' ? !subscribed : Boolean(false)} />
            {/* {user?.role === 'testator' &&
        <StyledNavItem
          component={RouterLink}
          to={`/dashboard/subscriptions?ref=${encodeURI(pathname)}`}
          sx={{
            flexWrap: "wrap",
            height: "fit-content",
            fontSize: 11,
            mb: 2,
            "& .MuiListItemText-root": {
              textAlign: "center",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize: 16
            },
            "&.active": {
              color: "text.primary",
              borderLeft: '8px solid',
              ml: -1,
              borderColor: 'primary.main',
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold"
            }
          }}
        >
          <StyledNavItemIcon><Money sx={{ml: 1.5, color: 'primary.main'}} /></StyledNavItemIcon>

          <ListItemText disableTypography primary={'Services'} />

        </StyledNavItem>} */}
            <Box sx={{ flexGrow: 1 }} />
            {/* <Button sx={{
        flexWrap: "wrap",
        height: "fit-content",
        fontSize: 11,
        "& .MuiListItemText-root": {
          textAlign: "center",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden"
        },
        "&.active": {
          color: "text.primary",
          borderLeft: '8px solid',
          borderColor: 'primary.main',
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold"
        }
      }} startIcon={<Logout sx={{ml: 1.5}} />} onClick={() => {
        axios.post('/auth/logout').then(() => toastr.success('Logged out!')).finally(() => navigate('/login', {replace: true}))
      }}>
        Logout
      </Button> */}

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            className={openNav ? 'open' : 'minimized'}
            sx={{
                "&.open .MuiListItemText-root": {
                    textAlign: 'left',
                    fontSize: 'large'
                }
                // flexShrink: { lg: 1 },
                // width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: openNav ? NAV_WIDTH : (48 + 24),
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

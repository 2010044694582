import { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardCard from './common/DashboardCard';
import { fCurrencyRupee } from '../../utils/formatNumber';

const TotalRevenueCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});

  useEffect(() => {
    const fetchTotalRevenueData = async () => {
      try {
        const response = await axios.get('/admin/total-revenue');
        const { totalRevenue } = response.data;
        setRepeatRateData({ totalRevenue });
      } catch (error) {
        console.error('Error fetching total revenue data:', error);
      }
    };

    fetchTotalRevenueData();
  }, []);

  const scrollToTable = () => {
    const element = document.getElementById('service-wise-revenue-container');
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const scrollPosition = elementPosition + window.scrollY - window.innerHeight * 0.1;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <DashboardCard
      title="Total Revenue Generated"
      subtitle={fCurrencyRupee(repeatRateData?.totalRevenue || 0)}
      handleClick={scrollToTable}
      isClickable
    />
  );
};

export default TotalRevenueCard;

import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { CheckCircle, Close, ErrorRounded, Info, InfoOutlined, InfoRounded, Warning } from "@mui/icons-material";
import toastr from "toastr";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Modal,
    FormControl,
    InputLabel,
    Select,
    ButtonGroup,
    Grid,
    ListItem,
    ListItemText,
    List,
    ListItemSecondaryAction,
    TextField,
    Tooltip,
    Stepper,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Step,
    StepLabel,
    Link
} from "@mui/material";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import Swal from "sweetalert2";
import dataChecklistPDF from '../assets/data-checklist.pdf';
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { UserContext } from "../layouts/dashboard/DashboardLayout";
import CenterModal from "../components/CenterModal";
import { LoadingContext } from "../App";
import Logo from "../components/logo";
// mock

// ----------------------------------------------------------------------


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const schema = {
    "properties": {
        "executor_copy": {
            "type": "boolean"
        }, "additional_copy": {
            "type": "string"
        }
    }
};

const uiSchema = {
    "type": "VerticalLayout", "elements": [{
        "type": "Control", "scope": "#/properties/executor_copy", "label": "Copy of Will has been kept with Executors"
    }, {
        "type": "Control", "scope": "#/properties/additional_copy", "label": "Additional Copy kept with / at: "
    }]
};

export default function DataChecklistPage() {

    const [page, setPage] = useState("");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [resp, setResp] = useState({});
    const [step, setStep] = useState(0);
    const [generated, setGenerated] = useState(false);
    const [willParams, setWillParams] = useState({
        sellImmovable: false,
        executorCopy: true,
        additionalCopy: false,
        additionalCopyPerson: "",
        additionalCopyPlace: ""
    });

    const { review } = useParams();
    const { user, fetchUser } = useContext(UserContext);
    const iframe = document.querySelector('iframe');

    const handleIframeLoad = () => {
        if (iframe) {
            iframe.contentWindow.document.title = "Data Checklist.pdf";
        }
    };

    
    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);
    
    const usage = useMemo(() => {
        const used = user?.Reviews?.filter(rev => rev.type === "REVIEW")?.length ?? 0;
        const sub = user?.Subscriptions;
        const allowed = sub?.reduce((p, c) => +p + +c.reviews, 0) ?? 0;
        return { used, allowed };
    }, [user]);
    const navigate = useNavigate();
    return (<>
        <Helmet>
            <title> Will Data Checklist | Get Will Done </title>
        </Helmet>

        <Container fullWidth style={{ maxWidth: "unset" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Will Data Checklist
                </Typography>
                
            </Stack>

            <Card style={{ padding: "1cm" }}>
                <iframe title="Will Data Checklist.pdf" src={dataChecklistPDF} width="100%" height="700px" onLoad={handleIframeLoad} />
            </Card>
            
        </Container>

    </>
    );
}

import { useContext, useState } from "react";
// @mui
import PropTypes from "prop-types";
import { Box, Stack, Button, Card, Typography, CardHeader, CardContent, Chip, IconButton, Tooltip } from "@mui/material";
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import AWS from 'aws-sdk';
import axios from "axios";
import toastr from "toastr";
import Swal from "sweetalert2";
import CenterModal from "../../../components/CenterModal";
import Iconify from "../../../components/iconify";
import { LoadingContext } from "../../../App";
// utils
import { fDateTime, fDate } from "../../../utils/formatTime";
import { UserContext } from "../../../layouts/dashboard/DashboardLayout";
// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    list: PropTypes.array.isRequired
};

// const useStyles = makeStyles({
//   timeline: {
//     transform: "rotate(90deg)"
//   },
//   timelineContentContainer: {
//     textAlign: "left"
//   },
//   timelineContent: {
//     display: "inline-block",
//     transform: "rotate(-90deg)",
//     textAlign: "center",
//     minWidth: 50
//   },
//   timelineIcon: {
//     transform: "rotate(-90deg)"
//   }
// });

export default function AppOrderTimeline({ title, subheader, list, ...other }) {
    const [open, setOpen] = useState(false);
    const [willId, setWillId] = useState(null);
    const { user, fetchUser } = useContext(UserContext);
    const { isLoading } = useContext(LoadingContext);

    const deleteWill = () => {
        setOpen(false);

        axios.delete(`/testator/will/${willId}`).then(response => {
            // toastr.success("Will deleted successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Will deleted successfully`,
                showConfirmButton: false,
                timer: 3000
            });
            fetchUser();

        }).catch(err => {
            // toastr.error("Failed to delete will")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Failed to delete will`,
                showConfirmButton: false,
                timer: 3000
            });
        });
    }
    return (
        <Card {...other} sx={{ height: "auto" }}>
            <CardHeader title={title} subheader={subheader} />

            <CardContent
                sx={{
                    overflow: "auto",
                    "& .MuiTimelineItem-missingOppositeContent:before": {
                        display: "none"
                    }
                }}
            >
                <Timeline>
                    {list.map((item, index) => (
                        <OrderItem key={item.id} item={item} isLast={index === list.length - 1} setWillId={(id) => { setWillId(id); setOpen(true) }} />
                    ))}
                </Timeline>
                <CenterModal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    centered
                    title={`Confirm Delete Will`}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                        <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                            <Typography component={"h2"} fontWeight={"bold"} fontSize={20} align={"center"}
                                sx={{ color: "text.secondary" }}>
                                Are you sure you want to delete Will?
                            </Typography>

                        </Stack>
                    </Box>
                    <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>

                        <Button onClick={() => {
                            setOpen(false);
                        }}>Cancel</Button>
                        <Button style={{ marginTop: "8px" }} onClick={() => {
                            deleteWill();
                        }} variant="contained">
                            Delete
                        </Button>
                    </Stack>
                </CenterModal>
            </CardContent>
        </Card>
    );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
    isLast: PropTypes.bool,
    item: PropTypes.shape({
        time: PropTypes.instanceOf(Date),
        isGenerated: PropTypes.bool,
        downloadFile: PropTypes.string,
        uploaded: PropTypes.bool,
        title: PropTypes.string,
        type: PropTypes.string,
        registeredWith: PropTypes.string, 
        serialNumber: PropTypes.string,
        registeredOfficeNumber:PropTypes.string

    }),

    setWillId: () => {

    }
};

function OrderItem({ item, isLast, setWillId }) {
    const { id, type, title, time, downloadFile, uploaded, isGenerated, registeredWith, registeredOfficeNumber, serialNumber } = item;
    const { user } = useContext(UserContext);
    const deleteWill = (id) => {
        setWillId(id);
    }
    return (
        <TimelineItem>
            <TimelineContent>
                <Tooltip title="Download Will">
                    <Typography sx={{ cursor: type !== "order2" ? "normal" : "pointer" }} color={isGenerated && (uploaded === Boolean(true) ? "primary.dark" : "primary")} variant="subtitle2" onClick={() => {
                        console.log('link', item);
                        if (type !== "order2") return;

                        // Configure AWS SDK with your S3 credentials
                        // AWS.config.update({
                        //     region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                        //     accessKeyId: process.env.REACT_APP_AWS_KEY,
                        //     secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                        // });
                        // Initialize the S3 instance
                        const s3 = new AWS.S3({
                            region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                            accessKeyId: process.env.REACT_APP_AWS_KEY,
                            secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                        });
                        // Set the parameters for the getObject request
                        const params = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME ?? "gwd-testing/gwd-wills-upload",   // Replace with your bucket name
                            Key: `${downloadFile}.pdf`, // Replace with the path to your file
                        };

                        // Make the getObject request
                        s3.getObject(params, (err, data) => {
                            if (err) {
                                console.error('Error downloading file:', err);
                            } else {
                                // Create a Blob from the data and generate a download link
                                const blob = new Blob([data.Body], { type: 'application/octet-stream' });
                                const url = URL.createObjectURL(blob);

                                // Create a download link and trigger click to start download
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = `will-${uploaded ? 'uploaded' : 'generated'}-${user.full_name ? user.full_name : user.email}.pdf`;
                                link.click();
                                link.remove();

                                // Clean up the object URL
                                URL.revokeObjectURL(url);
                            }
                        });

                        //   gwd - wills - upload
                        //   const anchor = document.createElement("a");
                        //   anchor.target = "_blank";
                        //   anchor.href = link;
                        //   anchor.download = `will-${user?.full_name}.pdf`;
                        //   anchor.click();
                        //   anchor.remove();

                    }}>{title}</Typography></Tooltip>

                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                    {fDate(time)}
                </Typography>
                <Box>
                    {
                        registeredWith && (
                            <Typography variant="body2">
                                Registered Office Place : {registeredWith}
                            </Typography>)
                    }
                     {
                        registeredOfficeNumber && (
                            <Typography variant="body2">
                                Registered Office Number: {registeredOfficeNumber}
                            </Typography>)
                    }
                    {
                        serialNumber && (
                            <Typography variant="body2">
                                Serial Number: {serialNumber}
                            </Typography>)
                    }
                     
                    
                </Box>

                {uploaded && (
                    <Tooltip title="Delete Will">
                        <IconButton
                            aria-label="delete"
                            onClick={() => { console.log('click delete will', id); deleteWill(id) }}
                            color="primary" // Choose your desired color
                        >
                            <Iconify icon={"fluent:delete-32-regular"} /> {/* Replace with your desired icon */}
                        </IconButton>
                    </Tooltip>
                )}
            </TimelineContent>
            <TimelineSeparator>
                <TimelineDot
                    color={
                        (type === "order1" && "primary") ||
                        (type === "order2" && "success") ||
                        (type === "order3" && "info") ||
                        (type === "order4" && "warning") ||
                        "error"
                    }
                />
                {isLast ? null : <TimelineConnector />}
            </TimelineSeparator>
        </TimelineItem>
    );
}

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Card,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { filter } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'service') {
    // Sort by the length of the service array
    const aLength = a[orderBy] ? a[orderBy].length : 0;
    const bLength = b[orderBy] ? b[orderBy].length : 0;
    return bLength - aLength;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'pan',
    label: 'PAN',
    alignRight: false,
  },
  {
    id: 'status',
    label: 'Status',
    alignRight: false,
  },
  {
    id: 'service',
    label: 'Service',
    alignCenter: true,
  },
];

const PartnerConversionRatioPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [conversionPageData, setConversionRatioData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterValue, setFilterValue] = useState('all'); // Added filter value state
  const navigate = useNavigate();

  const fetchClientsData = async () => {
    try {
      const response = await axios.get(`/partners/dashboard/signupVsPaidRatio?list=${true}`);
      const { conversionRatioData } = response.data;
      const updatedData =
        conversionRatioData &&
        conversionRatioData?.map((client) => {
          if (client?.subscriptions?.length > 0) {
            return {
              ...client,
              status: 'Service purchased',
              service: client?.subscriptions?.map((service) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0' }}>
                  <Chip size="small" key={service} label={service} variant="outlined" />
                </Box>
              )),
            };
          }
          return {
            ...client,
            status: 'Service not purchased',
            service: 'N/A',
          };
        });
      setConversionRatioData(updatedData || []);
    } catch (error) {
      console.error('Error fetching conversion ratio data:', error);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const filteredData = useMemo(() => {
    if (filterValue === 'paid') {
      return conversionPageData.filter((client) => client.subscriptions.length > 0);
    }
    if (filterValue === 'unpaid') {
      return conversionPageData.filter((client) => client.subscriptions.length === 0);
    }
    return conversionPageData;
  }, [filterValue, conversionPageData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - conversionPageData.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, conversionPageData, filterName, filterValue]
  );

  const handleBackClick = () => {
    navigate('/dashboard/partnerdashboard');
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: '24px' }}>
      <Helmet>
        <title> Sign-up Users VS Paid Users | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: '24px' }}>
        <IconButton color="primary" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Sign-up Users VS Paid Users</Typography>
        <div style={{ flexGrow: 5 }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </div>
        <FormControl style={{ flexGrow: 1 }}>
          <InputLabel>Role</InputLabel>
          <Select value={filterValue} onChange={handleFilterChange} label="Role">
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="unpaid">Unpaid</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Card>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row" padding="none">
                      {row.email}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.pan}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell align="center">{row.service}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {conversionPageData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

export default PartnerConversionRatioPage;
import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  disableSort: PropTypes.bool
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  secondRow,
  disableSort
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id && !disableSort ? order : false}
            rowSpan={headCell.rowSpan}
            colSpan={headCell.colSpan}
          >
            <TableSortLabel
              hideSortIcon
              active={!disableSort ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.id === "actions" ? "Actions" : headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>{order === "desc" ? "sorted descending" : "sorted ascending"}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      {secondRow && <TableRow>
        {secondRow.map((headCell) => (
          <TableCell
            style={{fontSize: '18px'}}
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            rowSpan={headCell.rowSpan}
            colSpan={headCell.colSpan}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>}
    </TableHead>
  );
}

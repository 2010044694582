import { withJsonFormsControlProps, useJsonForms } from "@jsonforms/react";
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const CustomAutocomplete = ({ data, handleChange, path, id, schema, label, enabled, disabled, errors, required, ...props }) => {
    const { readonly } = useJsonForms();
    console.log('CustomAuto', readonly)
    // console.log('schema', schema.options, schema.options.find(option => option.value === data));
    const [options, setOptions] = useState(schema.options || []);
    const [val, setVal] = useState(data ? options.find(option => option.value === data) : null);
    useEffect(() => {
        if (data === null) setVal(null);
    }, [data]);
    useEffect(() => {
        // console.log('value useffect', val);
        if(val)
        handleChange(path, val?.value ?? null);
        else
        handleChange(path, null);

    }, [val]);

    return <Autocomplete {...props}
        options={options}
        disabled={disabled || readonly}
        value={val ?? null}
        getOptionLabel={(option) => option.label || ''}
        disableClearable={false}
        clearOnBlur
        clearOnEscape
        onChange={
            (e, value) => {
                // console.log('valuee', value);
                if (value) {
                    // console.log('Selected option label:', value.label);
                    // console.log('Selected option value:', value.value);
                    // setVal(value.value);
                    setVal(value);
                }
                else{
                    setVal(null);
                }
            }
        }
        noOptionsText="No beneficiaries added"
        renderInput={(params) => (
            <TextField {...params} label={label || ''} required={required} helperText={errors} error={errors}  variant="standard" />
        )} />;
};

export default CustomAutocomplete;
export const OneOfCustomAutocompleteControl = withJsonFormsControlProps(CustomAutocomplete);

// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, useTheme } from "@mui/material";
// utils
import ReactApexChart from "react-apexcharts";
import { useMemo } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

AppRadialSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object
};

export default function AppRadialSummary({ title, data, color = "primary", sx, emptyComponent, ...other }) {
  const theme = useTheme();
  const options = {
    colors: [...theme.palette.graphs, ...Object.values(theme.palette.grey)],
    chart: {
      height: 300,
      type: "radialBar"
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: 20,
        fontWeight: 600
      }
    },
    plotOptions: {
      radialBar: {
        customScale: 0.75,
        offsetY: 30,
        startAngle: 0,
        endAngle: 240,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },
    labels: data?.labels ?? [],
    legend: {
      show: true,
      floating: true,
      fontSize: "12px",
      position: "left",
      offsetX: 0,
      offsetY: 30,
      labels: {
        useSeriesColors: true
      },
      markers: {
        size: 0
      },
      formatter(seriesName, opts) {
        return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]?.toFixed()} %`;
      },
      itemMargin: {
        vertical: 3
      }
    }
  };

  const totals = useMemo(() => ({
      total: (data?.series?.reduce((p, c) => +p + +c, 0) ?? 0) / (data?.series?.length ?? 1)
    }), [data?.series]);

  return (
    <Card
      sx={{
        py: 1,
        height: '100%',
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color][900],
        bgcolor: (theme) => theme.palette[color][500],
        ...sx
      }}
      {...other}
    >
      <ReactApexChart height={data?.series?.length > 0 ? 250 : 150} options={options} series={data?.series ?? []} type="radialBar" />
      {data?.series?.length > 0 ? <Typography sx={{
        pr: 3,
        pb: 3,
        position: 'absolute',
        bottom: 0,
        right: 0,
        lineHeight: 0.5,
        fontWeight: 500,
        "& small": {
          fontSize: '0.4em',
          fontWeight: 400,
          whiteSpace: 'pre',
          color: (theme) => theme.palette.text.disabled
        }
      }} variant="h1" fontWeight='normal' textAlign="right">{totals.total.toFixed()}%<small><br />Assets Distribution</small></Typography> : emptyComponent}
    </Card>
  );
}

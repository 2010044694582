import { withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, Box, Button, FormControl, Popper, TextField } from "@mui/material";
import { and, isEnumControl, or, rankWith, scopeEndsWith } from "@jsonforms/core";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import "react-phone-input-material-ui/lib/high-res.css";
import { useDebouncedChange } from "@jsonforms/material-renderers";
import rawCountries from "../../utils/rawCountries";

const countries = rawCountries.map(c => ({ country: c[0], code: c[2], dial: c[3] })).filter(c => !!c.dial);


const eventToValue = (ev) => ev.target.value === "" ? undefined : ev.target.value;

export const PhoneControl = ({ data, handleChange, path, id, schema, label, enabled, errors, required, ...props }) => {

    const [val, setVal] = useState(data);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputText, onChange, onClear] = useDebouncedChange(handleChange, "", data, path, eventToValue);
    const [error, setError] = useState(null);
    const [helperText, setHelperText] = useState(null);


    useEffect(() => {
        if (inputText) {
            const [code, dial, phone] = inputText?.split(":");
            if (phone) {
                setVal(phone);
            }
            else {
                setVal("");
            }
            if (code && dial) {
                selectCountry(countries.find(c => c.code === code) ?? {
                    country: "India",
                    code: "in",
                    dial: "91"
                });
            } else {
                selectCountry({
                    country: "India",
                    code: "in",
                    dial: "91"
                });
            }
        } else {
            setVal("");
            selectCountry({
                country: "India",
                code: "in",
                dial: "91"
            });
        }
    }, [inputText]);

    const [country, selectCountry] = useState({
        country: "India",
        code: "in",
        dial: "91"
    });

    const toggleMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(o => !o);
    };

    const validatePhoneNumber = (phone, countryCode) => {
        if (['in', 'us', 'gb','cn'].includes(countryCode)) {
            if (['in', 'us'].includes(countryCode) && phone.length < 10 || phone.length > 12) {
                setError(true);
                setHelperText('Please enter a valid phone');
                return false;
            } if (['gb', 'cn'].includes(countryCode) && phone.length <= 12) {
                setError(true);
                setHelperText('Please enter a valid phone')
                return false;
            }
        } else if (phone.length < 8 || phone.length > 12) {
            setError(true);
            setHelperText('Please enter a valid phone');
            return false;
        }
        setError(null);
        setHelperText(null);
        return (true);
    }

    return <Box className={"react-tel-input"}
        sx={{ width: "100%", "input": { height: 32, pl: "0!important" }, ".flag": { pl: 0 } }}>
        <TextField
            sx={{ '& :disabled': { cursor: 'default!important' }, '& input': { paddingLeft: '8px!important' } }}
            fullWidth
            required={required}
            helperText={helperText || error}
            error={error || Boolean(errors)}
            label={label}
            variant={"standard"}
            value={val}
            disabled={!enabled}
            onChange={e => {
                if (e.target.value) {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                        const value = `${country.code}:${country.dial}:${e.target.value}`;
                        onChange({ target: { value } });
                        if (validatePhoneNumber(e.target.value, country.code)) {
                            handleChange({ target: { value } })
                        }
                    }
                } else {
                    onChange({ target: { value: "" } });
                    handleChange({ target: { value: "" } });
                }
            }}
            InputProps={{
                startAdornment: <Button disabled={!enabled} size={"small"} startIcon={<div className={`flag ${country?.code}`} />} variant={"text"}
                    sx={{ color: 'black' }} onClick={toggleMenu}>+{country?.dial}</Button>
            }}
        />
        <Popper sx={{ zIndex: 1301 }} placement={"bottom-start"} open={open} anchorEl={anchorEl}>
            <Box sx={{ width: 300, background: "white", p: 2 }}>
                <Autocomplete
                    open
                    getOptionLabel={(option) => option.country}
                    value={country}
                    onChange={(e, v) => {
                        console.log('v', v ?? v);

                        selectCountry(v);
                        // onChange({ target: { value: `${v.code}:${v.dial}:${val}` } });
                        if (val) {
                            const isValid = validatePhoneNumber(val, v.code);
                            if (isValid) {
                                onChange({ target: { value: `${v.code}:${v.dial}:${val}` } })
                                handleChange({ target: { value: `${v.code}:${v.dial}:${val}` } })
                            }
                        }
                        toggleMenu(e);
                    }}
                    renderInput={(params) => <TextField {...params} variant={"outlined"} />}
                    options={countries}
                />
            </Box>
        </Popper>
    </Box>;
};

export const PhoneInputControl = withJsonFormsControlProps(PhoneControl);

// eslint-disable-next-line no-prototype-builtins
export const PhoneInputTester = rankWith(3, and(scopeEndsWith("phone")));
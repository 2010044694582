import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import toastr from "toastr";
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppleIcon from '@mui/icons-material/Apple';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, FormControl, FormControlLabel, RadioGroup, FormLabel, Radio, Checkbox, Typography, useTheme, Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import Iconify from "../../../components/iconify";
import { useStateWithCallback } from "../../../utils/useStateWithCallback";
import { PasswordField } from "../../../components/PasswordField";
import { OTPField } from "../../../components/OTPField";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [userType, setUserType] = useState('customer');
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [step, setStep] = useState(0);
    const [usePassword, setUsePassword] = useState(true);
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState("");

    const theme = useTheme();
    const location = useLocation();

    const interval = useRef(null);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref);
            //   toastr.error('OTPs have expired. Please click resend to generate new OTPs');
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000
            });
        }
    });

    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds])

    const handleClick = useCallback(() => {
        axios.post(step === 0 ? "/auth/login" : "/auth/verify", {
            phone, password, email, otp, userType
        }).then(() => {
            window.location.replace("/");
            //   navigate('/', {replace: true});
            // navigate('/', { replace: true });
        }).catch((e) => {
            console.log('login message', e)
            if (step === 0) {
                if (e.response.data === "Unauthorized") {
                    // toastr.error("Login Failed!. Please check the credentials and try again");
                    Swal.fire({
                        position: "top-center",
                        icon: "error",
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: `Sign in Failed!`,
                        text: `Please check the credentials and try again`,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
                else {
                    // toastr.error(e.response.data.message);
                    Swal.fire({
                        position: "top-center",
                        icon: "error",
                        color: '#fff',
                        background: '#00838F',
                        toast: true,
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            }
            else {
                // toastr.error("Login Failed!. Please check the OTP and try again");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Sign in Failed!. Please check the OTP and try again`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [step, phone, password, email, otp, userType]);

    const generateOTP = useCallback(() => {
        axios.post("/auth/otp", {
            phone
        }).then(() => {
            setStep(1);
            if (interval.ref !== null) clearInterval(interval.ref);
            setSeconds(900);
            interval.ref = setInterval(() => {
                setSeconds(s => s - 1);
            }, 1000);
        }).catch((e) => {
            console.log('error', e);
            if (e.response.data.type === "INTERNATIONAL_NUMBER") {
                // toastr.error("Please login using email and password");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Please login using email and password`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            else {
                // toastr.error("Login Failed!. Unable to send OTP! Please check the mobile number");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Sign in Failed!. Unable to send OTP! Please check the mobile number`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [phone]);

    useEffect(() => {
        console.log('location', window.location.search);
        const params = new URLSearchParams(window.location.search);
        console.log("errorparams", params);
        const error = params.get('error');
        if (error) {
            // toastr.error(`${error}`);
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `${error}`,
                showConfirmButton: false,
                timer: 3000
            });
            // window.history.replaceState({}, document.title, `${window.location.pathname}`);
            navigate({ search: '' });

        }
    }, [location.search]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === 'enter' && !e.repeat) {
                e.preventDefault();
                if (step === 0) {
                    //   if(/^[5-9][0-9]{9}$/.test(phone ?? "") || (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') && password.length > 7)) {
                    if (isRecaptchaVerified && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') && password.length > 7)) {
                        if (phone.length !== 10) {
                            handleClick();
                        } else {
                            generateOTP();
                        }
                    }
                } else if (step === 1) {
                    if (otp.length === 6) {
                        handleClick();
                    }
                }
            }
        };
        window.addEventListener('keyup', handleEnter)
        return () => {
            window.removeEventListener('keyup', handleEnter);
        }
    }, [phone, email, password, otp, step, handleClick])

    const handleChange = (event) => {
        setUserType(event.target.value);
    };

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setIsRecaptchaVerified(value);
    }
    return (
        <>
            <Stack spacing={3}>

                <Typography variant="h3">Sign In</Typography>
                <Typography variant="body2" sx={{ mb: 5 }}>
                    New User? <Link component={RouterLink} to={"/register"} variant="subtitle2">Create an Account</Link>
                </Typography>
                {[
                    <>

                        <TextField name="email" error={(!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) || (!!phone && !/^\+\d{1,3}-\d{6,10}$/.test(phone))} helperText={(!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) ? 'Please enter a valid email' : ''} label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {usePassword && <PasswordField name="password" type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />}
                        <Typography textAlign={"right"} variant="body2" sx={{ mb: 5 }}>
                            <Link component={RouterLink} to={"/forgot-password"} variant="subtitle2">Forgot Password?</Link>
                        </Typography>
                        <ReCAPTCHA
                            sitekey="6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6"
                            onChange={onCaptchaChange}
                        />
                        <LoadingButton disabled={!isRecaptchaVerified || !/^\+\d{1,3}-\d{6,10}$/.test(phone ?? "") && !(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') && password.length > 7)} fullWidth size="large" type="submit"
                            variant="contained" onClick={() => {
                                //   if (phone.length !== 10) {
                                if (email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
                                    handleClick();
                                } else if (phone && /^\+\d{1,3}-\d{6,10}$/.test(phone)) {
                                    generateOTP();
                                }
                            }}>
                            {usePassword ? "Sign In" : "GENERATE OTP"}
                        </LoadingButton>
                        <>
                            <Typography variant="body2" align={'center'} sx={{ m: 1 }}>

                                OR
                            </Typography>
                            {/* <TextField name="phone"
                    //    error={(!!phone && !/^[5-9][0-9]{9}$/.test(phone))}
                    // helperText={(!!phone && !/^[5-9][0-9]{9}$/.test(phone)) ? "Please enter a valid 10 digit registered mobile number" : ""}
                    error={(!!phone && !/^\+\d{1,3}-\d{6,10}$/.test(phone))}
                    placeholder="+91-1234567890"
                    helperText={(!!phone && !/^\+\d{1,3}-\d{6,10}$/.test(phone) && "Please enter a valid mobile number like +91-1234567890")}
                       label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />

                <LoadingButton disabled={!/^\+\d{1,3}-\d{6,10}$/.test(phone ?? "") && !(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? '') && password.length > 7)} fullWidth size="large" type="submit"
                           variant="contained" onClick={() => {
            //   if (phone.length !== 10) {
            if (email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
                handleClick();
            } else if (phone && /^\+\d{1,3}-\d{6,10}$/.test(phone)) {
                generateOTP();
              }
            }}>
              {usePassword ? "LOGIN" : "GENERATE OTP"}
            </LoadingButton> */}
                            <Stack spacing={3}>
                                {/* <Box sx={{ mt: 2 }}> */}
                                <Button
                                    // onClick={handleMicrosoftLogin}
                                    fullWidth
                                    sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                    variant="outlined"
                                    color="primary"
                                    component="a"
                                    startIcon={<img src={'/assets/Sign-In-Google.png'} height={'32px'} alt="Sign In with Google" />}
                                    href={`${process.env.REACT_APP_API_URL}/auth/google`}
                                >
                                    Sign In with Google
                                </Button>
                                <Button
                                    // onClick={handleMicrosoftLogin}
                                    fullWidth
                                    sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                    variant="outlined"
                                    color="primary"
                                    component="a"
                                    startIcon={<img src={'/assets/Sign-In-LinkedIn.png'} height={'32px'} alt="Sign In with LinkedIn" />}
                                    href={`${process.env.REACT_APP_API_URL}/auth/linkedin`}
                                >
                                    Sign In with LinkedIn
                                </Button>
                                <Button
                                    // onClick={handleMicrosoftLogin}
                                    fullWidth
                                    sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                    variant="outlined"
                                    color="primary"
                                    component="a"
                                    startIcon={<img src={'/assets/Sign-In-Apple.png'} height={'32px'} alt="Sign In with Apple" />}
                                    href={`${process.env.REACT_APP_API_URL}/auth/apple`}
                                >
                                    Sign In with Apple
                                </Button>
                                {/* </Box> */}
                            </Stack>

                        </>

                    </>, <>
                        <Typography variant="h5" align={"center"}>
                            OTP verification
                        </Typography>
                        <Typography variant="body2" align={"center"}>
                            Enter the OTP sent to {phone} <Link variant="subtitle2" onClick={() => setStep(0)}>Change</Link>
                        </Typography>
                        <OTPField containerStyle={{ justifyContent: "space-around" }} inputStyle={{
                            fontSize: theme.typography.fontSize,
                            width: theme.spacing(4),
                            padding: theme.spacing(1.5)
                        }} value={otp} onChange={setOtp} numInputs={6} isInputNum isInputSecure />
                        <Typography variant="body2"
                            sx={{ mb: 5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ display: 'flex', color: seconds <= 0 ? 'error.main' : seconds <= 60 ? 'warning.main' : 'text.primary' }}><Iconify sx={{ color: seconds <= 0 ? 'error.main' : seconds <= 60 ? 'warning.main' : 'text.primary' }} icon='uil:clock' /> {seconds > 0 ? timer : 'OTP Expired! Please click resend to generate new ones'}</Box><Link component={Button} disabled={seconds > 840} href={"#"} onClick={(e) => {
                                e.preventDefault();
                                generateOTP();
                            }} variant="subtitle2">Resend</Link>
                        </Typography>
                        <LoadingButton disabled={otp.length !== 6 || seconds <= 0} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                            VERIFY
                        </LoadingButton>
                    </>
                ][step]}
            </Stack>
        </>
    );
}

import axios from "axios";

export const fetchDataFromZip = ({ city, state, zip,countryCode}) => {
    return axios.get(`/testator/${countryCode}/${zip}`, {
      timeout: 8000
    }).then(response => {
      return response.data;
    }).catch((err) => {
      console.log(err)
      return {city, state};
    });
}
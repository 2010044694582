import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Stack, Button, Typography, Box } from "@mui/material";
import toastr from "toastr";
import Swal from 'sweetalert2';
import CenterModal from "./CenterModal";

const timeout = 900_000
const promptBeforeIdle = 10_000

const IdleTimeout = () => {

    const [remaining, setRemaining] = useState(timeout);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const onIdle = async () => {
        axios.post('/auth/logout').then(() => {
            // toastr.warning("Logged out successfully", "You have been idle for more than 15 minutes", { timeOut: 0 });
            Swal.fire({
                position: "top-center",
                icon: "warning",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "You have been logged out due to inactivity! Please login again to use the application.",
                showConfirmButton: false,
                timer: 3000
            }); 
            sessionStorage.removeItem('profile_saved');
            sessionStorage.removeItem('profile');
            sessionStorage.removeItem('beneficiary');
            sessionStorage.removeItem(`previousGenericbeneficiaryData`);
            sessionStorage.removeItem(`previousGenericexecutorData`);
            sessionStorage.removeItem(`previousGenericwitnessData`);
            sessionStorage.removeItem(`previousAssetData`);
            sessionStorage.removeItem(`previousProfileData`);

            sessionStorage.removeItem(`video_close_distribution`);
            sessionStorage.removeItem(`video_close_executors`);
            sessionStorage.removeItem(`video_close_assets`);
            sessionStorage.removeItem(`video_close_beneficiaries`);
            sessionStorage.removeItem(`video_close_witnesses`);
            sessionStorage.removeItem(`video_close_preview`);
            sessionStorage.removeItem(`video_close_app`);

        })
        .finally(() => navigate('/login', { replace: true }));
        setOpen(false)
    }

    const onActive = () => {
        setOpen(false)
    }

    const onPrompt = () => {
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    return (
        <>
            <CenterModal
                open={open}
                onClose={() => {
                    handleStillHere();
                }}
                centered
                title={`Are you still here?`}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                zIndex={1700}
            >
                <Box sx={{ m: -1, p: 2, bgcolor: "white" }}>
                    <Stack direction={"column"} sx={{ height: "100%", placeContent: "space-between" }}>
                        <Typography component={"h2"} fontWeight={"bold"} fontSize={20} align={"center"}
                            sx={{ color: "text.secondary" }}>
                            You will be logged out in {remaining} seconds.
                        </Typography>

                    </Stack>
                </Box>
                <Stack sx={{ bgcolor: "#BDBDBD", m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" gutterBottom>
                        &nbsp;
                    </Typography>
                    <Button style={{ marginTop: "8px" }} onClick={() => {
                        handleStillHere();
                    }} variant="contained">
                        I am still here
                    </Button>
                </Stack>
            </CenterModal>
        </>
    )
}
export default IdleTimeout;
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Autocomplete, Button, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { and, isEnumControl, rankWith, schemaTypeIs, scopeEndsWith } from "@jsonforms/core";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";

const FileUpload = ({ data, handleChange, path, id, schema, label, placement }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');


    useEffect(() => {
        if (data) handleChange(path, data);
    }, []);

    useEffect(() => {
        if (!data) {
            setFileName('');
            setFile(null);
        }
    }, [data]);

    useEffect(() => {
        if (!!file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                handleChange(path, e.target.result);
            };
            setFileName(file.name);
            reader.readAsDataURL(file);
        } else {
            setFileName('');
        }
    }, [file]);
    return <Stack>
        {file ? <Typography variant={"body"}>{fileName} <IconButton sx={{ float: 'right' }} onClick={() => setFile(null)}><Close /></IconButton></Typography> : 
             <Tooltip title={label} placement="top">
                <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                >
                    {label}
                    <input
                        onChange={(e) => {
                            if (!e.target.files) return;
                            const selectedFile = e.target.files[0];
                            console.log('selectedFile', selectedFile);
                            if (selectedFile) {
                                const allowedTypes = ['application/pdf'];
                                if (!allowedTypes.includes(selectedFile.type)) {
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "warning",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `Only PDF files are allowed`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                } else if (selectedFile.size > 5000000) {
                                    Swal.fire({
                                        position: "top-center",
                                        icon: "warning",
                                        color: '#fff',
                                        background: '#00838F',
                                        toast: true,
                                        title: `File size should not be greater than ${5} MB`,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                }
                                else {
                                    setFile(selectedFile);

                                }
                            }
                            // setFile(e.target.files[0]);
                        }}
                        type="file"
                        accept={"application/pdf"}
                        hidden
                    />
                </Button>
            </Tooltip>}
    </Stack>;
};

export const FileUploadControl = withJsonFormsControlProps(FileUpload);

// eslint-disable-next-line no-prototype-builtins
export const FileUploadTester = rankWith(3, and(schemaTypeIs("string"), scopeEndsWith("file")));
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppleIcon from '@mui/icons-material/Apple';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Checkbox,
    Typography,
    useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    Button
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
// components
import Iconify from "../../../components/iconify";
import { useStateWithCallback } from "../../../utils/useStateWithCallback";
import { PasswordField } from "../../../components/PasswordField";
import { OTPField } from "../../../components/OTPField";
import { PhoneControl } from "../../../components/renderers/PhoneInput";
import { PhoneField } from "../../../components/PhoneField";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function PartnerSignUpForm() {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [userType, setUserType] = useState('customer');
    const [partnerType, setPartnerType] = useState('Wealth Manager');

    const [pan, setPan] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [houseNo, setHouseNo] = useState("");
    const [houseName, setHouseName] = useState("");
    const [street, setStreet] = useState("");
    const [area, setArea] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zip, setZip] = useState("");
    const [kycPan, setKycPan] = useState("");
    const [kycDocument, setKycDocument] = useState("");
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [step, setStep] = useState(0);
    const [potp, setPOTP] = useState("");
    const [eotp, setEOTP] = useState("");
    const [accepted, setAccepted] = useState(false);
    const interval = useRef(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [seconds, setSeconds] = useStateWithCallback(900, (sec) => {
        if (sec <= 0) {
            if (interval.ref !== null) clearInterval(interval.ref);
            //   toastr.error("OTPs have expired. Please click resend to generate new OTPs");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `OTPs have expired. Please click resend to generate new OTPs`,
                showConfirmButton: false,
                timer: 3000
            });
        }
    });

    const timer = useMemo(() => {
        const date = new Date(seconds * 1000);
        const isoDate = date.toISOString();
        return isoDate.substring(14, 19);
    }, [seconds]);

    const handleClick = useCallback(() => {
        // if (userType === 'customer') {

        //     axios.post("/auth/register", {
        //         userType, email, password, eotp
        //     }).then(() => {
        //         // toastr.success("Registration Complete! Please login to create a Will");
        //         Swal.fire({
        //             position: "top-center",
        //             icon: "success",
        //             color: '#fff',
        //             background: '#00838F',
        //             toast: true,
        //             title: `Registration Complete! Please login to create a Will`,
        //             showConfirmButton: false,
        //             timer: 1500
        //         });
        //         navigate("/login", { replace: true });
        //     }).catch(() => {
        //         // toastr.error("Registration Failed! Please check the fields and try again");
        //         Swal.fire({
        //             position: "top-center",
        //             icon: "error",
        //             color: '#fff',
        //             background: '#00838F',
        //             toast: true,
        //             title: `Registration Failed! Please check the fields and try again`,
        //             showConfirmButton: false,
        //             timer: 1500
        //         });
        //     });
        // }
        // if (userType === 'partner') {

        axios.post("/auth/register", {
            userType: 'partner', email, phone, password, eotp, profile: { partnerType, pan, companyName, first_name: firstName, last_name: lastName, house_no: houseNo, house_name: houseName, street, area, city, state, country, zip, kyc_pan: kycPan, kyc_document: kycDocument }
        }).then(() => {
            // toastr.success("Registration Complete! Please login to create a Will");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: `Registration Complete! Please login to create a Will`,
                showConfirmButton: false,
                timer: 3000
            });
            // navigate(navigate().pathname.replace('/partner', ''));
            setTimeout(() => {
                window.location.replace("/");
            }, 3000);
            // navigate("/login", { replace: true });
        }).catch((e) => {
            console.log('rege', e);
            if (e?.response?.status === 400) {
                // toastr.error("Email or mobile already in use. Please proceed to login!");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });
            } else {
                // toastr.error("Registration Failed! Please check the fields and try again");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Registration Failed! Please check the fields and try again`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }

        });
        // }
    }, [email, password, eotp]);

    const generateOTPs = useCallback(() => {
        axios.post("/auth/pre-register", {
            email, phone
        }).then(() => {
            setStep(1);
            if (interval.ref !== null) clearInterval(interval.ref);
            setSeconds(900);
            interval.ref = setInterval(() => {
                setSeconds(s => s - 1);
            }, 1000);
        }).catch((e) => {
            if (e?.response?.status === 400) {
                // toastr.error("Email or mobile already in use. Please proceed to login!");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Email already in use. Please proceed to login!`,
                    showConfirmButton: false,
                    timer: 3000
                });
            } else {
                // toastr.error("Unable to generate OTP! Please check the email");
                Swal.fire({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: `Unable to generate OTP! Please check the email`,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }, [email, phone]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.code?.toLowerCase() === "enter" && !e.repeat && accepted) {
                e.preventDefault();
                if (step === 0) {
                    //   if (/^[5-9][0-9]{9}$/.test(phone ?? "") && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") && password.length > 7)) {
                    if (/^\+\d{1,3}-\d{6,10}$/.test(phone ?? "") && (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") && password.length > 7)) {
                        generateOTPs();
                    }
                } else if (step === 1) {
                    if (potp.length === 6 && eotp.length === 6 && seconds > 0) {
                        handleClick();
                    }
                }
            }
        };
        window.addEventListener("keyup", handleEnter);
        return () => {
            window.removeEventListener("keyup", handleEnter);
        };
    }, [phone, email, password, eotp, potp, step, handleClick, accepted]);

    const theme = useTheme();
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%^&*()_+])[a-zA-Z0-9!@#\\$%^&*()_+]{8,}$/;
    const handleChange = (event) => {
        setUserType(event.target.value);
    };
    const handlePartnerTypeChange = (event) => {
        setPartnerType(event.target.value);
    };
    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setIsRecaptchaVerified(value);
    }

    const isInvalidPhone = (phone) => {
        const [code, dial, phoneString] = phone?.split(":");
        if (code && dial && phoneString) {

            if (['in', 'us'].includes(code) && phoneString.length !== 10) {
                return true;
            }
            if (['gb'].includes(code) && phoneString.length !== 11) {
                return true;
            }
            if (phoneString.length < 7 && phoneString.length > 12) {
                return true;
            }
        }
        return false;
    }

    const checkIsDisabled = () => {
        // console.log('here checkIsDisabled', userType);
        // if (userType === "customer" && (!isRecaptchaVerified || !accepted || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || password !== confirmPassword || password.length < 8)) {
        //     return true;
        // }
        // if (userType === "partner") {
        const [code, dial, phoneString] = phone?.split(":");
        console.log('inside partner phone check', isInvalidPhone(phone));
        console.log('inside partner', !accepted || !isRecaptchaVerified || !partnerType || !firstName || !lastName || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || !phoneString || !pan || !houseNo || !houseName || !street || !area || !city || !state || !country || !zip || !kycPan || !kycDocument);
        // if (!accepted || !isRecaptchaVerified || !partnerType || !firstName || !lastName || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || !phoneString || isInvalidPhone(phone) || !/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "") || !houseNo || !houseName || !street || !area || !city || !state || !country || !zip || !/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(kycPan ?? "") || !kycDocument) {
        //     return true;
        // }
        if (!accepted || !isRecaptchaVerified || !partnerType || firstNameError || lastNameError || !firstName || !lastName || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "")) {
            return true;
        }
        // }
        return false;
    }
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const handleFileChange = (e) => {
        if (!e.target.files) return;
        setFile(e.target.files[0]);
    };
    useEffect(() => {
        if (!!file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setKycDocument(e.target.result);
            };
            setFileName(file.name);
            reader.readAsDataURL(file);
        } else {
            setFileName('');
        }
    }, [file]);

    return (
        <>
            <Stack spacing={3}>
                {[
                    <>
                        <Typography variant="h3">Create an account</Typography>

                        <>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-controlled-open-select-label">Partner Type</InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    value={partnerType}
                                    label="Partner Type"
                                    name="partnerType"
                                    onChange={handlePartnerTypeChange}
                                >
                                    <MenuItem value={'Wealth Manager'}>Wealth Manager</MenuItem>
                                    <MenuItem value={'Corporate'}>Corporate</MenuItem>
                                    <MenuItem value={'Bank'}>Bank</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField name="company_name" label="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            <TextField name="first_name" label="First Name" value={firstName} onChange={(e) => {
                                if (/^[a-zA-Z]+$/.test(e.target.value)) {
                                    setFirstNameError(null)
                                }
                                else {
                                    setFirstNameError("Only alphabets are allowed");
                                }
                                setFirstName(e.target.value)
                            }} />
                            {firstNameError && (
                                <Typography variant="body2" sx={{ marginTop: '2px!important' }} color="error">{`${firstNameError}`}</Typography>
                            )}
                            <TextField name="last_name" label="Last Name" value={lastName} onChange={(e) => {
                                if (/^[a-zA-Z]+$/.test(e.target.value)) {
                                    setLastNameError(null)
                                }
                                else {
                                    setLastNameError("Only alphabets are allowed");
                                }
                                setLastName(e.target.value)
                            }} />
                            {lastNameError && (
                                <Typography variant="body2" sx={{ marginTop: '2px!important' }} color="error">{`${lastNameError}`}</Typography>
                            )}
                            <TextField name="email" error={(!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))}
                                helperText={(!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) && "Please enter a valid email")}
                                label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {/* <TextField name="phone"
                            // error={( !!phone && !/^[5-9][0-9]{9}$/.test(phone))}
                            // helperText={(!!phone && !/^[5-9][0-9]{9}$/.test(phone) && "Please enter a valid 10 digit mobile number")}
                            error={(!!phone && !/^\+\d{1,3}-\d{6,10}$/.test(phone))}
                            placeholder="+91-1234567890"
                            helperText={(!!phone && !/^\+\d{1,3}-\d{6,10}$/.test(phone) && "Please enter a valid mobile number like +91-1234567890")}
                            // helperText={`+91-987654321`}
                            label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                            {/* <PhoneField
                                    name="phone"
                                    value={phone}
                                    required={Boolean(true)}
                                    label="Phone"
                                    errors={null}
                                    enabled={Boolean(true)}
                                    handleChange={(e) => setPhone(e.target.value)}
                                />
                                <TextField name="pan" label="PAN" error={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "")} helperText={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(pan ?? "") ? "Please enter a valid PAN" : ""} value={pan} onChange={(e) => setPan(e.target.value)} />
                                <TextField name="houseNo" label="House / Flat no." value={houseNo} onChange={(e) => setHouseNo(e.target.value)} />
                                <TextField name="houseName" label="Building / Bungalow name" value={houseName} onChange={(e) => setHouseName(e.target.value)} />
                                <TextField name="street" label="Street Name" value={street} onChange={(e) => setStreet(e.target.value)} />
                                <TextField name="area" label="Area/Locality" value={area} onChange={(e) => setArea(e.target.value)} />
                                <TextField name="city" label="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                <TextField name="state" label="State" value={state} onChange={(e) => setState(e.target.value)} />
                                <TextField name="country" label="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
                                <TextField name="zip" label="PIN Code" value={zip} onChange={(e) => setZip(e.target.value)} />
                                <TextField name="kyc_pan" error={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(kycPan ?? "")} helperText={!/(^[A-Za-z]{5}[0-9]{4}[A-Za-z]$)/.test(kycPan ?? "") ? "Please enter a valid PAN" : ""} label="KYC (PAN or Company PAN)" value={kycPan} onChange={(e) => setKycPan(e.target.value)} /> */}
                            {/* <TextField type="file" name="kycDocument" label="KYC Document" value={''}  /> */}
                            {/* <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                                    Upload KYC Document
                                    <VisuallyHiddenInput type="file" accept={"application/pdf"} onChange={handleFileChange} />
                                </Button> */}
                        </>
                        <PasswordField name="password" type="password" label="Password" value={password} error={(!!password && !passwordRegex.test(password))} helperText={(!!password && !passwordRegex.test(password) && "Password should be at least 8 characters long and must contain at least one uppercase, one lowercase, one number and one special case character")}
                            onChange={(e) => setPassword(e.target.value)} />
                        <PasswordField error={((!!confirmPassword && !!password) && password !== confirmPassword)}
                            helperText={((!!confirmPassword && !!password) && password !== confirmPassword && "Password mismatch! Please enter same password in both fields")}
                            name="confirm-password" type="password" label="Confirm Password" value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} />

                        <ReCAPTCHA
                            sitekey="6LfYHrYoAAAAABadQVuKliDO4491RrZ7ThRkEAL6"
                            onChange={onCaptchaChange}
                        />
                        <FormControlLabel
                            sx={{ m: 0 }}
                            control={<Checkbox checked={accepted} onChange={(e, value) => {
                                setAccepted(value);
                            }} />} label={<Typography align={"center"} variant="body2" sx={{ mb: 0 }}>
                                I have read and accept the <Link target={'_blank'} href={"https://getwilldone.com/terms-and-conditions/"} variant="subtitle2">Terms and Conditions</Link>
                            </Typography>} />

                        <LoadingButton disabled={checkIsDisabled()}

                            // disabled={!accepted || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email ?? "") || password !== confirmPassword || password.length < 8}
                            fullWidth size="large" type="button" variant="contained" onClick={() => generateOTPs()}>
                            SIGN UP
                        </LoadingButton>
                        <Typography align={"center"} variant="body2" sx={{ mb: 5 }}>
                            Already have an account? <Link component={RouterLink} to={"/login"} variant="subtitle2">Sign In</Link>
                        </Typography>


                    </>, <>
                        <Typography variant="h5" align={"center"}>
                            OTP verification
                        </Typography>
                        {phone.includes("+91-") && (
                            <>
                                <Typography variant="body2" align={"center"}>
                                    Enter the OTP sent to {phone} <Link variant="subtitle2" onClick={() => setStep(0)}>Change</Link>
                                </Typography>
                                <OTPField containerStyle={{ justifyContent: "space-around" }} inputStyle={{
                                    fontSize: theme.typography.fontSize,
                                    width: theme.spacing(4),
                                    padding: theme.spacing(1.5)
                                }} value={potp} onChange={setPOTP} numInputs={6} isInputNum isInputSecure />
                            </>
                        )}

                        <Typography variant="body2" align={"center"} sx={{ mb: 5 }}>
                            Enter the OTP sent to {email} <Link variant="subtitle2" onClick={() => setStep(0)}>Change</Link>
                        </Typography>
                        <OTPField containerStyle={{ justifyContent: "space-around" }} inputStyle={{
                            fontSize: theme.typography.fontSize,
                            width: theme.spacing(4),
                            padding: theme.spacing(1.5)
                        }} value={eotp} onChange={setEOTP} numInputs={6} isInputNum isInputSecure />
                        <LoadingButton disabled={seconds <= 0 || (eotp.length !== 6 && potp.length !== 6)} fullWidth size="large"
                            type="submit" variant="contained" onClick={handleClick}>
                            VERIFY
                        </LoadingButton>
                        <Typography variant="body2"
                            sx={{ mb: 5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{
                                display: "flex",
                                color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : "text.primary"
                            }}><Iconify sx={{ color: seconds <= 0 ? "error.main" : seconds <= 60 ? "warning.main" : "text.primary" }}
                                icon="uil:clock" /> {seconds > 0 ? timer : "OTP Expired! Please click resend to generate new ones"}
                            </Box><Link component={Button} disabled={seconds > 840} href={"#"} onClick={(e) => {
                                e.preventDefault();
                                generateOTPs();
                            }} variant="subtitle2">Resend</Link>
                        </Typography>
                    </>
                ][step]}
            </Stack>
        </>
    );
}

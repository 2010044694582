import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import toastr from "toastr";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import Swal from "sweetalert2";
import { Button, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import "toastr/build/toastr.min.css";
import "./styles.css";
import GDPR from "./layouts/dashboard/gdpr";
import usePreventScreenshot from "./hooks/usePreventScreenshot";

const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID ?? "G-W0L01N2JHS" // "G-X1SNY8PGKP" // "G-HFPGDX4605"; 
ReactGA.initialize(GA4_TRACKING_ID);

toastr.options.timeOut = 5000;
toastr.options.progressBar = true;
toastr.options.positionClass = "toast-bottom-left";
toastr.options.preventDuplicates = true;
// ----------------------------------------------------------------------
const AxiosInterceptor = ({ calls, children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const reqInt = async config => {
            calls.current += 1;
            return config;
        };
        const resInt = res => {
            calls.current -= 1;
            return res;
        };
        const errInt = err => {
            calls.current -= 1;
            if ((err?.response?.status === 401 || err?.response?.status === 403) && !["/login", "/register", "/forgot-password"].includes(window.location.pathname)) {
                Swal.fire({
                    position: "top-center",
                    icon: "warning",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "You have been logged out due to inactivity! Please login again to use the application.",
                    showConfirmButton: false,
                    timer: 3000
                });
                navigate("/login", { replace: true });
            }
            return Promise.reject(err);
        };
        const rint = axios.interceptors.request.use(reqInt);
        const reint = axios.interceptors.response.use(resInt, errInt);
        return true;
    }, []);
    return children;
};

export const LoadingContext = createContext({
    isLoading: false, showHelp: false, setShowHelp: () => {
    }, editProfile: false, video: null
});

const videos = {
    "app": {
        url: "https://www.youtube.com/embed/RhXl2fNhkjk?rel=0",
        title: "GetWillDone Introduction Video"
    },
    "assets": {
        url: "https://www.youtube.com/embed/bX-NIh3XW-A?si=blvUAHOn7N1AKUCM",
        title: "Who can be a beneficiary?"
    },
    "beneficiaries": {
        url: "https://www.youtube.com/embed/s4OMayI0H_4?si=w2AzRxrFpBVw9uw7",
        title: "What is a Beneficiary?"
    },
    "executors": {
        url: "https://www.youtube.com/embed/_n9984AxCZ0?rel=0",
        title: "Who is an Executor of a Will?"
    },
    "witnesses": {
        url: "https://www.youtube.com/embed/SUE-XG-NBtg?rel=0",
        title: "Who can be a witness to your Will?"
    },
    "distribution": {
        url: "https://www.youtube.com/embed/T_mauuyt5A0?si=u3N_6WkTIQcWWVvk",
        title: "What happens in absence of a Will?"
    },
    "preview": {
        url: "https://www.youtube.com/embed/XZlLsQsYDuA?rel=0",
        title: "What is a Will?"
    }
};

export default function App() {
    const [calls, setCalls] = useState(0);
    // const calls = useRef(0);
    const isLoading = useMemo(() => calls > 0, [calls]);
    const [showHelp, setShowHelp] = useState(true);
    const [editProfile, setEditProfile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isIframLoading, setIsIframLoading] = useState(true);
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    // useEffect(() => {
    //     loadScript("https://checkout.razorpay.com/v1/checkout.js").then(res => {
    //         if (!res) toastr.error('Unable to load Razorpay SDK');
    //     });
    // }, []);



    const temp = useMemo(() => {
        const reqInt = async config => {
            if (!config.url.includes("/testator/check/payment") && !config.url.includes('/generics') && !config.url.includes('/properties'))
                setCalls(c => c + 1);
            return config;
        };
        const resInt = res => {
            setCalls(c => c > 0 ? c - 1 : 0);
            return res;
        };
        const errInt = err => {
            setCalls(c => c > 0 ? c - 1 : 0);
            if ((err?.response?.status === 401 || err?.response?.status === 403) && !["/login", "/register", "/forgot-password", "/"].includes(location.pathname)) {
                // toastr.warning("You have been logged out due to inactivity! Please login again to use the application.");
                Swal.fire({
                    position: "top-center",
                    icon: "warning",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "You have been logged out due to inactivity! Please login again to use the application.",
                    showConfirmButton: false,
                    timer: 3000
                });
                navigate("/login", { replace: true });
            }
            return Promise.reject(err);
        };
        axios.interceptors.request.clear();
        axios.interceptors.response.clear();
        axios.interceptors.request.use(reqInt);
        axios.interceptors.response.use(resInt, errInt);
        return calls > 0;
    }, [location]);

    // const [isLoading, setLoading] = useState(false);
    // useEffect(() => {
    //   setLoading(calls > 0);
    // }, [calls])

    useEffect(() => {
        if (location.pathname === "/dashboard/app") setEditProfile(false);
    }, [location]);

    useEffect(() => {
        // Send the pageview hit to GA4
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [location.pathname]); // Triggered on path change

    const video = useMemo(() => videos[location.pathname.split('/').pop()], [location]);
    console.log('video', video);
    usePreventScreenshot();
    return (
        <ThemeProvider>
            {isLoading && <div className="clock-container">
                <div className="clock-loader" />
            </div>}
            {showHelp && video && <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 100000,
                background: "rgba(0, 0, 0, 0.8)",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center"
            }}>

                <Stack justify="flex-end" alignItems={"flex-end"}>
                    {isIframLoading ?
                        <Typography variant="h3" sx={{
                            color: '#ffffff', position: 'fixed',
                            top: '50%',
                            left: '50%'
                        }}>Loading</Typography>

                        :
                        <Button sx={{ color: "white", fontSize: "3rem" }}
                            onClick={() => {
                                setShowHelp(false);
                                setIsIframLoading(true);
                                if (location.pathname === "/dashboard/app") setEditProfile(true);
                                sessionStorage.setItem(`video_close_${location.pathname.split('/').pop()}`, true);
                                setTimeout(() => {
                                    document.getElementById("#/properties/first_name-input")?.focus();
                                }, 100);
                            }}><CloseRounded fontSize={"inherit"} /> <span style={{ fontSize: "1.5rem" }}>Close</span></Button>
                    }
                    <iframe width="853" height="480" src={video?.url ?? 'about:blank'} title={video?.title ?? 'Blank Page'}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        onLoad={() => setIsIframLoading(false)}
                    />
                </Stack>
            </div>}
            <ScrollToTop />
            <StyledChart />
            <LoadingContext.Provider value={{ isLoading, showHelp, setShowHelp, editProfile, video }}>
                <Router />
                {/* <GDPR /> */}
            </LoadingContext.Provider>
        </ThemeProvider>
    );
}

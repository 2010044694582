// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
    return {
        MuiTimeline: {
            styleOverrides: {
                root: {
                    flexDirection: 'row',
                    paddingLeft: theme.spacing(0),
                    margin: `auto -${theme.spacing(2)}`
                }
            }
        },
        MuiTimelineItem: {
            styleOverrides: {
                root: {
                    minWidth: 'fit-content'
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    alignSelf: 'inherit'
                }
            }
        },
        MuiTimelineSeparator: {
            styleOverrides: {
                root: {
                    flexDirection: 'row',
                    minWidth: '50px'
                }
            }
        },
        MuiTimelineConnector: {
            styleOverrides: {
                root: {
                    height: '2px'
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                variant: 'outlined'
            },
            styleOverrides: {
                paper: {
                    boxShadow: theme.customShadows.z20,
                },
            },
        },
    };
}

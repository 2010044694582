import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { Box, Card, Typography } from '@mui/material';

const PartnerVsDirectRevenueChart = () => {
  const chartRef = useRef(null);
  const [revenueData, setRevenueData] = useState({});

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const response = await axios.get('/admin/partner-vs-direct-revenue');
        const { partnerRevenue, directIndividualRevenue } = response.data;
        setRevenueData({ partnerRevenue, directIndividualRevenue });
      } catch (error) {
        console.error('Error fetching revenue data:', error);
      }
    };

    fetchRevenueData();
  }, []);

  useEffect(() => {
    let myChart = null;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy the existing Chart instance if it exists
      if (myChart) {
        myChart.destroy();
      }

      // Create a new Chart instance
      myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Partners Revenue', 'Direct Individual Revenue'],
          datasets: [
            {
              label: 'Partner vs Direct Revenue',
              data: [revenueData.partnerRevenue, revenueData.directIndividualRevenue],
              backgroundColor: ['#ba3f13','#f15d27'],
              borderColor: '#ffffff',
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label;
                  const value = context.formattedValue;
                  return `${label}: ₹${value}`;
                },
              },
            },
          },
        },
      });
    }

    // Cleanup function to destroy the Chart instance when the component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [revenueData]);

  return (
    <Card
      sx={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h5">Partner Revenue V/S Direct Individual Revenue</Typography>
      <Box sx={{ height: '300px' }}>
        <canvas ref={chartRef} style={{ height: '300px', width: '100%' }} />
      </Box>
    </Card>
  );
};

export default PartnerVsDirectRevenueChart;

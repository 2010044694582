import React from 'react';
import { Grid, Typography } from '@mui/material';
import { addDays, getYear } from "date-fns";

const Footer = () => {
    return (
        <Grid container component="footer" sx={{
            mt: 5,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            // backgroundColor: '#f0f0f0',
            // padding: '10px',
        }}>
            <Grid item xs={4}>
                <Typography variant="body2">{`Copyright © GetWillDone ${getYear(new Date())}. All rights reserved.`}</Typography>
            </Grid>
            {/* <Grid item xs={4}>
                <Typography variant="body2">Footer Column 2</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">Footer Column 3</Typography>
            </Grid> */}
        </Grid>
    );
};

export default Footer;

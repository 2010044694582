import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import DashboardCard from './common/DashboardCard';
import DashboardCard from '../admin-dashboard/common/DashboardCard';

const TotalNumberOfPartnerClientsCard = () => {
  const [clientsData, setClientsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalNumberOfPartnerClients = async () => {
      try {
        const response = await axios.get('/partners/dashboard/totalTestators');
        const { totalTestators } = response.data;
        setClientsData({ totalTestators });
      } catch (error) {
        console.error('Error fetching number of clients data:', error);
      }
    };

    fetchTotalNumberOfPartnerClients();
  }, []);

  const handleClick = () => {
    navigate('/dashboard/no-of-clients');
  };

  return (
    <>
      <DashboardCard
        title="Number of Clients"
        subtitle={(clientsData.totalTestators ?? '--') || 0}
        handleClick={handleClick}
        isClickable
      />
    </>
  );
};

export default TotalNumberOfPartnerClientsCard;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../admin-dashboard/common/DashboardCard';

const PartnersIncompleteWillsCard = () => {
  const [incompleteWillsData, setIncompleteWillsData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncompleteWillsData = async () => {
      try {
        const response = await axios.get('/partners/dashboard/incompleteWills');
        const { incompleteWills } = response.data;
        setIncompleteWillsData({ incompleteWills });
      } catch (error) {
        console.error('Error fetching total incomplete wills data:', error);
      }
    };

    fetchIncompleteWillsData();
  }, []);

  const handleClick = () => {
    navigate('/dashboard/incomplete-wills-page');
  };

  return (
    <DashboardCard
      title="Incomplete Wills"
      subtitle={incompleteWillsData?.incompleteWills ?? '--'}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default PartnersIncompleteWillsCard;

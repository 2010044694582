import { Helmet } from "react-helmet-async";
import { filter, property } from "lodash";
import { camelCase, capitalCase, sentenceCase } from "change-case";
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Joyride from "react-joyride";
import dayjs from "dayjs";

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination, Box, Modal, FormControl, InputLabel, Select, ButtonGroup, Grid, Tooltip
} from "@mui/material";
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialEnumControlTester, materialRenderers } from "@jsonforms/material-renderers";
import { isEnumControl } from "@jsonforms/core";
import toastr from "toastr";
import { Close } from "@mui/icons-material";

// components
import Swal from "sweetalert2";
import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import { OneOfControl, OneOfTester } from "../components/renderers/Autocomplete";
import { ImagePickerControl, ImagePickerTester } from "../components/renderers/ImagePicker";
import BottomModal from "../components/BottomModal";
import CenterModal from "../components/CenterModal";
import { translator } from "../utils/jsonForms";
import { getCorrectCase } from "../utils/formatString";
import { LoadingContext } from "../App";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: "edit-action" },
    { id: "type", label: "Type", alignRight: false },
    { id: "description", label: "Name / Description", alignRight: false },
    { id: "info", label: "Particulars", alignRight: false },
    // { id: "nominee", label: "Nominee", alignRight: false, alignCenter: true },
    { id: "action", label: "Actions" }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AssetPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("name");

    const [filterName, setFilterName] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [assets, setAssets] = useState([]);
    const [beneficiaries, setBeneficiaries] = useState([]);

    const [schemas, setSchemas] = useState({});

    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [assetData, setAssetData] = useState({});
    const [previousAssetData, setPreviousAssetData] = useState(null);
    const [errors, setErrors] = useState({});
    const [type, setType] = useState("");
    const [subType, setSubType] = useState("");
    const [reorderedArray, setReorderedArray] = useState("");

    const fetchAssets = () => {
        axios.get("/properties").then(response => {
            // Custom sorting function
            const customSort = (a, b) => {
                if (a.type === "other_assets" && b.type !== "other_assets") {
                    return 1; // Move a to the end
                }
                if (a.type !== "other_assets" && b.type === "other_assets") {
                    return -1; // Move b to the end
                }
                return 0; // Maintain the order
            };

            // Sort the array using the custom sorting function
            const sortedArray = response.data?.assets.slice().sort(customSort);
            setAssets(sortedArray ?? []);
        }).catch(err => console.error(err));
    };

    const fetchBeneficiaries = () => {
        axios.get("/generics/beneficiary").then(response => {
            setBeneficiaries(response.data?.generics ?? []);
        }).catch(err => console.error(err));
    };

    const fetchForms = () => {
        axios.get("/properties/forms").then(response => {
            if (response.data) {
                // Convert the object into an array of objects
                const assetArray = Object.entries(response.data.schemas).map(([label, value]) => ({ label, value }));
                // Move "Other Assets" option to the end
                const tempReorderedArray = [
                    ...assetArray.filter(option => option.label !== "other_assets"),
                    assetArray.find(option => option.label === "other_assets"),
                ];
                setReorderedArray(tempReorderedArray);
                // console.log('reorderedArray',reorderedArray);
            }

            setSchemas(response.data ?? {});
        }).catch(err => console.error(err));
    };
    const addNewAsset = () => {
        axios.post("/properties", { type, subType, asset: assetData }).then(response => {
            // toastr.success("Asset saved successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Asset saved successfully",
                showConfirmButton: false,
                timer: 3000
            });
            fetchAssets();
            setEdit(false);
            setOpen(false);
            setEditingId(null);
            setAssetData({});

            setAddNew(false);
            setType("");
            setSubType("");

            if (assets.length === 0) {
                setTimeout(() => {
                    Swal.fire({
                        title: `The next step is to distribute asset.`,
                        // text: ("Add Asset"),
                        icon: "info",
                        showConfirmButton: false,
                        showCancelButton: true,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                        // confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: "OK"
                    }).then((result) => {
                        console.log('pop up result', result);
                        if (result.isConfirmed) {
                            // updateMappings();
                        }
                    });
                }, 3000);
            }
        }).catch(err => {
            // toastr.error("Failed to add asset")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to add asset",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    const editAsset = (autoSave = false) => {
        axios.post(`/properties/${editingId}`, { type, subType, asset: assetData }).then(response => {
            //   toastr.success("Asset updated successfully");
            if (autoSave === false) {
                Swal.fire({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Asset updated successfully",
                    showConfirmButton: false,
                    timer: 3000
                });
                setEdit(false);
                setOpen(false);
                setEditingId(null);
            }
            fetchAssets();
        }).catch(err => {
            // toastr.error("Failed to update asset");
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to update asset",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    const deleteAsset = (id) => {
        axios.delete(`/properties/${id}`).then(response => {
            // toastr.success("Asset deleted successfully");
            Swal.fire({
                position: "top-center",
                icon: "success",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Asset deleted successfully",
                showConfirmButton: false,
                timer: 3000
            });
            fetchAssets();
            setAssetData({});
        }).catch(err => {
            // toastr.error("Failed to update asset")
            Swal.fire({
                position: "top-center",
                icon: "error",
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: "Failed to delete asset",
                showConfirmButton: false,
                timer: 3000
            });
        });
    };

    useEffect(() => {
        fetchAssets();
        fetchBeneficiaries();
        fetchForms();
    }, []);

    const handleOpenMenu = (event, row) => {
        setOpen(event.currentTarget);
        setType(row.type);
        setSubType(row.sub_type);
        setAssetData(row.information);
        setPreviousAssetData(row.information);

        setEditingId(row.id);
    };

    const handleCloseMenu = () => {
        setPreviousAsset();
        setOpen(null);
        setAssetData({});
        setType("");
        setSubType("");
        setEditingId(null);
        setEdit(false);
    };

    const setPreviousAsset = () => {
        if (editingId) {
            console.log('setPreviousAsset', assetData);
            const previousAssetData = JSON.parse(sessionStorage.getItem(`previousAssetData`));
            console.log('setPreviousAsset', previousAssetData);
            axios.post(`/properties/${editingId}`, { type, subType, asset: previousAssetData }).then(response => {
                //   toastr.success("Asset updated successfully");
                sessionStorage.removeItem(`previousAssetData`);
                fetchAssets();
            }).catch(err => {
                // toastr.error("Failed to update asset");
                // Swal.fire({
                //     position: "top-center",
                //     icon: "error",
                //     color: '#fff',
                //     background: '#00838F',
                //     toast: true,
                //     title: "Failed to update asset",
                //     showConfirmButton: false,
                //     timer: 3000
                // });
            });
        }

    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = assets.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - assets.length) : 0;

    const filteredUsers = applySortFilter(assets, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const steps = [
        {
            target: "#new-generic",
            content: `Click to add new asset`
        },
        {
            target: "body",
            content: `Fill in the data for asset`
        },
        {
            target: "#new-generic-button",
            content: "Click the save button"
        }
    ];
    const handleJoyrideCallback = (data) => {
        const { index, action, size } = data;
        if (action === "next" && index === 0) {
            setAddNew(true);
        } else if (action === "next" && index === size - 1) {
            setAddNew(false);
        }
    };

    const schema = useMemo(() => {
        const _schema = { ...(schemas.schemas?.[type]?.[subType] ?? {}) };
        if (_schema?.properties?.nominee && beneficiaries?.length > 0) {
            _schema.properties.nominee.dummyEnum = beneficiaries.map(bene => bene.name);
        }
        console.log('joint_holder_name', _schema.properties);
        if (_schema?.properties?.joint_holder_name && beneficiaries?.length > 0) {
            if (beneficiaries.length > 0)
                _schema.properties.joint_holder_name.dummyEnum = ["None", ...beneficiaries.map(bene => bene.name)];
            else
                _schema.properties.joint_holder_name.dummyEnum = ["None"];
        }
        if (_schema?.properties?.joint_owner_name && beneficiaries?.length > 0) {
            _schema.properties.joint_owner_name.dummyEnum = beneficiaries.map(bene => bene.name);
        }
        return _schema;
    }, [type, subType, schemas]);

    //   useEffect(() => {
    //     const handleEnter = (e) => {
    //       if (e.code?.toLowerCase() === "enter" && !e.repeat) {
    //         e.preventDefault();
    //         if (errors?.length === 0) {
    //           if (editingId) editAsset();
    //           else addNewAsset();
    //         }
    //       }
    //     };
    //     window.addEventListener("keyup", handleEnter);
    //     return () => {
    //       window.removeEventListener("keyup", handleEnter);
    //     };
    //   }, [editingId, errors]);

    const { isLoading, setShowHelp, editProfile } = useContext(LoadingContext);

    useEffect(() => {
        if (!sessionStorage.getItem(`video_close_assets`) && !isLoading && assets.length === 0) {
            setShowHelp(true);
        } else {
            setShowHelp(false);
        }
    }, [isLoading, isLoading, assets]);

    useEffect(() => {
        console.log('useEffect editgeneric', editingId);
        if (editingId) {

            // const saveToSessionStorage = () => {
            if (type !== "" && subType !== "" && errors.length === 0) {
                editAsset(true);
            }
            // };
            // // Set up auto-save interval
            // const autoSaveInterval = setInterval(saveToSessionStorage, 5000);

            // // Clean up interval on component unmount
            // return () => clearInterval(autoSaveInterval);
        }

        // return () => { };
    }, [assetData, editingId]);

    return (
        <>
            <Helmet>
                <title> Assets | Get Will Done </title>
            </Helmet>

            <Container fullWidth style={{ maxWidth: "unset" }}>
                {/* <Joyride callback={handleJoyrideCallback} run={assets.length === 0} continuous showProgress showSkipButton
                 steps={steps}
                 styles={{ options: { zIndex: 10000 } }} /> */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Assets
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    </div>
                    <Tooltip title={`New Asset`}>
                        <Button id={"new-generic"} onClick={() => {
                            handleCloseMenu(); setAddNew(true)
                        }} variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            New Asset
                        </Button>
                    </Tooltip>
                </Stack>
                <CenterModal
                    open={addNew}
                    title={"New Asset"}
                    onClose={() => {
                        setAddNew(false);
                        setEdit(false);
                        setType("");
                        setSubType("");
                        setAssetData({});
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ m: -1, p: 3, bgcolor: "white" }}>
                        <Stack direction={"row"} spacing={1} alignItems="center">
                            <FormControl size={"small"} sx={{ minWidth: 200, mb: 1, mt: 1 }}>
                                <InputLabel id="demo-simple-select-label">Asset type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Asset type"
                                    onChange={(e) => {
                                        setType(e.target.value ?? "");
                                        setSubType("");
                                        setAssetData({});
                                    }}
                                >
                                    {reorderedArray && reorderedArray.map(asset => <MenuItem
                                        value={asset.label} key={asset.label}>{getCorrectCase(asset.label)}</MenuItem>)}
                                    {/* {Object.keys(schemas?.schemas ?? {}).map(key => <MenuItem
                    value={key} key={key}>{getCorrectCase(key)}</MenuItem>)} */}
                                </Select>
                            </FormControl>
                            <FormControl size={"small"} sx={{ minWidth: 200, mb: 1, mt: 1 }}>
                                <InputLabel id="demo-simple-select-label">Asset Sub type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subType}
                                    label="Asset sub type"
                                    onChange={(e) => {
                                        setSubType(e.target.value ?? "");
                                        setAssetData({});
                                    }}
                                >
                                    {Object.keys(schemas?.schemas?.[type] ?? {}).map(key => <MenuItem
                                        value={key} key={key}>{getCorrectCase(key)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Stack>
                        {type !== "" && subType !== "" && <JsonForms
                            i18n={{ translate: translator('') }}
                            schema={schema}
                            uischema={schemas.ui_schemas?.[type]?.[subType]}
                            data={assetData}
                            renderers={[...materialRenderers, {
                                tester: OneOfTester,
                                renderer: OneOfControl
                            }, { tester: ImagePickerTester, renderer: ImagePickerControl }]}
                            cells={materialCells}
                            onChange={({ data, errors }) => {

                                // console.log('assets',assets);
                                // console.log(assets,data);
                                const exists = assets.some(asset => {
                                    return (
                                        asset.id !== editingId &&
                                        asset.type === type &&
                                        asset.sub_type === subType &&
                                        schema.required.every(field => asset.information[field] === data[field])
                                    );
                                });
                                // console.log('exists', exists);
                                if (exists) {
                                    // console.log(schema);
                                    // console.log(schemas.ui_schemas?.[type]?.[subType]);
                                    schema.required.forEach(property => {
                                        // console.log('add error', property);
                                        errors.push({
                                            // AJV style path to the property in the schema
                                            "instancePath": `/${property}`,
                                            // message to display
                                            message: `Already exists`,
                                            "schemaPath": `#/properties/${property}`,
                                            keyword: '',
                                            params: {},
                                        });
                                    });

                                }
                                console.log('assets', data, data.no_of_shares === 0);
                                if (data?.no_of_shares === 0) {
                                    console.log('asset if');
                                    errors.push({
                                        // AJV style path to the property in the schema
                                        instancePath: '/no_of_shares',
                                        // message to display
                                        message: `Must be greater than 0`,
                                        "schemaPath": `#/properties/no_of_shares`,
                                        keyword: '',
                                        params: {},
                                    });
                                }
                                if (data?.quantity === 0) {
                                    console.log('asset if');
                                    errors.push({
                                        // AJV style path to the property in the schema
                                        instancePath: '/quantity',
                                        // message to display
                                        message: `Must be greater than 0`,
                                        "schemaPath": `#/properties/quantity`,
                                        keyword: '',
                                        params: {},
                                    });

                                }
                                if (data?.number === 0) {
                                    console.log('asset if');
                                    errors.push({
                                        // AJV style path to the property in the schema
                                        instancePath: '/number',
                                        // message to display
                                        message: `Must be greater than 0`,
                                        "schemaPath": `#/properties/number`,
                                        keyword: '',
                                        params: {},
                                    });
                                }
                                console.log('errors', errors);
                                setAssetData(data);
                                setErrors(errors);
                            }}
                        />}
                    </Box>
                    <Stack sx={{ bgcolor: '#BDBDBD', m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            setAddNew(false);
                            setEdit(false);
                            setType("");
                            setSubType("");
                            setAssetData({});
                        }}>Cancel</Button>
                        <Button id={"new-generic-button"} disabled={type === "" || subType === "" || errors.length > 0}
                            onClick={() => {
                                addNewAsset();
                            }}
                            variant="contained" startIcon={<Iconify icon="eva:save-fill" />}>
                            Save
                        </Button>
                    </Stack>
                </CenterModal>

                <Card>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={assets.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { id, type, sub_type: subType, information } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;

                                        return (<>
                                            <TableRow
                                                sx={{ bgcolor: (theme) => editingId === id ? "rgba(255, 156, 65, 0.18)" : "initial" }}
                                                key={id} tabIndex={-1}>
                                                <TableCell align="right">
                                                    <IconButton onClick={(e) => {
                                                        if (editingId === id) {
                                                            handleCloseMenu();
                                                        } else {
                                                            handleOpenMenu(e, row);
                                                        }
                                                    }}><Iconify icon={editingId === id ? "uil:angle-down" : "uil:angle-right"} /></IconButton>
                                                </TableCell>

                                                <TableCell align="left">{getCorrectCase(type)} &gt; {getCorrectCase(subType ?? "")}</TableCell>
                                                <TableCell align="left">{[0, 1].includes(Object.keys(information ?? {}).indexOf("add_picture")) ? "-" : (Object.keys(information ?? {}).includes("maturity_date") && Object.keys(information)[0] === 'maturity_date') ? (information.maturity_date !== null && dayjs(information.maturity_date).format('DD-MM-YYYY')) : Object.values(information ?? {})?.[0]}</TableCell>
                                                <TableCell align="left">{Object.keys(information ?? {}).includes("add_picture") ? (information?.add_picture ? "Expand to see image" : "-") : (Object.keys(information ?? {}).includes("maturity_date") && Object.keys(information)[1] === 'maturity_date') ? (information.maturity_date !== null && dayjs(information.maturity_date).format('DD-MM-YYYY')) : Object.values(information ?? {})?.[1] ?? "-"}</TableCell>
                                                {/* <TableCell align="left">{information?.nominee ?? "-"}</TableCell> */}

                                                <TableCell>
                                                    {<>
                                                        <ButtonGroup>
                                                            <Tooltip title={`Edit Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    handleOpenMenu(e, row);
                                                                    setEdit(true);
                                                                }}>
                                                                    <Iconify icon={"fluent:edit-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={`Delete Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: (`Do you want to delete the asset?`),
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        allowEnterKey: false,
                                                                        allowOutsideClick: false,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            deleteAsset(id);
                                                                        }
                                                                    });
                                                                }}>
                                                                    <Iconify icon={"fluent:delete-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                            {editingId === id && <TableRow
                                                sx={{ bgcolor: (theme) => editingId === id ? "rgba(255, 156, 65, 0.18)" : "initial" }}>
                                                <TableCell
                                                    colSpan={6}>
                                                    <Box sx={{ width: "100%", bgcolor: "white", p: 2 }}>
                                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                                            <FormControl size={"small"} sx={{ minWidth: 200, mb: 1, mt: 1 }}>
                                                                <InputLabel id="demo-simple-select-label">Asset type</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={type}
                                                                    label="Asset type"
                                                                    disabled
                                                                    onChange={(e) => {
                                                                        setType(e.target.value ?? "");
                                                                        setSubType("");
                                                                    }}
                                                                >
                                                                    {Object.keys(schemas?.schemas ?? {}).map(key => <MenuItem
                                                                        value={key} key={key}>{getCorrectCase(key)}</MenuItem>)}
                                                                </Select>
                                                            </FormControl>
                                                            <FormControl size={"small"} sx={{ minWidth: 200, mb: 1, mt: 1 }}>
                                                                <InputLabel id="demo-simple-select-label">Asset Sub type</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={subType}
                                                                    label="Asset sub type"
                                                                    disabled
                                                                    onChange={(e) => setSubType(e.target.value ?? "")}
                                                                >
                                                                    {Object.keys(schemas?.schemas?.[type] ?? {}).map(key => <MenuItem
                                                                        value={key} key={key}>{getCorrectCase(key)}</MenuItem>)}
                                                                </Select>
                                                            </FormControl>
                                                        </Stack>
                                                        {type !== "" && subType !== "" && <JsonForms
                                                            i18n={{ translate: translator('') }}
                                                            readonly={!edit}
                                                            schema={schema}
                                                            uischema={schemas.ui_schemas?.[type]?.[subType]}
                                                            data={assetData}
                                                            renderers={[...materialRenderers, {
                                                                tester: OneOfTester,
                                                                renderer: OneOfControl
                                                            }, { tester: ImagePickerTester, renderer: ImagePickerControl }]}
                                                            cells={materialCells}
                                                            onChange={({ data, errors }) => {
                                                                //   console.log('assets', assets);
                                                                //   console.log(assets, data);
                                                                const exists = assets.some(asset => {
                                                                    return (
                                                                        asset.id !== editingId &&
                                                                        asset.type === type &&
                                                                        asset.sub_type === subType &&
                                                                        schema.required.every(field => asset.information[field] === data[field])
                                                                    );
                                                                });
                                                                //   console.log('exists', exists);
                                                                if (exists) {
                                                                    //   console.log(schema);
                                                                    //   console.log(schemas.ui_schemas?.[type]?.[subType]);
                                                                    schema.required.forEach(property => {
                                                                        //   console.log('add error', property);
                                                                        errors.push({
                                                                            // AJV style path to the property in the schema
                                                                            "instancePath": `/${property}`,
                                                                            // message to display
                                                                            message: `Already exists`,
                                                                            "schemaPath": `#/properties/${property}`,
                                                                            keyword: '',
                                                                            params: {},
                                                                        });
                                                                    });
                                                                }
                                                                console.log('assets', data, data.no_of_shares === 0);
                                                                if (data?.no_of_shares === 0) {
                                                                    console.log('asset if');
                                                                    errors.push({
                                                                        // AJV style path to the property in the schema
                                                                        instancePath: '/no_of_shares',
                                                                        // message to display
                                                                        message: `Must be greater than 0`,
                                                                        "schemaPath": `#/properties/no_of_shares`,
                                                                        keyword: '',
                                                                        params: {},
                                                                    });
                                                                }
                                                                if (data?.quantity === 0) {
                                                                    console.log('asset if');
                                                                    errors.push({
                                                                        // AJV style path to the property in the schema
                                                                        instancePath: '/quantity',
                                                                        // message to display
                                                                        message: `Must be greater than 0`,
                                                                        "schemaPath": `#/properties/quantity`,
                                                                        keyword: '',
                                                                        params: {},
                                                                    });
                                                                }
                                                                if (data?.number === 0) {
                                                                    console.log('asset if');
                                                                    errors.push({
                                                                        // AJV style path to the property in the schema
                                                                        instancePath: '/number',
                                                                        // message to display
                                                                        message: `Must be greater than 0`,
                                                                        "schemaPath": `#/properties/number`,
                                                                        keyword: '',
                                                                        params: {},
                                                                    });
                                                                }
                                                                if (editingId) {
                                                                    console.log('genericDatachange', previousAssetData);
                                                                    // const previousGenericData = {...genericData};
                                                                    sessionStorage.setItem(`previousAssetData`, JSON.stringify(previousAssetData));
                                                                }
                                                                setAssetData(data);
                                                                setErrors(errors);
                                                            }}
                                                        />}
                                                        <Stack direction="row" justifyContent={"space-between"} spacing={1}>
                                                            <Button style={{ marginTop: "8px" }} onClick={() => {
                                                                handleCloseMenu();
                                                            }}
                                                                variant="text">
                                                                Cancel
                                                            </Button>
                                                            {edit && <Button disabled={type === "" || subType === "" || errors.length > 0}
                                                                style={{ marginTop: "8px" }} onClick={() => {
                                                                    editAsset();
                                                                }}
                                                                variant="contained">
                                                                Save
                                                            </Button>}
                                                        </Stack>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>}
                                        </>);
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25,50,100]}
                        component="div"
                        count={assets.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

        </>
    );
}

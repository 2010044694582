import { Box, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { UserListHead } from '../../sections/@dashboard/user';
import { fCurrencyRupee } from '../../utils/formatNumber';

// Define a mapper for the service keys to labels
const serviceMapping = {
  Make_your_own_Will: 'Make your own Will',
  Call_with_Legal_Expert: 'Call with Legal Expert',
  // Notary_at_Doorstep: 'Notary at Doorstep',
  // Register_your_Will: 'Register your Will',
  Review_by_Lawyer: 'Review by Lawyer',
};

// List of all possible services
const allServices = Object.keys(serviceMapping);

const ServiceWiseRevenue = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [serviceRevenueData, setServiceRevenueData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const response = await axios.get('/admin/service-wise-revenue');
        const { data } = response;

        let completeData = [];

        // Check if the data is in the new format (object) or old format (array)
        if (Array.isArray(data)) {
          // Old format: convert to the new format
          completeData = allServices.map((service) => {
            const serviceData = data.find((item) => item.name === serviceMapping[service]);
            return {
              name: serviceMapping[service],
              totalRevenue: serviceData ? parseFloat(serviceData.totalRevenue) : 0,
            };
          });
        } else {
          // New format: process as received
          completeData = allServices.map((service) => ({
            name: serviceMapping[service],
            totalRevenue: data[service] ? parseFloat(data[service]) : 0,
          }));
        }

        setServiceRevenueData(completeData);
      } catch (error) {
        console.error('Error fetching revenue data:', error);
      }
    };

    fetchRevenueData();
  }, []);

  return (
    <Card sx={{ padding: '16px' }} id="service-wise-revenue-container">
      <Typography variant="h5">Service Wise Revenue</Typography>
      <Box sx={{ margin: '16px 0', overflow: 'auto' }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={serviceRevenueData.map((item) => ({
                id: item.name.toLowerCase().replaceAll(' ', '-'),
                label: item.name,
                alignRight: false,
              }))}
              rowCount={1}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              <TableRow>
                {serviceRevenueData.map((item) => (
                  <TableCell key={item.name}>{fCurrencyRupee(item.totalRevenue)}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default ServiceWiseRevenue;

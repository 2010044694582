import DiscountIcon from '@mui/icons-material/Discount';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
// component
import {
    AccountTree, Apartment,
    Build,
    Dashboard,
    Gavel,
    Group,
    House,
    HowToReg, Money,
    Person, Preview,
    Verified,
    VerifiedUser
} from "@mui/icons-material";
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    {
        title: 'Partner Dashboard',
        path: '/dashboard/partnerdashboard',
        role: ['partner'],
        icon: <GroupsIcon color={'primary'} />,
    },
    {
        title: 'profile',
        path: '/dashboard/app',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Dashboard.png"} alt={""} />,
    },
    {
        title: 'beneficiaries',
        path: '/dashboard/beneficiaries',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Beneficiaries.png"} alt={""} />,
    },
    {
        title: 'assets',
        path: '/dashboard/assets',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Assets.png"} alt={""} />,
    },
    {
        title: 'distribution',
        path: '/dashboard/distribution',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Distribution.png"} alt={""} />
    },
    {
        title: 'executors',
        path: '/dashboard/executors',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Executors.png"} alt={""} />
    },
    {
        title: 'witnesses',
        path: '/dashboard/witnesses',
        role: ['testator', 'partner'],
        icon: <img src={"/assets/icons/Witnesses.png"} alt={""} />
    },
    {
        title: 'Services',
        path: '/dashboard/subscriptions',
        role: ['testator', 'partner'],
        icon: <Money color={'primary'} />
    },
    {
        title: 'Generate Will',
        path: '/dashboard/preview',
        role: ['testator', 'partner'],
        icon: <Preview color={'primary'} />
    },
    // {
    //     title: 'Will Data Checklist',
    //     path: '/dashboard/datachecklist',
    //     role: ['testator', 'partner'],
    //     icon: <Preview color={'primary'} />
    // },
    {
        title: 'dashboard',
        path: '/reviewer/app',
        role: ['reviewer'],
        icon: <img src={"/assets/icons/Dashboard.png"} alt={""} />
    },
    {
        title: 'Admin Dashboard',
        path: '/admin/analyticaldashboard',
        role: ['admin'],
        icon: <AdminPanelSettingsIcon color={'primary'} />
    },
    {
        title: 'Coupons',
        path: '/admin/coupons',
        role: ['admin'],
        icon: <DiscountIcon color={'primary'} />
    },
    {
        title: 'Advisors/Partners',
        path: '/admin/partners',
        role: ['admin'],
        icon: <Diversity3Icon color={'primary'} />
    },
    {
        title: 'Reports',
        path: '/admin/reports',
        role: ['admin'],
        icon: <AssessmentIcon color={'primary'} />
    },
    {
        title: 'Commission',
        path: '/dashboard/commission',
        role: ['partner'],
        icon: <AssessmentIcon color={'primary'} />
    },
    // {
    //   title: 'assets',
    //   path: '/dashboard/assets',
    //   role: 'reviewer',
    //   icon: <Apartment />,
    // },
    // {
    //   title: 'beneficiaries',
    //   path: '/dashboard/beneficiaries',
    //   role: 'reviewer',
    //   icon: <Group />,
    // },
    // {
    //   title: 'executors',
    //   path: '/dashboard/executors',
    //   role: 'reviewer',
    //   icon: <Gavel />,
    // },
    // {
    //   title: 'witnesses',
    //   path: '/dashboard/witnesses',
    //   role: 'reviewer',
    //   icon: <HowToReg />,
    // },
    // {
    //   title: 'distribution',
    //   path: '/dashboard/distribution',
    //   role: 'reviewer',
    //   icon: <AccountTree />,
    // },
    // {
    //   title: 'preview and finalise',
    //   path: '/dashboard/preview',
    //   icon: <Preview />,
    // },
    // {
    //   title: 'user',
    //   path: '/dashboard/user',
    //   icon: icon('ic_user'),
    // },
    // {
    //   title: 'product',
    //   path: '/dashboard/products',
    //   icon: icon('ic_cart'),
    // },
    // {
    //   title: 'blog',
    //   path: '/dashboard/blog',
    //   icon: icon('ic_blog'),
    // },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: icon('ic_lock'),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: icon('ic_disabled'),
    // },
];

export default navConfig;

import { useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Close, Fullscreen, FullscreenExit } from "@mui/icons-material";
import { Box, ButtonGroup, Checkbox, FormControlLabel, Modal, Stack } from "@mui/material";
import { NAV_WIDTH } from "../layouts/dashboard/nav";
import Scrollbar from "./scrollbar";

const Transition = forwardRef((
  props,
  ref
) => <Slide direction="up" ref={ref} {...props} />);

const style = {
  position: "absolute",
  top: "50%",
  right: (theme) => theme.spacing(1),
  left: NAV_WIDTH,
  transform: "translateY(-50%)",
  background: (theme) => `linear-gradient(${theme.palette.secondary.main} 0 calc(100% - 8px), #BDBDBD calc(100% - 8px) 100%), linear-gradient(white, white)`,
  color: "white",
  border: "none",
  // borderTop: "5px solid",
  // borderTopColor: (theme) => theme.palette.primary.main,
  borderRadius: (theme) => theme.spacing(1),
  boxShadow: 24,
  p: 1,
  pb: 2,
  "&.centered": {
    top: "50%",
    left: "50%",
    width: "fit-content",
    transform: "translate(-50%, -50%)"
  },
  "&.fullwidth": {
    top: "50%",
    left: "50%",
    width: "90%",
    transform: "translate(-50%, -50%)"
  },
  "&.fullscreen": {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: "none",
    height: "100vh"
  }
};

export default function CenterModal({
                                      open,
                                      onSubmit,
                                      onReset,
                                      onClose,
                                      schema,
                                      data,
                                      onChange,
                                      button,
                                      title,
                                      children,
                                      centered,
                                      fullwidth,
                                      zIndex,
                                      ...props
                                    }) {
  const handleClose = (ev, reason) => {
    if (reason && reason === "backdropClick")
      return;
    onClose();
  };

  const [fullscreen, setFullscreen] = useState(false);

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
          sx={{ zIndex: zIndex || '' }}
    >
      <Box sx={style} className={fullscreen ? "fullscreen" : (centered ? "centered" : fullwidth ? "fullwidth" : "")}>
        {title && <Typography sx={{ mb: 1, p: 1 }} id="modal-modal-title" variant="h6"
                              component="h2">
          {title} <ButtonGroup style={{ float: "right" }}>
          <IconButton onClick={() => setFullscreen(!fullscreen)}>{fullscreen ? <FullscreenExit /> :
            <Fullscreen />}</IconButton>
          {onClose && <IconButton onClick={handleClose}><Close /></IconButton>}
        </ButtonGroup>
        </Typography>}
        <Box sx={{ color: "black", maxHeight: "calc(100vh - 100px)", width: 'calc(100% + 16px)', m: -1, pr: 1, overflow: 'auto'}}>
            {children}
        </Box>
      </Box>
    </Modal>
  );
}
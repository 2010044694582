import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const CompleteWillsCard = () => {
  const [completeWillData, setCompleteWillData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGstPaidData = async () => {
      try {
        const response = await axios.get('/admin/complete-wills-count');
        const { completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount } = response.data;
        setCompleteWillData({ completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount });
      } catch (error) {
        console.error('Error fetching complete wills data:', error);
      }
    };

    fetchGstPaidData();
  }, []);

  const handleClick = () => {
    navigate('/admin/complete-wills-page');
  };

  return (
    <DashboardCard
      title="Total Completed Wills(Individual v/s Partner)"
      subtitle={`${completeWillData?.completeWillsCount ?? '--'} (${
        completeWillData?.testatorCompleteWillsCount ?? '--'
      } / ${completeWillData?.partnerCompleteWillsCount ?? '--'})`}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default CompleteWillsCard;

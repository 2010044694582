import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';

const RepeatRateCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRepeatRateData = async () => {
      try {
        const response = await axios.get('/admin/repeat-rate');
        const { repeatRate } = response.data;
        setRepeatRateData({ repeatRate });
      } catch (error) {
        console.error('Error fetching repeat rate data:', error);
      }
    };

    fetchRepeatRateData();
  }, []);

  const handleClick = () => {
    navigate('/admin/repeat-rate-page');
  };

  return (
    <DashboardCard
      title="Repeat Rate of Customer"
      subtitle={(repeatRateData.repeatRate ?? '--') || 0}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default RepeatRateCard;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './common/DashboardCard';
import { fCurrencyRupee } from '../../utils/formatNumber';

const TotalCommissionPaidCard = () => {
  const [commissionPaidData, setCommissionPaidData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommissionPaidData = async () => {
      try {
        const response = await axios.get('/admin/total-commission');
        const { totalCommission } = response.data;
        setCommissionPaidData({ totalCommission });
      } catch (error) {
        console.error('Error fetching total commission paid data:', error);
      }
    };

    fetchCommissionPaidData();
  }, []);

  const handleClick = () => {
    navigate('/admin/commission-details-page', { state: { fromAdminDashboard: true } });
  };

  return (
    <DashboardCard
      title="Total Commission Paid"
      subtitle={fCurrencyRupee(commissionPaidData.totalCommission || 0)}
      handleClick={handleClick}
      isClickable
    />
  );
};

export default TotalCommissionPaidCard;

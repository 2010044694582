import { Box, Card, Typography } from '@mui/material';
import { noop } from 'lodash';
import { NavLink as RouterLink } from 'react-router-dom';

const DashboardCard = ({ title = '', subtitle = '', sx = {}, handleClick = noop, path = '', isClickable = false }) => (
  <Card
    onClick={handleClick}
    sx={{
      p: '24px',
      m: '16px',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      justifyContent: 'center',
      '&:hover': {
        // Styles for hover effect
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transform: 'scale(1.01)',
        transition: 'box-shadow 0.3s, transform 0.3s',
      },
      cursor: isClickable ? 'pointer' : 'default',
      ...sx,
    }}
  >
    <Typography variant="h5" color="primary">
      {title}
    </Typography>
    {/* <Box component={RouterLink} to={path}> */}
    <Box>
      <Typography variant="h6">{subtitle}</Typography>
    </Box>
  </Card>
);

export default DashboardCard;

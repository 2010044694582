// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme, isGrayedOut }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
    color: isGrayedOut ? theme.palette.text.disabled : theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledNavItemIcon = styled(ListItemIcon)(({ theme, isGrayedOut }) => ({
  width: 22,
  height: 22,
    color: isGrayedOut ? theme.palette.text.disabled : 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

import { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardCard from './common/DashboardCard';

const AverageTimeCard = () => {
  const [averageTimeData, setAverageTimeData] = useState({});

  useEffect(() => {
    const fetchAverageTimeData = async () => {
      try {
        const response = await axios.get('/admin/AvgTimeOfWillGeneration');
        const { averageTimeOfWillGeneration } = response.data;
        setAverageTimeData({ averageTimeOfWillGeneration });
      } catch (error) {
        console.error('Error fetching average time data:', error);
      }
    };

    fetchAverageTimeData();
  }, []);

  return (
    <DashboardCard
      title="Average Time To Complete Will"
      subtitle={(averageTimeData.averageTimeOfWillGeneration ?? '--') || 0}
    />
  );
};

export default AverageTimeCard;
